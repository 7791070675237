import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AcuerdoPagoDetalleExtendedModel } from '../../../../../core/models/configuracion-basica/acuerdo-pago-desceunto-detalle-extended.model';
import { SpinnerService } from '../../../../../core/services/spinner.service';
import { AcuerdoPagoDescuentoDetalleServiceImpl } from '../../../../../core/http/configuracion-basica/impl/acuerdo-pago-descuento-detalle.service.impl';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ef-gestion-descuentos-config-detail',
  templateUrl: './gestion-descuentos-config-detail.component.html',
  styleUrls: ['./gestion-descuentos-config-detail.component.scss']
})
export class GestionDescuentosConfigDetailComponent {

  loading = false;
  descuentoDetalle: any;
  acuerdoPagoDetalleExtended: AcuerdoPagoDetalleExtendedModel;
  mensajeError = false;

  constructor(
    public acuerdoPagoDetalleService: AcuerdoPagoDescuentoDetalleServiceImpl,
    public toast: ToastrService,
    public dialogRef: MatDialogRef<GestionDescuentosConfigDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.descuentoDetalle = data;
  }

}
