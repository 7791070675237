import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { GestionPasosInterface } from '../gestion-pasos.service.interface';
import ProcesoModel from 'src/app/core/models/configuracion-solicitud-credito/proceso.models';
import ProcesoPaso from 'src/app/core/models/configuracion-solicitud-credito/proceso-paso.model';

@Injectable({
  providedIn: 'root',
})
export class GestionPasoServiceImpl
  extends ServiceImplService<ProcesoPaso>
  implements GestionPasosInterface
{
  constructor(private http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'paso/';
  }
 

	getOrCreateDefaultPaso(proceso: ProcesoModel) {
		const path = `default`;
		const data = { ...proceso };
		return this.post(data, path);
	}
}
