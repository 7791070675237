import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AcuerdoPagoServiceImpl } from 'src/app/core/http/acuerdo-pago/Impl/acuerdo-pago.service.impl';
import { ToastrService } from 'ngx-toastr';
import { MatSelectChange } from '@angular/material/select';
import { tipoRechazoConst } from 'src/app/core/constants/tipo-rechazo-acuerdo-pago.constants';

@Component({
	selector: 'ef-acuerdo-pago-rechazar',
	templateUrl: './acuerdo-pago-rechazar.component.html',
	styleUrls: [ './acuerdo-pago-rechazar.component.scss' ]
})
export class AcuerdoPagoRechazarComponent implements OnInit {
	acuerdoPagoId: number;
	observacion: string;
	tipoRechazo: string;
	estadoAcuerdoPago: string;
	contratoAcademico: number;
	contratoEdufactoring: any;
	nombreTitular: any;
	valorFinanciado: any;

	constructor(
		public dialogRef: MatDialogRef<AcuerdoPagoRechazarComponent>,
		public acuerdoPagoService: AcuerdoPagoServiceImpl,
		public toast: ToastrService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.acuerdoPagoId = data.id;
		this.estadoAcuerdoPago = data.estado;
		this.contratoAcademico = data.contratoAcademico;
		this.contratoEdufactoring = data.contratoEdufactoring;
		this.nombreTitular = data.nombreTitular;
		this.valorFinanciado = data.valorFinanciado;
	}

	ngOnInit(): void {}

	rechazarAcuerdoPago() {

		var data = {
			observacion: this.observacion,
			tipoRechazo: this.tipoRechazo,
			estadoAcuerdoPago: this.estadoAcuerdoPago,
			contratoAcademico: this.contratoAcademico
		};
		if (this.acuerdoPagoId == null) {
			this.toast.error('No se puede rechazar', 'Faltan Datos Importantes');
			return;
		}
		this.acuerdoPagoService.rechazarAcuerdoPago(this.acuerdoPagoId, data).subscribe(
			() => {
				;
				this.dialogRef.close(false);
				this.toast.success('El acuerdo de pago ha sido rechazado');
			},
			() => {
				;
				this.toast.error('Ocurrio un error al rechazar acuerdo de pago', 'Datos Incompletos');
			}
		);
	}

	getTipoRechazo(): Array<string> {
		const tipoRechazo = new Array<string>();
		for (const tipo in tipoRechazoConst) {
			tipoRechazo.push(tipoRechazoConst[tipo]);
		}
		return tipoRechazo;
	}

	selectTipoRechazo(event: MatSelectChange) {
		this.tipoRechazo = event.value;
	}
}
