import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SolicitudcreditoDocumentoServiceImpl } from '../../../core/http/solicitud-credito/impl/solicitud-credito-documento.service.impl';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileHelper } from 'src/app/core/helpers/file.helper';
import { TipoDocumentoServiceImpl } from '../../../core/http/configuracion-solicitud-credito/impl/tipo-documento.service.impl';
import { Auth0ServiceImpl } from 'src/app/core/http/security/impl/auth0.service.impl';

@Component({
  selector: 'ef-solicitud-credito-documentos-add',
  templateUrl: './solicitud-credito-documentos-add.component.html',
  styleUrls: ['./solicitud-credito-documentos-add.component.scss']
})
export class SolicitudCreditoDocumentosAddComponent implements OnInit {

  tipos: Array<any> = [];
  formAddFiles: FormGroup;
  files: FormArray;
  solicitudId: any;
  permission: any;
  isFirmaManual: boolean;
  tipoDocumento: string;

  constructor(public router: Router,
    public toast: ToastrService,

    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SolicitudCreditoDocumentosAddComponent>,
    public solicitudCreditoDocumentoService: SolicitudcreditoDocumentoServiceImpl,
    public tipoDocumentoService: TipoDocumentoServiceImpl,
    public authenticationService: Auth0ServiceImpl,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.solicitudId = data.solicitudId;
    this.isFirmaManual = data.isFirmaManual;
    this.tipoDocumento = data.tipoDocumento;
  }

  ngOnInit(): void {
    this.getTipos();
    this.formAddFiles = this.formBuilder.group({
      files: this.formBuilder.array([])
    });
    this.addItem();
  }

  onFormSubmit() {
    let documents = new Array();
    this.files.getRawValue().forEach((item) => {
      let insert = {
        tipoDocumentoId: item.tipoDocumento.id,
        descripcion: item.descripcion,
        nombre: item.tipoDocumento.nombre,
        extension: 'pdf',
        base64: String(item.base64).split('data:application/pdf;base64,')[1],
        kbytes: this.calculateImageSize(item.base64)
      };
      documents.push(insert);
    });


    let insertRequest = {
      solicitudCreditoId: this.solicitudId,
      documents: documents
    };

    this.solicitudCreditoDocumentoService.saveMasivo(insertRequest)
      .subscribe(
        {
          next: (res: any) => {
            let count = 0;
            res.data.forEach((item) => {
              if (!item.idFile) {
                this.toast.error('No fue posible cargar el documento ' + item.tipoDocumento, 'Error');
                count++;
              }
            });

            if (count == 0) {
              this.toast.success('Todos los archivos fueron cargados exitosamente');
            }
            this.dialogRef.close(true);
          },
          error: (err) => {
            ;
            if (err.error.errors) {
              for (const error of err.error.errors) {
                this.toast.error(error.message, 'Error');
              }
            } else {
              if (err.error.message) {
                this.toast.error(err.error.message, 'Error');
              }
            }
          }
        });

  }


  calculateImageSize(base64): any {
    let padding, inBytes, base64Length;
    if (base64.endsWith('==')) {
      padding = 2;
    } else if (base64.endsWith('=')) {
      padding = 1;
    } else {
      padding = 0;
    }
    base64Length = base64.length;
    inBytes = (base64Length / 4) * 3 - padding;
    let kbytes = inBytes / 1000;
    return kbytes;
  }

  addItem() {
    this.files = this.formAddFiles.get('files') as FormArray;
    this.files.push(this.createItem());
  }

  createItem() {
    return this.formBuilder.group({
      tipoDocumento: [null, [Validators.required]],
      base64: [null, [Validators.required]],
      descripcion: [null, [Validators.required]],
    });
  }

  remove(index) {
    this.files.removeAt(index);
  }

  getTipos() {
    this.tipoDocumentoService.filter({ estado: 'ACTIVO' })
      .subscribe(
        {
          next: (res) => {
            res.data.forEach(element => {
              if (this.isFirmaManual) {
                if (this.authenticationService.hasAccess(`solicitud-cargar-documento:${element.nombre.replace(/\s/g, '_')}`) && element.nombre == this.tipoDocumento) {
                  this.tipos.push(element);
                }
              } else {
                if (this.authenticationService.hasAccess(`solicitud-cargar-documento:${element.nombre.replace(/\s/g, '_')}`)) {
                  this.tipos.push(element);
                }
              }
            });
          },
          error: () => {
            this.toast.error('Error al consultar los tipos en la base de datos', 'Error');
          }
        }
      );
  }

  fileChangeEvent(event, item) {
    let filesToUpload = <Array<File>>event.target.files;
    if (filesToUpload[0].type != 'application/pdf') {
      this.toast.warning('Por favor solo seleccionar archivos en formato PDF', 'Importante');
      item.controls.base64.setValue(null);
      return;
    }
    FileHelper.toBase64(filesToUpload[0]).then((data: string) => {
      item.controls.base64.setValue(data);
    });
  }
}
