import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { IConsultaSarlaftProcessService } from '../consulta-sarlaft.-process.service.interface';
import { Observable, map } from 'rxjs';
import { HistorialConsultaSarlaftDTO } from '../../../models/historial-consulta-sarlaft/consulta-manual-sarlaft-dto.model';
import { ServiceImplService } from '../../service.impl.service';

@Injectable({
    providedIn: 'root'
})
export class ConsultaSarlaftProcessServiceImpl extends ServiceImplService<any> implements IConsultaSarlaftProcessService {

    httpClient: HttpClient;
    apiUrl: string;
    resource: string;
    constructor(public http: HttpClient) {
        super();
        this.httpClient = http;
        this.apiUrl = environment.apiUrl;
        this.resource = 'consulta-sarlaft-process/';
    }

    getFullPath() {
        return this.apiUrl + this.resource;
    }


    consultaSarlaftManual(consultaSarlaftManual: HistorialConsultaSarlaftDTO) {
        const endPoint = `manual`;
        return this.httpClient.patch(this.getFullPath() + endPoint, consultaSarlaftManual)
            .pipe(map((res: any) => res));
    }

    consultaSarlaft(documento: string, tipoDocumento: string) {
		return this.httpClient.get(this.getFullPath() + `numeroDocumento/${documento}/tipoDocumento/${tipoDocumento}`)
		  .pipe(map((res: any) => res.data));
	}


    getDownloadDocumentPDF(id: number): Observable<any> {
		const path = `${id}/historial-consulta/pdf`
        return this.executeGetPDF(path);
	}

    putAprobarConsulta(dataUpdate: any): Observable<any> {
        const path = `/aprobar-consulta`;
        return this.httpClient.put(this.getFullPath() + path, dataUpdate).pipe(
            map((res: any) => res.data)
        );
    }

    putRechazarConsulta(dataUpdate: any): Observable<any> {
      const path = `/rechazar-consulta`;
      return this.httpClient.put(this.getFullPath() + path, dataUpdate).pipe(
          map((res: any) => res.data)
      );
    }

    validarDownloadDocumentPDF(idHistorialSarlaft: number) {
        const path = `${idHistorialSarlaft}/validar-historial-consulta/pdf`;
        return this.http.get(this.getFullPath() + path);
      }

    enviarCodigoSarlaftCorreo(bodyReq: any): Observable<any> {
      const endPoint = `validar-cliente-consuta-sarlaft/correo`;
      return this.httpClient.post(this.getFullPath() + endPoint, bodyReq)
        .pipe(map((res: any) => res));
    }

    enviarCodigoSarlaftTelefono(bodyReq: any): Observable<any> {
      const endPoint = `validar-cliente-consuta-sarlaft/telefono`;
      return this.httpClient.post(this.getFullPath() + endPoint, bodyReq)
        .pipe(map((res: any) => res));
    }

    confirmarCodigoSarlaft(codigo: string, documento: string): Observable<any> {
      return this.httpClient.patch(this.getFullPath() + `validar-codigo/${codigo}/numero-documento/${documento}`, null)
		  .pipe(map((res: any) => res.data));
    }
}
