import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import UbicacionGeograficaModel from 'src/app/core/models/ubicaciones/ubicacion-geografica.model';
import { UbicacionGeograficaServiceImpl } from 'src/app/core/http/ubicaciones/impl/ubicacion-geografica.service.impl';
import { DepartamentoServiceImpl } from 'src/app/core/http/ubicaciones/impl/departamento.service.impl';
import PaisModel from 'src/app/core/models/ubicaciones/pais.model';
import DepartamentoModel from 'src/app/core/models/ubicaciones/departamento.model';
import RegionModel from 'src/app/core/models/ubicaciones/region.model';


@Component({
	selector: 'ef-ubicacion-geografica-add',
	templateUrl: './ubicacion-geografica-add.component.html',
	styleUrls: [ './ubicacion-geografica-add.component.scss' ]
})
export class UbicacionGeograficaAddComponent implements OnInit {
	fDepartamento: boolean = false;
	formAddUbicacion: FormGroup;

	pais: PaisModel;
	region: RegionModel;
	departamento: DepartamentoModel;
	municipio: any;

	codDepartamento: number = 0;
	codMunicipio: number = 0;

	constructor(
		public matDialog: MatDialog,
		public formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<UbicacionGeograficaAddComponent>,
		
		public ubicacionService: UbicacionGeograficaServiceImpl,
		public toasterService: ToastrService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.pais = data.pais;
		this.region = data.region;
		this.departamento = data.departamento;
	}

	ngOnInit() {
		this.formAddUbicacion = this.formBuilder.group({
			pais: [ '', [ Validators.required ] ],
			region: [ '', [ Validators.required ] ],
			departamento: [ '', [ Validators.required ] ],
			codDepartamento: [ '', [ Validators.required ] ],
			municipio: [ '', [ Validators.required ] ],
			codMunicipio: [ '', [ Validators.required ] ],
		});

		if (this.pais) {
			this.form.pais.setValue(this.pais);
			this.form.pais.disable();
		}

		if (this.region) {
			this.form.region.setValue(this.region);
			this.form.region.disable();
		}

		if (this.departamento) {
			this.fDepartamento = true;
			this.form.departamento.setValue(this.departamento.departamento);
			this.form.codDepartamento.setValue(this.departamento.id);
			this.codDepartamento = this.departamento.id;
			this.form.departamento.disable();
		}
	}

	get form() {
		return this.formAddUbicacion.controls;
	}

	onFormSubmit() {
		if (navigator.onLine) {
			const formValues = Object.assign({}, this.formAddUbicacion.getRawValue());
			const ubicacionNew = <UbicacionGeograficaModel>{
				paisId: formValues.pais.id,
				region: formValues.region.region,
				codigoRegion: formValues.region.codigo,
				departamento: formValues.departamento,
				codigoDaneDpto: this.codDepartamento,
				municipio: formValues.municipio,
				codigoDaneMunicipio: 0,
				estado: 'ACTIVO'
			};

		

			this.ubicacionService.save(ubicacionNew).subscribe(
				(ubicaiconId) => {
					;
					this.dialogRef.close(ubicaiconId);
					this.toasterService.success('Se ha registrado la ubicacion geografica');
				},
				(error) => {
					try {
						for (const field of error.error.errors) {
							this.toasterService.error(field.message, 'Error');
						}
					} catch (e) {}
				}
			);

			this.matDialog.closeAll();
		}
	}
}
