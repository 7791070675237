<div class="popup-card-width" [style.width.%]="100">
    <div>
        <h2 mat-dialog-title i18n>Información Línea de Crédito</h2>
    </div>
    <mat-divider class="primary-border"></mat-divider>
    <mat-dialog-content *ngIf="lineaCreditoDetail">
      <div class="grid md:grid-cols-2 xs:grid-cols-1 gap-2 mrgn-t-sm">

        <div>
          <strong>Código: </strong>
        </div>
        <div>
          <span>{{lineaCreditoDetail.codigo}}</span>
        </div>

        <div>
          <strong>Nombre: </strong>
        </div>
        <div>
          <span>{{lineaCreditoDetail.nombre}}</span>
        </div>

        <div>
          <strong>Académico Jamestown: </strong>
        </div>
        <div>
          <span>{{lineaCreditoDetail.fEducativoJamestown ? 'SI' : 'NO'}}</span>
        </div>

        <div>
          <strong>Abreviatura Contable: </strong>
        </div>
        <div>
          <span>{{lineaCreditoDetail.abreviaturaContable ?
            lineaCreditoDetail.abreviaturaContable : "NO REGISTRA"}}</span>
        </div>

        <div>
          <strong>Tasa Interés: </strong>
        </div>
        <div>
          <span>{{lineaCreditoDetail.tasaInteres + "%"}}</span>
        </div>

        <div>
          <strong>Cobertura: </strong>
        </div>
        <div>
          <span>{{lineaCreditoDetail.cobertura}}</span>
        </div>

        <div>
          <strong>Requisitos: </strong>
        </div>
        <div>
          <span>{{lineaCreditoDetail.requisitos}}</span>
        </div>

        <div>
          <strong>Descripción: </strong>
        </div>
        <div>
          <span>{{lineaCreditoDetail.descripcion}}</span>
        </div>

        <div>
          <strong>Estado: </strong>
        </div>
        <div>
          <span>{{lineaCreditoDetail.estado}}</span>
        </div>

        <div>
          <strong>Empresa: </strong>
        </div>
        <div>
          <span>{{lineaCreditoDetail.empresa | spaceUnderscore}}</span>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
        <button type="button" mat-raised-button (click)="dialogRef.close()">
            Cerrar
        </button>
    </mat-dialog-actions>
</div>
