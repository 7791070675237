import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from '@angular/common/http';
import { IParametroGeneralService } from '../parametro-general.interface';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import ParametroGeneral from 'src/app/core/models/configuracion-basica/parametro-general.model';


@Injectable({
	providedIn: 'root'
})
export class ParametroGeneralService extends ServiceImplService<ParametroGeneral> implements IParametroGeneralService {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'parametros-generales/';
	}

  getServerInfo(): Observable<any> {
    const path = `infoServer`;
    return this.executeGet(path).pipe(map((res: any) => res.data));
  }
}
