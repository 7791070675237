<div class="popup-card-width">
    <div>
      <h2 mat-dialog-title i18n>Información de Concepto Prioridad</h2>
    </div>
    <mat-divider class="primary-border mb-4"></mat-divider>
    <mat-dialog-content *ngIf="conceptoPrioridad">
      <div class="flex flex-wrap space-between">
        <div class="sm:w-[40%] xs:w-full md:w-[30%]">
          <strong>Prioridad:</strong>
        </div>
        <div class="sm:w-[60%] xs:w-full md:w-[70%]">
          <span>{{conceptoPrioridad.prioridad}}</span>
        </div>
      </div>
      <div class="flex flex-wrap space-between">
        <div class="sm:w-[40%] xs:w-full md:w-[30%]">
          <strong>Concepto</strong>
        </div>
        <div class="sm:w-[60%] xs:w-full md:w-[70%]">
          <span>{{conceptoPrioridad.concepto}}</span>
        </div>
      </div>

      <div class="flex flex-wrap space-between">
        <div class="sm:w-[40%] xs:w-full md:w-[30%]">
          <strong>Estado:</strong>
        </div>
        <div class="sm:w-[60%] xs:w-full md:w-[70%]">
          <span>{{conceptoPrioridad.estado}}</span>
        </div>
      </div>

      <div class="flex flex-wrap space-between">
        <div class="sm:w-[40%] xs:w-full md:w-[30%]">
          <strong>Vigencia:</strong>
        </div>
        <div class="sm:w-[60%] xs:w-full md:w-[70%]">
          <span>{{conceptoPrioridad.flagVigente ? 'VIGENTE':'NO VIGENTE'}}</span>
        </div>
      </div>

      <div class="flex flex-wrap space-between">
        <div class="sm:w-[40%] xs:w-full md:w-[30%]">
          <strong>Form Control:</strong>
        </div>
        <div class="sm:w-[60%] xs:w-full md:w-[70%]">
          <span>{{conceptoPrioridad.formControl}}</span>
        </div>
      </div>

    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
      <button type="button" mat-raised-button (click)="dialogRef.close()">Cerrar
      </button>
    </mat-dialog-actions>
  </div>
