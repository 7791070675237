import { DatePipe } from '@angular/common';
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import BaseFilter from 'src/app/core/filters/base.filter';
import { PagosServiceImpl } from 'src/app/core/http/pagos/impl/pagos.service.impl';
import { DialogService } from 'src/app/core/services/dialog.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';

@Component({
  selector: 'ef-historico-pagos',
  templateUrl: './historico-pagos.component.html',
  styleUrls: ['./historico-pagos.component.scss'],
})
export class HistoricoPagosComponent implements OnInit {
  item: any;
  isSolicitud: boolean;
  columnNames: Array<TableColumn>;
  filter: BaseFilter = new BaseFilter();
  historial: any;
  messageEmpty = 'No hay registros';
  total;
  isTable = false;
  isMessage = false;
  @ViewChild('optionTemplate', { static: true })
  optionTemplate: TemplateRef<any>;

  constructor(
    public dialogRef: MatDialogRef<HistoricoPagosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public pagosService: PagosServiceImpl,
    public datePipe: DatePipe,
    public spinner: SpinnerService,
    public toast: ToastrService,
    public dialogService: DialogService
  ) {
    this.item = data.item;
    this.isSolicitud = data.isSolicitud;
  }

  ngOnInit(): void {
    this.filter.pageSize = 10;
    this.filter.pageNumber = 0;
    this.columnNames = [
      {
        name: 'Fecha',
        prop: 'createdDate',
        cellClass: 'text-center',
        pipe: this.datePipe,
      },
      { name: 'Valor Abono', prop: 'valorAbono', cellClass: 'text-center' },
      {
        name: 'Valor Gastos Cobranza',
        prop: 'valorGastosCobranza',
        cellClass: 'text-center',
      },
      { name: 'Valor Póliza', prop: 'valorPoliza', cellClass: 'text-center' },
      {
        name: 'Valor Recargo Jurídico',
        prop: 'valorRecargoJuridico',
        cellClass: 'text-center',
      },
      { name: 'Estado', prop: 'estado', cellClass: 'text-center' },
      {
        name: 'Opciones',
        cellTemplate: this.optionTemplate,
        cellClass: 'text-center',
      },
    ];
    if (this.isSolicitud) {
      this.getHistorialSolicitud();
    } else {
      this.getHistorialAcuerdo();
    }
  }

  getHistorialAcuerdo() {
    if (this.item && this.item.acuerdoPagoExtended) {
      this.filter['idAcuerdoPago'] = this.item.acuerdoPagoExtended.id;
      this.pagosService.filter(this.filter).subscribe({
        next: (res) => {
          if (res.data) {
            this.historial = res.data;
            this.isTable = true;
            this.isMessage = false;
            this.total = res.count;
          } else {
            this.isTable = false;
            this.isMessage = true;
          }
        },
      });
    }
  }

  getHistorialSolicitud() {

    this.filter['idSolicitudCredito'] = this.item.solicitudCreditoExtended.id;
    this.pagosService.filter(this.filter).subscribe({
      next: (res) => {
        if (res.data) {
          ;
          this.historial = res.data;
          this.isTable = true;
          this.isMessage = false;
          this.total = res.count;
        } else {
          this.isTable = false;
          this.isMessage = true;
        }
      },
    });
  }

  getPageSize() {
    return this.filter.pageSize;
  }

  getTotalElements(): number {
    return this.total;
  }

  getFilterPage(pageNumber = 0) {
    this.filter.pageNumber = pageNumber;
    if (this.isSolicitud) {
      this.getHistorialSolicitud();
    } else {
      this.getHistorialAcuerdo();
    }
  }

  anular(row) {
    this.dialogService
      .modalGeneric(
        'Anular Pago',
        '¿Estas seguro que desea anular el pago?'
      )
      .subscribe({
        next: (res) => {
          if (res) {
            this.pagosService.anularPago(row.id).subscribe({
              next: (res) => {
                this.toast.success('Pago anulado exitosamente');
                this.getFilterPage(0);
              },
            });
          }
        }
      });
  }

  sincronizar(row) {
    if (this.isSolicitud) {
      this.sincronizarSolicitud(row);
    } else {
      this.sincronizarAcuerdo(row);
    }
  }

  sincronizarAcuerdo(row) {
    this.dialogService
      .modalGeneric(
        'Sincronizar Pago',
        '¿Estas seguro que desea sincronizar el pago?'
      )
      .subscribe({
        next: (res) => {
          if (res) {
            this.pagosService.getMigrarPagoAcuerdo(row.idAcuerdoPago).subscribe({
              next: (data) => {
                this.dialogRef.close();
                this.toast.success('Pago sincronizado exitosamente');
                this.getFilterPage(0);
              },
            });
          }
        }
      });
  }

  sincronizarSolicitud(row) {
    this.dialogService
      .modalGeneric(
        'Sincronizar Pago',
        '¿Estas seguro que desea sincronizar el pago?'
      )
      .subscribe({
        next: (res) => {
          if (res) {
            this.pagosService
              .getMigrarPagoSolicitud(row.idSolicitudCredito)
              .subscribe({
                next: (data) => {
                  this.dialogRef.close();
                  this.toast.success('Pago sincronizado exitosamente');
                  this.getFilterPage(0);
                },
              });
          }
        }
      });
  }
}
