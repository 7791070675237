<div class="popup-card-width">
    <div>
        <h2 mat-dialog-title i18n>Información de Pasos</h2>
    </div>
    <mat-divider class="mb-4 primary-border"></mat-divider>
    <mat-dialog-content *ngIf="pasoDetail">
        <div class="grid md:grid-cols-2">
            <div class="flex gap-2">
                <strong>Nombre:</strong>
                <div>
                    <span>{{ pasoDetail.nombre }}</span>
                </div>
            </div>
        </div>
        <div class="grid md:grid-cols-2">
            <div class="flex gap-2">
                <strong>Ruta:</strong>
                <div>
                    <span>{{ pasoDetail.path }}</span>
                </div>
            </div>
        </div>
        <div class="grid md:grid-cols-2">
            <div class="flex gap-2">
                <strong>Estado:</strong>
                <div>
                    <span>{{ pasoDetail.estado }}</span>
                </div>
            </div>
        </div>
        <div class="flex gap-2">
                <strong>Descripcion:</strong>
                <div>
                    <span class="mt-2">{{ pasoDetail.descripcion }}</span>
                </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
        <button type="button" mat-raised-button (click)="dialogRef.close()">
            Cerrar
        </button>
    </mat-dialog-actions>
</div>