<div *ngIf="!isLoading; else spinner">
  <mat-card *ngIf="planPagos.length > 0">
    <div class="gene-card-title" *ngIf="isSolicitud">
      <div class="flex justify-center items-center">
        <div class="flex flex-col">
          <h3>{{ 'Solicitud - ' }} {{'No. ' + item.solicitudCreditoExtended.numeroSolicitud}}
          </h3>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="gene-card-title" *ngIf="!isSolicitud">
      <div class="flex justify-center items-center">
        <div class="flex flex-col">
          <h3>{{ 'Acuerdo de pago - ' }} {{'No. ' + item.acuerdoPagoExtended.numeroAcuerdoPago}}
          </h3>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="flex md:flex-col pad-y-lg gap-[15px]" *ngIf="isSolicitud">
      <div class="text-center flex flex-col">
        <strong>Valor Total</strong>
        <p>{{item.solicitudCreditoExtended.valorTotal | currency:'COP ':'symbol':'1.0-0'}}</p>
      </div>
      <div class="text-center flex flex-col">
        <strong>Valor Seguro</strong>
        <p *ngIf="valorSeguro > 0">{{valorSeguro | currency:'COP ':'symbol':'1.0-0'}}</p>
        <p *ngIf="valorSeguro == 0">{{'NO APLICA'}}</p>
      </div>
      <div class="rounded" style="background-color: rgba(242, 145, 0, 0.3);">
        <div class="flex">
          <mat-icon class="px-4 mr-1 pt-3" style="color: rgba(18, 37, 55, 0.8);">info</mat-icon>
          <p class="italic pt-3 text-sm pr-2" style="color: rgba(18, 37, 55, 0.8);">
            <strong>
              NOTA:<br>
            </strong>
            Este Plan de Pago Inicial solo tiene validez si los pagos son efectuados
            en cada una de las fechas comprometidas. En caso contrario los intereses se calculan
            en tiempo real de acuerdo a cada fecha en que realmente se efectúe el pago.
          </p>
        </div>
      </div>
    </div>

    <div class="flex md:flex-col pad-y-lg gap-[15px]" *ngIf="!isSolicitud">
      <div class="text-center flex flex-col">
        <strong>Valor Total</strong>
        <p>{{item.acuerdoPagoExtended.valorTotal | currency:'COP ':'symbol':'1.0-0'}}</p>
      </div>
    </div>
  </mat-card>

  <mat-card>
    <ngx-datatable *ngIf="planPagos.length > 0" [style.width.%]="100" class="material" [rows]="planPagos"
                   [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                   [limit]="totalCuotas"
                   [selected]="selected" [selectionType]="SelectionType.checkbox" (select)="onSelect($event)">

      <ngx-datatable-column name="Cuota" prop="numeroCuota" [width]="10">
        <ng-template ngx-datatable-cell-template let-row="row" let-value='value' class="text-center">
          <span class="text-center">{{row.cuota}}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Fecha Pago" [width]="90">
        <ng-template ngx-datatable-cell-template let-row="row" let-value='value' class="text-center">
          <span class="text-center">{{formatDate(row.fechaPago) | titlecase}}</span>
        </ng-template>
      </ngx-datatable-column>


      <ngx-datatable-column name="Saldo Inicial" prop="saldoInicial">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" class="text-center">
              <span class="text-center">{{row.saldoInicial | currency:'COP':'symbol':'1.0-0'}}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Saldo Final" prop="saldoFinal">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" class="text-center">
                <span>{{row.saldoFinal | currency:'COP':'symbol':'1.0-0'}}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Seguro" prop="seguroVida">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" class="text-center">
                <span>{{row.seguroVida | currency:'COP':'symbol':'1.0-0'}}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Valor" prop="valorCuota">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" class="text-center">
            <span class="text-center">{{row.valorCuota | currency:'COP':'symbol':'1.0-0'}}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
        [resizeable]="false" *ngIf="!isDetail">
        <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
        let-selectFn="selectFn">
        <input type="checkbox" [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)"/>
        </ng-template>
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value"
                     let-onCheckboxChangeFn="onCheckboxChangeFn" let-isSelected="isSelected">
          <input type="checkbox"
                 (change)="onCheckboxChangeFn($event)"/>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
    <div class="mrgn-t-sm flex justify-end items-center" *ngIf="!isDetail">
      <button mat-raised-button class="accept-button" (click)="generatePayLink()">
        Generar Link de Pago
      </button>
    </div>
  </mat-card>
</div>
<ng-template #spinner>
  <mat-spinner class="m-auto mt-2"></mat-spinner>
</ng-template>
