<form [formGroup]="formAdd" novalidate autocomplete="off" class="popup-card-width">
  <div>
    <h2 mat-dialog-title i18n>Nuevo Permiso</h2>
  </div>
  <mat-dialog-content>
    <div>
      <div>
        <mat-form-field>
          <mat-label>Nombre</mat-label>
          <input matInput formControlName="name" type="text" class="form-control">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Descripción</mat-label>
          <input matInput formControlName="description" type="text" class="form-control">
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close(false)">Cancelar
    </button>
    <button type="submit" mat-raised-button [disabled]="formAdd.invalid" color="primary" (click)="onFormSubmit()">Crear
    </button>
  </mat-dialog-actions>
</form>