import {RegionEnum} from '../enums/RegionEnum';

export class RegionHelper {

  public getFromEnum(value: string): number {
    let region = 0;
    switch (value) {
      case RegionEnum.RGCO:
        region = 2;
        break;

      case RegionEnum.RGCS:
        region = 3;
        break;
      case RegionEnum.RGECA:
        region =  4;
        break;
      case RegionEnum.RGLL:
        region =  5;
        break;
    }
    return region;
  }

  public getFromEnumById(value: number): string {
    let region = '';
    switch (value) {
      case 2:
        region = RegionEnum.RGCO;
        break;

      case 3:
        region = RegionEnum.RGCS;
        break;
      case 4:
        region =  RegionEnum.RGECA;
        break;
      case 5:
        region =  RegionEnum.RGLL;
        break;
    }
    return region;
  }

  public getFromEnumByIdAux(value: number): string {
    let region = '';
    switch (value) {
      case 2:
        region = 'Región Centro Oriente';
        break;

      case 3:
        region = 'Región Centro Sur';
        break;
      case 4:
        region =  'Región Eje Cafetero - Antioquia';
        break;
      case 5:
        region =  'Región Llano';
        break;
    }
    return region;
  }

}
