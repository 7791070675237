import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AcuerdoPagoDescuentoServiceImpl } from '../../../../core/http/configuracion-basica/impl/acuerdo-pago-descuento.service.impl';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ef-gestion-descuentos-edit',
  templateUrl: './gestion-descuentos-edit.component.html',
  styleUrls: ['./gestion-descuentos-edit.component.scss'],
})
export class GestionDescuentosEditComponent implements OnInit {
  loading = false;
  idDescuento;
  formEdit: FormGroup;
  mensajeError = false;
  tipos = [
    'PROSPECTO_POSITIVO',
    'PROSPECTO_NEGATIVO',
    'REACTIVACION_ACADEMICA',
  ];
  caracteresEscritos: number = 0;

  constructor(
    public toast: ToastrService,
    public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GestionDescuentosEditComponent>,
    public acuerdoPagoDescuentoService: AcuerdoPagoDescuentoServiceImpl
  ) {
    if (data && data.descuento) {
      this.idDescuento = data.descuento.id;
    } else {
      console.error('Datos de descuento no proporcionados o incompletos');
    }
  }

  ngOnInit(): void {
    this.formEdit = this.formBuilder.group({
      nombre: [null, [Validators.required]],
      tipo: [null, [Validators.required]],
      estado: [false],
      descripcion: [null, [Validators.required]],
    });
    this.initForm();
  }

  initForm() {
    this.acuerdoPagoDescuentoService
      .get(this.idDescuento)
      .subscribe({
        next: (info) => {
          this.idDescuento = info.id;
          this.formEdit.patchValue(info);
        }
      });
  }

  get form() {
    return this.formEdit.controls;
  }

  onFormSubmit() {
    if (navigator.onLine) {
      this.mensajeError = null;
      const descuento = Object.assign({}, this.formEdit.value);
      this.acuerdoPagoDescuentoService
        .update(this.idDescuento, descuento)
        .subscribe(
          {
            next: (descuento) => {
              this.dialogRef.close(descuento);
            },
            error: (error) => {
              try {
                for (const field of error.error.errors) {
                  this.toast.error(field.message, 'Error');
                }
              } catch (e) {
                this.mensajeError = error.error.message;
              }
            }
          }
        );
    } else {
      this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
    }
  }

  calcularCaracteresEscritos(event: Event): void {
    const texto = (event.target as HTMLTextAreaElement).value;
    this.caracteresEscritos = texto.length;
  }
}
