import {
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import MunicipioModel from 'src/app/core/models/ubicaciones/municipio.model';
import { Observable } from 'rxjs';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import DepartamentoModel from 'src/app/core/models/ubicaciones/departamento.model';
import { DepartamentoServiceImpl } from 'src/app/core/http/ubicaciones/impl/departamento.service.impl';
import { map } from 'rxjs/operators';
import PaisModel from 'src/app/core/models/ubicaciones/pais.model';
import RegionModel from 'src/app/core/models/ubicaciones/region.model';
import { DialogService } from 'src/app/core/services/dialog.service';
import * as _ from 'lodash';

@Component({
	selector: 'ef-select-municipio',
	templateUrl: './select-municipio.component.html',
	styleUrls: ['./select-municipio.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectMunicipioComponent),
			multi: true
		}
	],
	encapsulation: ViewEncapsulation.None
})
export class SelectMunicipioComponent implements ControlValueAccessor, OnInit, OnChanges {
	municipio: MunicipioModel;

	municipios: Array<MunicipioModel> = [];

	filteredMunicipios: Observable<MunicipioModel[]>;

	municipioControl = new FormControl(null, CustomValidatorsHelper.validateSelect);

	@Input() public departamento: DepartamentoModel;

	@Input() public pais: PaisModel | any;

	@Input() public region: RegionModel;

	@Input() public municipioDefault: MunicipioModel;

	@Input() flagRegion = false;

	@Input() width: any;

	@Input() label: any;

	@Input() isRequired = false;

	@Input() enableIcon = false;

	@Output() onCopyMunicipio = new EventEmitter();

	isDisabled = false;

	@Input() enableAdd = false;

	@Input() enableAddForm = false;

	@Output() ubicacionNew = new EventEmitter();

	onTouched = () => { };

	constructor(public departamentoService: DepartamentoServiceImpl, public dialogService: DialogService) { }

	ngOnInit(): void {
		this.subscribeMunicipios();
	}

	subscribeMunicipios() {
		this.filteredMunicipios = this.municipioControl.valueChanges.pipe(
			map((value: any) => {
				if (_.isString(value)) {
					this.municipio = undefined;
					this.propagateChange(this.municipio);
				}

				if (value && value.municipio) {
					if (value.municipio !== undefined) {
						value = value.municipio;
					} else {
						value = value;
					}
				}
				return this._filter(value);
			})
		);
	}

	onChangeDepartamento() {
		if (this.departamento) {
			this.departamentoService.getMunicipios(this.departamento.id).subscribe({
				next: (municipios: any) => {
					this.municipios = municipios.data;

					if (this.municipios.length == 1 && !this.flagRegion) {
						this.writeValue(this.municipios[0]);
						this.propagateChange(this.municipio);
						this.onTouched();
					}
					this.subscribeMunicipios();
					this.municipioControl.enable();
					this.enableAdd = true;
				}
			});
		}
	}

	onMunicipioChange(event) {
		const newMunicipio = <MunicipioModel>event.option.value;
		this.onChange(event, newMunicipio);
	}

	onChange(e: Event, value: any) {
		this.municipio = value;
		this.propagateChange(this.municipio);
		this.onTouched();
	}

	get value(): any {
		return this.municipio;
	}

	set value(v: any) {
		if (v !== this.municipio) {
			this.municipio = v;
		}
	}

	propagateChange = (_: any) => { };

	writeValue(municipio: any) {
		this.municipio = municipio;
		this.municipioControl.setValue(municipio);
	}

	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	displayFn(municipio?: MunicipioModel): string | undefined {
		return municipio ? municipio.municipio : undefined;
	}

	public _filter(value: string): Array<MunicipioModel> {
		if (value) {
			const filterValue = value.toLowerCase();
			return this.municipios.filter((municipio) => {
				return municipio.municipio.toLowerCase().includes(filterValue);
			});
		}
		return this.municipios;
	}

	ngOnChanges(changes: SimpleChanges): void {
		const departamentoChange = changes.departamento;
		if (departamentoChange != null && departamentoChange != undefined) {
			const anteriorDepartamento = departamentoChange.previousValue;
			const nuevoDepartamento = departamentoChange.currentValue;
			if (anteriorDepartamento) {
				this.writeValue(null);
				this.municipioControl.setValue(null);
				this.propagateChange(this.municipio);
			}
			if (!nuevoDepartamento && !departamentoChange.isFirstChange()) {
				this.municipios = [];
				this.municipioControl.disable();
				this.enableAdd = false;
			} else if (anteriorDepartamento !== nuevoDepartamento) {
				this.municipios = [];
				this.municipioControl.disable();
				this.enableAdd = false;
				this.departamento = nuevoDepartamento;
				this.onChangeDepartamento();
			}
		}
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	copyMunicipio() {
		this.onCopyMunicipio.emit(true);
	}

	addNewUbicacionDialog() {
		if (this.flagRegion) {
			this.municipioControl.disable();
			this.dialogService.addNewUbicacionDialog(this.pais, this.region, this.departamento).subscribe({
				next: (ubicacionId) => {
					this.municipioControl.enable();
					if (ubicacionId) {
						this.ubicacionNew.emit(ubicacionId);
					}
				}
			});
		} else {
			this.municipioControl.disable();
			let department = typeof this.departamento !== 'undefined' ? this.departamento : null;
			this.dialogService.listUbicacionDialog(this.pais, department).subscribe({
				next: (ubicacionId) => {
					this.municipioControl.enable();
					if (ubicacionId) {
						this.ubicacionNew.emit(ubicacionId);
					}
				}
			});
		}
	}
}
