import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ToggleService {
	stateMenu: boolean;
	listeners;

	constructor() {
		this.listeners = [];
	}

	notifyListeners() {
		this.listeners.map((cb) => cb(this.stateMenu));
	}

	setStateMenu(newState: boolean): void {
		this.stateMenu = newState;
		this.notifyListeners();
	}

	toggleState(): void {
		this.stateMenu = !this.stateMenu;
		this.notifyListeners();
	}

	subscribe(callback) {
		this.listeners.push(callback);

		return () => {
			const index: number = this.listeners.indexOf(callback);
			index >= 0 && this.listeners.splice(index, 1);
		};
	}
}
