<div class="popup-card-width">
	<h1 class="text-3xl">Confirmar Inactivar</h1>
	<mat-divider class="mat-divider mt-3 primary-border mat-divider-horizontal"></mat-divider>
	<div class="mt-4">
		<mat-dialog-content>
			<div>
				{{deleteMessage}}
			</div>
		</mat-dialog-content>
	</div>
	<div class="mt-4">
		<ng-container *ngIf="mensajeError.length>0">
			<span class="active-status-bg status-label ng-star-inserted flex justify-start items-center">
				<mat-icon>warning</mat-icon>
				{{mensajeError}}
			</span>
		</ng-container>
	</div>
	<div class="mb-3">
		<mat-dialog-actions class=" pad-b-none text-right">
			<button type="button" mat-raised-button (click)="dialogRef.close()">No
			</button>
			<button (click)="onDeleteSubmit()" [disabled]="loading" mat-raised-button class="accept-button">Si, estoy
				seguro
			</button>
		</mat-dialog-actions>
	</div>
</div>