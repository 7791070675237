<h2 mat-dialog-title>Acuerdo de pago {{historial.numeroAcuerdoPago}}</h2>
<mat-dialog-content class="mat-typography">
  <mat-card>
    <mat-card-title class="flex justify-center items-center">
      Detalles
    </mat-card-title>
    <mat-card-subtitle class="flex justify-center items-center">
      Número de Solicitud: {{historial.numeroSolicitud}}
    </mat-card-subtitle>
    <div class="flex flex-col justify-center items-stretch gap-[5px]">

      <div class="flex justify-between items-center gap-[5px]" >
        <p class="w-[50%]">
          <strong>Valor Total:</strong> {{historial.valorTotal}}
        </p>
        <p class="w-[50%]">
          <strong>Valor Capital:</strong> {{historial.valorCapital}}
        </p>
      </div>

      <div class="flex justify-between items-center gap-[5px]" >
        <p class="w-[50%]">
          <strong>Número Cuotas:</strong> {{historial.numeroCuota}}
        </p>
        <p class="w-[50%]">
          <strong>Valor Inicial:</strong> {{historial.valorInicial}}
        </p>
      </div>

      <div class="flex justify-between items-center gap-[5px]" >
        <p class="w-[50%]">
          <strong>Estado Cartera:</strong> {{historial.estadoCartera}}
        </p>
        <p class="w-[50%]">
          <strong>Días Mora:</strong> {{historial.diasMora}}
        </p>
      </div>

      <div class="flex justify-between items-center gap-[5px]" >
        <p class="w-[50%]">
          <strong>Saldo Contabilidad:</strong> {{historial.saldoContabilidad}}
        </p>
        <p class="w-[50%]">
          <strong>Valor Financiado:</strong> {{historial.valorFinanciado}}
        </p>
      </div>

    </div>
  </mat-card>
  <mat-card>
    <mat-card-title class="flex justify-center items-center">
      Personal Administrativo
    </mat-card-title>
    <mat-card-content>
      <div class="flex flex-col justify-center items-stretch gap-[5px]" >

        <div class="flex justify-between items-center gap-[5px]" >
          <p class="w-[50%]">
            <strong>Nombre Completo:</strong> {{historial.personalAdministrativo?.nombres | titlecase}}
            {{historial.personalAdministrativo?.apellidos | titlecase}}
          </p>
          <p class="w-[50%]">
            <strong>Documento:</strong> {{historial.personalAdministrativo?.numeroDocumento}}
          </p>
        </div>
        <div class="flex justify-between items-center gap-[5px]" >
          <p class="flex">
            <strong>Email:</strong> {{historial.personalAdministrativo?.email}}
          </p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>
<mat-dialog-actions class="mt-2 pad-b-none text-right">
  <button mat-button mat-dialog-close>Aceptar</button>
</mat-dialog-actions>
