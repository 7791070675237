import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceImplService } from '../../service.impl.service';
import { Login } from '../../../models/security/login.model';
import { ISecurityRolPermisoService } from '../security-rol-permiso.service.interface';

@Injectable()
export class SecurityRolPermisoServiceImpl extends ServiceImplService<Login> implements ISecurityRolPermisoService {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'security-rol-permiso/';
	}

	permisosByRolId(idRol) {
		return this.httpClient.get(`${this.getFullPath()}permisos/${idRol}`);
	}

	actualizarPermisosByRol(idRol, body) {
		return this.httpClient.put(`${this.getFullPath()}actualizar/${idRol}`, body);
	}

	duplicarRol(idRolNuevo: any, idRolExistente: any) {
		return this.httpClient.put(`${this.getFullPath()}rol-nuevo/${idRolNuevo}/rol-existente/${idRolExistente}`, null);
	}
}
