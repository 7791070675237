import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClienteListaNegraServiceImpl } from 'src/app/core/http/solicitud-credito/impl/cliente-lista-negra.service.impl';

@Component({
  selector: 'ef-modal-insert-lista-negra',
  templateUrl: './modal-insert-lista-negra.component.html',
  styleUrls: ['./modal-insert-lista-negra.component.scss']
})
export class ModalInsertListaNegraComponent implements OnInit {

  formAdd: FormGroup;
  cliente: any;
  flagListaNegra : boolean;

  constructor(
    public formBuilder: FormBuilder,
    public toast: ToastrService,
    
    public clienteListaNegraService: ClienteListaNegraServiceImpl,
    public dialogRef: MatDialogRef<ModalInsertListaNegraComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.cliente = data.cliente;
    this.flagListaNegra = data?.flagListaNegra || false; 
  }


  ngOnInit(): void {
    this.flagListaNegra = false;
    this.formAdd = this.formBuilder.group({
      motivo: ['', Validators.required]
     
    });
  }


  get form() {
    return this.formAdd.controls;
  }

  onFormSubmit() {
    

    let insert = {
      idCliente: this.cliente.id,
      observacion: this.formAdd.getRawValue().motivo,
      isBloqueo: this.cliente?.flagListaNegra ? 0 : 1
    };
    this.clienteListaNegraService.post(insert)
      .subscribe(
        {
          next: (res) => {
            ;
            this.dialogRef.close(true);
            if (this.cliente?.flagListaNegra) {
              this.toast.success('Cliente desbloqueado por lista negra');
            } else {
              this.toast.success('Cliente bloqueado por lista negra');
            }
          },
          error: () => {
            ;
            this.toast.error('Ocurrio un error al guardar el registro', 'Error');
          }
        }
      );

  }

}
