import { Component, OnInit, Inject, ViewChild, AfterViewChecked } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import format from 'date-fns/format';

import { DetalleDAD } from 'src/app/core/models/debito-automatico/debito-automatico.model';
import { es } from 'date-fns/locale';
import { ColumnMode, SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { SolicitudAmortizacionServiceImpl } from 'src/app/core/http/solicitud-credito/impl/solicitud-amortizacion.service.impl';
import { parseISO } from 'date-fns';
import { Router } from '@angular/router';
import { DebitoAutomaticoDetalleServiceImpl } from 'src/app/core/http/debito-automatico/impl/debito-automatico-detalle.service.impl';
import { DialogService } from 'src/app/core/services/dialog.service';
import { ToastrService } from 'ngx-toastr';
import { ModalPromptComponent } from '../../../shared/modal-prompt/modal-prompt.component';

@Component({
	selector: 'ef-debito-automatico-detalle-pagos',
	templateUrl: './debito-automatico-detalle-pagos.component.html',
	styleUrls: ['./debito-automatico-detalle-pagos.component.scss']
})
export class DebitoAutomaticoDetallePagosComponent implements OnInit, AfterViewChecked {
	selected = [];
	totalCuotas = 0;
	detalle: DetalleDAD;
	ColumnMode = ColumnMode;
	SelectionType = SelectionType;

	@ViewChild(DatatableComponent) table: DatatableComponent;

	constructor(
		public route: Router,
		public toast: ToastrService,
		public matDialog: MatDialog,

		@Inject(MAT_DIALOG_DATA) public data: any,
		public detalleDAService: DebitoAutomaticoDetalleServiceImpl,
		public dialogRef: MatDialogRef<DebitoAutomaticoDetallePagosComponent>
	) {
		this.detalle = data;
		this.totalCuotas = this.detalle.items?.length;
	}

	ngOnInit(): void { }

	ngAfterViewChecked() {
		window.dispatchEvent(new Event('resize'));
	}

	formatDate(date: number | string): string {
		let fecha = '--- --, ----';
		if (date) {
			if (typeof date === 'number') {
				fecha = format(date, 'MMM dd, yyyy', { locale: es });
			}
			if (typeof date === 'string') {
				fecha = format(parseISO(date), 'MMM dd, yyyy', { locale: es });
			}
		}
		return fecha;
	}

	onSelect({ selected }) {
		selected = selected.filter((el) => el.estado === 'PAGADO');
		this.selected.splice(0, this.selected.length);
		this.selected.push(...selected);
	}

	validateViewAdmin(): Boolean {
		let flag = false;
		if (this.route.url.includes('cliente')) {
			flag = true;
		}
		return flag;
	}

	returnPay() {
		const items = this.selected.map((el) => el.id);
		this.modalPrompt(
			'MOTIVO DEVOLUCIÓN',
			'Motivo',
			'¿Cuál es el motivo de la devolución?',
			'Aceptar',
			'Cerrar',
			260
		).subscribe({
			next: (info) => {
				if (info) {
					this.detalleDAService
						.paymentezReturnPay(
							items,
							info,
							this.selected[0].amount * this.selected.length,
							this.detalle.transactionId,
							this.selected[0].debitoAutomatico.codigoRegion
						)
						.subscribe(
							{
								next: (info: any) => {
									this.toast.success(info.message);
									this.dialogRef.close(true);
								},
								error: (err) => {
									if (err.error.errors) {
										for (const error of err.error.errors) {
											this.toast.error(error.message, error.field);
										}
									}
									if (err.error.message) {
										this.toast.error(err.error.message, 'Error');
									}
								}
							}
						);
				}
			}
		});
	}

	public assignOptions(newInformation) {
		return Object.assign(
			{},
			{
				disableClose: true
			},
			newInformation
		);
	}

	public modalPrompt(title, titleField, message, accept = 'si', cancel = 'no', height = 220, width = 560) {
		let dialogRef: MatDialogRef<ModalPromptComponent>;
		dialogRef = this.matDialog.open(
			ModalPromptComponent,
			this.assignOptions({
				data: { title, titleField, message, accept, cancel },
				height,
				width
			})
		);
		return dialogRef.afterClosed();
	}

	getColor(estado): string {
		let color = 'inactive-status-bg';
		switch (estado) {
			case 'ACTIVO':
				color = 'active-status-bg';
				break;
			case 'APROBADO':
				color = 'aprobado-status-bg';
				break;
			case 'PENDIENTE':
				color = 'pendiente-status-bg';
				break;
			case 'ANULADO':
			case 'RECHAZADO':
				color = 'anulado-status-bg';
				break;
		}
		return color;
	}
}
