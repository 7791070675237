import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import ConceptoCuotaPrioridad from 'src/app/core/models/acuerdo-pago/concepto-cuota-prioridad.model';
import { ConceptoCuotaPrioridadServiceImpl } from 'src/app/core/http/acuerdo-pago/Impl/concepto-cuota-prioridad.impl';

@Component({
	selector: 'ef-concepto-prioridad-add',
	templateUrl: './concepto-cuota-prioridad-add.component.html',
	styleUrls: [ './concepto-cuota-prioridad-add.component.scss' ]
})
export class ConceptoCuotaPrioridadAddComponent implements OnInit {
	formAddConceptoPrioridad: FormGroup;

	formControls = ["ValorCapital","ValorGastosCobranza","ValorInteresesMoratorios", "ValorHonorarios"]

	constructor(
		public formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<ConceptoCuotaPrioridadAddComponent>,
		public conceptoPrioridadService: ConceptoCuotaPrioridadServiceImpl,
		public toasterService: ToastrService,
	) {
	}

	ngOnInit() {
		this.formAddConceptoPrioridad = this.formBuilder.group({
			prioridad: [ '', [ Validators.required, CustomValidatorsHelper.numeric ] ],
			concepto: [ '', Validators.required ],
			formControl: [ '', Validators.required ],
		});
	}

	get form() {
		return this.formAddConceptoPrioridad.controls;
	}

	onFormSubmit() {
		if (navigator.onLine) {
			if (this.formAddConceptoPrioridad.valid) {
				const formValues = Object.assign({}, this.formAddConceptoPrioridad.value);
				const conceptoPrioridadNew = <ConceptoCuotaPrioridad>{
          prioridad: formValues.prioridad,
			    concepto: formValues.concepto,
			    formControl: formValues.formControl,
				estado: 'ACTIVO',
				flagVigente: true
				};
				this.conceptoPrioridadService.save(conceptoPrioridadNew).subscribe(
					(conceptoPrioridad) => {
						this.dialogRef.close(conceptoPrioridad);
					},
					(error) => {
						try {
							for (const field of error.error.errors) {
								this.toasterService.error(field.message, 'Error');
							}
						} catch (e) {}
					}
				);
			}
		}
	}
}
