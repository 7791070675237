import {
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnInit,
	Output,
	OnChanges,
	ViewEncapsulation,
	SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import PaisModel from 'src/app/core/models/ubicaciones/pais.model';
import RegionModel from 'src/app/core/models/ubicaciones/region.model';

import { Observable } from 'rxjs';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import { UbicacionGeograficaServiceImpl } from 'src/app/core/http/ubicaciones/impl/ubicacion-geografica.service.impl';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog.service';

@Component({
	selector: 'ef-select-region',
	templateUrl: './select-region.component.html',
	styleUrls: [ './select-region.component.scss' ],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectRegionComponent),
			multi: true
		}
	],
	encapsulation: ViewEncapsulation.None
})
export class SelectRegionComponent implements ControlValueAccessor, OnInit, OnChanges {
	filteredPaises: Observable<PaisModel[]>;

	region: RegionModel;
	regiones: Array<RegionModel> = [];
	filteredRegiones: Observable<RegionModel[]>;
	regionControl = new FormControl(null);

	@Input() public pais: PaisModel | any;

	@Input() width: any;

	@Input() label: any;

	@Input() isRequired = false;

	@Output() public onSelectRegion = new EventEmitter();

	isDisabled = false;

	onTouched = () => {};
	propagateChange = (_: any) => {};

	constructor(
		public ubicacionGeograficaServiceImpl: UbicacionGeograficaServiceImpl,
		public dialogService: DialogService
	) {}

	ngOnInit(): void {
		this.subscribeRegiones();
	}

	displayFn(region?: RegionModel): string | undefined {
		return region ? region.region : undefined;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	subscribeRegiones() {
		this.filteredRegiones = this.regionControl.valueChanges.pipe(
			map((value:any) => {
				if (_.isString(value)) {
					this.region = undefined;
					this.propagateChange(this.region);
					this.onSelectRegion.emit(this.region);
				}

				if (value && value.region) {
					if (value.region !== undefined) {
						value = value.region;
					} else {
						value = value;
					}
				}
				return this._filter(value);
			})
		);
	}

	onChangePais() {
		if (this.pais) {
			this.ubicacionGeograficaServiceImpl.getRegiones(this.pais.id).subscribe({
				next:(regiones: any) => {
				this.regiones = regiones.data;
				if (this.regiones.length == 0) {
					this.dialogService.modalGeneric(
						'ADVERTENCIA',
						'Este pais no tiene una region asociada, por favor comuniquese con el administrador del sistema para realizar la asociacion',
						230,
						560,
						'Aceptar',
						'Aceptar',
						false,
						true
					);
					return;
				}
				this.regionControl.enable();
				this.subscribeRegiones();
			}});
		}
	}

	onRegionChange(event) {
		const newRegion = <RegionModel>event.option.value;
		this.onChange(event, newRegion);
		this.onSelectRegion.emit(event.option.value);
	}

	onChange(e: Event, value: any) {
		this.region = value;
		this.propagateChange(this.region);
		this.onTouched();
	}

	get value(): any {
		return this.region;
	}

	set value(v: any) {
		if (v !== this.region) {
			this.region = v;
		}
	}

	writeValue(region: any) {
		this.region = region;
		this.regionControl.setValue(region);
		this.regionControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
	}

	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	public _filter(value: string): Array<RegionModel> {
		if (value) {
			const filterValue = value.toLowerCase();
			return this.regiones.filter((region) => {
				return region.region.toLowerCase().includes(filterValue);
			});
		}
		return this.regiones;
	}

	ngOnChanges(changes: SimpleChanges): void {
		const paisChange = changes.pais;
		if (typeof paisChange !== 'undefined') {
			const anteriorPais = paisChange.previousValue;
			const nuevoPais = paisChange.currentValue;
			if (anteriorPais) {
				this.writeValue(null);
				this.regionControl.setValue(null);
				this.propagateChange(this.region);
			}
			if (!nuevoPais && !paisChange.isFirstChange()) {
				this.regiones = [];
				this.regionControl.disable();
			} else if (anteriorPais !== nuevoPais) {
				this.regiones = [];
				this.regionControl.disable();
				this.pais = nuevoPais;
				this.onChangePais();
			}
		}
	}
}
