import { ServiceImplService } from '../../service.impl.service';
import { IAcuerdoPagoDescuentoService } from '../acuerdo-pago-descuento.service.interface';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class AcuerdoPagoDescuentoServiceImpl extends ServiceImplService<any> implements IAcuerdoPagoDescuentoService {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'acuerdo-pago-descuento/';
	}
}