import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'safeVideo'
})
export class SafeVideoPipe implements PipeTransform {
	constructor(public sanitizer: DomSanitizer) {}

	transform(value) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(value);
	}
}
