import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ESTADOS_BASE } from 'src/app/core/constants/estados.constants';
import { ProcesoServiceImpl } from '../../../../core/http/configuracion-solicitud-credito/impl/proceso.service.impl';

@Component({
  selector: 'ef-proceso-add',
  templateUrl: './proceso-add.component.html',
  styleUrls: ['./proceso-add.component.scss'],
})
export class ProcesoAddComponent implements OnInit {
  formAdd: FormGroup;
  tipoPersona = ['CLIENTE', 'ADMINISTRATIVO'];
  caracteresEscritos: number = 0;
  procesoNombres: string[];
  aplicaEnum = ['SOLICITUD', 'ACUERDO'];
  id: number;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ProcesoAddComponent>,
    private procesoService: ProcesoServiceImpl,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.id = this.data;
  }

  ngOnInit(): void {
    this.formAdd = this.formBuilder.group({
      nombre: [null, [Validators.required, Validators.pattern(/^\S.*\S$/)]],
      descripcion: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(500), Validators.pattern(/^\S.*\S$/)]],
      tipoPersona: [this.tipoPersona[0], [Validators.required]],
      aplica: [null, [Validators.required]],
    });

    this.procesoService.filter({
      estado: ESTADOS_BASE.ACTIVO,
      id: this.id
    })
      .subscribe({
        next: (rest) => {
          this.procesoNombres = rest.data.map(el => el.nombre.toLowerCase());
        }
      })
  }

  get form() {
    return this.formAdd.controls;
  }

  onFormSubmit() {
    if (navigator.onLine) {
      if (this.formAdd.valid) {
        const procesoNuevo = { ...this.formAdd.value, estado: ESTADOS_BASE.ACTIVO };
        const existNombre = this.procesoNombres.includes(procesoNuevo.nombre.toLowerCase())
        if (existNombre) {
          this.toast.error('Este nombre ya esta en uso');
          return;
        }
        this.procesoService.save(procesoNuevo).subscribe(
          {
            next: (proceso) => {
              this.dialogRef.close(proceso);
            },
            error: (error) => {
              const errorMessage = error.error.message || 'Ha ocurrido un error';
              this.toast.error(errorMessage, 'Error');
            }
          }
        );
      } else {
        this.toast.error('Por favor, completa el formulario correctamente', 'Error');
      }
    } else {
      this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
    }
  }

  calcularCaracteresEscritos(event: Event): void {
    const texto = (event.target as HTMLTextAreaElement).value;
    this.caracteresEscritos = texto.length;
  }

}
