<form [formGroup]="formAdd"
  novalidate
  autocomplete="off"
  class="popup-card-width">
    <div>
      <h2 class="text-center" mat-dialog-title i18n>{{title}}</h2>
    </div>
    <mat-divider class="primary-border mrgn-b-xs"></mat-divider>
    <mat-dialog-content class="pad-t-md">
      <div class="flex justify-around items-center mrgn-b-xs" [innerHTML]="message"></div>
      <mat-card class="p-0 m-0 my-4">
        <mat-radio-group formControlName="debitoAutomatico">
          <mat-radio-button *ngFor="let debito of debitosAutomaticos" class="full-wid mrgn-y-xs rounded pad-all-xs m-auto"
            [ngClass]="{'border border-orange-300 bg-orange-100' : debito.id === form.debitoAutomatico.value?.id}"
            [value]="debito">
            <div class="w-full flex gap-5">
              <img class="w-[10%]" [src]="'https://'+debito.cardUrlLogoPing" alt="card_brand_name">
              <p class="mb-0 inline-block align-center w-[60%]">{{debito.cardHolderName}}</p>
              <p class="mb-0 inline-block align-center w-[15%] text-right">***{{debito.cardNumber}}</p>
              <div class="h-full flex flex-col items-center w-[15%]">
                <small class="pad-all-xs rounded active-status-bg">{{debito.estado}}</small>
              </div>
            </div>
          </mat-radio-button>
        </mat-radio-group>
      </mat-card>
    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
      <button *ngIf="!hideButton" type="button" mat-raised-button
        (click)="dialogRef.close(null)" [disabled]="isLoading">
        {{textButton}}
      </button>
      <button *ngIf="!hideButton" mat-raised-button color="primary"
        (click)="dialogRef.close('agregarTarjeta')" [disabled]="isLoading">
        Agregar Tajeta
        <mat-icon>credit_card</mat-icon>
      </button>
      <button *ngIf="aceptarButton" mat-raised-button
        (click)="onFormSubmit()"  class="btn-color-succes"
        [disabled]="!formAdd.valid">
        Continuar
      </button>
    </mat-dialog-actions>
</form>
