import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PreguntasMedicasServiceImpl } from 'src/app/core/http/configuracion-solicitud-credito/impl/preguntas-medicas.service.impl';
import PreguntasMedicas from 'src/app/core/models/configuracion-solicitud-credito/preguntas-medicas.model';

@Component({
  selector: 'ef-preguntas-medicas-edit',
  templateUrl: './preguntas-medicas-edit.component.html',
  styleUrls: ['./preguntas-medicas-edit.component.scss'],
})
export class PreguntasMedicasEditComponent implements OnInit {
  formEditPreguntasMedicas: FormGroup;
  loading = false;
  mensajeError = false;
  preguntasMedicas: PreguntasMedicas;
  fRechazoAutomatico = false;

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PreguntasMedicasEditComponent>,
    public toast: ToastrService,
    public preguntasMedicasService: PreguntasMedicasServiceImpl,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.preguntasMedicas = data;
  }

  ngOnInit(): void {
    this.loading = true;
    this.formEditPreguntasMedicas = this.formBuilder.group({
      pregunta: [null, [Validators.required]],
      orden: [null, [Validators.required]],
      fRechazoAutomatico: [null],
      estado: [''],
    });
    this.initForm();
  }

  initForm() {
    this.form.pregunta.setValue(this.preguntasMedicas.pregunta);
    this.form.orden.setValue(this.preguntasMedicas.orden);
    this.form.fRechazoAutomatico.setValue(
      this.preguntasMedicas.fRechazoAutomatico
    );
    this.form.estado.setValue(this.preguntasMedicas.estado);
    this.loading = false;
  }
  get form() {
    return this.formEditPreguntasMedicas.controls;
  }

  onFormSubmit() {
    if (navigator.onLine) {
      this.mensajeError = null;
      const preguntasMedicasEdit = Object.assign(
        {},
        this.formEditPreguntasMedicas.value
      );
      if (
        preguntasMedicasEdit.pregunta != this.preguntasMedicas.pregunta ||
        preguntasMedicasEdit.fRechazoAutomatico != this.preguntasMedicas.fRechazoAutomatico ||
        preguntasMedicasEdit.estado != this.preguntasMedicas.estado
      ) {
        this.preguntasMedicasService
          .update(String(this.preguntasMedicas.id), preguntasMedicasEdit)
          .subscribe(
            {
              next: (preguntasMedicas) => {
                this.dialogRef.close(preguntasMedicas);
              },
              error: (error) => {
                try {
                  for (const field of error.error.errors) {
                    this.toast.error(field.message, 'Error');
                  }
                } catch (e) {
                  this.mensajeError = error.error.message;
                }
              }
            }
          );
      } else {
        this.dialogRef.close();
      }
    } else {
      this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
    }
  }

  eventToggleChange(event) {
    this.fRechazoAutomatico = event.checked;
  }
}
