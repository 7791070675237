import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CargoAdministrativoServiceImpl } from 'src/app/core/http/configuracion-basica/impl/cargo-administrativo.service.impl';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';


@Component({
  selector: 'ef-cargo-administrativo-add',
  templateUrl: './cargo-administrativo-add.component.html',
  styleUrls: ['./cargo-administrativo-add.component.scss']
})
export class CargoAdministrativoAddComponent implements OnInit {

  formAddCargoAdministrativo: FormGroup;
  loading = false;
  mensajeError = false;
  procesos = new Array<string>();

  procesoSelected: string;

  constructor(
    public formBuilder: FormBuilder,
    public cargoAdministrativoService: CargoAdministrativoServiceImpl,
    
    public toast: ToastrService,
    public dialogRef: MatDialogRef<CargoAdministrativoAddComponent>,
    @Inject(MAT_DIALOG_DATA) public proceso: string,
  ) {
    this.procesoSelected = proceso;
  }

  ngOnInit() {
    this.formAddCargoAdministrativo = this.formBuilder.group({
      nombre: [
        '',
        [
          Validators.required,
          CustomValidatorsHelper.noWhitespaceValidator
        ]
      ],
      escala: [null,],
      proceso: [null],
      flagUserSecurity: [false]
    });

    this.formAddCargoAdministrativo.get('proceso').setValue(this.procesoSelected);
  }

  get form() {
    return this.formAddCargoAdministrativo.controls;
  }

  onFormSubmit() {
    
    this.mensajeError = null;
    const formValues = Object.assign(
      {},
      this.formAddCargoAdministrativo.value
    );
    formValues.estado = 'ACTIVO';
    this.cargoAdministrativoService
      .save(formValues)
      .subscribe(
        {
          next: cargoAdminvo => {
            ;
            this.dialogRef.close(cargoAdminvo)
          },
          error: error => {
            ;
            try {
              for (const field of error.error.errors) {
                this.toast.error(field.message, 'Error');
              }
            } catch (e) {
            }
          }
        }
      );
  }
}
