import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ef-modal-sin-instructivo',
  templateUrl: './modal-sin-instructivo.component.html',
  styleUrls: ['./modal-sin-instructivo.component.scss']
})
export class ModalSinInstructivoComponent implements OnInit {

  lineaPlanCreditoSelect;
  mensaje;
  constructor(
    public dialogRef: MatDialogRef<ModalSinInstructivoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    this.lineaPlanCreditoSelect = data.isCliente ? data.lineaPlanCreditoSelect : data?.lineaPlanCreditoSelect[0];
    this.mensaje = `Actualmente la línea de crédito <strong>${this.lineaPlanCreditoSelect.lineaCredito.nombre}</strong> asociado a la empresa
    <strong>${this.lineaPlanCreditoSelect.lineaCredito.empresa.replace(/_/g, ' ')}</strong>, no cuenta con un instructivo
    configurado, por favor informar al administrador del sistema para que realice la configuración
     del instructivo correspondiente.`;
   }

  ngOnInit(): void {
  }

}
