<form
[formGroup]="formAdd"
novalidate
autocomplete="off"
class="popup-card-width">
    <div>
      <h2 class="text-center" mat-dialog-title i18n>{{title}}</h2>
    </div>
    <mat-divider class="primary-border mrgn-b-xs"></mat-divider>
    <mat-dialog-content class="pad-t-md">
      <div class="flex justify-around items-center" [innerHTML]="message"></div>
      <mat-form-field class="margin-selects">
        <mat-label class="placeholder" required>Linea de Crédito</mat-label>
        <mat-select formControlName="lineasCredito" required>
          <mat-option *ngFor="let type of lineasCredito" [value]="type">
            {{ type.lineaCredito.nombre }} - {{type.planCredito.nombre }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.lineasCredito.errors">
          <mat-error
            *ngIf="form.lineasCredito.errors.required"
            class="required alert-error"
          >
            <span i18n>Este campo es requerido</span>
          </mat-error>
        </mat-error>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
      <button *ngIf="!hideButton" type="button" mat-raised-button (click)="dialogRef.close()" [disabled]="isLoading">
        {{textButton}}
      </button>
      <button *ngIf="aceptarButton" type="button" mat-raised-button (click)="onFormSubmit()" [disabled]="isLoading">
        {{'Aceptar'}}
      </button>
    </mat-dialog-actions>
</form>
