import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AcuerdoPagoPlanAmortizacionServiceImpl } from 'src/app/core/http/acuerdo-pago/Impl/acuerdo-pago-plan-amortizacion.service.impl';
import { DialogService } from 'src/app/core/services/dialog.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import { es } from 'date-fns/locale';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { LinkPagoConceptoServiceImpl } from 'src/app/core/http/gestion-usuarios/impl/link-pago-concepto.service.impl';
import { LinkPagoServiceImpl } from 'src/app/core/http/gestion-usuarios/impl/link-pago.service.impl';

@Component({
  selector: 'ef-plan-pago-acuerdo',
  templateUrl: './plan-pago-acuerdo.component.html',
  styleUrls: ['./plan-pago-acuerdo.component.scss'],
})
export class PlanPagoAcuerdoComponent implements OnInit {
  acuerdoPagoId;
  totalCuotas: number;
  cuotasPlan: any[] = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  selected = [];
  isComponentAmortizacionAcuerdoPago = true;
  acuerdoPago: any;
  conceptoPagoAcuerdo;
  isDetail: boolean;

  constructor(
    public dialogRef: MatDialogRef<PlanPagoAcuerdoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public toast: ToastrService,
    public spinner: SpinnerService,
    public acuerdoPagoPlanAmoortizacionService: AcuerdoPagoPlanAmortizacionServiceImpl,
    public dialogService: DialogService,
    public linkPagoConceptoService: LinkPagoConceptoServiceImpl,
    public linkPagoService: LinkPagoServiceImpl
  ) {
    this.acuerdoPagoId = data.item.acuerdoPagoExtended.id;
    this.acuerdoPago = data.item;
    this.isDetail = data.isDetail;
  }

  ngOnInit(): void {
    this.loadPlanAmortizacion(this.acuerdoPagoId);
    this.getConceptoPagoAcuerdo();
  }
  getConceptoPagoAcuerdo() {
    this.linkPagoConceptoService
      .filter({
        empresa: 'EDUFACTORING',
        conceptoAsociado: 'PAGO_CUOTA_ACUERDO_PAGO',
      })
      .subscribe({
        next: (conceptos) => (this.conceptoPagoAcuerdo = conceptos.data)
      });
  }

  loadPlanAmortizacion(acuerdoPagoId) {
    
    this.acuerdoPagoPlanAmoortizacionService
      .filter({ acuerdoPagoId: acuerdoPagoId })
      .subscribe(
        {
          next: (info) => {
            ;
            this.cuotasPlan = info.data;
            this.totalCuotas = info.count;
          },
          error: (err) => {
            ;
            if (err.error.errors) {
              for (const error of err.error.errors) {
                this.toast.error(error.message, 'Error');
              }
            }
            if (err.error.message) {
              this.toast.error(err.error.message, 'Error');
            }
          }
        }
      );
  }

  formatDate(date: string): string {
    return format(parseISO(date), 'MMM dd, yyyy', { locale: es });
  }

  getColor(estado): string {
    let color = 'inactive-status-bg';
    switch (estado) {
      case 'ACTIVO':
        color = 'active-status-bg';
        break;
      case 'APROBADO':
        color = 'aprobado-status-bg';
        break;
      case 'PENDIENTE':
        color = 'pendiente-status-bg';
        break;
      case 'ANULADO':
      case 'RECHAZADO':
        color = 'anulado-status-bg';
        break;
    }
    return color;
  }

  onSelect({ selected }) {
    selected = selected.filter((el) => el.estado === 'PENDIENTE');
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  detail(row) {
    const filter = {
      acuerdoPagoId: row.acuerdoPagoId,
      numeroCuota: row.numeroCuota,
    };
    this.dialogService.showPlanAmortizacionDetalle(filter).subscribe({
      next: () => { }
    });
  }

  GenerarLinkPago(cuotas, isSolicitud) {
    let valor = 0;
    for (let cuota of cuotas) {
      valor += (cuota.valorCuota);
    }
    if (valor < this.acuerdoPago.saldoMinimo) {
      this.toast.warning(`El Valor del pago $${valor} es menor que el saldo mínimo $${this.acuerdoPago.saldoMinimo}`);
      return;
    }
    if (valor > this.acuerdoPago.saldoTotal) {
      this.toast.warning(`El Valor del pago $${valor} es mayor que el saldo total $${this.acuerdoPago.saldoTotal}`);
      return;
    }
    this.dialogService
      .modalLinkPago(
        isSolicitud,
        this.acuerdoPago.valorSeguro,
        'GENERAR LINK DE PAGO',
        cuotas,
        'fit-content',
        800,
        'Si',
        'No',
        true,
        this.conceptoPagoAcuerdo[0]
      )
      .subscribe({
        next: (info: any) => {
          if (info) {
            let linkPago: any;
            linkPago = {
              acuerdoPagoId: this.acuerdoPagoId,
              acuerdoPagoPlanAmortizacionIds: info.planAmortizacionIds,
              concepto: this.conceptoPagoAcuerdo[0].nombre,
              currency: info.currency,
              descripcion:
              info.linkPagoConcepto.nombre + ' - ' + info.descripcion,
              importeImpuestoVat: info.importeImpuestoVat,
              linkPagoConceptoId: info.linkPagoConcepto.id,
              monto: info.monto,
              valorDescuento: 0,
              estadoCuenta: true,
              fechasPago: info.fechasPago,
              linkPagoConceptoAsociado: this.conceptoPagoAcuerdo[0].conceptoAsociado
            };
            this.generarLinkPagoAcuerdoPago(linkPago);
          }
        }
      });
  }

  generarLinkPagoAcuerdoPago(linkPago) {
    
    this.linkPagoService.generatePayLinkAcuerdoPago(linkPago).subscribe(
      {
        next: (enlace: any) => {
          ;
          this.showModalLinkPago(enlace);
        },
        error: (err) => {
          ;
          this.errorLinkPago(err);
        }
      }
    );
  }

  showModalLinkPago(enlace) {
    this.dialogService
      .modalGeneric(
        'LINK DE PAGO',
        `<p class="pad-all-md">Este es su link de pago <a class="primary-text" href="${enlace.data.url}" target="_blank">
								${enlace.data.url}</a></p>`,
        250,
        800,
        'Aceptar',
        'Cerrar',
        false,
        true
      )
      .subscribe({
        next: () => {
          this.dialogRef.close();
        }
      });
  }

  errorLinkPago(err) {
    if (err.error.errors) {
      for (const error of err.error.errors) {
        this.toast.error(error.message, 'Error');
      }
    }
    if (err.error.message) {
      this.toast.error(err.error.message, 'Error');
    }
  }
}
