import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FormControl, Validators } from '@angular/forms';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';

@Component({
	selector: 'ef-modal-prompt',
	templateUrl: './modal-prompt.component.html',
	styleUrls: [ './modal-prompt.component.scss' ]
})
export class ModalPromptComponent implements OnInit {
	title: string;
	message: string;
	titleField: string;
	promptValue: FormControl;
	errorMsg = '';
	accept;
	cancel;

	constructor(
		public dialogRef: MatDialogRef<ModalPromptComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		
	) {
		this.title = data.title;
		this.titleField = data.titleField;
		this.message = data.message;
		this.accept = data.accept;
		this.cancel = data.cancel;
	}

	ngOnInit(): void {
		this.promptValue = new FormControl(null, [
			Validators.required,
			Validators.minLength(8),
			CustomValidatorsHelper.noWhitespaceValidator
		]);
	}
}
