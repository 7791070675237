import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomValidatorsHelper } from '@core/helpers/customValidators.helper';
import { ConsultaSarlaftProcessServiceImpl } from '@core/http/historial-consulta-sarlaft/impl/consulta-sarlaft-process.service.impl';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';

type ModalData = {
  clienteNombre: string,
  correo: string,
  numeroDocumento: string,
  numeroTelefono: string
}

@Component({
  selector: 'ef-code-otp',
  templateUrl: './code-otp.component.html',
  styleUrls: ['./code-otp.component.scss']
})
export class CodeOtpComponent implements OnInit {

  formCodigo: FormGroup;
  info: any;
  code: string = '';
  isLoading: boolean = false;
  type: string;

  @ViewChild("inputNumber1", { static: true })
	inputNumber1!: ElementRef;
	@ViewChild("inputNumber2", { static: true })
	inputNumber2!: ElementRef;
	@ViewChild("inputNumber3", { static: true })
	inputNumber3!: ElementRef;
	@ViewChild("inputNumber4", { static: true })
	inputNumber4!: ElementRef;
	@ViewChild("inputNumber5", { static: true })
	inputNumber5!: ElementRef;
	@ViewChild("inputNumber6", { static: true })
	inputNumber6!: ElementRef;

  constructor(
    public formBuilder: FormBuilder,
    public consultaSarlaftProcess: ConsultaSarlaftProcessServiceImpl,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private toast: ToastrService,
    public dialogRef: MatDialogRef<CodeOtpComponent>,
  ) {
    this.info = data;
  }

  ngOnInit(): void {
    this.sendCode();
    this.code = "";
    this.formCodigo = this.formBuilder.group({
			number1: [
				"",
				[Validators.required, CustomValidatorsHelper.numeric],
			],
			number2: [
				"",
				[Validators.required, CustomValidatorsHelper.numeric],
			],
			number3: [
				"",
				[Validators.required, CustomValidatorsHelper.numeric],
			],
			number4: [
				"",
				[Validators.required, CustomValidatorsHelper.numeric],
			],
			number5: [
				"",
				[Validators.required, CustomValidatorsHelper.numeric],
			],
			number6: [
				"",
				[Validators.required, CustomValidatorsHelper.numeric],
			],
		});

    setTimeout(() => {
      this.inputNumber1.nativeElement.focus();
    }, 250);

    this.subscribeNumber1();
		this.subscribeNumber2();
		this.subscribeNumber3();
		this.subscribeNumber4();
		this.subscribeNumber5();

  }

  subscribeNumber1() {
		this.formCodigo.get("number1")?.valueChanges.subscribe((res: any) => {
			if (String(res).length == 1) {
				this.inputNumber2.nativeElement.focus();
			}
		});
	}

	subscribeNumber2() {
		this.formCodigo.get("number2")?.valueChanges.subscribe((res: any) => {
			if (String(res).length == 1) {
				this.inputNumber3.nativeElement.focus();
			}
		});
	}

	subscribeNumber3() {
		this.formCodigo.get("number3")?.valueChanges.subscribe((res: any) => {
			if (String(res).length == 1) {
				this.inputNumber4.nativeElement.focus();
			}
		});
	}

	subscribeNumber4() {
		this.formCodigo.get("number4")?.valueChanges.subscribe((res: any) => {
			if (String(res).length == 1) {
				this.inputNumber5.nativeElement.focus();
			}
		});
	}

	subscribeNumber5() {
		this.formCodigo.get("number5")?.valueChanges.subscribe((res: any) => {
			if (String(res).length == 1) {
				this.inputNumber6.nativeElement.focus();
			}
		});
	}

  clickConfirmButton() {
    for (let key of Object.keys(this.formCodigo.getRawValue())) {
			try {
				this.code += this.formCodigo.get(key)?.value;
			} catch (e) { }
		}

    let values = {
      codigo: this.code,
      numeroDocumento: this.info.info.numeroDocumento,
    };

    this.isLoading = true;
    this.consultaSarlaftProcess.confirmarCodigoSarlaft(values.codigo, values.numeroDocumento)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
      next: (res) => {
        this.code = "";
        this.toast.success("Código OTP confirmado exitosamente");
        localStorage.setItem('codigoSarlaftConfirmado', JSON.stringify(true));
        this.dialogRef.close("true");
      },
      error: (err) => {
        this.code = "";
        this.clearAll();
        this.toast.error(err.error.message);
      },
      complete: () => {
        this.code = "";
      }
    });
  }

  sendCode() {
    this.type = "EMAIL";
    this.isLoading = true;
    let req = {
      correo: this.info.info?.correo,
      documento: this.info.info?.numeroDocumento,
      numeroTelefono: this.info.info?.numeroTelefono,
      clienteNombre: this.info.info?.clienteNombre
    }
    this.consultaSarlaftProcess.enviarCodigoSarlaftCorreo(req)
    .pipe(finalize(() => this.isLoading = false))
    .subscribe({
      next: (res) => {
        this.toast.success("Código OTP enviado exitosamente al correo electrónico del cliente.");
      },
      error: (err) => {
        this.toast.error(err.error.message);
      }
    });

  }

  resendCode() {
    this.type = "EMAIL";
    this.isLoading = true;
    let req = {
      correo: this.info.info.correo,
      documento: this.info.info.numeroDocumento,
      numeroTelefono: this.info.info.numeroTelefono,
      clienteNombre: this.info.info.clienteNombre,
    }
    this.consultaSarlaftProcess.enviarCodigoSarlaftCorreo(req)
    .pipe(finalize(() => this.isLoading = false))
    .subscribe({
      next: (res) => {
        this.toast.success("Código OTP reenviado exitosamente.");
      },
      error: (err) => {
        this.toast.error(err.error.message);
      }
    });
  }

  resendCodeToSMS() {
    this.type = "SMS";
    let req = {
      correo: this.info.info.correo,
      documento: this.info.info.numeroDocumento,
      numeroTelefono: this.info.info.numeroTelefono,
      clienteNombre: this.info.info.clienteNombre,
    }

    this.consultaSarlaftProcess.enviarCodigoSarlaftTelefono(req)
    .pipe(finalize(() => this.isLoading = false))
    .subscribe({
      next: (res) => {
        this.toast.success("Código OTP reenviado exitosamente por mensaje de texto.");
      },
      error: (err) => {
        this.toast.error(err.error.message);
      }
    });
  }

  clearAll() {
		this.formCodigo.reset();
		setTimeout(() => {
			this.inputNumber1.nativeElement.focus();
		}, 100);
	}

  exit() {
		this.dialogRef.close("exit");
	}

}
