<div class="popup-card-width" [style.width.%]="100">
    <h2 mat-dialog-title i18n>Información Del Motor De Consulta</h2>
   
    <mat-divider class="mb-2 primary-border"></mat-divider>
    <mat-dialog-content *ngIf="lineaMotorConsultaDetail.lineaCredito">
        <div class="w-full flex space-between center">
            <div class="md:w-1/3 sm:w-full">
                <strong>Estado: </strong>
            </div>
            <div class="md:w-2/3 sm:w-full">
                <span>{{lineaMotorConsultaDetail.lineaCredito.estado}}</span>
            </div>
        </div>
        <div class="w-full flex space-between center">
            <div class="md:w-1/3 sm:w-full">
                <strong>Nombre: </strong>
            </div>
            <div class="md:w-2/3 sm:w-full">
                <span>{{lineaMotorConsultaDetail.motorConsulta.nombre}}</span>
            </div>
        </div>
        <div class="w-full flex space-between center">
            <div class="md:w-1/3 sm:w-full">
                <strong>Descripción: </strong>
            </div>
            <div class="md:w-2/3 sm:w-full">
                <span>{{lineaMotorConsultaDetail.motorConsulta.descripcion}}</span>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
        <button type="button" mat-raised-button (click)="dialogRef.close()">
            Cerrar
        </button>
    </mat-dialog-actions>
</div>