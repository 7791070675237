export default class UbicacionGeograficaModel {
	id: number;
	codigoRegion: number;
	region: string;
	codigoDaneDpto: number;
	departamento: string;
	codigoDaneMunicipio: number;
	paisId: number;
	municipio: string;
	estado: string;
}
