import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'ef-dialog-generic-shared',
  templateUrl: './dialog-generic-shared.component.html',
  styleUrls: ['./dialog-generic-shared.component.scss']
})
export class DialogGenericSharedComponent implements OnInit {
	@Input() title: string;
	@Input() message: string;
	@Input() accept;
	@Input() cancel;
  form: FormGroup;
	confirmacion = false;
	width;

  constructor(
		public dialogRef: MatDialogRef<DialogGenericSharedComponent>,
		public formBuilder: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any
  ) {
		this.title = data.title;
		this.message = data.message;
		this.accept = data.accept;
		this.cancel = data.cancel;
		this.confirmacion = data.confirmacion;
  }

  ngOnInit(): void {
  }

}
