import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SecurityPermissionServiceImpl } from 'src/app/core/http/security/impl/security-permissions.service.impl';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';

@Component({
	selector: 'ef-permiso-add',
	templateUrl: './permiso-add.component.html',
	styleUrls: [ './permiso-add.component.scss' ]
})
export class PermisoAddComponent implements OnInit {
	formAdd: FormGroup;

	constructor(
		public formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<PermisoAddComponent>,
		
		public toast: ToastrService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public permisoService: SecurityPermissionServiceImpl
	) {}

	ngOnInit() {
		this.initForm();
	}

	initForm() {
		this.formAdd = this.formBuilder.group({
			name: [ null, [ Validators.required, CustomValidatorsHelper.noWhitespaceValidator ] ],
			description: [ null, [ Validators.required, CustomValidatorsHelper.noWhitespaceValidator ] ]
		});
	}

	onFormSubmit() {
		const data = this.formAdd.value;
		data.estado = 'ACTIVO';
		
		this.permisoService.post(data).subscribe(
			(info) => {
				;
				this.toast.success('Permiso creado exitosamente.');
				this.dialogRef.close();
			},
			(err) => {
				;
				if (err.error.errors) {
					for (const error of err.error.errors) {
						this.toast.error(error.message, 'Error');
					}
				} else {
					if (err.error.message) {
						this.toast.error(err.error.message, 'Error');
					}
				}
			}
		);
	}
}
