import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FileHelper } from 'src/app/core/helpers/file.helper';
import { AcuerdoPagoAdjuntoServiceImpl } from 'src/app/core/http/acuerdo-pago/Impl/acuerdo-pago-adjunto.service.impl';
import { DocumentoFaltanteServiceImpl } from 'src/app/core/http/solicitud-aprobacion-manual/impl/documento-faltante.service.impl';

@Component({
	selector: 'ef-adjuntar-documentos-generic',
	templateUrl: './adjuntar-documentos-generic.component.html',
	styleUrls: [ './adjuntar-documentos-generic.component.scss' ],
	encapsulation: ViewEncapsulation.None
})
export class AdjuntarDocumentosGenericComponent implements OnInit {
	@Input() dialog = false;
	@Input() nombreCompleto = null;
	@Input() isPublic = false;
	@Input() isAcuerdoPago = false;
	tipos: Array<string>;
	formAddFiles: FormGroup;
	files: FormArray;
	tiposPublic = [ 'SOPORTE_COMPROBANTE_PAGO' ];

	constructor(
		public documentosFaltantes: DocumentoFaltanteServiceImpl,
		public acuerdoPagoAdjuntoService: AcuerdoPagoAdjuntoServiceImpl,
		public formBuilder: FormBuilder,
		public toast: ToastrService
	) {}

	ngOnInit(): void {
		this.getTipos();
		this.formAddFiles = this.formBuilder.group({
			files: this.formBuilder.array([])
		});
		this.addItem();
	}

	getTipos() {
		if (this.isPublic) {
			this.tipos = this.tiposPublic;
		} else if (this.isAcuerdoPago) {
			this.acuerdoPagoAdjuntoService
				.getTiposDocumentos()
				.subscribe(
					(res) => (this.tipos = res.data),
					() => this.toast.error('Error al consultar los tipos en la base de datos', 'Error')
				);
		} else {
			this.documentosFaltantes
				.getTiposDocumento()
				.subscribe(
					(res) => (this.tipos = res),
					() => this.toast.error('Error al consultar los tipos en la base de datos', 'Error')
				);
		}
	}

	addItem() {
		this.files = this.formAddFiles.get('files') as FormArray;
		this.files.push(this.createItem());
	}

	createItem() {
		return this.formBuilder.group({
			tipoDocumento: [ null, [ Validators.required ] ],
			base64: [ null, [ Validators.required ] ],
			descripcion: [ null, [ Validators.required ] ]
		});
	}

	remove(index) {
		this.files.removeAt(index);
	}

	fileChangeEvent(event, item) {
		let filesToUpload = <Array<File>>event.target.files;
		if (filesToUpload[0].type != 'application/pdf') {
			this.toast.warning('Por favor solo seleccionar archivos en formato PDF', 'Importante');
			item.controls.base64.setValue(null);
			return;
		}
		FileHelper.toBase64(filesToUpload[0]).then((data: string) => {
			item.controls.base64.setValue(data);
		});
	}
}
