<form [formGroup]="formAdd" novalidate autocomplete="off" class="popup-card-width">
  <h2 mat-dialog-title i18n>Nuevo Motor De Consulta</h2>
  <mat-dialog-content>
    <div class="flex flex-wrap">
      <mat-form-field class="w-full mt-3 mb-0">
        <mat-label class="placeholder" required>Línea de Motor de Consulta</mat-label>
        <mat-select formControlName="motorConsulta" required>
          <mat-option *ngFor="let type of motorConsulta" [value]="type">
            {{ type.nombre | titlecase }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.motorConsulta.errors">
          <mat-error *ngIf="form.motorConsulta.errors.required" class="required alert-error">
            <span i18n>Este campo es requerido</span>
          </mat-error>
        </mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="showDetalle" class="mt-3 flex flex-wrap border border-gray-300 rounded-sm pb-2 mx-1">
      <h3 class="w-full text-white py-2 text-center mb-2" style="background-color: #8e8e8e;" i18n>
          Detalle del Motor De Consulta
      </h3>
      <div class="w-1/2 flex flex-wrap mt-2">
          <span class="w-full font-bold text-center">Nombre</span>
          <span class="w-full text-center">{{motorConsultaDetalle.nombre | titlecase}}</span>
      </div>
      <div class="w-1/2 flex flex-wrap mt-2">
          <span class="w-full font-bold text-center">Algoritmo</span>
          <span class="w-full text-center">{{motorConsultaDetalle.algoritmo}}</span>
      </div>
  </div>
  </mat-dialog-content>

  <mat-dialog-actions class="mt-3 mb-1 mx-0 pad-b-none">
    <div class="text-right">
      <button type="button" mat-raised-button (click)="dialogRef.close()">
        Cancelar
      </button>
      <button (click)="onFormSubmit()" [disabled]="!formAdd.valid" mat-raised-button class="accept-button mx-0">
        Asociar
      </button>
    </div>
  </mat-dialog-actions>
</form>
