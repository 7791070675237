import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SecurityRolServiceImpl } from 'src/app/core/http/security/impl/security-rol.service.impl';
import { SecurityPermissionServiceImpl } from 'src/app/core/http/security/impl/security-permissions.service.impl';
import { SecurityRolPermisoServiceImpl } from 'src/app/core/http/security/impl/security-rol-permiso.service.impl';

@Component({
	selector: 'ef-rol-add',
	templateUrl: './rol-add.component.html',
	styleUrls: [ './rol-add.component.scss' ]
})
export class RolAddComponent implements OnInit {
	formAdd: FormGroup;
	permisos: any[] = [];
	search = new FormControl();
	permissionControl = new FormControl();

	$search;

	constructor(
		public formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<RolAddComponent>,
		
		public toast: ToastrService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public rolService: SecurityRolServiceImpl,
		public permisoService: SecurityPermissionServiceImpl,
		public rolPermisoService: SecurityRolPermisoServiceImpl
	) {}

	get form() {
		return this.formAdd.controls;
	}

	ngOnInit() {
		this.formAdd = this.formBuilder.group({
			name: '',
			description: ''
		});
		
		this.permisoService.getAll().subscribe(
			(data) => {
				;
				this.permisos = data;
				this.$search = this.search.valueChanges.pipe(
					startWith(null),
					debounceTime(200),
					switchMap((res: string) => {
						if (!res) {
							return of(this.permisos);
						}
						res = res.toLowerCase();
						return of(this.permisos.filter((x) => x.name.toLowerCase().indexOf(res) >= 0));
					})
				);
			},
			(err) => {
				;
				this.toast.error(err.error.message || 'Error en la carga de los permisos.');
			}
		);
	}

	selectionChange(option: any) {
		let value = this.permissionControl.value || [];
		if (option.selected) {
			value.push(option.value);
		} else {
			value = value.filter((x: any) => x !== option.value);
		}
		this.permissionControl.setValue(value);
	}

	onFormSubmit() {
		if (
			this.formAdd.invalid ||
			this.permissionControl.value === null ||
			this.permissionControl.value.length === 0
		) {
			this.toast.warning('Debe incluir nombre, descripción y elegir almenos un permiso.');
			return;
		}
		
		this.rolService
			.post({
				...this.formAdd.value,
				estado: 'ACTIVO'
			})
			.subscribe(
				(data) => {
					;
					const permisosAux: any[] = this.permissionControl.value;
					this.toast.success('Se creó el rol exitósamente.');
					permisosAux.forEach((elem) => {
						this.rolPermisoService
							.post({
								idRol: data,
								idPermiso: elem.id,
								estado: 'ACTIVO'
							})
							.toPromise();
					});
					this.toast.success('Se asignaron exitósamente todos los permisos al rol creado.');
					this.dialogRef.close();
				},
				(err) => {
					;
					if (err.error.errors) {
						for (const error of err.error.errors) {
							this.toast.error(error.message, error.field);
						}
					} else {
						if (err.error.message) {
							this.toast.error(err.error.message, 'Error');
						}
					}
				}
			);
	}
}
