import { DepartamentoServiceInterface } from '../departamento.service.interface';
import { ServiceImplService } from '../../service.impl.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import DepartamentoModel from '../../../models/ubicaciones/departamento.model';
import { Observable } from 'rxjs';
import ListResponse from '../../../models/core/list-response.model';
import MunicipioModel from '../../../models/ubicaciones/municipio.model';
import BarrioModel from '../../../models/ubicaciones/barrio.model';

@Injectable({
	providedIn: 'root'
})
export class DepartamentoServiceImpl extends ServiceImplService<DepartamentoModel>
	implements DepartamentoServiceInterface {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'departamento/';
	}

	getAll(): Observable<DepartamentoModel[]> {
		return this.executeGet('');
	}

	getBarrios(idDepartamento, idMunicipio): Observable<Array<BarrioModel>> {
		const path = `${idDepartamento}/municipios/${idMunicipio}/barrios`;
		return this.executeGet(path);
	}

	getMunicipios(idDepartamento): Observable<Array<MunicipioModel>> {
		const path = `${idDepartamento}/municipios`;
		return this.executeGet(path);
	}

	getDepartamento(idDepartamento): Observable<DepartamentoModel> {
		const path = `${idDepartamento}/departamento`;
		return this.executeGet(path);
	}

  	getDepartamentoRegion(idPais, idRegion): Observable<DepartamentoModel[]> {
		const path = `pais/${idPais}/region/${idRegion}/departamentos`;
		return this.executeGet(path);
	}
}
