import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ServiceImplService} from '../../service.impl.service';
import { ISecurityRolService } from '../security-rol.service.interface';

@Injectable()
export class SecurityRolServiceImpl extends ServiceImplService<any> implements ISecurityRolService {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'security-rol/';
	}
}
