import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeFirstLetter'
})
@Injectable({
  providedIn: 'root'
})
export class CapitalizeFirstLetterPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    const separators = [' ', '-', '/'];
    return value.toLowerCase().split(new RegExp('[' + separators.join('') + ']'))
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }
}
