<form [formGroup]="formConfigPasoProceso" class="formConfigPasoProceso" novalidate (ngSubmit)="onFormSubmit()">
	<div>
	  <div>
		<h2 mat-dialog-title i18n text>Asignar Paso</h2>
	  </div>
	  <mat-dialog-content>
		<div class="text-center w-full" *ngIf="(!pasos || !pasos.length) && !isLoading">
		  <mat-icon class="ef-large-icon">mood_bad</mat-icon>
		  <p>No existen registros de pasos.</p>
		</div>
		<div class="flex flex-wrap justify-between items-center">
		  <mat-form-field class="mrgn-b-sm custom-label w-full">
			<mat-label style="color: #000">Pasos Disponibles</mat-label>
			<mat-select formControlName="paso">
			  <mat-option *ngFor="let paso of pasos" [value]="paso">
				{{ paso.nombre }}
			  </mat-option>
			</mat-select>
		  </mat-form-field>
		  <mat-form-field class="w-full">
			<mat-label>Orden</mat-label>
			<input type="number" min="0" matInput formControlName="orden">
		  </mat-form-field>
		</div>
	  </mat-dialog-content>
	  <mat-dialog-actions class="mrgn-b-xs pad-b-none flex text-right mt-2">
		<mat-progress-bar mode="indeterminate" *ngIf="isLoading" class="margin-bottom"></mat-progress-bar>
		<button type="button" mat-raised-button (click)="dialogRef.close()">Cancelar
		</button>
		<button type="submit" [disabled]="!formConfigPasoProceso.valid" mat-raised-button color="primary">Asignar
		</button>
	  </mat-dialog-actions>
	</div>
  </form>
  