import { Component, OnInit, Inject } from '@angular/core';
import OpcionModel from '../../../../core/models/configuracion-solicitud-credito/opcion.models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ef-opcion-detail',
  templateUrl: './opcion-detail.component.html',
  styleUrls: ['./opcion-detail.component.scss'],
})

export class OpcionDetailComponent implements OnInit {
  loading = false;
  opcionDetail: OpcionModel;

  constructor(
    public dialogRef: MatDialogRef<OpcionDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.opcionDetail = data;
  }
  ngOnInit(): void { 
  }
}
