import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { EstadoAcuerdoPagoEnum } from 'src/app/core/enums/estado-acuerdo-pago-enum';
import { RazonesAcuerdoPagoPreAprobacionEnum } from 'src/app/core/enums/razones-acuerdo-pago-pre-aprobacion.enum';
import { RazonesSolicitudAprobacionManualAprobadaEnum } from 'src/app/core/enums/razones-solicitud-aprobacion-manual-aprobada.enum';
import { SpaceUnderscorePipe } from 'src/app/core/pipes/space-underscore.pipe';

@Component({
	selector: 'ef-acuerdo-pago-aprobar-dialog',
	templateUrl: './acuerdo-pago-aprobar-dialog.component.html',
	styleUrls: [ './acuerdo-pago-aprobar-dialog.component.scss' ]
})
export class AcuerdoPagoAprobarDialogComponent implements OnInit {
	formAdd: FormGroup;
	isOtro = false;
	numeroAcuerdoPago: number;
	mensajeError = false;
	otroControl = new FormControl(null);
	observacionControl = new FormControl(null, Validators.required);
	razones: Array<string>;
	razonesSelect: Array<string>;
	preAprobar: boolean = false;

	constructor(
		public formBuilder: FormBuilder,
		public toast: ToastrService,
		public pipeService: SpaceUnderscorePipe,
		public dialogRef: MatDialogRef<AcuerdoPagoAprobarDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		const { numeroAcuerdoPago, preAprobar } = data;
		this.numeroAcuerdoPago = numeroAcuerdoPago;
		this.preAprobar = preAprobar;
	}

	ngOnInit(): void {
		this.razonesSelect = new Array<string>();
		this.formAdd = this.formBuilder.group({
			observacion: [ '', Validators.required ]
		});
		if (this.preAprobar) {
			this.razones = this.getRazonesPreAprobar();
		}else {
			this.razones = this.getRazonesAprobar();
		}
	}

	get form() {
		return this.formAdd.controls;
	}

	onFormSubmit() {
		if (this.isOtro) {
			if (this.otroControl.value == '' || this.otroControl.value == null) {
				this.toast.warning('El campo otro es requerido', 'Importante');
				return;
			} else {
				this.razonesSelect.push(this.otroControl.value);
			}
		} else {
			if (this.razonesSelect.length == 0) {
				this.toast.warning('Por favor seleccione al menos una razon', 'Importante');
				return;
			}
		}

		if (this.observacionControl.value == '' || this.observacionControl.value == null) {
			this.toast.warning('El campo observacón es requerido', 'Importante');
			return;
		}

		this.dialogRef.close(`Razon: ${this.razonesSelect.toString()}. ${this.observacionControl.value}`);
	}

	getRazonesAprobar(): Array<string> {
		let documents = new Array<string>();
		for (let type in RazonesSolicitudAprobacionManualAprobadaEnum) {
			documents.push(RazonesSolicitudAprobacionManualAprobadaEnum[type]);
		}
		documents.push('OTRO');
		return documents;
	}

	getRazonesPreAprobar(): Array<string> {
		let documents = new Array<string>();
		for (let type in RazonesAcuerdoPagoPreAprobacionEnum) {
			documents.push(RazonesAcuerdoPagoPreAprobacionEnum[type]);
		}
		documents.push('OTRO');
		return documents;
	}

	setData(event, item) {
		if (event.checked) {
			this.razonesSelect.push(this.pipeService.transform(item));
		} else {
			let index = this.razonesSelect.indexOf(item);
			if (index != -1) {
				this.razonesSelect.splice(index, 1);
			}
		}
	}

	setDataOtro(event) {
		this.isOtro = event.checked;
		if (!event.checked) {
			this.otroControl.setValue('');
		}
	}
}
