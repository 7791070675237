<div class="text-center">
  <h3>Detalle de Pagos Realizados</h3>
</div>
<mat-divider class="primary-border"></mat-divider>
<div class="grid md:grid-cols-5 grid-cols-2 mrgn-y-xs">
  <b>Fecha: </b><span class="col-span-4">{{formatDate(detalle.paymentDate) | titlecase}}</span>
  <b>Transacción: </b><span class="col-span-4">{{detalle.transactionId}}</span>
  <b>Descripción: </b><span class="col-span-4">{{detalle.descripcion}}</span>
</div>
<div>
  <ngx-datatable *ngIf="detalle.items?.length > 0" [style.width.%]="100" class="material" [rows]="detalle.items"
    [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [limit]="totalCuotas"
    [selected]="selected" [selectionType]="SelectionType.checkbox" (select)="onSelect($event)">
    <ngx-datatable-column name="Cuota" prop="solicitudPlanAmortizacion.numeroCuota" [width]="10">
      <ng-template ngx-datatable-cell-template let-row="row" let-value='value' class="text-center">
        <span class="text-center">{{row.solicitudPlanAmortizacion.numeroCuota}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Valor" prop="amount">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value" class="text-center">
        <span class="text-center">{{row.amount | currency:'COP':'symbol':'1.0-0'}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Estado">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value" class="text-center">
        <span class="{{getColor(row.estado)}} status-label"> {{row.estado}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
      [resizeable]="false" class="text-center" *ngIf="validateViewAdmin()">
      <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
        let-selectFn="selectFn">
        <input type="checkbox" [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)" />
      </ng-template>
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value" class="text-center mrgn-all-xs"
        let-onCheckboxChangeFn="onCheckboxChangeFn" let-isSelected="isSelected">
        <input type="checkbox" [checked]="row.estado === 'DEVUELTO' || isSelected"
          [disabled]="row.estado === 'DEVUELTO'" [checked]="" (change)="onCheckboxChangeFn($event)" />
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
<div class="flex justify-end mt-4">
  <button (click)="dialogRef.close(false)" class="cancel-button bg-gray-300 text-gray-700 px-4 py-2 rounded-md mr-2">
    {{ 'cerrar' | titlecase }}
  </button>
  <button (click)="returnPay()" class="accept-button bg-blue-500 text-white px-4 py-2 rounded-md" mat-raised-button
    *ngIf="validateViewAdmin()" [disabled]="selected?.length === 0">
    {{ 'Reembolsar' | titlecase }}
  </button>
</div>
