<form [formGroup]="formAddConfigSarlaft" novalidate autocomplete="off" class="popup-card-width overflow-hidden">
    <div>
        <h2 mat-dialog-title i18n>Nueva Configuración Sarlaft</h2>
    </div>
    <mat-dialog-content>
        <div class="grid md:grid-cols-2 gap-3">
            <mat-form-field class="mrgn-b-sm">
                <input matInput type="text" formControlName="nombre" class="form-control" placeholder="Nombre" />
                <mat-error *ngIf="form.nombre.touched && form.nombre.errors">
                    <mat-error *ngIf="form.nombre.errors.required" class="required alert-error">
                        <span i18n>Este campo es requerido</span>
                    </mat-error>
                    <mat-error
                        *ngIf="formAddConfigSarlaft.get('nombre').errors && formAddConfigSarlaft.get('nombre').errors.hasOwnProperty('maxlength')">
                        <span i18n>El nombre debe tener como máximo 100 caracteres.</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="mrgn-b-sm">
                <input matInput type="text" formControlName="codigo" class="form-control" placeholder="Codigo"/>
                <mat-error *ngIf="form.codigo.touched && form.codigo.errors">
                    <mat-error *ngIf="form.nombre.errors.required" class="required alert-error">
                        <span i18n>Este campo es requerido</span>
                    </mat-error>
                    <mat-error
                        *ngIf="formAddConfigSarlaft.get('codigo').errors && formAddConfigSarlaft.get('codigo').errors.hasOwnProperty('maxlength')">
                        <span i18n>El codigo debe tener como máximo 50 caracteres.</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>
        <mat-form-field class="mrgn-b-sm">
            <textarea matInput type="text" formControlName="descripcion" class="form-control"
                placeholder="Descripción" (input)="calcularCaracteresEscritos($event)">
            </textarea>
            <p class="mb-0">{{ caracteresEscritos }}/500</p>
            <mat-error *ngIf="form.descripcion.touched && form.descripcion.errors">
                <mat-error *ngIf="form.descripcion.errors.required" class="required alert-error">
                    <span i18n>Este campo es requerido</span>
                </mat-error>
                <mat-error
                    *ngIf="formAddConfigSarlaft.get('descripcion').errors && formAddConfigSarlaft.get('descripcion').errors.hasOwnProperty('maxlength')">
                    <span i18n>La descripción debe tener como máximo 500 caracteres.</span>
                </mat-error>
                <mat-error
                    *ngIf="formAddConfigSarlaft.get('descripcion').errors && formAddConfigSarlaft.get('descripcion').errors.hasOwnProperty('minlength')">
                    <span i18n>La descripción debe tener como minimo 10 caracteres.</span>
                </mat-error>
            </mat-error>
        </mat-form-field>
        <div class="grid md:grid-cols-2 gap-3">
            <div class="ml-2 grid">
                <mat-label class="placeholder mr-2">Rechazado</mat-label>
                <mat-slide-toggle formControlName="fRechazo" class="justify-center">
                    {{ form.fRechazo.value ? "SI" : "NO" }}
                </mat-slide-toggle>
            </div>
            <div class="ml-2 grid">
                <mat-label class="placeholder mr-2">Pendiente</mat-label>
                <mat-slide-toggle formControlName="fPendiente" class="justify-center">
                    {{ form.fPendiente.value ? "SI" : "NO" }}
                </mat-slide-toggle>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
        <button type="button" mat-raised-button (click)="dialogRef.close()">
            Cerrar
        </button>
        <button (click)="onFormSubmit()" type="submit" [disabled]="!formAddConfigSarlaft.valid || loading" mat-raised-button
            color="primary">
            Crear
        </button>
    </mat-dialog-actions>
</form>