import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'ef-solicitud-credito-observaciones-detail',
  templateUrl: './solicitud-credito-observaciones-detail.component.html',
  styleUrls: ['./solicitud-credito-observaciones-detail.component.scss']
})
export class SolicitudCreditoObservacionesDetailComponent implements OnInit {
  loading = false;
  observacionDetail: any;

  constructor(
    public dialogRef: MatDialogRef<SolicitudCreditoObservacionesDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
   this.observacionDetail = data.observacionDetail;
  }

  ngOnInit() {
  }

}
