import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TrebolServiceImpl } from '../../core/http/trebol/impl/trebol.service.impl';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { SelectionType, ColumnMode } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';

import { DialogService } from '../../core/services/dialog.service';
import { LinkPagoServiceImpl } from '../../core/http/gestion-usuarios/impl/link-pago.service.impl';
import { LinkPago } from '../../core/models/solicitud-credito/link-pago.model';

@Component({
  selector: 'ef-plan-pago-trebol',
  templateUrl: './plan-pago-trebol.component.html',
  styleUrls: ['./plan-pago-trebol.component.scss']
})
export class PlanPagoTrebolComponent implements OnInit {

  @Input() item;
  @Input() isDetail;
  planPagos: any[] = [];
  totalCuotas = 0;
  SelectionType = SelectionType;
  ColumnMode = ColumnMode;
  selected = [];
  idSolicitudAcuerdo: number;
  @Output() cuotasSeleted = new EventEmitter();
  numeroDocumento: any;
  numero: any;
  isSolicitud: boolean;
  valorSeguro: number = 0;
  isLoading: boolean = true;

  constructor(
    public trebolService: TrebolServiceImpl,
    public toast: ToastrService,

    public dialgoService: DialogService,
    public linkPagoService: LinkPagoServiceImpl,
  ) { }

  ngOnInit(): void {
    if (this.item?.solicitudCreditoExtended) {
      this.isSolicitud = true;
      this.idSolicitudAcuerdo = this.item.solicitudCreditoExtended.id;
      this.numeroDocumento = this.item.solicitudCreditoExtended.cliente.numeroDocumento;
      this.numero = this.item.numero;
    } else {
      this.isSolicitud = false;
      this.idSolicitudAcuerdo = this.item?.acuerdoPagoExtended.id;
      this.numeroDocumento = this.item?.acuerdoPagoExtended.cliente.numeroDocumento;
      this.numero = this.item?.numero;
    }
    this.getPlanPago();
  }

  getPlanPago() {
    let xtotal = this.isDetail ? "S" : "N";
    this.trebolService.getPlanPago(this.numeroDocumento,
      this.numero, xtotal, {isSolicitud: this.isSolicitud, idSolicitudAcuerdo: this.idSolicitudAcuerdo})
      .subscribe({next: (res: any) => {
        this.isLoading = false;
        this.planPagos = res.data;
        this.totalCuotas = res.count;
        this.valorSeguro = res.data.reduce((acc, transaction) => acc + transaction.seguroVida, 0);
      }})
  }

  formatDate(date: any): string {
    const fecha = new Date(date[0], date[1] - 1, date[2]);
    const fechaString = fecha.toLocaleDateString();
    return fechaString;
  }

  generatePayLink() {
    if (this.selected.length === 0) {
      this.toast.warning(
        'Debe elegir almenos una cuota para generar el link de pago.'
      );
      return;
    }
    this.cuotasSeleted.emit(this.selected);
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
}
