import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { IAdministrativo } from '../administrativo.service.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AdministrativoService extends ServiceImplService<any> implements IAdministrativo {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'administrativo/';
	}

	validateNumeroDocumento(numeroDocumento: any): Observable<any> {
		const path = `validar-numero-documento`;
		const myParams = new HttpParams().set('numeroDocumento', numeroDocumento);
		return this.httpClient.get(this.getFullPath() + path, { params: myParams });
	}

	validateAcademico(numeroDocumento: any): Observable<any> {
		const path = `validar-persona-academico`;
		let myParams = new HttpParams().set('numeroDocumento', numeroDocumento);
		return this.httpClient.get(this.getFullPath() + path, { params: myParams });
	}

	updatePersonaSuiteCrm(numeroDocumento: any): Observable<any> {
		const path = `/actualizar/idSsuiteCrm/${numeroDocumento}`;
		return this.httpClient.put(`${this.getFullPath()}${path}`, {});
	}

	activar(idAdministrativo) {
		const path = `${idAdministrativo}/activar`;
		return this.httpClient.put(`${this.getFullPath()}${path}`, {});
	}

	extend(idAdministrativo) {
		const path = `${idAdministrativo}/extend`;
		return this.httpClient.get(`${this.getFullPath()}${path}`);
	}

	verAsesores() {
		const path = `ver/asesores`;
		return this.httpClient.get(`${this.getFullPath()}${path}`);
	}

	cargos(idCargo) {
		const path = `cargos/${idCargo}`;
		return this.httpClient.get(`${this.getFullPath()}${path}`);
	}

	personasInfo(numeroDocumento: number) {
		const path = `personas/${numeroDocumento}/info`;
		return this.httpClient.get(`${this.getFullPath()}${path}`);
	}

	usernameSession() {
		const path = `username/session`;
		return this.httpClient.get(`${this.getFullPath()}${path}`);
	}

	validarEdad(fechaNacimiento) {
		const path = `validar/edad`;
		return this.httpClient.get(`${this.getFullPath()}${path}`, { params: fechaNacimiento });
	}
}
