import { Component, OnInit, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AcuerdoPagoDescuentoDetalleServiceImpl } from '../../../../../core/http/configuracion-basica/impl/acuerdo-pago-descuento-detalle.service.impl';
import { ConceptoCuotaPrioridadServiceImpl } from '../../../../../core/http/acuerdo-pago/Impl/concepto-cuota-prioridad.impl';
import { SpinnerService } from '../../../../../core/services/spinner.service';

@Component({
  selector: 'ef-gestion-descuentos-config-edit',
  templateUrl: './gestion-descuentos-config-edit.component.html',
  styleUrls: ['./gestion-descuentos-config-edit.component.scss']
})
export class GestionDescuentosConfigEditComponent implements OnInit {

  loading = false;
  idDescuentoDetalle;
  formEdit: FormGroup;
  mensajeError = false;
  aplicaciones = ['IGUAL', 'MENOR_IGUAL', 'MENOR', 'MAYOR_IGUAL', 'MAYOR'];
  conceptos: Array<any> = [];
  acuerdoPagoDescuentoId: any;

  constructor(
    public toast: ToastrService,
    public formBuilder: FormBuilder,
    public spinner: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GestionDescuentosConfigEditComponent>,
    public conceptoCuotaPrioridadService: ConceptoCuotaPrioridadServiceImpl,
    public acuerdoPagoDescuentoDetalleService: AcuerdoPagoDescuentoDetalleServiceImpl
  ) {
    this.idDescuentoDetalle = data.descuentoDetalle.id;
  }

  ngOnInit(): void {
    this.formEdit = this.formBuilder.group({
      porcentaje: [null, [Validators.required]],
      aplicacion: [null, [Validators.required]],
      conceptoCuotaPrioridad: [null, [Validators.required]],
    });
    this.loadConceptos();
    this.initForm();
  }

  initForm() {
    this.acuerdoPagoDescuentoDetalleService
      .filterExtended({ id: this.idDescuentoDetalle })
      .subscribe({
        next: (info) => {
          this.acuerdoPagoDescuentoId = info.data[0].acuerdoPagoDescuento.id;
          this.formEdit.get('porcentaje').setValue(info.data[0].porcentaje);
          this.formEdit.get('aplicacion').setValue(info.data[0].aplicacion);
          const selectedConcepto = this.conceptos.find(type => type.concepto === info.data[0].conceptoCuotaPrioridad.concepto);
          this.formEdit.get('conceptoCuotaPrioridad').setValue(selectedConcepto);

        }
      });
  }

  get form() {
    return this.formEdit.controls;
  }

  onFormSubmit() {
    if (navigator.onLine) {
      this.mensajeError = null;
      const descuento = Object.assign({}, this.formEdit.value);
      const descuentoDetalle = {
        porcentaje: descuento.porcentaje,
        aplicacion: descuento.aplicacion,
        conceptoCuotaPrioridadId: descuento.conceptoCuotaPrioridad.id,
        estado: 'ACTIVO',
        acuerdoPagoDescuentoId: this.acuerdoPagoDescuentoId,
        id: this.idDescuentoDetalle
      }
      this.acuerdoPagoDescuentoDetalleService
        .update(this.idDescuentoDetalle, descuentoDetalle)
        .subscribe(
          {
            next: (descuento) => {
              this.dialogRef.close(descuento);
            },
            error: (error) => {
              try {
                for (const field of error.error.errors) {
                  this.toast.error(field.message, 'Error');
                }
              } catch (e) {
                this.mensajeError = error.error.message;
              }
            }
          });
    } else {
      this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
    }
  }

  loadConceptos() {
    
    this.conceptoCuotaPrioridadService.filter({ estado: 'ACTIVO' }).subscribe(
      {
        next: (res) => {
          this.conceptos = res.data;
          ;
        },
        error: (err) => {
          ;
          if (err.error.errors) {
            for (const error of err.error.errors) {
              this.toast.error(error.message, 'Error');
            }
          } else {
            if (err.error.message) {
              this.toast.error(err.error.message, 'Error');
            }
          }
        }
      }
    );
  }
}
