import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { EnfermedadesServiceImpl } from 'src/app/core/http/configuracion-solicitud-credito/impl/enfermedades.service.impl';
import Enfermedad from 'src/app/core/models/configuracion-solicitud-credito/enfermedad-model';

@Component({
  selector: 'ef-enfermedades-edit',
  templateUrl: './enfermedades-edit.component.html',
  styleUrls: ['./enfermedades-edit.component.scss'],
})
export class EnfermedadesEditComponent implements OnInit {
  formEditEnfermedad: FormGroup;
  loading = false;
  mensajeError = false;
  enfermedad: Enfermedad;
  fRechazoAutomatico = false;

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EnfermedadesEditComponent>,
    public toast: ToastrService,
    public enfermedadService: EnfermedadesServiceImpl,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.enfermedad = data;
  }

  ngOnInit(): void {
    this.loading = true;
    this.formEditEnfermedad = this.formBuilder.group({
      nombre: [null, [Validators.required]],
      descripcion: [null, [Validators.required]],
      fRechazoAutomatico: [null],
      estado: [''],
    });
    this.initForm();
  }

  initForm() {
    this.form.nombre.setValue(this.enfermedad.nombre);
    this.form.descripcion.setValue(this.enfermedad.descripcion);
    this.form.fRechazoAutomatico.setValue(this.enfermedad.fRechazoAutomatico);
    this.form.estado.setValue(this.enfermedad.estado);
    this.loading = false;
  }

  get form() {
    return this.formEditEnfermedad.controls;
  }

  onFormSubmit() {
    if (navigator.onLine) {
      this.mensajeError = null;
      const enfermedadEdit = Object.assign({}, this.formEditEnfermedad.value);

      if (
        enfermedadEdit.nombre != this.enfermedad.nombre ||
        enfermedadEdit.fRechazoAutomatico != this.enfermedad.fRechazoAutomatico ||
        enfermedadEdit.estado != this.enfermedad.estado
      ) {
        this.enfermedadService
          .update(String(this.enfermedad.id), enfermedadEdit)
          .subscribe(
            {
              next: (enfermedad) => {
                this.dialogRef.close(enfermedad);
              },
              error: (error) => {
                try {
                  for (const field of error.error.errors) {
                    this.toast.error(field.message, 'Error');
                  }
                } catch (e) {
                  this.mensajeError = error.error.message;
                }
              }
            }
          );
      } else {
        this.dialogRef.close();
      }
    } else {
      this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
    }
  }

  eventToggleChange(event) {
    this.fRechazoAutomatico = event.checked;
  }
}
