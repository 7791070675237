import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { PersonaServiceInterface } from '../persona.service.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import Archivo from '../../../models/core/archivo.model';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class PersonaServiceImpl extends ServiceImplService<any> implements PersonaServiceInterface {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'persona/';
	}

	validateEmail(correo: any): Observable<any> {
		const path = `validar/correo`;
		let params:any = {
			correo: correo
		};
		params = _.omitBy(params, [ _.isUndefined, _.isNull, _.isEmpty ]);
		return this.httpClient.get(this.getFullPath() + path, { params: params });
	}

	validateAge(fechaNacimiento: any): Observable<any> {
		const path = `validar/mayoria-edad`;
		let params:any = {
			fechaNacimiento: fechaNacimiento
		};
		params = _.omitBy(params, [ _.isUndefined, _.isNull, _.isEmpty ]);
		return this.httpClient.get(this.getFullPath() + path, { params: params });
	}

	validateCodigoEmail(email: any, codigo: string): Observable<any> {
		const path = `validar-correo/codigo`;
		let params:any = {
			correo: email,
			codigo: codigo
		};
		params = _.omitBy(params, [ _.isUndefined, _.isNull, _.isEmpty ]);
		return this.httpClient.get(this.getFullPath() + path, { params: params });
	}

	validateCodigoCellPhone(indicativo: any, numeroTelefono: any, codigo: any): Observable<any> {
		const path = `validar-telefono/codigo`;
		let params:any = {
			indicativo: indicativo,
			numeroTelefono: numeroTelefono,
			codigo: codigo
		};
		params = _.omitBy(params, [ _.isUndefined, _.isNull, _.isEmpty ]);
		return this.httpClient.get(this.getFullPath() + path, { params: params });
	}

	validateTelfono(indicativo: any, numeroTelefono: any): Observable<any> {
		const path = `validar/telefono`;
		let params:any = {
			indicativo: indicativo,
			numeroTelefono: numeroTelefono
		};
		params = _.omitBy(params, [ _.isUndefined, _.isNull, _.isEmpty ]);
		return this.httpClient.get(this.getFullPath() + path, { params: params });
	}

	getFotografiaByDocumentoSession(): Observable<Archivo> {
		const path = `fotografia/Session`;
		return this.executeGet(path);
	}

	getFotografiaByDocumento(documento: any): Observable<any> {
		const path = `documento/${documento}/fotografia`;
		return this.executeGet(path);
	}

	setFotografiaByDocumento(personaId: any, params: any): Observable<any> {
		const path = `${personaId}/fotografia`;
		return this.httpClient.post(this.getFullPath() + path, params).pipe(map((res: any) => res));
	}

	getByDocument(documento: any): Observable<any> {
		const path = `documento/${documento}`;
		return this.executeGet(path);
	}

	convertFotoToWebp(data: any): Observable<any> {
		const path = `convert-foto`;
		return this.httpClient.post(this.getFullPath() + path, data);
	}

	actualizarDatos(data: any): Observable<string> {
		const path = `actualizar-persona-general`;
		return this.httpClient.put(this.getFullPath() + path, data).pipe(map((res: any) => res));
	}

	getInfoPersonSession(): Observable<any> {
		const path = `info/Session`;
		return this.executeGet(path);
	}

	actualizarSuiteCrmMasivo(): Observable<any> {
		const path = `actualizar-suitecrm`;
		return this.executeGet(path);
	}
}
