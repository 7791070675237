import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { AcuerdoPagoAdjuntoServiceInterface } from '../acuerdo-pago-adjunto.service.interface';
import AdjuntarDocumentoModel from 'src/app/core/models/acuerdo-pago/adjuntar-documento';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AcuerdoPagoAdjuntoServiceImpl extends ServiceImplService<any>
	implements AcuerdoPagoAdjuntoServiceInterface {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'acuerdo-pago-adjunto/';
	}

	generarTokenCargaDocumentos(email: string, acuerdoPagoId: number) {
		const path = `enviar-email/cliente`;
		const params: AdjuntarDocumentoModel = {
			email: email,
			acuerdoPagoId: acuerdoPagoId
		};
		return this.httpClient.put(this.getFullPath() + path, params).pipe(map((res: any) => res));
	}

	cargarDocumentos(numeroAcuerdoPago: number, documents: Array<any>) {
		const path = `documento`;
		let insertRequest = {
			numeroAcuerdoPago: numeroAcuerdoPago,
			documents: documents
		};
		return this.httpClient.post(this.getFullPath() + path, insertRequest).pipe(map((res: any) => res));
	}

	filterExtended(filter) {
		filter = _.omitBy(filter, _.isNil);
		const path = `filter-extended`;
		return this.httpClient.get(this.getFullPath() + path, { params: filter }).pipe(map((res: any) => res));
	}

	getTiposDocumentos() {
		const path = `tipos-documento`;
		return this.httpClient.get(this.getFullPath() + path).pipe(map((res: any) => res));
	}

	getDocumentoAdjunto(documentoId: string): Observable<any> {
		const path = `${documentoId}/documento`;
		return this.httpClient.get(this.getFullPath() + path).pipe(map((res: any) => res));
	}
}
