import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'ef-modal-server-error',
	templateUrl: './modal-server-error.component.html',
	styleUrls: [ './modal-server-error.component.scss' ]
})
export class ModalServerErrorComponent implements AfterContentInit {
	errorResponse: HttpErrorResponse;
	errorData: HttpErrorResponse;
	visible: boolean;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ModalServerErrorComponent>) {}
  //Comentario de prueba
	ngAfterContentInit() {
		this.errorResponse = this.data.errorResponse;
	}
}
