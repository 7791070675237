import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from '@angular/common/http'; 
import LineaMotorConsultaModel from 'src/app/core/models/configuracion-basica/linea-motor-consulta';
import { LineaMotorConsultaInterface } from '../lineas-motor-consulta.service.interface';

@Injectable({
  providedIn: 'root',
})
export class LineaMotorConsultaServiceImpl
  extends ServiceImplService<LineaMotorConsultaModel>
  implements LineaMotorConsultaInterface
{
  constructor(private http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'linea-motor-consulta/';
  }
}
