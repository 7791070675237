import RouteModel from '../models/route.model';

export const ROUTES_CONST = {
	LOGIN: { name: 'Login', routerLink: '/security/login', path: 'security/login' } as RouteModel,
	FORBIDDEN: { name: 'Forbidden', routerLink: '/security/403', path: 'security/403' } as RouteModel,
  CONFIRM_PSWD: <RouteModel>{ name: 'Confirmar Clave', routerLink: '/security/confirmar-clave', path: 'security/confirmar-clave' },
	HOME: { name: 'Inicio', routerLink: '/main/dashboard', path: 'main/dashboard' } as RouteModel,
	PERFIL: { name: 'Perfil', routerLink: '/security/perfil', path: 'security/perfil' } as RouteModel,
	HELP: { name: 'Ayuda', routerLink: '/main/ayuda', path: 'main/ayuda' } as RouteModel
};
