<form
  [formGroup]="formAddCargoAdministrativo"
  novalidate
  autocomplete="off"
  class="popup-card-width">
  <div>
    <h2 mat-dialog-title i18n>Nuevo Cargo</h2>
  </div>
  <mat-dialog-content>
    <div>
      <div>
        <div class="flex justify-center">
          <div class="w-[80%]">
            <mat-form-field class="mrgn-b-sm">
              <input matInput type="text" formControlName="nombre" class="form-control" placeholder="Nombre"/>
              <mat-error *ngIf="form.nombre.touched && form.nombre.errors">
                <mat-error *ngIf="form.nombre.errors.required" class="required alert-error">
                  <span i18n>Este campo es requerido</span>
                </mat-error>
                <mat-error *ngIf="!form.nombre.errors.required" class="required alert-error">
                  <span i18n>{{ form.nombre.errors }}</span>
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close()">
      Cerrar
    </button>
    <button (click)="onFormSubmit()" type="submit" [disabled]="!formAddCargoAdministrativo.valid || loading"
            mat-raised-button color="primary">
      Crear
    </button>
  </mat-dialog-actions>
</form>
