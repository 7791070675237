<form [formGroup]="formAddCargoAdministrativo" novalidate autocomplete="off" class="popup-card-width">
  <div>
    <h1 mat-dialog-title i18n>Nuevo Cargo Administrativo</h1>
  </div>
  <mat-dialog-content>
    <div class="grid md:grid-cols-2 gap-2">
      <mat-form-field class="mrgn-b-sm mt-2">
        <input matInput type="text" formControlName="nombre" class="form-control" placeholder="Nombre" />
        <mat-error *ngIf="form.nombre.touched && form.nombre.errors">
          <mat-error *ngIf="form.nombre.errors.required" class="required alert-error">
            <span i18n>Este campo es requerido</span>
          </mat-error>
          <mat-error *ngIf="!form.nombre.errors.required" class="required alert-error">
            <span i18n>{{ form.nombre.errors }}</span>
          </mat-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="margin-selects pt-2">
        <mat-label class="placeholder" required>Proceso</mat-label>
        <mat-select formControlName="proceso" required>
          <mat-option *ngFor="let type of procesos" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.proceso.errors">
          <mat-error *ngIf="form.proceso.errors.required" class="required alert-error">
            <span i18n>Este campo es requerido</span>
          </mat-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="mrgn-b-sm">
        <input matInput type="number" formControlName="escala" class="form-control" placeholder="Escala" />
        <mat-error *ngIf="form.escala.touched && form.escala.errors">
          <mat-error *ngIf="form.escala.errors.min" class="required alert-error">
            <span i18n>La escala debe ser mayor a cero</span>
          </mat-error>
        </mat-error>
      </mat-form-field>
      <div>
        <div class="flex justify-center">Usuario Auth0</div>
        <div class="flex justify-center">
          <mat-slide-toggle formControlName="flagUserSecurity">
            {{ form.flagUserSecurity.value ? 'SI':'NO' }}
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mt-4 text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close()">
      Cerrar
    </button>
    <button (click)="onFormSubmit()" type="submit" [disabled]="!formAddCargoAdministrativo.valid || loading"
      mat-raised-button color="primary">
      Crear
    </button>
  </mat-dialog-actions>
</form>