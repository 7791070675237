import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ESTADOS_BASE } from 'src/app/core/constants/estados.constants';
import { OpcionServiceImpl } from '../../../../core/http/configuracion-solicitud-credito/impl/opcion.service.impl';
import OpcionModel from 'src/app/core/models/configuracion-solicitud-credito/opcion.models';

@Component({
  selector: 'ef-opcion-add',
  templateUrl: './opcion-add.component.html',
  styleUrls: ['./opcion-add.component.scss'],
})
export class OpcionAddComponent implements OnInit {
  formAdd: FormGroup;
  tipoPersona = ['CLIENTE', 'ADMINISTRATIVO', 'AMBOS'];
  caracteresEscritos: number = 0;
  opciones: OpcionModel[];
  opcionNombres: string[];
  loading = false;
  mensajeError = false;
  id: number;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<OpcionAddComponent>,
    private opcionService: OpcionServiceImpl,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) {
    this.id = this.data;
  }

  ngOnInit(): void {
    this.formAdd = this.formBuilder.group({
      nombre: [null, [Validators.required, Validators.pattern(/^\S.*\S$/)]],
      descripcion: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(500), Validators.pattern(/^\S.*\S$/)]],
      tipoPersona: [this.tipoPersona[0], [Validators.required]],
    });

    this.opcionService.filter({
      estado: ESTADOS_BASE.ACTIVO,
      id: this.id
    })
      .subscribe({
        next: (rest) => {
          this.opcionNombres = rest.data.map(el => el.nombre.toLowerCase());
        }
      })
  }

  get form() {
    return this.formAdd.controls;
  }

  onFormSubmit() {
    if (navigator.onLine) {
      if (this.formAdd.valid) {
        const opcionNuevo = { ...this.formAdd.value, estado: ESTADOS_BASE.ACTIVO };
        const existNombre = this.opcionNombres.includes(opcionNuevo.nombre.toLowerCase())
        if (existNombre) {
          this.toast.error('Este nombre ya esta en uso');
          return;
        }
        this.opcionService.save(opcionNuevo).subscribe(
          {
            next: (opcion) => {
              this.dialogRef.close(opcion);
            },
            error: (error) => {
              const errorMessage = error.error.message || 'Ha ocurrido un error';
              this.toast.error(errorMessage, 'Error');
            }
          }
        );
      } else {
        this.toast.error('Por favor, completa el formulario correctamente', 'Error');
      }
    } else {
      this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
    }
  }

  calcularCaracteresEscritos(event: Event): void {
    const texto = (event.target as HTMLTextAreaElement).value;
    this.caracteresEscritos = texto.length;
  }

}
