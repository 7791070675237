import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServiceImplService } from "../../service.impl.service";
import { IDebitoAutomaticoProcesoService } from "../debito-automatico-proceso.service.interface";
import { DebitoAutomaticoProcesoModel } from "src/app/core/models/debito-automatico/debito-automatico-proceso.model";

@Injectable({
	providedIn: 'root'
})
export class DebitoAutomaticoProcesoServiceImpl
  extends ServiceImplService<DebitoAutomaticoProcesoModel>
  implements IDebitoAutomaticoProcesoService {

	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'debito-automatico-proceso/';
	}
}
