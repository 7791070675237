<form [formGroup]="formAdd"
      novalidate
      class="popup-card-width">
  <div>
    <h2 mat-dialog-title
        i18n>{{cliente?.flagListaNegra ? 'Desbloqueo Lista Negra':'Bloqueo Lista Negra'}}</h2>
  </div>
  <mat-dialog-content>
    <div>
      <div class="flex justify-center items-center">
        <div class="flex flex-col text-center">
          <strong>Nombre Cliente</strong>
          <p>{{cliente?.nombreCompleto}}</p>
        </div>
      </div>
      <div class="flex">
        <mat-form-field>
						<textarea
              matInput
              autocomplete="off"
              cdkTextareaAutosize
              cdkAutosizeMinRows="6"
              cdkAutosizeMaxRows="6"
              maxlength="1000"
              formControlName="motivo"></textarea>
          <mat-label class="placeholder">Motivo</mat-label>
          <mat-error *ngIf="form.motivo.touched && form.motivo.errors">
            <mat-error *ngIf="form.motivo.errors.required"
                       class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
          </mat-error>
        </mat-form-field>

      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button"
            mat-raised-button
            (click)="dialogRef.close(false)">Cancelar
    </button>
    <button (click)="onFormSubmit()"
            type="submit"
            [disabled]="!formAdd.valid"
            mat-raised-button
            color="primary">Guardar
    </button>
  </mat-dialog-actions>
</form>
