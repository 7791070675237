import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataTypeAttribute } from '../../../../core/constants/data-type-attribute.constants';
import { PlantillaParametroServiceImpl } from '../../../../core/http/configuracion-firma-electronica/impl/plantilla-parametro.service.impl';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';

@Component({
  selector: 'ef-gestion-plantillas-parametro-edit',
  templateUrl: './gestion-plantillas-parametro-edit.component.html',
  styleUrls: ['./gestion-plantillas-parametro-edit.component.scss']
})
export class GestionPlantillasParametroEditComponent implements OnInit {

  plantillaParmetro;
	formEditPlantillaParametro!: FormGroup;
	loading = false;
	mensajeError:any = false;
	plantillaParametroCargado = false;
	tipoAtributos: Array<String> = Object.values(DataTypeAttribute);
	atributoNombres:any[] = [];
	proceso: any;
  
  constructor(
		public  formBuilder: FormBuilder,
		public   dialogRef: MatDialogRef<GestionPlantillasParametroEditComponent>,
		public  plantillaParametroService: PlantillaParametroServiceImpl,
		public  toasterService: ToastrService,
		@Inject(MAT_DIALOG_DATA) public   data: any) {
		this.plantillaParmetro = data.data;
		this.proceso = data.proceso;
	}

  ngOnInit() {
		this.getAtributoNombres();
		this.formEditPlantillaParametro = this.formBuilder.group({
			atributoNombre: [this.plantillaParmetro?.atributoNombre || '', [Validators.required, CustomValidatorsHelper.noWhitespaceValidator]],
			atributoTipo: [this.plantillaParmetro?.atributoTipo || '', [Validators.required]],
			nombreLlave: [this.plantillaParmetro?.nombreLlave || '', [Validators.required, CustomValidatorsHelper.noWhitespaceValidator]],
		});
		this.formEditPlantillaParametro.get('atributoTipo')?.disable();
		this.formEditPlantillaParametro.get('nombreLlave')?.disable();
		this.plantillaParametroCargado = true;
	}

	getAtributoNombres() {
		this.plantillaParametroService.getAtributosNombre(this.proceso).subscribe({
			next: (data: any) => {
				let mapa = data.parametrosClass;
				this.atributoNombres = Object.keys(mapa).map(clave => (clave));
			}
		})
	}

	get form() {
		return this.formEditPlantillaParametro.controls;
	}

	onFormSubmit() {
		if (navigator.onLine) {
			this.mensajeError = null;
			const parametroGeneralEdit = Object.assign({}, this.formEditPlantillaParametro.value);
			this.plantillaParmetro.atributoNombre = parametroGeneralEdit.atributoNombre;
			
			this.plantillaParametroService.update(String(this.plantillaParmetro.id), this.plantillaParmetro)
				
				.subscribe(
					(response: any) => this.dialogRef.close(response),
					(error: any) => {
						try {
							for (let field of error.error.errors) {
								this.toasterService.error(field.message, 'Error');
							}
						} catch (e) {
							this.mensajeError = error.error.message
						}
					}
				);
		} else {
			this.toasterService.error('Por favor, revisa tu conexión a internet', 'Error');
		}
	}

}
