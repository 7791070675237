<form [formGroup]="formAddBarrio" novalidate autocomplete="off" class="popup-card-width">
  <div>
    <h2 mat-dialog-title i18n>Nuevo Barrio</h2>
  </div>
  <mat-dialog-content>
    <div>
      <div>
        <ef-select-departamento [width]="100" [departamentoDefault]="departamento" class="form-control mrgn-b-sm"
          [label]="'Departamento'" formControlName="departamento" [pais]="pais">
        </ef-select-departamento>

        <ef-select-municipio [width]="100" class="form-control mrgn-b-sm" [label]="'Municipio'"
          formControlName="municipio" [departamento]="form.departamento.value">
        </ef-select-municipio>
        <mat-form-field class="mrgn-b-sm">
          <input matInput type="text" formControlName="nombre" class="form-control" required>
          <mat-placeholder class="placeholder">Nombre</mat-placeholder>
          <mat-error *ngIf="form.nombre.touched && form.nombre.errors">
            <mat-error *ngIf="form.nombre.errors.required" class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
            <mat-error *ngIf="!form.nombre.errors.required" class="required alert-error">
              <span i18n>{{form.nombre.errors}}</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="mrgn-b-sm">
          <input matInput type="text" formControlName="comuna" class="form-control">
          <mat-placeholder class="placeholder">Comuna</mat-placeholder>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close(false)">Cancelar
    </button>
    <button type="submit" [disabled]="!formAddBarrio.valid" (click)="onFormSubmit()" mat-raised-button
      color="primary">Crear
    </button>
  </mat-dialog-actions>
</form>