import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from '@angular/common/http';
import { SolicitudFirmaDocumentoModel } from 'src/app/core/models/configuracion-solicitud-credito/solicitud-firma-documento.model';
import { SolicitudFirmaDocumentoServiceInterface } from '../solicitud-firma-documento.service.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SolicitudFirmaDocumentoServiceImpl
  extends ServiceImplService<SolicitudFirmaDocumentoModel>
  implements SolicitudFirmaDocumentoServiceInterface
{
  constructor(public http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'solicitud-firma-documento/';
  }

  getContratoUnificadoZapSingPdf(idSolicitud: number): Observable<BlobPart> {
    const path = `${idSolicitud}/contrato-unificado-zap-sign-pdf`;
    return this.executeGetPDF(path);
  }

  validarContratoUnificadoZapSing(idSolicitud: number) {
    const path = `${idSolicitud}/validar-contrato-unificado-zap-sign-pdf`;
    return this.http.get(this.getFullPath() + path);
  }
}
