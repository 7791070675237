import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FileHelper } from 'src/app/core/helpers/file.helper';
import Archivo from 'src/app/core/models/core/archivo.model';
import { finalize } from 'rxjs/operators';
import { PlantillaServiceImpl } from 'src/app/core/http/configuracion-firma-electronica/impl/plantilla.service.impl';

@Component({
  selector: 'ef-modal-cargar-archivo',
  templateUrl: './modal-cargar-archivo.component.html',
  styleUrls: ['./modal-cargar-archivo.component.scss']
})
export class ModalCargarArchivoComponent implements OnInit {

  base64: string = '';
	opcion: any = 0;
	public   documentList: any[] = [];
	libro: any;
	tipoArchivo: string = '';
	nombreArchivo: string = '';
	sizeArchivo: any = 0;
	mensajeError: any = false;
	loading: boolean = false;
	proceso: string;
	porcentajeCarga: string = `Progreso de carga: 0%`

  constructor(private toast: ToastrService,
		private plantillaService: PlantillaServiceImpl,
		public dialogRef: MatDialogRef<ModalCargarArchivoComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.opcion = data.opcion;
		this.proceso = data.proceso;
	}

  ngOnInit(): void {
  }

  fileChangeEvent(event: any) {
		let filesToUpload = <Array<File>>event.target.files;
		let type = filesToUpload[0].name.split('.')[1];
		if (this.opcion == 5 && type != 'docx') {
			this.toast.warning('Por favor solo seleccionar archivos en formato DOCX (Word)', 'Importante');
			return;
		} else if (type != 'xlsx' && this.opcion != 5) {
			this.toast.warning('Por favor solo seleccionar archivos en formato XLSX', 'Importante');
			return;
		}
		this.tipoArchivo = filesToUpload[0].name.split('.')[1];
		this.nombreArchivo = filesToUpload[0].name.split('.')[0];
		this.sizeArchivo = filesToUpload[0].size;
		this.documentList = event.target.files;
		FileHelper.toBase64(filesToUpload[0]).then((data: any) => {
			this.base64 = data.split(',')[1];
		});
	}


	aceptar() {
		let file = <Archivo>{
			base64: this.base64,
			base64WebP: '',
			bucket: '',
			extension: '',
			fechaCreacion: '',
			nombre: '',
			kbytes: 0,
			kbytesWebP: 0
		}

		if (this.opcion == 5) {
			let data = {
				base64: this.base64,
				nombre: this.nombreArchivo,
				nombreLibroLabs: this.libro,
				extension: this.tipoArchivo,
				kbytes: this.sizeArchivo
			}
			this.loading = true;
			this.plantillaService.insertarPlantillaWord(data, this.proceso)
				.pipe(finalize(() => this.loading = false))
				.subscribe(
					(res: any) => {
						this.dialogRef.close(res);
					},
					(error: any) => {
						this.toast.error('Ocurrio un error al ejecutar el proceso de carga', 'Error');
						try {
							for (let field of error.error.errors) {
								this.toast.error(field.message, field.field);
							}
						} catch (e) {
							this.mensajeError = error.error.message;
						}
					});
		}
	}

}
