import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import { GestionPasoServiceImpl } from 'src/app/core/http/configuracion-basica/impl/gestion-pasos.service.impl';
import GestionPaso from 'src/app/core/models/configuracion-solicitud-credito/gestion-pasos.model';

@Component({
  selector: 'ef-gestion-pasos-edit',
  templateUrl: './gestion-pasos-edit.component.html',
  styleUrls: ['./gestion-pasos-edit.component.scss']
})
export class GestionPasosEditComponent implements OnInit {

  formEditPaso: FormGroup;
  loading = false;
  mensajeError = false;
  paso: GestionPaso;
  pasoCargando = false;
  estados = ['ACTIVO', 'INACTIVO']
  caracteresEscritos: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<GestionPasosEditComponent>,
    private toast: ToastrService,
    private pasosService: GestionPasoServiceImpl,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.paso = data;
  }

  ngOnInit() {
    this.loading = false;
    this.formEditPaso = this.formBuilder.group({
      nombre: [this.paso.nombre, [Validators.required, CustomValidatorsHelper.noWhitespaceValidator]],
      path: [this.paso.path, [Validators.maxLength(250)]],
      descripcion: [this.paso.descripcion, [Validators.required, Validators.minLength(10), Validators.maxLength(500)]],
    });
  }

  get form() {
    return this.formEditPaso.controls;
  }

  onFormSubmit() {
    
    this.mensajeError = null;
    const formValue = Object.assign(
      {},
      this.formEditPaso.value
    );
    const dataEdit = {
      descripcion: formValue.descripcion,
      nombre: formValue.nombre,
      path: formValue.path,
      estado: this.paso.estado
    }
    this.pasosService
      .update(String(this.paso.id), dataEdit)
      .subscribe(
        {
          next: response => {
            ;
            this.dialogRef.close(response)
          },
          error: error => {
            ;
            try {
              for (const field of error.error.errors) {
                this.toast.error(field.message, 'Error');
              }
            } catch (e) {
              this.mensajeError = e.error.message;
            }
          }
        }
      );
  }

  calcularCaracteresEscritos(event: Event): void {
    const texto = (event.target as HTMLTextAreaElement).value;
    this.caracteresEscritos = texto.length;
  }
}
