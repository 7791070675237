<form
  [formGroup]="formAdd"
  novalidate
  autocomplete="off"
  class="popup-card-width"
>
  <div>
    <h2 mat-dialog-title i18n>Agregar Nueva Enfermedad</h2>
  </div>
  <mat-dialog-content>
    <div>
      <div>
        <mat-form-field>
          <mat-label class="placeholder">Nombre</mat-label>
          <input matInput formControlName="nombre" />
          <mat-error *ngIf="form.nombre.touched && form.nombre.errors">
            <mat-error
              *ngIf="form.nombre.errors.required"
              class="required alert-error"
            >
              <span i18n>Este campo es requerido</span>
            </mat-error>
            <mat-error
              *ngIf="!form.nombre.errors.required"
              class="required alert-error"
            >
              <span i18n>{{ form.nombre.errors }}</span>
            </mat-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label class="placeholder">Descripción</mat-label>
          <input matInput formControlName="descripcion" />
          <mat-error
            *ngIf="form.descripcion.touched && form.descripcion.errors"
          >
            <mat-error
              *ngIf="form.descripcion.errors.required"
              class="required alert-error"
            >
              <span i18n>Este campo es requerido</span>
            </mat-error>
            <mat-error
              *ngIf="!form.descripcion.errors.required"
              class="required alert-error"
            >
              <span i18n>{{ form.descripcion.errors }}</span>
            </mat-error>
          </mat-error>
        </mat-form-field>

        <div class="pad-y-md mx-0">
          <div>
            <div>
              <strong> Rechazo Automatico </strong>
            </div>
            <div>
              <mat-slide-toggle formControlName="fRechazoAutomatico" (change)="eventToggleChange($event)">
                {{form.fRechazoAutomatico.value ? 'SI' : 'NO'}}
              </mat-slide-toggle>
            </div>
          </div>
        </div>

      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="mrgn-b-xs pad-b-none">
    <div class="text-right">
      <button type="button" mat-raised-button (click)="dialogRef.close()">
        Cancelar
      </button>
      <button
        (click)="onFormSubmit()"
        [disabled]="!formAdd.valid"
        mat-raised-button
        class="accept-button"
      >
        Crear
      </button>
    </div>
  </mat-dialog-actions>
</form>
