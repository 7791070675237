import { IPlantillaServiceInterface } from "../plantilla.service.interface";
import { map } from "rxjs/operators";
import { ServiceImplService } from '../../service.impl.service';
import {Injectable} from '@angular/core';
import PlantillaModel from '../../../models/configuracion-firma-electronica/plantilla.model';
import {HttpClient} from '@angular/common/http';

@Injectable({ providedIn: "root" })
export class PlantillaServiceImpl extends ServiceImplService<PlantillaModel> implements IPlantillaServiceInterface {
  constructor(public  http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = "plantilla/";
	}

	getExtended(id: number): any {
		const path = `${id}/extended`;
		return this.executeGet(path).pipe(map((res: any) => res.data));
	}

	insertarPlantillaWord(data: any, proceso: string) {
		let path = `proceso/${proceso}/insertar-plantilla-word`
		return this.httpClient.post(this.getFullPath() + path, data).pipe(map((res: any) => res.data));
	}
}
