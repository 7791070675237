<div>
    <h2 mat-dialog-title i18n>
        Autorizar Debito Automatico
        <mat-divider></mat-divider>
    </h2>
</div>
<div class="h-auto">
    <form *ngIf="needRegion" class="grid md:grid-cols-2 sm:grid-cols-1 gap-2 mrgn-b-xs" [formGroup]="regionForm">
        <ef-select-generic formControlName="departamento" [items]="departamentos" [title]="'Departamento'">
        </ef-select-generic>
        <ef-select-generic formControlName="municipio" [items]="municipios" [title]="'Municipio'">
        </ef-select-generic>
        <span class="col-span-2 text-center">{{region?region.region:'Región --------'}}</span>
    </form>
    <div id="loadPyament">
        <form class="panel" id="add-card-form">
            <div class="paymentez-form rebus" id="my-card" data-capture-name="true"></div>
            <mat-dialog-actions class="mrgn-t-xs pad-b-none text-right">
                <button mat-raised-button color="primary" class="btn">
                    Guardar Tarjeta
                </button>
                <button mat-raised-button class="warn-bg" (click)="dialogRef.close(false)">
                    Cerrar
                </button>
            </mat-dialog-actions>
            <br />
            <div id="messages"></div>
        </form>
    </div>
</div>
