import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomValidatorsHelper } from '@core/helpers/customValidators.helper';
import { ToastrService } from 'ngx-toastr';
import { RazonesSolicitudAprobacionManualAprobadaEnum } from 'src/app/core/enums/razones-solicitud-aprobacion-manual-aprobada.enum';
import { RazonesSolicitudAprobacionManualRechazadaEnum } from 'src/app/core/enums/razones-solicitud-aprobacion-manual-rechazada.enum';
import { SolicitudAprobacionManualServiceImpl } from 'src/app/core/http/solicitud-aprobacion-manual/impl/solicitud-aprobacion-manual.service.impl';
import { SpaceUnderscorePipe } from 'src/app/core/pipes/space-underscore.pipe';

@Component({
  selector: 'ef-modal-aprobar-rechazar-solicitud',
  templateUrl: './modal-aprobar-rechazar-solicitud.component.html',
  styleUrls: ['./modal-aprobar-rechazar-solicitud.component.scss']
})
export class ModalAprobarRechazarSolicitudComponent implements OnInit {

  formAdd: FormGroup;
  loading = false;
  mensajeError = false;
  caracteresEscritos: number = 0;
  
  motivoRechazo: string[] = [
    'Capacidad de endeudamiento',
    'Capacidad de pago',
    'Perfil crediticio no apto para crédito',
    'Situación de salud no apta para póliza',
    'Actividad económica inconsistente',
    'Solicitud funcionario alianza'
  ];

  otroControl = new FormControl(null);
  isOtroSelected = false;
  solicitudAprobhacionManualId: any;
  isAprobar = false;
  razones: Array<string>;
  razonesSelect: Array<string>;

  constructor(
    public formBuilder: FormBuilder,
    public toast: ToastrService,
    public pipeService: SpaceUnderscorePipe,
    public solicitudAprobacionManual: SolicitudAprobacionManualServiceImpl,
    public dialogRef: MatDialogRef<ModalAprobarRechazarSolicitudComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.solicitudAprobhacionManualId = data.solicitudAprobhacionManualId;
    this.isAprobar = data.isAprobar;
  }

  ngOnInit(): void {
    this.razonesSelect = new Array<string>();
    this.formAdd = this.formBuilder.group({
      observacion: ['', [CustomValidatorsHelper.noWhitespaceValidator, Validators.required, Validators.minLength(12)]],
      motivoRechazo: [null, [CustomValidatorsHelper.noWhitespaceValidator, Validators.required]],
    });
    this.addValidatorMotivoRechazo();
    if (this.isAprobar) {
      this.razones = this.getRazonesAprobar();
    } else {
      this.razones = this.getRazonesRechazar();
    }
  }

  get form() {
    return this.formAdd.controls;
  }

  addValidatorMotivoRechazo() {
    if(this.isAprobar) {
      this.formAdd.get('motivoRechazo').clearValidators();
    }
  }

  onFormSubmit() {
    const formValue = this.formAdd.value;
    if (this.isOtroSelected) {
      if (this.otroControl.value == '' || this.otroControl.value == null) {
        this.toast.warning('El campo otro es requerido', 'Importante');
        return;
      } else {
        this.razonesSelect.push(this.otroControl.value);
      }
    } else {
      if (this.razonesSelect.length == 0) {
        this.toast.warning('Por favor seleccione al menos una razon', 'Importante');
        return;
      }
    }

    if (formValue.observacion == '' || formValue.observacion == null) {
      this.toast.warning('El campo observacón es requerido', 'Importante');
      return;
    }

    let insertRequest = {
      observacion: formValue.observacion,
      motivoRechazo: formValue.motivoRechazo,
      razones: this.razonesSelect,
      solicitudAprobacionId: this.solicitudAprobhacionManualId
    };

    if (this.isAprobar) {
      this.solicitudAprobacionManual.aprobarSolicitud(insertRequest)
        .subscribe(
          {
            next: (res) => {
              ;
              this.dialogRef.close(true);
            },

            error: (error) => {
              ;
              this.toast.error('Ocurrido un error al intentar aprobar la solicitud', 'Error');
              try {
                for (let field of error.error.errors) {
                  this.toast.error(field.message, field.field);
                }
              } catch (e) {
              }
            }
          });

    } else {
      this.solicitudAprobacionManual.recharzarSolicitud(insertRequest)
        .subscribe(
          {
            next: (res) => {
              ;
              this.dialogRef.close(true);
            },

            error: (error) => {
              ;
              this.toast.error('Ocurrido un error al enviar la notificacion', 'Error');
              try {
                for (let field of error.error.errors) {
                  this.toast.error(field.message, field.field);
                }
              } catch (e) {
              }
            }
          });
    }

  }

  calcularCaracteresEscritos(event: Event): void {
    const texto = (event.target as HTMLTextAreaElement).value;
    this.caracteresEscritos = texto.length;
  }

  setData(event, item) {
    if (event.checked) {
      this.razonesSelect.push(this.pipeService.transform(item));
    } else {
      let index = this.razonesSelect.indexOf(item);
      if (index != -1) {
        this.razonesSelect.splice(index, 1);
      }
    }
  }

  getRazonesAprobar(): Array<string> {
    let documents = new Array<string>();
    for (let type in RazonesSolicitudAprobacionManualAprobadaEnum) {
      documents.push(RazonesSolicitudAprobacionManualAprobadaEnum[type]);
    }
    documents.push('OTRO');
    return documents;
  }

  getRazonesRechazar(): Array<string> {
    let documents = new Array<string>();
    for (let type in RazonesSolicitudAprobacionManualRechazadaEnum) {
      documents.push(RazonesSolicitudAprobacionManualRechazadaEnum[type]);
    }
    documents.push('OTRO');
    return documents;
  }

  setDataOtro(event) {
    this.isOtroSelected = event.checked;
    if (!event.checked) {
      this.otroControl.setValue('');
    }
  }

}
