import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {FirmanteServiceImpl} from '../../../../core/http/configuracion-firma-electronica/impl/firmante.service.impl';
import FirmanteModel from '../../../../core/models/configuracion-firma-electronica/firmante.model';
import {SISTEMA} from '../../../../core/constants/sistemas.contants';


@Component({
	selector: 'jt-gestion-firmantes-edit',
	templateUrl: './gestion-firmantes-edit.component.html',
	styleUrls: ['./gestion-firmantes-edit.component.scss']
})
export class GestionFirmantesEditComponent implements OnInit {

	formFirmante!: FormGroup;
	idFirmante: any= 0;

	constructor(
		public  formBuilder: FormBuilder,
		public   dialogRef: MatDialogRef<GestionFirmantesEditComponent>,
		public  toast: ToastrService,
		public  firmanteService: FirmanteServiceImpl,
		@Inject(MAT_DIALOG_DATA) public   data: any
	) {
		this.idFirmante = data.idFirmante;
	}

	ngOnInit() {
		this.formFirmante = this.formBuilder.group({
			nombre: [null, [Validators.required]],
			estado: ["ACTIVO"],
      sistemaId: [SISTEMA['EDUFACTORING']]
		});
		this.firmanteService.get(this.idFirmante.toString()).subscribe(
			(firmante: FirmanteModel) => this.initForm(firmante),
			(error:any) => {
				this.dialogRef.close();
			}
		);
	}

	initForm(firmante:any) {
		this.form.nombre.setValue(firmante.nombre);
	}
	get form() {
		return this.formFirmante.controls;
	}

	onFormSubmit() {
		const firmanteEdicion = this.formFirmante.getRawValue();

		this.firmanteService
			.update(String(this.idFirmante), firmanteEdicion)

			.subscribe(
				(firmante) => this.dialogRef.close(firmante)
			);
	}
}
