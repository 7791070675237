import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LineaCreditoServiceImpl } from 'src/app/core/http/configuracion-basica/impl/lineas-credito.service.impl';
import { LineaMotorConsultaServiceImpl } from 'src/app/core/http/configuracion-solicitud-credito/impl/linea-motor-consulta.service.impl';
import { MotorConsultaServiceImpl } from 'src/app/core/http/configuracion-solicitud-credito/impl/motor-consulta.service.impl';
import MotorConsultaModel from 'src/app/core/models/configuracion-solicitud-credito/motor-consulta.models';

@Component({
  selector: 'ef-lineas-motor-consulta-add',
  templateUrl: './lineas-motor-consulta-add.component.html',
  styleUrls: ['./lineas-motor-consulta-add.component.scss']
})
export class LineasMotorConsultaAddComponent implements OnInit {

  formAdd: FormGroup;
  motorConsulta: Array<MotorConsultaModel> = [];
  lineaMotorConsultaId: number;
  showDetalle: boolean = false;
  motorConsultaDetalle: any;
  mensajeError: string;

  constructor(
    public lineaMotorConsultaService: LineaMotorConsultaServiceImpl,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<LineasMotorConsultaAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public toastrService: ToastrService,
    public motorConsultaService: MotorConsultaServiceImpl
  ) {
    this.lineaMotorConsultaId = Number(data);
  }

  ngOnInit(): void {
    this.formAdd = this.formBuilder.group({
      motorConsulta: [null, [Validators.required]],
    });
    this.subscribeMotorConsulta();
    this.getMotorConsulta();
  }

  subscribeMotorConsulta() {
    this.formAdd.get('motorConsulta').valueChanges.subscribe({
      next: (motorConsulta) => {
        this.showDetalle = true;
        this.motorConsultaDetalle = motorConsulta;
      }
    });
  }

  getMotorConsulta() {
    this.motorConsultaService
      .filter({
        estado: 'ACTIVO',
      })
      .subscribe({
        next: (res) => {
          if (res.count <= 0) {
            this.toastrService.warning('No hay motores de consultas creadas');
          }
          this.motorConsulta = res.data;
        },
        error: (err) => {
          this.toastrService.error('Error al obtener los motores de consulta');
        }
      });
  }

  get form() {
    return this.formAdd.controls;
  }

  onFormSubmit() {
    if (navigator.onLine) {
      this.mensajeError = null;
      const lineaCreditoNuevo = Object.assign({}, this.formAdd.value);
      let newData = {
        estado: 'ACTIVO',
        idMotorConsulta: lineaCreditoNuevo.motorConsulta.id,
        idLineaCredito: this.lineaMotorConsultaId,
      };
      this.lineaMotorConsultaService.save(newData).subscribe(
        {
          next: (motorConsulta) => {
            this.dialogRef.close(motorConsulta);
          },
          error: (error) => {
            try {
              for (const field of error.error.errors) {
                this.toastrService.error(field.message, 'Error');
              }
            } catch (e) {
              this.mensajeError = error.error.message;
            }
          }
        }
      );
    } else {
      this.toastrService.error(
        'Por favor, revisa tu conexión a internet',
        'Error'
      );
    }
  }
}
