import {Injectable} from '@angular/core';
import {ServiceImplService} from '../../service.impl.service';
import {HttpClient} from '@angular/common/http';
import {InformacionLaboralServiceInterface} from '../informacion-laboral.service.interface';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import ListResponse from '../../../models/core/list-response.model';
import * as _ from 'lodash';
import {ClienteReferenciaServiceInterface} from '../cliente-referencia.service.interface';


@Injectable({
  providedIn: 'root'
})
export class ClienteListaNegraServiceImpl extends ServiceImplService<any>
  implements ClienteReferenciaServiceInterface {

  constructor(public http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'cliente-lista-negra/';
  }

}
