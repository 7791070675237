import { ServiceImplService } from '../../service.impl.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LinkPago } from '../../../models/solicitud-credito/link-pago.model';
import { LinkPagoConceptoServiceInterface } from '../link-pago-concepto.service.interface';

@Injectable({
	providedIn: 'root'
})
export class LinkPagoConceptoServiceImpl extends ServiceImplService<any> implements LinkPagoConceptoServiceInterface {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'link-pago-concepto/';
	}
}
