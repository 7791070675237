<div class="popup-card-width">
  <div class="gene-card-title">
    <div class="flex justify-center">
      <div class="flex flex-col">
        <h2 class="placeholder">Editar Fecha Primera Cuota</h2>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <br>
  <mat-dialog-content>

    <div class="text-center">
      <strong> Fecha Anterior </strong>
      <p>{{solicitud?.fechaPrimeraCuota | date:'MMM dd, yyyy'}}</p>
    </div>

    <mat-form-field>
      <input matInput
             [max]="maxDate"
             [matDatepicker]="fecha"
             [formControl]="fechaControl" required>
      <mat-placeholder class="placeholder">Nueva Fecha
      </mat-placeholder>
      <mat-datepicker-toggle matSuffix [for]="fecha">
      </mat-datepicker-toggle>
      <mat-datepicker #fecha></mat-datepicker>
    </mat-form-field>

  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button mat-raised-button
            color="primary"
            (click)="dialogRef.close(false)">Cancelar
    </button>
    <button mat-raised-button
            color="primary"
            [disabled]="!fechaControl.valid"
            (click)="onFormSubmit()">Guardar
    </button>
  </mat-dialog-actions>
</div>
