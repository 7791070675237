import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ESTADOS } from '@core/constants/estado.constants';
import { EstadoSolicitudCreditoEnum } from '@core/enums/estado-solicitud-credito.enum';
import { DebitoAutomaticoProcesoServiceImpl } from '@core/http/debito-automatico/impl/debito-automatico-proceso.service.impl';
import { SolicitudCreditoServiceImpl } from '@core/http/solicitud-credito/impl/solicitud-credito.service.impl';
import ListResponse from '@core/models/core/list-response.model';
import { SolicitudCreditoExtendedModel } from '@core/models/solicitud-credito/solicitud-credito-extended.model';
import { ToastrService } from 'ngx-toastr';
import { Subject, map, switchMap, takeUntil, tap, lastValueFrom } from 'rxjs';
import { saveAs } from 'file-saver';
import { DebitoAutomaticoProcesoModel } from '@core/models/debito-automatico/debito-automatico-proceso.model';
import { DebitoAutomaticoProcessServiceImpl } from '@core/http/debito-automatico/impl/debito-automatico-process.service.impl';
import { finalize } from 'rxjs/operators';
import { SolicitudTipoFirmaServiceImpl } from '@core/http/solicitud-credito/impl/solicitud-tipo-firma.service.impl';
import { TipoFirma } from '@core/enums/tipo-firma-solicitud.enum';
import { SolicitudFirmaDocumentoServiceImpl } from '@core/http/configuracion-solicitud-credito/impl/solicitud-firma-documento.service.impl';
import { DebitoAutomaticoServiceImpl } from '@core/http/debito-automatico/impl/debito-automatico.service.impl';

type ModalData = {
  idDebito: number,
  idCliente: number
}

@Component({
  selector: 'ef-modal-descarga-documentos-debito',
  templateUrl: './modal-descarga-documentos-debito.component.html',
  styleUrls: ['./modal-descarga-documentos-debito.component.scss']
})
export class ModalDescargaDocumentosDebitoComponent implements OnInit, OnDestroy {

  isLoading: boolean = true;
  idDebito: number;
  idCliente: number;
  solicitudes: SolicitudCreditoExtendedModel[];

  private _unsubscribe: Subject<void> = new Subject<void>()

  constructor(
    public dialogRef: MatDialogRef<ModalDescargaDocumentosDebitoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    public debitoProcesoService: DebitoAutomaticoProcesoServiceImpl,
    public solicitudCreditoService: SolicitudCreditoServiceImpl,
    public debitoAutomaticoService: DebitoAutomaticoServiceImpl,
    public solicitudTipoFirmaService: SolicitudTipoFirmaServiceImpl,
    public solicitudFirmaDocumentoService: SolicitudFirmaDocumentoServiceImpl,
    public debitoProcessService: DebitoAutomaticoProcessServiceImpl,
    public toasterService: ToastrService
  ) {
    this.idDebito = data.idDebito;
    this.idCliente = data.idCliente;

  }

  ngOnInit() {
    const solicitudFilter = {
      idCliente: this.idCliente,
      estados: [
        EstadoSolicitudCreditoEnum.DESEMBOLSADO,
        EstadoSolicitudCreditoEnum.APROBADO
      ]
    }
    this.solicitudCreditoService.filterExtended(solicitudFilter)
      .pipe(
        takeUntil(this._unsubscribe),
        map((res: ListResponse<SolicitudCreditoExtendedModel>) => res.data),
        tap((data: SolicitudCreditoExtendedModel[]) => this.solicitudes = data),
        switchMap((data: SolicitudCreditoExtendedModel[]) => {
          const debitoProcesoFilter = {
            idProcesos: this.solicitudes.map(sol => sol.id),
            estado: ESTADOS.ACTIVO,
            idDebito: this.idDebito
          }
          return this.debitoProcesoService.filter(debitoProcesoFilter)
        }),
        map((res: ListResponse<DebitoAutomaticoProcesoModel>) => res.data)
      )
      .subscribe({
        next: (data) => {
          const procesos = data.map(proceso => proceso.idProceso);
          this.solicitudes = this.solicitudes.filter(solicitud => procesos.includes(solicitud.id));
          this.isLoading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.toasterService.error('No ha sido posible consultar los procesos asociados a este débito.')
        }
      });
  }

  async downloadDocument(solicitudId: number) {
    this.isLoading = true;
    try {
      const tipoFirma$ = this.solicitudTipoFirmaService.validarTipoFirmaSolicitud(solicitudId);
      const tipoFirma = await lastValueFrom(tipoFirma$);
      if (tipoFirma.tipoFirma === TipoFirma.ZAPSIGN && tipoFirma.flagUploadFile) {
        const hasDocumentoZapSing$ = this.solicitudFirmaDocumentoService
          .validarContratoUnificadoZapSing(solicitudId);
        const hasDocumentoZapSing = await lastValueFrom(hasDocumentoZapSing$);

        if (hasDocumentoZapSing['success']) {

          const contratoUnificadoZapSing$ = this.solicitudFirmaDocumentoService
            .getContratoUnificadoZapSingPdf(solicitudId);
          const hasDocumentoZapSing = await lastValueFrom(contratoUnificadoZapSing$);
          if (!hasDocumentoZapSing) throw new Error('Error al consultar el documento.');

          const blob = new Blob([hasDocumentoZapSing], { type: 'application/pdf' });
          saveAs(blob, 'Solicitud_Credito_' + this.solicitudes.find(el => el.id === solicitudId).numeroSolicitud + '.pdf');
        }
        this.isLoading = false
      } else if (tipoFirma.tipoFirma === TipoFirma.ZAPSIGN
        && !tipoFirma.flagUploadFile && tipoFirma.ulrFirmaZapsing) {
        window.open(tipoFirma.ulrFirmaZapsing, "_blank");
        this.isLoading = false
      } else {
        this.getDocumentsFromIdVision(solicitudId);
      }
    } catch (error) {
      this.getDocumentsFromIdVision(solicitudId);
    }
  }
  getDocumentsFromIdVision(solicitudId: number) {
    this.debitoAutomaticoService.getReportAutorizacionDebito(this.idDebito, this.idCliente, solicitudId)
      .pipe(finalize(()=> this.isLoading = false))
      .subscribe({
        next: (res) => {
          let blob = new Blob([res], { type: 'application/pdf' });
          saveAs(blob, 'SOLICITUD_CONTRATO_Autorizacion_Debito_Automatico_' + this.idDebito +
            '.pdf'
          );
        },
        error: (err) => {
          this.toasterService.error('Error al descargar el PDF de autorización débito automático', 'Error');
        },
      });
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
