<form [formGroup]="formDetail">
  <mat-dialog-content class="pad-b-md">
    <div class="grid grid-cols-3">
      <strong>Nombre:</strong>
      <p class="col-span-2" *ngIf="tipo === 'paso'; else nombreTemplate">{{objeto.paso.nombre}}</p>
      <ng-template #nombreTemplate>
        <p class="col-span-2">{{objeto.opcion.nombre}}</p>
      </ng-template>
    </div>
    <div class="grid grid-cols-3">
      <strong>Descripción:</strong>
      <p class="col-span-2 text-justify" *ngIf="tipo === 'paso'; else descripcionTemplate">{{objeto.paso.descripcion}}</p>
      <ng-template #descripcionTemplate>
        <p class="col-span-2 text-justify">{{objeto.opcion.descripcion}}</p>
      </ng-template>
    </div>
    <div class="grid grid-cols-3" *ngIf="tipo === 'paso'; else prioridadTemplate">
      <strong>Orden:</strong>
      <mat-form-field class="col-span-2">
        <input matInput type="number" min="0" formControlName="orden">
        <mat-error *ngIf="form.orden.errors">
          <mat-error *ngIf="form.orden.errors?.required">El campo es requerido</mat-error>
          <mat-error *ngIf="form.orden.errors.min">Debe ser mayor o igual a cero (0)</mat-error>
          <mat-error *ngIf="form.orden.errors.numeric">El campo debe ser numérico</mat-error>
        </mat-error>
      </mat-form-field>
    </div>
    <ng-template #prioridadTemplate>
      <div class="grid grid-cols-3">
        <strong>Prioridad:</strong>
        <mat-form-field class="col-span-2">
          <input matInput type="number" min="0" formControlName="prioridad">
          <mat-error *ngIf="form.prioridad.errors">
            <mat-error *ngIf="form.prioridad.errors?.required">El campo es requerido</mat-error>
            <mat-error *ngIf="form.prioridad.errors.min">Debe ser mayor o igual a cero (0)</mat-error>
            <mat-error *ngIf="form.prioridad.errors.numeric">El campo debe ser numérico</mat-error>
          </mat-error>
        </mat-form-field>
      </div>
    </ng-template>
    <div class="grid grid-cols-3">
      <strong>Estado:</strong>
      <div class="col-span-2">
        <mat-slide-toggle matInput formControlName="estado">
        </mat-slide-toggle>
        <label class="pad-l-xs">{{form.estado.value ? 'Activo' : 'Inactivo'}}</label>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="text-right mrgn-all-sx">
    <button type="button" mat-raised-button (click)="dialogRef.close(false)">Cerrar</button>
    <button (click)="onFormSubmit()" mat-raised-button color="primary">Guardar</button>
  </mat-dialog-actions>
</form>