<form [formGroup]="formEditPlantillaParametro"
    novalidate
    autocomplete="off"
    class="popup-card-width">
    <div>
        <h2 mat-dialog-title
            i18n>Editar Plantilla Parámetro</h2>
    </div>
    <mat-dialog-content *ngIf="plantillaParametroCargado">
        <div>
            <div class="flex flex-col">
                <mat-form-field class="mrgn-b-sm">
                    <input matInput
                        type="text"
                        formControlName="nombreLlave"
                        class=" space-top">
                    <mat-placeholder>Nombre</mat-placeholder>
                </mat-form-field>
                <mat-form-field class="mrgn-b-sm">
                    <input matInput
                        type="text"
                        formControlName="atributoTipo"
                        class=" space-top">
                    <mat-placeholder>Tipo Atributo</mat-placeholder>
                </mat-form-field>
                <mat-form-field class="mrgn-b-sm">
                    <mat-placeholder>Parámetro</mat-placeholder>
                    <mat-select formControlName="atributoNombre" required>
                        <mat-option *ngFor="let atributoNombre of atributoNombres" [value]="atributoNombre">
                            {{atributoNombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="mrgn-b-xs pad-b-none">
        <div>
            <button
                type="button"
                mat-raised-button
                (click)="dialogRef.close()">Cancelar
            </button>
            <button (click)="onFormSubmit()"
                [disabled]="!formEditPlantillaParametro.valid"
                mat-raised-button
                color="primary">Actualizar
            </button>
        </div>
    </mat-dialog-actions>
</form>