<div class="flex flex-col">
    <h2 *ngIf="title" class="placeholder">{{title}}</h2>
    <mat-divider *ngIf="title" class="mrgn-b-md primary-border"></mat-divider>
    <mat-dialog-content>
        <p *ngIf="message">{{message}}</p>
        <mat-form-field [style.width.%]="100">
            <mat-label class="placeholder">{{titleField}}</mat-label>
            <textarea matInput [formControl]="promptValue"></textarea>
            <mat-error *ngIf="promptValue.touched && promptValue.errors">
                <mat-error *ngIf="promptValue.errors.required" class="required alert-error">
                  <span i18n>Este campo es requerido</span>
                </mat-error>
                <mat-error *ngIf="promptValue.errors.minlength" class="required alert-error">
                  <span i18n>Debe tener entre 8 o más caracteres.</span>
                </mat-error>
              </mat-error>
        </mat-form-field>
        <div class="mrgn-b-xs pad-b-none text-right">
            <button (click)="dialogRef.close(false)" mat-raised-button class="btn-color-volver">
                {{cancel | titlecase}}
            </button>
            <button (click)="dialogRef.close(promptValue.value)" [disabled]="promptValue.invalid" mat-raised-button
                class="btn-color-succes">
                {{accept | titlecase}}
            </button>
        </div>
    </mat-dialog-content>
</div>
