import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingSpinnerService {
  public isLoading = new BehaviorSubject<any>(0);
  public loadingText = new BehaviorSubject<String>('');

  constructor() { }
}
