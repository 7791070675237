import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ef-plan-credito-config-detail',
  templateUrl: './plan-credito-config-detail.component.html',
  styleUrls: ['./plan-credito-config-detail.component.scss'],
})
export class PlanCreditoConfigDetailComponent implements OnInit {
  loading = false;
  planLineaCreditoDetail: any;
  abreviatura: any = null;

  constructor(
    public dialogRef: MatDialogRef<PlanCreditoConfigDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.planLineaCreditoDetail = data;
  }

  ngOnInit(): void {
    if (this.planLineaCreditoDetail.lineaCredito.abreviatura) {
      this.abreviatura = this.setAbreviatura(
        this.planLineaCreditoDetail.lineaCredito.abreviatura
      );
    }
  }

  setAbreviatura(abreviaturaContable: string): string {
    let abreviatura = '';
    switch (abreviaturaContable) {
      case 'P':
        abreviatura = 'PEREIRA';
        break;
      case 'V':
        abreviatura = 'VILLAVICENCIO';
        break;
      case 'BU':
        abreviatura = 'BUCARAMANGA';
        break;
      case 'C':
        abreviatura = 'CUCUTA';
        break;
      case 'I':
        abreviatura = 'IBAGUE';
        break;
      default:
        break;
    }
    return abreviatura;
  }
}
