<div class="popup-card-width" [style.width.%]="100">
<form
  [formGroup]="formEditEnfermedad"
  novalidate
  autocomplete="off"
  class="popup-card-width"
>
  <div>
    <h2 mat-dialog-title i18n>Editar Descuento</h2>
  </div>
  <mat-dialog-content>
    <div>
      <div>
        <mat-form-field>
          <mat-label class="placeholder">Nombre</mat-label>
          <input matInput formControlName="nombre" />
          <mat-error *ngIf="form.nombre.touched && form.nombre.errors">
            <mat-error
              *ngIf="form.nombre.errors.required"
              class="required alert-error"
            >
              <span i18n>Este campo es requerido</span>
            </mat-error>
            <mat-error
              *ngIf="!form.nombre.errors.required"
              class="required alert-error"
            >
              <span i18n>{{ form.nombre.errors }}</span>
            </mat-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label class="placeholder">Descripcion</mat-label>
          <input matInput formControlName="descripcion" />
          <mat-error
            *ngIf="form.descripcion.touched && form.descripcion.errors"
          >
            <mat-error
              *ngIf="form.descripcion.errors.required"
              class="required alert-error"
            >
              <span i18n>Este campo es requerido</span>
            </mat-error>
            <mat-error
              *ngIf="!form.descripcion.errors.required"
              class="required alert-error"
            >
              <span i18n>{{ form.descripcion.errors }}</span>
            </mat-error>
          </mat-error>
        </mat-form-field>

        <div class="mx-0 flex gap-3 mrgn-all-xs">
          <mat-label class="placeholder">Rechazo Automatico: </mat-label>
          <mat-slide-toggle formControlName="fRechazoAutomatico" class="mrgn-b-sm">
            {{ form.fRechazoAutomatico.value ? "SI" : "NO" }}
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="mrgn-b-xs pad-b-none">
    <div class="text-right">
      <button type="button" mat-raised-button (click)="dialogRef.close()">
        Cancelar
      </button>
      <button
        (click)="onFormSubmit()"
        [disabled]="!formEditEnfermedad.valid"
        mat-raised-button
        class="accept-button"
      >
        Actualizar
      </button>
    </div>
  </mat-dialog-actions>
</form>
</div>
