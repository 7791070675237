import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageHelper {
	addItem(key: string, value: string): void {
		localStorage.setItem(this.encode(key), this.encode(value));
	}

	getItem(key: string): string {
		const keyValue = localStorage.getItem(this.encode(key));
		if (!keyValue) {
			return keyValue;
		}
		return this.decode(keyValue);
	}

	removeItem(key: string): void {
		localStorage.removeItem(this.encode(key));
	}

	existsItem(key: string): boolean {
		return localStorage.getItem(key) !== null || localStorage.getItem(this.encode(key)) !== null;
	}

	public encode(value: string): string {
		return btoa(value);
	}

	public decode(value: string): string {
		return atob(value);
	}
}
