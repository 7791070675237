<div class="popup-card-width" [style.width.%]="100" [style.height.%]="100">
  <form [formGroup]="formEditPreguntasMedicas" novalidate autocomplete="off" class="popup-card-width">
    <div>
      <h2 mat-dialog-title i18n>Editar Descuento</h2>
    </div>
    <mat-dialog-content>
      <div class="grid gap-2">
        <div>
          <mat-form-field>
            <mat-label class="placeholder">Pregunta Medica</mat-label>
            <textarea  rows="3" matInput formControlName="pregunta"></textarea>
            <mat-error *ngIf="form.pregunta.touched && form.pregunta.errors">
              <mat-error *ngIf="form.pregunta.errors.required" class="required alert-error">
                <span i18n>Este campo es requerido</span>
              </mat-error>
              <mat-error *ngIf="!form.pregunta.errors.required" class="required alert-error">
                <span i18n>{{ form.pregunta.errors }}</span>
              </mat-error>
            </mat-error>
          </mat-form-field>

          <div class="text-center">
            <mat-form-field>
              <mat-label class="placeholder text-center">Orden</mat-label>
              <input matInput formControlName="orden" />
              <mat-error *ngIf="form.orden.touched && form.orden.errors">
                <mat-error *ngIf="form.orden.errors.required" class="required alert-error">
                  <span i18n>Este campo es requerido</span>
                </mat-error>
                <mat-error *ngIf="!form.orden.errors.required" class="required alert-error">
                  <span i18n>{{ form.orden.errors }}</span>
                </mat-error>
              </mat-error>
            </mat-form-field>

            <div class="text-center">
              <div class="flex mr-5 pr-5">
                <div class="w-6/12">
                  <mat-label class="placeholder">Rechazo Automatico: </mat-label>
                  <mat-slide-toggle formControlName="fRechazoAutomatico" class="mrgn-b-sm">
                    {{ form.fRechazoAutomatico.value ? "SI" : "NO" }}
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions class="mrgn-b-xs pad-b-none">
      <div class="text-right">
        <button type="button" mat-raised-button (click)="dialogRef.close()">
          Cancelar
        </button>
        <button (click)="onFormSubmit()" [disabled]="!formEditPreguntasMedicas.valid" mat-raised-button
          class="accept-button">
          Actualizar
        </button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
