import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { style } from '@angular/animations';

@Component({
	selector: 'ef-modal-generic',
	templateUrl: './modal-generic.component.html',
	styleUrls: [ './modal-generic.component.scss' ]
})
export class ModalGenericComponent implements OnInit {
	@Input() title: string;
	@Input() deleteMessage: string;
	@Input() accept;
	@Input() cancel;
	form: FormGroup;
	confirmacion = false;
	pdf = false;
	video = false;
	info = false;
	width;

	constructor(
		public dialogRef: MatDialogRef<ModalGenericComponent>,
		public formBuilder: FormBuilder,
		
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.title = data.title;
		this.deleteMessage = data.message;
		this.accept = data.accept;
		this.cancel = data.cancel;
		this.pdf = data.pdf;
		this.info = data.info;
		this.video = data.video;
		this.confirmacion = data.confirmacion;
		const dialogModal = document.getElementsByClassName('mat-dialog-content');
		
		setTimeout(() => {
			const dialogMat = document.querySelector('.mat-dialog-content');
			dialogMat['style'].setProperty('max-height', '100%')
			dialogModal[0].scrollTop = 0;
			;
		}, 500);
	}

	ngOnInit() {
		this.form = this.formBuilder.group({
			motivo: [ '', [ Validators.required ] ]
		});
	}

	redirectTo() {
		window.open('https://edufactoring.co/contacto', '_blank');
	}

	onFormSubmit() {
		const motivo = {
			motivo: this.form.getRawValue().motivo
		};
		this.dialogRef.close(motivo);
	}
}
