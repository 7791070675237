import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from '@angular/common/http';
import { TipoDocumentoServiceInterface } from '../tipo-documento.service.interface';

@Injectable({
  providedIn: 'root',
})
export class TipoDocumentoServiceImpl
  extends ServiceImplService<any>
  implements TipoDocumentoServiceInterface
{
  constructor(public http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'tipo-documento/';
  }
}
