import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import format from 'date-fns/format/index';
import { ParametroGeneralService } from 'src/app/core/http/configuracion-basica/impl/parametro-general.service.impl';


@Component({
  selector: 'ef-solicitud-asistida-desembolso',
  templateUrl: './solicitud-asistida-desembolso.component.html',
  styleUrls: ['./solicitud-asistida-desembolso.component.scss']
})
export class SolicitudAsistidaDesembolsoComponent implements OnInit {

  solicitudId: any;
  formDesembolso: FormGroup;
  minDate = new Date();
  maxDate = new Date();
  fechaMinLoaded: boolean = false;
  fechaMaxLoaded: boolean = false;
  valorSolicitud: any;
  fechaPrimeraCuota: any;

  constructor(
    public formBuilder: FormBuilder,
    public parameroGeneralService: ParametroGeneralService,
    public dialogRef: MatDialogRef<SolicitudAsistidaDesembolsoComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.solicitudId = data.solicitudId;
    this.valorSolicitud = data.valorTotal;
    console.log(data);

    this.fechaPrimeraCuota = data.fechaPrimeraCuota;
   }

  ngOnInit(): void {
    this.formDesembolso = this.formBuilder.group({
			fechaDesembolso: [ null, Validators.required ],
			cuentaDestino: [ null ],
      valorDesembolso: [ null, Validators.required],
      fechaPrimeraCuota: [null]
		});

    this.formDesembolso.get('valorDesembolso').setValue(this.valorSolicitud);
    this.formDesembolso.get('fechaPrimeraCuota').setValue(this.fechaPrimeraCuota)

		this.parameroGeneralService.get('DIAS_MIN_FECHA_DESEMBOLSO').subscribe({next: (data) => {
			;
			this.minDate.setDate(this.minDate.getDate() - Number(data.valor));
      this.fechaMinLoaded = true;
		}})

    this.parameroGeneralService.get('DIAS_MAX_FECHA_DESEMBOLSO').subscribe({next: (data) => {
			;
			this.maxDate.setDate(this.maxDate.getDate() + Number(data.valor));
      this.fechaMaxLoaded = true;
		}})

  }

  get form() {
		return this.formDesembolso.controls;
	}

  onFormSubmit() {
    const formValues = this.formDesembolso.value;
    let dataDesembolso = {
      estado: "ACTIVO",
      fechaDesembolso: format(formValues.fechaDesembolso, 'yyyy-MM-dd'),
      idSolicitud: this.solicitudId,
      valorDesembolso: formValues.valorDesembolso
    }
    this.dialogRef.close(dataDesembolso);
  }
}
