<div novalidate class="popup-card-width">
  <div class="gene-card-title">
    <div class="flex justify-center">
      <div class="flex flex-col">
        <h2 class="placeholder">Aprobar Acuerdo Pago {{numeroAcuerdoPago}}</h2>
      </div>
    </div>
  </div>
  <mat-dialog-content>
    <div>
      <perfect-scrollbar class="scroll">
        <div *ngFor="let razon  of razones">

            <div class="flex pad-t-aux" *ngIf="razon != 'OTRO'">
                <mat-checkbox (change)="setData($event, razon)"> {{razon | spaceUnderscore}}
                </mat-checkbox>
            </div>

            <div class="flex pad-t-aux space-x-5" *ngIf="razon == 'OTRO'">
                <div class="flex flex-col w-3/20">
                    <mat-checkbox class="pad-control" (change)="setDataOtro($event)"> {{razon | spaceUnderscore}}
                    </mat-checkbox>
                </div>
                <div class="flex flex-col w-4/5">
                    <mat-form-field *ngIf="isOtro">
                        <input matInput type="text" [formControl]="otroControl" class="form-control">
                        <mat-placeholder class="placeholder">Otro...</mat-placeholder>
                    </mat-form-field>
                </div>
            </div>

        </div>
      </perfect-scrollbar>

      <div class="flex">
        <mat-form-field>
          <textarea matInput autocomplete="off" cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5"
            maxlength="1000" [formControl]="observacionControl"></textarea>
          <mat-label class="placeholder">Observación</mat-label>
        </mat-form-field>
      </div>

    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close(null)">Cancelar
    </button>
    <button (click)="onFormSubmit()" type="submit" mat-raised-button color="primary">Aprobar
    </button>
  </mat-dialog-actions>
</div>