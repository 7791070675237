<form [formGroup]="formAddPaso" novalidate autocomplete="off" class="popup-card-width">
    <div>
        <h2 mat-dialog-title i18n>Nuevo Paso</h2>
    </div>
    <mat-dialog-content>
        <div class="grid gap-3">
            <mat-form-field class="mrgn-b-sm">
                <input matInput type="text" formControlName="nombre" class="form-control" placeholder="Nombre" />
                <mat-error *ngIf="form.nombre.touched && form.nombre.errors">
                    <mat-error *ngIf="form.nombre.errors.required" class="required alert-error">
                        <span i18n>Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="!form.nombre.errors.required" class="required alert-error">
                        <span i18n>{{ form.nombre.errors }}</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="mrgn-b-sm">
                <textarea matInput type="text" formControlName="path" class="form-control" placeholder="Url">
                </textarea>
                <mat-error *ngIf="form.path.touched && form.path.errors">
                    <mat-error
                        *ngIf="formAddPaso.get('path').errors && formAddPaso.get('path').errors.hasOwnProperty('maxlength')">
                        <span i18n>La descripción debe tener como máximo 250 caracteres.</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="mrgn-b-sm">
                <textarea matInput type="text" formControlName="descripcion" class="form-control"
                    placeholder="Descripción" (input)="calcularCaracteresEscritos($event)">
                </textarea>
                <p class="mb-0">{{ caracteresEscritos }}/500</p>
                <mat-error *ngIf="form.descripcion.touched && form.descripcion.errors">
                    <mat-error *ngIf="form.descripcion.errors.required" class="required alert-error">
                        <span i18n>Este campo es requerido</span>
                    </mat-error>
                    <mat-error
                        *ngIf="formAddPaso.get('descripcion').errors && formAddPaso.get('descripcion').errors.hasOwnProperty('maxlength')">
                        <span i18n>La descripción debe tener como máximo 200 caracteres.</span>
                    </mat-error>
                    <mat-error
                        *ngIf="formAddPaso.get('descripcion').errors && formAddPaso.get('descripcion').errors.hasOwnProperty('minlength')">
                        <span i18n>La descripción debe tener como minimo 10 caracteres.</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
        <button type="button" mat-raised-button (click)="dialogRef.close()">
            Cerrar
        </button>
        <button (click)="onFormSubmit()" type="submit" [disabled]="!formAddPaso.valid || loading" mat-raised-button
            color="primary">
            Crear
        </button>
    </mat-dialog-actions>
</form>