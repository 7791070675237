import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { format } from 'date-fns'; 
import { LOCAL_STORAGE_CONSTANTS } from 'src/app/core/constants/local-storage.constants';
import { LocalStorageHelper } from 'src/app/core/helpers/local-storage.helper';
import { AcuerdoPagoObservacionServiceImpl } from 'src/app/core/http/acuerdo-pago/Impl/acuerdo-pago-observacion.service.impl';
import { PersonaServiceImpl } from 'src/app/core/http/gestion-usuarios/impl/persona.service.impl';

@Component({
  selector: 'ef-acuerdo-pago-observaciones-add',
  templateUrl: './acuerdo-pago-observaciones-add.component.html',
  styleUrls: ['./acuerdo-pago-observaciones-add.component.scss']
})
export class AcuerdoPagoObservacionesAddComponent implements OnInit {

  formAddObservacion: FormGroup;
	loading = false;
	mensajeError = false;

	idAcuerdoPago: any;
	numeroSolicitud: any;
	listTipoObservacions: Array<string>;
	personaId: number;

  constructor(
		public formBuilder: FormBuilder,
		public toast: ToastrService,
		public acuerdoPagoObservacionesService: AcuerdoPagoObservacionServiceImpl,
		public personaService: PersonaServiceImpl,
		public localStorageHelper: LocalStorageHelper,
		public dialogRef: MatDialogRef<AcuerdoPagoObservacionesAddComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.idAcuerdoPago = data.idAcuerdoPago;
	}

	ngOnInit(): void {
		this.formAddObservacion = this.formBuilder.group({
			tipoObservacion: [ '', Validators.required ],
			descripcion: [ '', [Validators.required, Validators.minLength(15)]]
		});
		this.getTiposObservacion();
		this.getPersonaId();
	}

	getTiposObservacion() {
		this.acuerdoPagoObservacionesService.getTiposObservacion().subscribe(
			(res: any) => {
				this.listTipoObservacions = res;
			},
			() => {
				this.toast.error('Error al cargar los tipos de observaciones', 'Error');
			}
		);
	}

	getPersonaId() {
		this.personaService
			.getByDocument(this.localStorageHelper?.getItem(LOCAL_STORAGE_CONSTANTS.USERNAME))
			.subscribe(
				(res) => (this.personaId = res.data.id),
				() => this.toast.error('No se encontro la persona en sesión')
			);
	}

	get form() {
		return this.formAddObservacion.controls;
	}

	onFormSubmit() {
		this.mensajeError = null;
		const formValues = this.formAddObservacion.value;
		let date = new Date();
		const newObservacion = <any>{
			tipo: formValues.tipoObservacion,
			descripcion: formValues.descripcion,
			idAcuerdoPago: +this.idAcuerdoPago,
			idPersona: this.personaId,
			fecha: format(date, 'yyyy-MM-dd hh:MM:ss'),
			estado: 'ACTIVO'
		};
		this.dialogRef.close(newObservacion);
	}

}
