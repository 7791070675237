<div>
  <h2 class="placeholder">{{title}}</h2>
  <mat-divider></mat-divider>
  <mat-dialog-content>
    <ng-container>
      <div class="font-size pad-t-md" >
        {{message}}
      </div>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions class="mt-2 pad-b-none text-right">
    <button *ngIf="cancel" type="button" class="btn-color-volver" mat-raised-button (click)="dialogRef.close(false)">
      {{cancel}}
    </button>
    <button (click)="dialogRef.close(true)"  mat-raised-button class="accept-button">
      {{accept}}
    </button>
  </mat-dialog-actions>
</div>
