import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ServiceImplService } from '../../service.impl.service';
import * as _ from 'lodash';
import { IPagos } from '../pagos.service.interface';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PagoModel } from '@core/models/debito-automatico/pago.model';

@Injectable({
	providedIn: 'root'
})
export class PagosServiceImpl extends ServiceImplService<PagoModel> implements IPagos {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'pago/';
	}

	getMigrarPagoSolicitud(idSolicitud) {
        return this.http.get(`${this.getFullPath()}/migrar-pago-solicitud/${idSolicitud}`);
	}

    getMigrarPagoAcuerdo(idAcuerdo) {
        return this.http.get(`${this.getFullPath()}/migrar-pago-acuerdo/${idAcuerdo}`);
	}

    anularPago(id: string): Observable<string> {
		return this.httpClient.put(`${this.getFullPath()}anular/${id}`, null).pipe(map((res: any) => res));
	}
}
