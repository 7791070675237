import {Injectable} from '@angular/core';
import {ServiceImplService} from '../../service.impl.service';
import {HttpClient} from '@angular/common/http';
import Lugar from '../../../models/ubicaciones/lugar.model';
import {ILugarService} from '../lugar.service.interface';

@Injectable({
  providedIn: 'root'
})
export class LugarServiceImpl extends ServiceImplService<Lugar>
  implements ILugarService {
  constructor(public http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'lugar/';
  }
}
