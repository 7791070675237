<div class="popup-card-width">
  <div mat-dialog-title>
    <h2 mat-dialog-title i18n>Detalle Capacidad Endeudamiento</h2>
  </div>
  <mat-divider class="primary-border mrgn-b-xs"></mat-divider>
  <mat-dialog-content>


    <div class="mrgn-t-md flex">
      <strong><span class="text-uppercase primary-text text-center">Calculo De Ingreso Mensual Ajustado</span></strong>
    </div>

    <div class="flex justify-evenly">
      <div class="pad-y-sm flex flex-col w-[45%]" >
        <strong class="flex">Validador de Ingresos:</strong>
        <span class="flex">{{sumaryResponse.edufactoringProspeccionRequestResult.incomeValidator == null ?
          'NO REGISTRA' : sumaryResponse.edufactoringProspeccionRequestResult.incomeValidator |
          currency:'COP':'symbol':'1.0-0'}}</span>
      </div>
      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">Ingreso Estimado:</strong>
        <span class="flex">{{sumaryResponse.edufactoringProspeccionRequestResult.incomeEstimator == null ?
          'NO REGISTRA' : sumaryResponse.edufactoringProspeccionRequestResult.incomeEstimator |
          currency:'COP':'symbol':'1.0-0'}}</span>
      </div>
    </div>

    <div class="flex justify-evenly">
      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">Ingreso Capturado:</strong>
        <span class="flex">
          <p>{{sumaryResponse.aqmGoEntryForm.monthlyIncome | currency:'COP':'symbol':'1.0-0'}}</p>
        </span>
      </div>

      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">SMMLV:</strong>
        <span class="flex">{{sumaryResponse.edufactoringProspeccionRequestResult.smmlv == null ?
          'NO REGISTRA' : sumaryResponse.edufactoringProspeccionRequestResult.smmlv |
          currency:'COP':'symbol':'1.0-0'}}</span>
      </div>
    </div>

    <div class="flex justify-evenly">
      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">Income Estimator en SMMLV:</strong>
        <span class="flex">{{sumaryResponse.edufactoringProspeccionRequestResult.incomeEstimatorSMMLV == null ?
          'NO REGISTRA' : sumaryResponse.edufactoringProspeccionRequestResult.incomeEstimatorSMMLV |
          currency:'COP':'symbol':'1.0-0'}}</span>
      </div>

      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">Ocupación:</strong>
        <span class="flex">{{sumaryResponse.aqmGoEntryForm.occupation == null ?
          'NO REGISTRA' : sumaryResponse.aqmGoEntryForm.occupation}}</span>
      </div>
    </div>

    <div class="flex justify-evenly">

      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">Ingreso Mensual Ajustado:</strong>
        <span class="flex">{{sumaryResponse.edufactoringProspeccionRequestResult.incomeMonthlyAjustado == null ?
          'NO REGISTRA' : sumaryResponse.edufactoringProspeccionRequestResult.incomeMonthlyAjustado |
          currency:'COP':'symbol':'1.0-0'}}</span>
      </div>

      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">Ingreso Estimado Ajustado:</strong>
        <span class="flex">{{sumaryResponse.edufactoringProspeccionRequestResult.incomeEstimatorAjustado == null ?
          'NO REGISTRA' : sumaryResponse.edufactoringProspeccionRequestResult.incomeEstimatorAjustado |
          currency:'COP':'symbol':'1.0-0'}}</span>
      </div>
    </div>


    <div class="flex justify-evenly">

      <div class="pad-y-sm flex flex-col w-[65%]">
        <strong class="flex">Porcentaje ajustado por Matriz Income Estimator:</strong>
        <span class="flex">{{sumaryResponse.edufactoringProspeccionRequestResult.MatrizIncomeEstimatorAjustado == null ?
          'NO REGISTRA' : sumaryResponse.edufactoringProspeccionRequestResult.MatrizIncomeEstimatorAjustado + '
          %'}}</span>
      </div>

      <div class="pad-y-sm flex flex-col w-[20%]">
      </div>
    </div>

    <div class="mrgn-t-md flex">
      <strong><span class="text-uppercase primary-text text-center">Calculo De Ingreso Ajustado Al
          Riesgo</span></strong>
    </div>

    <div class="flex justify-evenly">
      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">Score:</strong>
        <span class="flex">
          <p>{{sumaryResponse.edufactoringProspeccionRequestResult.score}}</p>
        </span>
      </div>

      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">Matriz Ajuste de riesgo:</strong>
        <span class="flex">{{sumaryResponse.edufactoringProspeccionRequestResult.matrizAjustedeRiesgo == null ?
          'NO REGISTRA' : sumaryResponse.edufactoringProspeccionRequestResult.matrizAjustedeRiesgo + ' %'}}</span>
      </div>
    </div>

    <div class="flex justify-evenly">
      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">Ingreso Ajustado Al Riesgo:</strong>
        <span class="flex">{{sumaryResponse.edufactoringProspeccionRequestResult.incomeAjustadoRisk == null ?
          'NO REGISTRA' : sumaryResponse.edufactoringProspeccionRequestResult.incomeAjustadoRisk |
          currency:'COP':'symbol':'1.0-0'}}</span>
      </div>

      <div class="pad-y-sm flex flex-col w-[45%]">
      </div>

    </div>


    <div class="mrgn-t-md flex">
      <strong><span class="text-uppercase primary-text text-center">Calculo De Capacidad De
          Endeudamiento</span></strong>
    </div>

    <div class="flex justify-evenly">
      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">Ingreso ajustado en SMMLV:</strong>
        <span class="flex">{{sumaryResponse.edufactoringProspeccionRequestResult.incomeMonthlyAjustadoSMMLV == null ?
          'NO REGISTRA' : sumaryResponse.edufactoringProspeccionRequestResult.incomeMonthlyAjustadoSMMLV |
          currency:'COP':'symbol':'1.0-0'}}</span>
      </div>

      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">Matriz Capacidad de Endeudamiento Neta:</strong>
        <span class="flex">{{sumaryResponse.edufactoringProspeccionRequestResult.matrizCapEndeudamientoNeta == null ?
          'NO REGISTRA' : sumaryResponse.edufactoringProspeccionRequestResult.matrizCapEndeudamientoNeta + ' %'}}</span>
      </div>
    </div>

    <div class="flex justify-evenly">
      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">Capacidad de Endeudamiento Neta:</strong>
        <span class="flex">{{sumaryResponse.edufactoringProspeccionRequestResult.incomeMonthlyAjustadoSMMLV == null ?
          'NO REGISTRA' : sumaryResponse.edufactoringProspeccionRequestResult.incomeMonthlyAjustadoSMMLV |
          currency:'COP':'symbol':'1.0-0'}}</span>
      </div>

      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">Porcentaje Gastos Imprevistos:</strong>
        <span class="flex">{{sumaryResponse.edufactoringProspeccionRequestResult.matrizImprevistos == null ?
          'NO REGISTRA' : sumaryResponse.edufactoringProspeccionRequestResult.matrizImprevistos+ ' %'}}</span>
      </div>
    </div>

    <div class="flex justify-evenly">
      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">Gastos imprevistos:</strong>
        <span class="flex">{{sumaryResponse.edufactoringProspeccionRequestResult.gastosImprevistos == null ?
          'NO REGISTRA' : sumaryResponse.edufactoringProspeccionRequestResult.gastosImprevistos |
          currency:'COP':'symbol':'1.0-0'}}</span>
      </div>

      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">Capacidad Neta Ajustada:</strong>
        <span class="flex">{{sumaryResponse.edufactoringProspeccionRequestResult.capacidadNetaAjustada == null ?
          'NO REGISTRA' : sumaryResponse.edufactoringProspeccionRequestResult.capacidadNetaAjustada |
          currency:'COP':'symbol':'1.0-0'}}</span>
      </div>
    </div>


    <div class="flex justify-evenly">
      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">Cuota Buró:</strong>
        <span class="flex">{{sumaryResponse.edufactoringProspeccionRequestResult.cuotaBuro == null ?
          'NO REGISTRA' : sumaryResponse.edufactoringProspeccionRequestResult.cuotaBuro |
          currency:'COP':'symbol':'1.0-0'}}</span>
      </div>

      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">Disponible Final:</strong>
        <span class="flex">{{sumaryResponse.edufactoringProspeccionRequestResult.disponibleFinal == null ?
          'NO REGISTRA' : sumaryResponse.edufactoringProspeccionRequestResult.disponibleFinal |
          currency:'COP':'symbol':'1.0-0'}}</span>
      </div>
    </div>

    <div class="flex justify-evenly">

      <div class="pad-y-sm flex flex-col w-[45%]">
        <strong class="flex">Endeudamiento máximo:</strong>
        <span class="flex">{{sumaryResponse.edufactoringProspeccionRequestResult.endeudamientoMaximo == null ?
          'NO REGISTRA' : sumaryResponse.edufactoringProspeccionRequestResult.endeudamientoMaximo |
          currency:'COP':'symbol':'1.0-0'}}</span>
      </div>

      <div class="pad-y-sm flex flex-col w-[45%]">
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs flex justify-end items-center">
    <button mat-raised-button [mat-dialog-close] cdkFocusInitial>Cerrar</button>
  </mat-dialog-actions>
</div>
