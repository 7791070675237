<div class="popup-card-width">
  <div>
    <h2 mat-dialog-title i18n>Información del Descuento</h2>
  </div>
  <mat-divider class="primary-border"></mat-divider>
  <mat-dialog-content class="mrgn-t-sm" *ngIf="descuentoDetail">
    <div class="flex flex-wrap justify-between">
        <div class="md:w-[30%] sm:w-[40%] xs:full-wid">
          <strong>Fecha:</strong>
        </div>
        <div class="md:w-[70%] sm:w-[60%] xs:full-wid">
          <span>{{ descuentoDetail.createdDate }}</span>
        </div>
      </div>
      <div class="flex flex-wrap justify-between">
        <div class="md:w-[30%] sm:w-[40%] xs:full-wid">
        <strong>Nombre:</strong>
      </div>
      <div class="md:w-[70%] sm:w-[60%] xs:full-wid">
        <span>{{ descuentoDetail.nombre }}</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-between">
      <div class="md:w-[30%] sm:w-[40%] xs:full-wid">
          <strong>Tipo:</strong>
        </div>
        <div class="md:w-[70%] sm:w-[60%] xs:full-wid">
          <span>{{ descuentoDetail.tipo | spaceUnderscore }}</span>
        </div>
      </div>
      <div class="flex flex-wrap justify-between">
        <div class="md:w-[30%] sm:w-[40%] xs:full-wid">
          <strong>Estado:</strong>
        </div>
        <div class="md:w-[70%] sm:w-[60%] xs:full-wid">
          <span>{{ descuentoDetail.estado }}</span>
        </div>
      </div>
      <div class="flex flex-wrap justify-between">
        <div class="md:w-[30%] sm:w-[40%] xs:full-wid">
          <strong>Descripcion:</strong>
        </div>
        <div class="md:w-[70%] sm:w-[60%] xs:full-wid">
          <span>{{ descuentoDetail.descripcion }}</span>
        </div>
      </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button
      type="button"
      mat-raised-button
      (click)="dialogRef.close()">
      Cerrar
    </button>
  </mat-dialog-actions>
</div>
