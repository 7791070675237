import { Overlay } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import { LoadingSpinnerService } from '@core/services/loading-spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  config: any;

  constructor(
    public overlay: Overlay,
    public spinnerService: LoadingSpinnerService,
  ) { }

  ngOnInit(): void {
    this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      positionStrategy: this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically()
    })
  }
}
