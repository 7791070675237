import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ISecurityUserRolService} from '../security-user-rol.service.interface';
import { ServiceImplService } from '../../service.impl.service';
import { Login } from '../../../models/security/login.model';

@Injectable()
export class SecurityUserRolServiceImpl extends ServiceImplService<Login> implements ISecurityUserRolService {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'security-usuario-rol/';
	}

	rolessByUserlId(idUser) {
		return this.httpClient.get(`${this.getFullPath()}roles/${idUser}`);
	}

	actualizarRolesByUser(idUser, body) {
		return this.httpClient.put(`${this.getFullPath()}actualizar/${idUser}`, body);
	}
}
