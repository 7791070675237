import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TipoDocumentoServiceImpl } from '../../../../core/http/configuracion-solicitud-credito/impl/tipo-documento.service.impl';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';

@Component({
	selector: 'ef-gestion-tipo-documentos-edit',
	templateUrl: './gestion-tipo-documentos-edit.component.html',
	styleUrls: ['./gestion-tipo-documentos-edit.component.scss']
})
export class GestionTipoDocumentosEditComponent implements OnInit {

	loading = false;
	idTipoDocumento;
	formEdit: FormGroup;
	mensajeError = false;
	caracteresEscritos: number = 0;

	constructor(
		public toast: ToastrService,
		public formBuilder: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<GestionTipoDocumentosEditComponent>,
		public tipoDocumentoService: TipoDocumentoServiceImpl
	) { }

  ngOnInit(): void {
    this.formEdit = this.formBuilder.group({
			nombre: [ null, [ Validators.required, CustomValidatorsHelper.noWhitespaceValidator ] ],
			descripcion: [ null, [ Validators.required, CustomValidatorsHelper.noWhitespaceValidator, Validators.minLength(10), Validators.maxLength(500)]]
		});
		this.initForm();
	}

	initForm() {
		this.tipoDocumentoService.get(this.data.idTipoDocumento).subscribe({
			next: (info) => {
				this.idTipoDocumento = info.id;
				this.formEdit.patchValue(info);
			}
		});
	}

	get form() {
		return this.formEdit.controls;
	}

	onFormSubmit() {
		if (navigator.onLine) {
			this.mensajeError = null;
			const tipoDocumento = Object.assign({}, this.formEdit.value);
			tipoDocumento.estado = 'ACTIVO';
			this.tipoDocumentoService.update(this.idTipoDocumento, tipoDocumento).subscribe(
				{
					next: (res) => {
						this.dialogRef.close(res);
					},
					error: (error) => {
						try {
							for (const field of error.error.errors) {
								this.toast.error(field.message, 'Error');
							}
						} catch (e) {
							this.mensajeError = error.error.message;
						}
					}
				}
			);
		} else {
			this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
		}
	}

	calcularCaracteresEscritos(event: Event): void {
		const texto = (event.target as HTMLTextAreaElement).value;
		this.caracteresEscritos = texto.length;
	  }
}
