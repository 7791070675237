<div class="popup-card-width">
  <div class="gene-card-title">
    <div class="flex justify-center">
      <div class="flex flex-col">
        <h2 class="placeholder">Líneas De Crédito</h2>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <br>
  <mat-dialog-content>

    <div class="flex justify-center items-center gap-[15px] pad-all-md">
      <button mat-raised-button class="inline-block gene-fab-flat-icon botton-aux" mat-card-icon
        matTooltipPosition="above" matTooltip="Crédito Educativo EasyHub" (click)="setAction('educativoJamestown')">
        <mat-icon class="icon-aux text-black opacity-80">school</mat-icon>
      </button>
      <button mat-raised-button class="inline-block gene-fab-flat-icon botton-aux" mat-card-icon
        *efActionValidate="'otras-lineas-credito:crear'" matTooltipPosition="above" matTooltip="Otras Líneas de Crédito"
        (click)="setAction('otrasLineasCreditos')">
        <mat-icon class="icon-aux text-black opacity-80">account_balance</mat-icon>
      </button>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button mat-raised-button color="primary" (click)="dialogRef.close('cancelar')">Cancelar
    </button>
  </mat-dialog-actions>
</div>
