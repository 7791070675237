import {Component, Inject, OnInit} from '@angular/core';
import ResponseSummary from 'src/app/core/models/solicitud-credito/dtos/response-summary';
import {NgxSpinnerService} from 'ngx-spinner';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'ef-modal-detalle-capacidad-endeudamiento',
  templateUrl: './modal-detalle-capacidad-endeudamiento.component.html',
  styleUrls: ['./modal-detalle-capacidad-endeudamiento.component.scss']
})
export class ModalDetalleCapacidadEndeudamientoComponent implements OnInit {

  sumaryResponse: ResponseSummary;

  constructor(
    
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.sumaryResponse = data.sumaryResponse;
  }

  ngOnInit(): void {
  }

}
