import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import { EstadosHelper } from 'src/app/core/helpers/estados.helper';
import { ConceptoCuotaPrioridadServiceImpl } from 'src/app/core/http/acuerdo-pago/Impl/concepto-cuota-prioridad.impl';
import ConceptoCuotaPrioridad from 'src/app/core/models/acuerdo-pago/concepto-cuota-prioridad.model';

@Component({
	selector: 'ef-concepto-cuota-prioridad-edit',
	templateUrl: './concepto-cuota-prioridad-edit.component.html',
	styleUrls: ['./concepto-cuota-prioridad-edit.component.scss']
})
export class ConceptoCuotaPrioridadEditComponent implements OnInit {
	conceptoPrioridad: ConceptoCuotaPrioridad;
	formEdit: FormGroup;
	formControls = ["ValorCapital", "ValorGastosCobranza", "ValorInteresesMoratorios", "ValorHonorarios"]
	mensajeError = null;

	constructor(
		public toast: ToastrService,
		public formBuilder: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<ConceptoCuotaPrioridadEditComponent>,

		public conceptoPrioridadService: ConceptoCuotaPrioridadServiceImpl
	) {
		this.conceptoPrioridad = data.conceptoPrioridad;
	}

	ngOnInit() {
		this.formEdit = this.formBuilder.group({
			prioridad: [null, [Validators.required, CustomValidatorsHelper.numeric]],
			concepto: [null, [Validators.required]],
			formControl: [null, [Validators.required]],
			vigencia: []
		});
		this.initForm();
	}

	initForm() {
		if (this.conceptoPrioridad) {
		  this.form.prioridad.setValue(this.conceptoPrioridad.prioridad ?? '');
		  this.form.concepto.setValue(this.conceptoPrioridad.concepto ?? '');
		  this.form.formControl.setValue(this.conceptoPrioridad.formControl ?? '');
		  this.form.vigencia.setValue(this.conceptoPrioridad.flagVigente ?? false);
		}
	  }
	  

	get form() {
		return this.formEdit.controls;
	}

	onFormSubmit() {
		const formValues = this.formEdit.value;
		const conceptoPrioridadEdit = <ConceptoCuotaPrioridad>{
			id: this.conceptoPrioridad.id,
			estado: this.conceptoPrioridad.estado,
			prioridad: formValues.prioridad,
			concepto: formValues.concepto,
			formControl: formValues.formControl,
			flagVigente: formValues.vigencia
		};

		this.conceptoPrioridadService.update(String(this.conceptoPrioridad.id), conceptoPrioridadEdit)
			.subscribe(
				(response) => {
					this.dialogRef.close(response);
					;
				},
				(error) => {
					try {
						for (let field of error.error.errors) {
							this.toast.error(field.message, 'Error');
						}
					} catch (e) {
						this.mensajeError = error.error.message;
						this.toast.error(this.mensajeError, 'Error');
					}
				}
			);
	}
}

