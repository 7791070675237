import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription, lastValueFrom } from 'rxjs';
import { AdministrativoService } from 'src/app/core/http/gestion-usuarios/impl/administrativo.service.impl';
import HistorialConsultaSarlaftModel from 'src/app/core/models/historial-consulta-sarlaft/historial-consulta-sarlaft.model';
import { saveAs } from 'file-saver'
import Persona from 'src/app/core/models/personas/models/persona.model';
import { ConsultaSarlaftProcessServiceImpl } from '@core/http/historial-consulta-sarlaft/impl/consulta-sarlaft-process.service.impl';

@Component({
  selector: 'ef-historial-consulta-sarlaft-detail',
  templateUrl: './historial-consulta-sarlaft-detail.component.html',
  styleUrls: ['./historial-consulta-sarlaft-detail.component.scss']
})
export class HistorialConsultaSarlaftDetailComponent implements OnInit {

  loading = false;
  historialConsultaSarlaftDetail: HistorialConsultaSarlaftModel;
  personalAdministrativo: Persona;
  subscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<HistorialConsultaSarlaftDetailComponent>,
    public personalAdministrativoService: AdministrativoService,
    private consultaSarlaftService: ConsultaSarlaftProcessServiceImpl,
    public toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.historialConsultaSarlaftDetail = data;
    console.log(this.historialConsultaSarlaftDetail);
  }

  ngOnInit(): void {
    this.subscription = this.personalAdministrativoService.get(String(this.historialConsultaSarlaftDetail.idAdmin))
      .subscribe({
        next: (administrativo) => {
          this.personalAdministrativo = administrativo;
        },
        error: (err) => {
          this.toast.error(err.message, 'Error');
        }
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async downloadPDF() {
    try {
      const hasDocumentoPDF$ = this.consultaSarlaftService.validarDownloadDocumentPDF(this.historialConsultaSarlaftDetail.id);
      const hasDocumentoPDF = await lastValueFrom(hasDocumentoPDF$);
      if (hasDocumentoPDF['success']) {
        this.consultaSarlaftService.getDownloadDocumentPDF((this.historialConsultaSarlaftDetail.id)).subscribe({
          next: (res: any) => {
            if (!res) {
              this.toast.error("No se encontró ningún archivo para mostrar")
              return;
            }
            saveAs(res, `CONSULTA_SARLAFT_${this.historialConsultaSarlaftDetail.id}.pdf`);
          },
          error: (error: HttpErrorResponse) => {
            this.toast.error("Ocurrió un error al intentar descargar el archivo");
          }
        });
      }
    } catch (error) {
    }
  }
}


