import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { PersonaNotificacionServiceImpl } from 'src/app/core/http/configuracion-basica/impl/persona-notificacion-service.impl';
import { DialogService } from 'src/app/core/services/dialog.service';

@Component({
	selector: 'ef-persona-notificacion-detail',
	templateUrl: './persona-notificacion-detail.component.html',
	styleUrls: [ './persona-notificacion-detail.component.scss' ]
})
export class PersonaNotificacionDetailComponent implements OnInit {
	loading = false;
	items: Array<any>;
	proceso: string;

	constructor(
		public toast: ToastrService,
		
		public personaNotificacion: PersonaNotificacionServiceImpl,
		public dialogRef: MatDialogRef<PersonaNotificacionDetailComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.proceso = this.data.proceso;
	}

	ngOnInit() {
		let filter = {
			proceso: this.proceso,
			estado: 'ACTIVO'
		};
		
		this.personaNotificacion.getFilterExtended(filter).subscribe(
			(res: any) => {
				;
				this.items = res.data;
			},
			(err) => {
				;
				if (err.error.errors) {
					for (const error of err.error.errors) {
						this.toast.error(error.message, 'Error');
					}
				} else {
					if (err.error.message) {
						this.toast.error(err.error.message, 'Error');
					}
				}
			}
		);
	}

	remove(item) {
		this.dialogRef.close(item);
	}

	getFotoCliente(item): string {
		let foto = 'https://image.flaticon.com/icons/svg/3011/3011276.svg';
		if (item.fotoPersona != null) {
			foto = 'data:image/' + item.fotoPersona.extension + ';base64,' + item.fotoPersona.base64;
		}
		return foto;
	}
}
