<div class="popup-card-width">
  <div>
    <h2 mat-dialog-title i18n>Observación</h2>
  </div>
  <mat-dialog-content *ngIf="observacionDetail">
    <div class="flex flex-wrap justify-between items-center">
      <div class="w-full sm:w-2/5 md:w-3/10">
        <strong>No. Acuerdo Pago:</strong>
      </div>
      <div class="w-full sm:w-3/5 md:w-7/10">
        <span>{{ observacionDetail.acuerdoPago.numeroAcuerdoPago }}</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-between items-center">
      <div class="w-full sm:w-2/5 md:w-3/10">
        <strong>Tipo:</strong>
      </div>
      <div class="w-full sm:w-3/5 md:w-7/10">
        <span>{{ observacionDetail.tipo | spaceUnderscore}}</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-between items-center">
      <div class="w-full sm:w-2/5 md:w-3/10">
        <strong>Fecha:</strong>
      </div>
      <div class="w-full sm:w-3/5 md:w-7/10">
        <span>{{ observacionDetail.createdDate }}</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-between items-center">
      <div class="w-full sm:w-2/5 md:w-3/10">
        <strong>Hecho por:</strong>
      </div>
      <div class="w-full sm:w-3/5 md:w-7/10">
        <span>{{observacionDetail.persona.nombreCompleto}}</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-between items-center">
        <div class="w-full sm:w-2/5 md:w-3/10">
            <strong>Estado:</strong>
        </div>
        <div class="w-full sm:w-3/5 md:w-7/10">
            <span>{{ observacionDetail.estado }}</span>
        </div>
    </div>

    <div class="flex flex-wrap justify-between items-center" *ngIf="observacionDetail.descripcion.length < 150">
        <div class="w-full sm:w-2/5 md:w-3/10">
            <strong class="text-justify">Descripcion:</strong>
        </div>
        <div class="w-full sm:w-3/5 md:w-7/10">
            <span class="text-justify">{{ observacionDetail.descripcion }}</span>
        </div>
    </div>
    <br>
    <div *ngIf="observacionDetail.descripcion.length >= 150">
        <div class="flex flex-wrap justify-between items-center">
            <strong>Descripcion:</strong>
        </div>
        <div class="flex flex-wrap justify-between items-center">
            <p class="text-justify">{{ observacionDetail.descripcion }}</p>
        </div>
    </div>


  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close()" [disabled]="loading">Cerrar
    </button>
  </mat-dialog-actions>
</div>