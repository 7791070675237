<form [formGroup]="formDesembolso" novalidate class="popup-card-width" *ngIf="fechaMaxLoaded && fechaMinLoaded">
    <div>
        <h2 mat-dialog-title i18n>Registrar Desembolso</h2>
    </div>
    <mat-dialog-content>
        <div>
            <div class="flex">
                <div class="flex flex-col w-full text-center">
                    <p style="margin-bottom: 0rem;">Fecha Primera Cuota</p>
                    <p>{{fechaPrimeraCuota | date:'MMM dd, yyyy' | titlecase}}</p>
                </div>
            </div>

            <div class="flex">
                <div class="flex flex-col w-full">
                    <mat-form-field>
                        <input currencyMask matInput type="text" formControlName="valorDesembolso">
                        <mat-placeholder class="placeholder">Valor Desembolso</mat-placeholder>
                        <mat-error *ngIf="form.valorDesembolso.errors">
                            <mat-error *ngIf="form.valorDesembolso.errors.required" class="required alert-error">
                                Este campo es <b>requerido</b>.
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="flex">
                <div class="flex flex-col w-full">
                    <mat-form-field>
                        <input matInput [matDatepicker]="fechaDesembolso" formControlName="fechaDesembolso"
                            [min]="minDate" [max]="maxDate" />
                        <mat-placeholder class="placeholder">Fecha Desembolso</mat-placeholder>
                        <mat-datepicker-toggle matSuffix [for]="fechaDesembolso"></mat-datepicker-toggle>
                        <mat-datepicker #fechaDesembolso></mat-datepicker>
                        <mat-error *ngIf="form.fechaDesembolso.errors">
                            <mat-error *ngIf="form.fechaDesembolso.errors.required" class="required alert-error">
                                Este campo es <b>requerido</b>.
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex">
                <div class="flex flex-col w-full">
                    <mat-form-field>
                        <input matInput type="number" formControlName="cuentaDestino">
                        <mat-placeholder class="placeholder">No. Cuenta Destino</mat-placeholder>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
        <button type="button" mat-raised-button (click)="dialogRef.close()">Cerrar
        </button>
        <button (click)="onFormSubmit()" type="submit" [disabled]="!formDesembolso.valid" mat-raised-button
            color="primary">Crear
        </button>
    </mat-dialog-actions>
</form>