import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import ListResponse from '../../../models/core/list-response.model';
import * as _ from 'lodash';
import { AcuerdoPagoServiceInterface } from '../acuerdo-pago.service.interface';
import { OtpDA, CodeDA, ResponseTakeExam, OtpAP } from '../../../models/debito-automatico/trans-union-debito.model';

@Injectable({
	providedIn: 'root'
})
export class AcuerdoPagoServiceImpl extends ServiceImplService<any> implements AcuerdoPagoServiceInterface {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'acuerdo-pago/';
	}

	getFilterExtended(filter: any): Observable<ListResponse<any>> {
		filter = _.omitBy(filter, _.isNil);
		return this.httpClient
			.get(this.getFullPath() + `filter-extended`, { params: filter })
			.pipe(map((res: ListResponse<any>) => res));
	}

	rechazarAcuerdoPago(acuerdoPagoId: number, data: any): Observable<any> {
		const path = `${acuerdoPagoId}/rechazar`;
		return this.httpClient.put(this.getFullPath() + path, data).pipe(map((res: ListResponse<any>) => res));
	}

	getContratosFinanciadosTitular(numeroDocumentoTitular: string) {
		const path = `contratos-financiados-titular/${numeroDocumentoTitular}`;
		return this.httpClient.get(this.getFullPath() + path).pipe(map((res: any) => res));
	}

	getEstadoCartera(abreviaturas: Array<string>, numeroContratos: Array<number>) {
		const params = {
			abreviaturasContables: abreviaturas,
			numerosContratos: numeroContratos
		};
		const path = `estado-cartera`;
		return this.httpClient.post(this.getFullPath() + path, params).pipe(map((res: any) => res));
	}

  getEstadoCarteraAntiguo(numeroContratoAntiguo: string) {
    const path = `estado-cartera-contrato-antiguo/${numeroContratoAntiguo}`;
    return this.httpClient.get(this.getFullPath() + path)
      .pipe(map(res => res));
  }

	preAprobar(acuerdoPagoId: number, data: any): Observable<number> {
		const path = `pre-aprobacion/${acuerdoPagoId}`;
		return this.httpClient.put(this.getFullPath() + path, data).pipe(map((res: any) => res.data));
	}

	aprobarManual(acuerdoPagoId: number): Observable<any> {
		const path = `${acuerdoPagoId}/aprobacion-manual`;
		return this.httpClient
			.put(this.getFullPath() + path, { acuerdoPagoId: acuerdoPagoId })
			.pipe(map((res: any) => res));
	}

	fillForm(body) {
		const url = `${this.getFullPath()}trans-union/idvision/fillform`;
		return this.httpClient.post(url, body);
	}

	getAcuerdoPagoPdf(acuerdoPagoId: number): Observable<any> {
		const path = `${acuerdoPagoId}/acuerdo-pago/pdf`;
		return this.httpClient.get(`${this.getFullPath() + path}`);
	}

	loadDocuments(body) {
		const url = `${this.getFullPath()}trans-union/load-documents`;
		return this.httpClient.put(url, body);
	}

	signDocuments(acuerdoPagoId) {
		const url = `${this.getFullPath()}trans-union/idvision/acuerdo/${acuerdoPagoId}/sign-documents`;
		return this.httpClient.post(url, {});
	}

	getDocuments(acuerdoPagoId) {
		const url = `${this.getFullPath()}trans-union/idvision/acuerdo/${acuerdoPagoId}/get-documents`;
		return this.httpClient.post(url, {});
	}

	transUnionSendNumber(body: OtpAP) {
		return this.httpClient.post(`${this.getFullPath()}trans-union/idvision/sendNumber`, body);
	}

	transUnionDoByPass(acuerdoPagoId) {
		return this.httpClient.post(`${this.getFullPath()}trans-union/idvision/doByPass`, { acuerdoPagoId });
	}

	transUnionSendCode(body: CodeDA) {
		return this.httpClient.post(`${this.getFullPath()}trans-union/idvision/sendCode`, body);
	}

	transUnionTakeExam(body: ResponseTakeExam) {
		return this.httpClient.post(`${this.getFullPath()}trans-union/idvision/takeExam`, body);
	}

	activarAcuerdoPago(acuerdoPagoId: number, servicioSuite: boolean): Observable<any> {
		const path = `${acuerdoPagoId}/activar-acuerdo?servicioSuite=${servicioSuite}`;
		return this.httpClient
			.put(this.getFullPath() + path, { acuerdoPagoId: acuerdoPagoId })
			.pipe(map((res: any) => res));
	}

	getAcuerdoPagoReport(acuerdoPagoId: number): Observable<any> {
		const path = `${acuerdoPagoId}/acuerdo-pago/report`;
		return this.httpClient.get(`${this.getFullPath() + path}`);
	}

	reintentarFirma(body) {
		const url = `${this.getFullPath()}/reintentar-firma`;
		return this.httpClient.put(url, body);
	}

	retrySignDocuments(acuerdoPagoId) {
		const url = `${this.getFullPath()}trans-union/idvision/acuerdo/${acuerdoPagoId}/sign-documents/reintentar`;
		return this.httpClient.post(url, {});
	}

	reiniciarFlujoFirma(acuerdoPagoId) {
		const path = `${acuerdoPagoId}/reiniciar-firma`;
		return this.httpClient.put(`${this.getFullPath() + path}`, null);
	}
}
