import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatListOption } from '@angular/material/list';
import { format } from 'date-fns';
import { parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { SolicitudCreditoExtendedModel } from 'src/app/core/models/solicitud-credito/solicitud-credito-extended.model';

@Component({
  selector: 'ef-select-solicitudes-modal',
  templateUrl: './modal-solicitud-credito-select.component.html',
  styleUrls: ['./modal-solicitud-credito-select.component.scss']
})
export class ModalSolicitudCreditoSelectComponent implements OnInit {

  solicitudes: SolicitudCreditoExtendedModel[];
  isDetail: boolean = false;
  message: string;

  solicitudesControl = new FormControl([], Validators.required);

  constructor(
    public dialogRef: MatDialogRef<ModalSolicitudCreditoSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: FormBuilder
  ) {

    this.solicitudes = data.solicitudes ?? [];
    this.isDetail    = data.isDetail;
    this.message     = data.message;
    this.solicitudesControl.setValue(this.solicitudes);
  }

  ngOnInit(): void {
  }

	selectionChange(option: MatListOption) {
		let value = this.solicitudesControl.value || [];
		if (option.selected) {
			value.push(option.value);
		} else {
			value = value.filter((x: SolicitudCreditoExtendedModel) => x !== option.value);
		}
		this.solicitudesControl.setValue(value);
	}

  formatDate(date: string): string {
		return format(parseISO(date), 'MMM dd, yyyy', { locale: es });
	}
}
