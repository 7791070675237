<div style="width: 1380px;">
    <h2 class="text-center" mat-dialog-title i18n>Historial de Pagos</h2>

    <mat-dialog-content>
        <div class="jt-table-container flex flex-wrap justify-center items-center">
            <div class="text-center" *ngIf="isMessage">
                <mat-icon class="jt-large-icon">mood_bad</mat-icon>
                <p>{{ messageEmpty }}</p>
            </div>
             <div class="w-full" >
                <ef-data-table [columnNames]="columnNames" [data]="historial" [showFilter]="false" *ngIf="isTable"
                    [limit]="getPageSize()" [externalPaging]="true" [count]="getTotalElements()"
                    [pageNumber]="filter.pageNumber" (onSelectPage)="getFilterPage($event)">
                </ef-data-table>

                <ng-template #optionTemplate let-row="row" let-value="value" class="text-center">
                    <button class="mrgn-all-xs" mat-raised-button mat-icon-button mat-button-sm matTooltip="Sincronizar"
                      matTooltipPosition="above" (click)="sincronizar(row)" [disabled]="row.estado != 'ACTIVO'">
                      <mat-icon>sync</mat-icon>
                    </button>
                    <button class="mrgn-all-xs" mat-raised-button mat-icon-button mat-button-sm matTooltip="Anular"
                      matTooltipPosition="above" (click)="anular(row)" [disabled]="row.estado != 'ACTIVO' || row.idSuiteCrm == null">
                      <mat-icon>delete</mat-icon>
                    </button>
                </ng-template>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
        <button type="button" mat-raised-button (click)="dialogRef.close()">
            Cerrar
        </button>
    </mat-dialog-actions>
</div>
