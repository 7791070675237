import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ef-modal-linea-credito-select',
  templateUrl: './modal-linea-credito-select.component.html',
  styleUrls: ['./modal-linea-credito-select.component.scss']
})
export class ModalLineaCreditoSelectComponent implements OnInit {

  isLoading: boolean;
  title: string;
  message: string;
  textButton: string;
  tipoHorario: boolean;
  hideButton: boolean;
  aceptarButton: boolean;
  formAdd: FormGroup;
  lineasCredito: any;

  constructor(public dialogRef: MatDialogRef<ModalLineaCreditoSelectComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: FormBuilder) {
    this.isLoading = false;
    const { title, message, textButton } = data;
    this.title = title;
    this.message = message;
    this.textButton = textButton || 'Cerrar';
    this.hideButton = data.hideButton;
    this.aceptarButton = data.aceptarButton;
    this.lineasCredito = data.lineasCredito;
  }

  ngOnInit(): void {
    this.formAdd = this.formBuilder.group({
      lineasCredito: [this.lineasCredito, [Validators.required]],
    });
  }

  onFormSubmit() {
    if (navigator.onLine) {
      const lineaCredito = this.formAdd.value;
      this.dialogRef.close(lineaCredito);
    }
  }

  get form() {
    return this.formAdd.controls;
  }
}
