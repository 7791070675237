
export const environment = {
    production: true,
	apiUrl: 'https://apigateway.edufactoring.co/v1/',
	loginPath: 'security/login',
	timeIntervalChecker: 1000,
	limitSecondsInactivity: 43200,
	limitSecondsBeforeNotifyInactivity: 90,
	paymentezMode: 'stg',
	paymentezAppCode: 'EDUFACTORING-CO-CLIENT',
	paymentezAppKey: '6lspxeKqrG7rjk9m9C034gd6TtU6Fh',
	idGA: 'UA-232168808-1'

};
