<div class="popup-card-width">
  <div>
    <h1 mat-dialog-title i18n class="text-center"> IMPORTANTE</h1>
  </div>
  <mat-divider class="primary-border mb-4"></mat-divider>
  <mat-dialog-content>
    <div class="text-center">
      {{'¿Está seguro que desea rechazar la solicitud de crédito actual?'}}
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close(false)">No
    </button>
    <button (click)="onDeleteSubmit()"
            mat-raised-button class="accept-button">Si, estoy seguro
    </button>
  </mat-dialog-actions>
</div>
<!-- <mat-spinner class="mx-auto" *ngIf="isLoading"></mat-spinner> -->
<app-spinner *ngIf="isLoading"></app-spinner>
