import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ParametroGeneralService } from 'src/app/core/http/configuracion-basica/impl/parametro-general.service.impl';
import { ToastrService } from 'ngx-toastr';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import { EstadosHelper } from 'src/app/core/helpers/estados.helper';

@Component({
	selector: 'ef-parametros-generales-edit',
	templateUrl: './parametros-generales-edit.component.html',
	styleUrls: ['./parametros-generales-edit.component.scss']
})
export class ParametrosGeneralesEditComponent implements OnInit {
	loading = false;
	idParametro;
	formEdit: FormGroup;
	mensajeError = false;

	constructor(
		public toast: ToastrService,
		public formBuilder: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<ParametrosGeneralesEditComponent>,
		public parametrosGeneralesService: ParametroGeneralService
	) { }

	ngOnInit() {
		this.formEdit = this.formBuilder.group({
			nombre: [null, [Validators.required, CustomValidatorsHelper.noWhitespaceValidator]],
			valor: [null, [Validators.required, CustomValidatorsHelper.noWhitespaceValidator]],
			estado: [false]
		});
		this.initForm();
	}

	initForm() {
		this.parametrosGeneralesService.get(this.data.idParametroGeneral).subscribe({
			next: (info) => {
				this.idParametro = info.id;
				this.formEdit.patchValue(info);
			}
		});
	}

	get form() {
		return this.formEdit.controls;
	}

	onFormSubmit() {
		if (navigator.onLine) {
			this.mensajeError = null;
			const parametro = Object.assign({}, this.formEdit.value);
			parametro.estado = 'ACTIVO';
			this.parametrosGeneralesService.update(this.idParametro, parametro).subscribe(
				{
					next: (parametroGeneral) => {
						this.dialogRef.close(parametroGeneral);
					},
					error: (error) => {
						try {
							for (const field of error.error.errors) {
								this.toast.error(field.message, 'Error');
							}
						} catch (e) {
							this.mensajeError = error.error.message;
						}
					}
				}
			);
		} else {
			this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
		}
	}
}
