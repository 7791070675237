
export const environment = {
    production: false,
	apiUrl: 'http://190.121.157.211:2482/v1/',
	loginPath: 'security/login',
	timeIntervalChecker: 1000,
	limitSecondsInactivity: 64800,
	limitSecondsBeforeNotifyInactivity: 240,
	paymentezMode: 'test',
	paymentezAppCode: 'EDUFACTORING-CO-CLIENT',
	paymentezAppKey: '6lspxeKqrG7rjk9m9C034gd6TtU6Fh',
    idGA: 'UA-232170119-1'

};
