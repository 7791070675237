import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {ToastrService} from 'ngx-toastr';

@Component({
	selector: 'ef-delete-modal',
	templateUrl: './delete-modal.component.html',
	styleUrls: [ './delete-modal.component.scss' ]
})
export class DeleteModalComponent {
	loading = false;
	mensajeError = '';
	objectId: number;
	deleteMethod: any;
	deleteMessage: string;
	fnPrevalidation?: () => any;

	constructor(
		public dialogRef: MatDialogRef<DeleteModalComponent>,
		
		public toast: ToastrService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.objectId = data.objectId;
		this.deleteMessage = data.deleteMessage;
		this.deleteMethod = data.deleteMethod;
		this.fnPrevalidation = data.fnPrevalidation;
	}

	onDeleteSubmit() {
		this.loading = true;
		this.mensajeError = '';
		
		if (this.fnPrevalidation && !this.fnPrevalidation()) {
			return this.dialogRef.close(false);
		}
		this.deleteMethod(this.objectId).subscribe(
			(response) => {
				;
				this.dialogRef.close(true);
			},
			(err) => {
				;
				this.dialogRef.close();
        ;
        if (err.error.errors) {
          for (const error of err.error.errors) {
            this.toast.error(error.message, 'Error');
          }
        } else {
          if (err.error.message) {
            this.toast.error(err.error.message, 'Error');
          }
        }
			}
		);
	}
}
