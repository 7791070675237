import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import { PersonaServiceImpl } from 'src/app/core/http/gestion-usuarios/impl/persona.service.impl';


@Component({
  selector: 'ef-modal-code-confirm',
  templateUrl: './modal-code-confirm.component.html',
  styleUrls: ['./modal-code-confirm.component.scss']
})
export class ModalCodeConfirmComponent implements OnInit {

  isLoading: boolean;
  formCodigo: FormGroup;

  code: string;
  value: string;
  type: string;

  @ViewChild('inputNumber1') inputNumber1: ElementRef;
  @ViewChild('inputNumber2') inputNumber2: ElementRef;
  @ViewChild('inputNumber3') inputNumber3: ElementRef;
  @ViewChild('inputNumber4') inputNumber4: ElementRef;
  @ViewChild('inputNumber5') inputNumber5: ElementRef;
  @ViewChild('inputNumber6') inputNumber6: ElementRef;


  constructor(public dialogRef: MatDialogRef<ModalCodeConfirmComponent>,
    public formBuilder: FormBuilder,
    
    public personaService: PersonaServiceImpl,
    public toasterService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.value = data.value;
    this.type = data.type;
  }

  ngOnInit() {
    this.code = '';
    this.formCodigo = this.formBuilder.group({
      number1: ['', [Validators.required, CustomValidatorsHelper.numeric]],
      number2: ['', [Validators.required, CustomValidatorsHelper.numeric]],
      number3: ['', [Validators.required, CustomValidatorsHelper.numeric]],
      number4: ['', [Validators.required, CustomValidatorsHelper.numeric]],
      number5: ['', [Validators.required, CustomValidatorsHelper.numeric]],
      number6: ['', [Validators.required, CustomValidatorsHelper.numeric]]
    });

    setTimeout(() => {
      this.inputNumber1.nativeElement.focus();
    }, 250);

    this.subscribeNumber1();
    this.subscribeNumber2();
    this.subscribeNumber3();
    this.subscribeNumber4();
    this.subscribeNumber5();

  }

  subscribeNumber1() {
    this.formCodigo.get('number1').valueChanges.subscribe(
      {
        next: res => {
          if (String(res).length == 1) {
            this.inputNumber2.nativeElement.focus();
          }
        }
      });

  }

  subscribeNumber2() {
    this.formCodigo.get('number2').valueChanges.subscribe(
      {
        next: res => {
          if (String(res).length == 1) {
            this.inputNumber3.nativeElement.focus();
          }
        }
      });

  }

  subscribeNumber3() {
    this.formCodigo.get('number3').valueChanges.subscribe(
      {
        next: res => {
          if (String(res).length == 1) {
            this.inputNumber4.nativeElement.focus();
          }
        }
      });

  }

  subscribeNumber4() {
    this.formCodigo.get('number4').valueChanges.subscribe(
      {
        next: res => {
          if (String(res).length == 1) {
            this.inputNumber5.nativeElement.focus();
          }
        }
      });

  }

  subscribeNumber5() {
    this.formCodigo.get('number5').valueChanges.subscribe(
      {
        next: res => {
          if (String(res).length == 1) {
            this.inputNumber6.nativeElement.focus();
          }
        }
      });

  }


  clickConfirmButton() {
    this.code = '';
    for (let key of Object.keys(this.formCodigo.getRawValue())) {
      try {
        this.code += this.formCodigo.get(key).value;
      } catch (e) {
      }

    }

    

    if (this.type == 'EMAIL') {

      this.personaService.validateCodigoEmail(this.value, this.code)
        .subscribe(
          {
            next: (res: any) => {
              ;
              if (res.data.validacion) {
                this.toasterService.success('Su correo ha sido validado exitosamente');
                this.dialogRef.close('true');
              } else {
                this.toasterService.error('Codigo Invalido', 'Error');
              }
            },
            error: () => {
              ;
              this.toasterService.error('Se presentaron fallas técnicas al validar el codigo', 'Error');
            },
            complete: () => this.code = ''
          });
    }

    if (this.type == 'CELLPHONE') {
      this.personaService.validateCodigoCellPhone('57', this.value, this.code)
        .subscribe(
          {
            next: (res: any) => {
              ;
              if (res.data.validacion) {
                this.toasterService.success('Su telefono celular ha sido validado exitosamente');
                this.dialogRef.close('true');
              } else {
                this.toasterService.error('Codigo Invalido', 'Error');
              }
            },
            error: () => {
              ;
              this.toasterService.error('Se presentaron fallas técnicas al validar el codigo', 'Error');
            },
            complete: () => this.code = ''
          });
    }


  }

  resendCode() {
    

    if (this.type == 'EMAIL') {
      this.personaService.validateEmail(this.value)
        .subscribe(
          {
            next: (res: any) => {
              if (res === 'VALIDADO') {
                ;
                this.toasterService.success('El email ya ha sido verificado');

              }
              if (res === 'NO_VALIDADO') {
                this.toasterService.success('El nuevo código ha sido enviado exitosamente');
              }
            },
            error: (error) => {
              ;
              this.toasterService.error('Se presentaron fallas técnicas al validar el email', 'Error');
            }
          }
        );
    }

    if (this.type == 'CELLPHONE') {
      this.personaService.validateTelfono('57', this.value)
        .subscribe(
          {
            next: (res: any) => {
              ;
              if (res === 'VALIDADO') {
                this.toasterService.success('El numero de telefono ya ha sido verificado');

              }
              if (res === 'INICIO_VALIDACION') {
                this.toasterService.success('El nuevo código ha sido enviado exitosamente');
              }
            },
            error: (error) => {
              ;
              this.toasterService.error('Se presentaron fallas técnicas al validar el email', 'Error');
            }
          }
        );
    }
  }

  clearAll() {
    this.formCodigo.reset();
    setTimeout(() => {
      this.inputNumber1.nativeElement.focus();
    }, 100);
  }

  exit() {
    this.dialogRef.close('exit');
  }

}
