import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SecurityRolServiceImpl } from 'src/app/core/http/security/impl/security-rol.service.impl';
import { SecurityPermissionServiceImpl } from 'src/app/core/http/security/impl/security-permissions.service.impl';
import { SecurityRolPermisoServiceImpl } from 'src/app/core/http/security/impl/security-rol-permiso.service.impl';

@Component({
	selector: 'jt-rol-edit',
	templateUrl: './rol-edit.component.html',
	styleUrls: ['./rol-edit.component.scss']
})
export class RolEditComponent implements OnInit {
	formAdd: FormGroup;
	permisos: any[] = [];
	search = new FormControl();
	permissionControl = new FormControl();

	$search;

	constructor(
		public formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<RolEditComponent>,
		
		public toast: ToastrService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public rolService: SecurityRolServiceImpl,
		public permisoService: SecurityPermissionServiceImpl,
		public rolPermisoService: SecurityRolPermisoServiceImpl
	) { }

	get form() {
		return this.formAdd.controls;
	}

	validObject(permiso) {
		const validados = this.permissionControl.value.filter((el) => el.name === permiso.name);
		return validados.length > 0;
	}

	ngOnInit() {
		this.formAdd = this.formBuilder.group({
			name: '',
			description: '',
			idRolesAuth0: ''
		});
		this.loadInfoRol();
		
		this.permisoService.getAll().subscribe(
			{
				next: (data) => {
					;
					this.permisos = data;
					this.$search = this.search.valueChanges.pipe(
						startWith(null),
						debounceTime(200),
						switchMap((res: string) => {
							if (!res) {
								return of(this.permisos);
							}
							res = res.toLowerCase();
							return of(this.permisos.filter((x) => x.name.toLowerCase().indexOf(res) >= 0));
						})
					);
				},
				error: (err) => {
					this.toast.error(err.error.message || 'Error en la carga de los permisos.');
				}
			}
		);
	}

	loadInfoRol() {
		this.rolPermisoService.permisosByRolId(this.data.id).subscribe({
			next: (info: any) => {
				this.permissionControl.setValue(info.data.permisos);
				this.formAdd.get('name').setValue(info.data.rol.name);
				this.formAdd.get('description').setValue(info.data.rol.description);
				this.formAdd.get('idRolesAuth0').setValue(info.data.rol.idRolesAuth0);
			}
		});
	}

	selectionChange(option: any) {
		const value = this.permissionControl.value || [];
		const findItem = value.findIndex((el: any) => el.name === option[0].value.name);
		if (findItem >= 0) {
			value.splice(findItem, 1);
		} else {
			value.push(option[0].value);
		}
		this.permissionControl.setValue(value);
	}

	onFormSubmit() {
		if (
			this.formAdd.invalid ||
			this.permissionControl.value === null ||
			this.permissionControl.value.length === 0
		) {
			this.toast.warning('Debe incluir nombre, descripción y elegir al menos un permiso.');
			return;
		}
		
		this.rolService
			.update(this.data.id, {
				...this.formAdd.value,
				estado: 'ACTIVO'
			})
			.subscribe({
				next: (data) => {
					;
					const permisosAux: any[] = this.permissionControl.value;
					this.toast.success('Se editó el rol exitósamente.');
					this.rolPermisoService
						.actualizarPermisosByRol(this.data.id, permisosAux.map((el) => el.id))
						.toPromise();
					this.toast.success('Se modificaron exitósamente todos los permisos al rol.');
					this.dialogRef.close();
				}
			});
	}
}
