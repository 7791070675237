<div class="popup-card-width">
  <div class="gene-card-title" *ngIf="title">
    <div class="flex justify-center">
      <div class="flex flex-col">
        <h2 class="primary-text">{{title | underscoreToSpace }}</h2>
      </div>
    </div>
  </div>
  <mat-divider *ngIf="title"></mat-divider>
  <br>
  <mat-dialog-content>
    <div class="pdf-container-generic">
      <pdf-viewer [src]="deleteMessage"
                  [zoom]="1"
                  [fit-to-page]="false"
                  [original-size]="false" [render-text]='false'  style="height: 100%; width: 100%;"
      ></pdf-viewer>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button"
            mat-raised-button mat-button-sm
            (click)="dialogRef.close(false)">
      {{cancel | titlecase}}
    </button>
    <button *ngIf="!hideAccept"
            type="button"
            class="btn-accept"
            mat-raised-button mat-button-sm color="primary"
            (click)="dialogRef.close(true)">
      {{accept | titlecase}}
    </button>
  </mat-dialog-actions>
</div>
