import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from '@angular/common/http';
import { EnfermedadesService } from '../enfermedades.service.interface';

@Injectable({
	providedIn: 'root'
})
export class EnfermedadesServiceImpl extends ServiceImplService<any> implements EnfermedadesService {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'enfermedad/';
	}

}
