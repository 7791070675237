<div class="popup-card-width">
    <div class="gene-card-title">
      <div class="flex justify-between tems-center">
        <div class="flex flex-col">
          <h3>{{ 'Cargar Documentos' }}</h3>
        </div>
        <span class="flex"></span>
        <mat-card-actions class="mat-pad-none margin-none">
          <button mat-raised-button
                  mat-button-sm
                  color="primary"
                  (click)="addItem()">
            <span i18n>Agregar Archivo</span>
            <mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon>
          </button>
        </mat-card-actions>
      </div>
      <mat-divider></mat-divider>
    </div>

    <form [formGroup]="formAddFiles"
          novalidate
          autocomplete="off">
      <mat-dialog-content class="pad-all-md">
        <div formArrayName="files" class="mx-4"
             *ngFor="let item of files.controls; let i = index;">

          <mat-card class="mrgn-l-sm mrgn-r-sm mrgn-t-sm w-full">
            <div [formGroupName]="i">
              <div class="grid md:grid-cols-3 items-center gap-4">
                <div class="">
                  <mat-form-field class="pad-t-aux">
                    <mat-label class="placeholder">Tipo Documento</mat-label>
                    <mat-select formControlName="tipoDocumento" required>
                      <mat-option *ngFor="let item of tipos" [value]="item">
                        {{item.descripcion | spaceUnderscore}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="ml-5 mt-2">
                  <input type="file" accept="application/pdf" (change)='fileChangeEvent($event, item)'/>
                </div>
                <div class="flex justify-center mr-5 mt-2">
                  <a (click)="remove(i)" matSuffix mat-icon-button>
                    <mat-icon>delete</mat-icon>
                  </a>
                </div>
              </div>
              <div class="w-full">
                <mat-form-field>
                          <textarea
                matInput
                autocomplete="off"
                cdkTextareaAutosize
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="3"
                maxlength="1000"
                formControlName="descripcion"></textarea>
                  <mat-label class="placeholder">Descripción</mat-label>
                </mat-form-field>
              </div>
            </div>
          </mat-card>
        </div>
      </mat-dialog-content>
    </form>

    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
      <button type="button"
              mat-raised-button
              (click)="dialogRef.close(false)">Cancelar
      </button>
      <button (click)="onFormSubmit()"
              type="submit"
              [disabled]="!formAddFiles.valid"
              mat-raised-button
              color="primary">Crear
      </button>
    </mat-dialog-actions>
  </div>
