<div
  class="popup-card-width">

  <div>
    <h2 mat-dialog-title
        i18n>Nueva Configuracion Personas Notificacion</h2>
  </div>
  <mat-dialog-content>

    <form [formGroup]="formAdd" novalidate autocomplete="off">
      <div class="grid grid-cols-2 gap-4">
        <div class="full-wid">
          <mat-form-field>
            <mat-label class="placeholder">Proceso</mat-label>
            <mat-select formControlName="proceso" required>
              <mat-option *ngFor="let item of procesos" [value]="item">
                {{item | spaceUnderscore}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="full-wid">
          <mat-form-field>
            <mat-label class="placeholder">Region</mat-label>
            <mat-select formControlName="region" required>
              <mat-option *ngFor="let region of regiones" [value]="region">
                {{region}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>

    <mat-card class="card-full-width">
      <div class="gene-card-title">
        <div class="flex justify-between">
          <div>
            <h3>{{ 'Personal' }}</h3>
          </div>
          <mat-card-actions class="mat-pad-none margin-none">
            <button mat-raised-button
                    mat-button-sm
                    color="primary"
                    (click)="addItem()">
              <span i18n>Agregar persona</span>
              <mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon>
            </button>
          </mat-card-actions>
        </div>
        <mat-divider></mat-divider>
      </div>

      <form [formGroup]="formAddPersonas"
            novalidate
            autocomplete="off">
        <mat-dialog-content class="pad-all-md">
          <div formArrayName="personas"
               *ngFor="let item of personas.controls; let i = index;">
            <div [formGroupName]="i">

              <div class="flex gap-2 items-center">
                <div class="w-[30%]">
                  <ef-validate-cliente-numero-documento
                    [width]="100" [isRequired]="true" [isPersonNotificacion]="true"
                    (personaEmitter)="setPersona($event, item)" formControlName="numeroDocumento" class="pad-t-aux">
                  </ef-validate-cliente-numero-documento>
                </div>

                <div class="w-[60%]">
                  <mat-form-field >
                    <input matInput type="text" formControlName="nombreCompleto" class="form-control"
                           maxlength="100" required  [disabled]="true" >
                    <mat-placeholder class="placeholder">Nombre Completo</mat-placeholder>
                  </mat-form-field>
                </div>

                <div class="w-[5%]">
                  <a  (click)="remove(i)" matSuffix mat-icon-button>
                    <mat-icon>delete</mat-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </mat-dialog-content>
      </form>

    </mat-card>

  </mat-dialog-content>

<mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
  <button type="button"
          mat-raised-button
          (click)="dialogRef.close()">Cancelar
  </button>
  <button (click)="onFormSubmit()"
          type="submit"
          [disabled]="!formAdd.valid || !formAddPersonas.valid"
          mat-raised-button
          color="primary">Crear
  </button>
</mat-dialog-actions>
</div>
