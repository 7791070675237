import {
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	TemplateRef,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import * as _ from 'lodash';
// import StringUtil from 'src/app/core/util/string-util';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EstadosHelper } from 'src/app/core/helpers/estados.helper';
import StringUtil from 'src/app/core/util/string-util';
// import {ToggleLeftMenuService} from 'src/app/core/services/toggle-left-menu.service';
import { ToggleService } from 'src/app/core/services/toggle.service';

@Component({
	selector: 'ef-data-table',
	templateUrl: './data-table.component.html',
	styleUrls: ['./data-table.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DataTableComponent implements OnInit {
	@Input() columnNames: Array<any>;
	@Output() columnNamesChange = new EventEmitter<Array<any>>();

	// Cuando los columnNames cambien, debes emitir el evento
	updateColumnNames(newColumnNames: Array<any>) {
		this.columnNames = newColumnNames;
		this.columnNamesChange.emit(this.columnNames);
	}

	@Input() rowClass: any;

	@Input() onFilter;

	@Input() showFilter = true;

	@Input() limit = 10;

	@Input() count: number;

	@Input() emptyMessage: string;

	@Input() externalPaging = false;

	@Input() isFilter = true;

	@Input() pageNumber = 0;

	@Output() onSelectPage: EventEmitter<any> = new EventEmitter();

	@Output() onSort: EventEmitter<any> = new EventEmitter();

	@Input() rowHeight: number | 'auto' = 'auto';

	@Input() messageEmpty = 'empty';

	@Input() showFilterEstados = true;

	selected = [];

	messages: any;

	displayData: Array<any>;

	formFilter: FormGroup;

	estadoProp = 'estado';

	timeoutResizer;

	@Input() loadingIndicator = false;

	@HostListener('window:resize')
	onWindowResize() {
		if (this._data) {
			if (this.timeoutResizer) {
				clearTimeout(this.timeoutResizer);
			}
			this.timeoutResizer = setTimeout(() => {
				this._data = [...this._data];
				this.ngOnInit();
			}, 150);
		}
	}

	@ViewChild(DatatableComponent) table: DatatableComponent;

	SelectionType = SelectionType;

	@ViewChild('estadoTemplate', { static: true })
	estadoTemplate: TemplateRef<any>;

	_data: Array<any>;

	subscribedLeftMenuService: boolean;

	constructor(public formBuilder: FormBuilder, public toggleLeftMenuService: ToggleService) {
		this.subscribedLeftMenuService = false;
	}

	ngOnInit() {
		this.messages = {
			emptyMessage: this.messageEmpty === 'empty' ? 'No se encontraron elementos.' : this.messageEmpty,
			totalMessage: 'Total',
			selectedMessage: 'Seleccionado'
		};

		this.columnNames = this.columnNames.map((columnName: any) => {
			columnName.headerClass = 'text-center';
			if (columnName.prop === this.estadoProp) {
				columnName.cellTemplate = this.estadoTemplate;
			}
			return columnName;
		});
		this.updateColumnNames(this.columnNames);

		if (this.emptyMessage) {
			this.messages.emptyMessage = this.emptyMessage;
		}
		if (this._data) {
			this.displayData = [...this._data];
		}
		this.formFilter = this.formBuilder.group({
			textFilter: [''],
			statusFilter: [null]
		});

		if (!this.subscribedLeftMenuService) {
			this.toggleLeftMenuService.subscribe({
				next:() => {
				setTimeout(() => {
					this.ngOnInit();
				}, 350);
			}});

			this.subscribedLeftMenuService = true;
		}
	}

	onSelect({ selected }) {
		this.selected.splice(0, this.selected.length);
		this.selected.push(...selected);
	}

	@Input()
	set data(data: any) {
		if (data) {
			this._data = [...data];
			this.ngOnInit();
		}
	}

	applyFilter() {
		const listColumns = this.columnNames
			.map((columnName) => columnName.prop)
			.filter((columnName) => columnName !== this.estadoProp);
		this.displayData = this._data.filter((dataItem: any) => {
			let isValidItem = false;
			listColumns.forEach((columnName) => {
				const value = _.get(dataItem, columnName, false);
				if (!isValidItem) {
					isValidItem =
						value &&
						StringUtil.normalize(String(value))
							.toLocaleLowerCase()
							.includes(StringUtil.normalize(this.form.textFilter.value).toLocaleLowerCase());
				}
			});
			return isValidItem;
		});
		if (this.form.statusFilter.value) {
			this.displayData = this.displayData.filter((dataItem: any) => {
				const value = _.get(dataItem, this.estadoProp, false);
				return value && value === this.form.statusFilter.value;
			});
		}
	}

	setPage(pageInfo) {
		this.onSelectPage.emit(pageInfo.offset);
	}

	onSortClick(event) {
		this.onSort.emit(event);
	}

	get form() {
		return this.formFilter.controls;
	}

	isActivo(status: string): boolean {
		return EstadosHelper.isActivo(status);
	}

	getColor(estado): string {
		let color = 'inactive-status-bg';
		switch (estado) {
			case 'ACTIVO':
				color = 'active-status-bg';
				break;
			case 'APROBADO':
			case 'PAGADO':
			case 'SUCCESS':
				color = 'aprobado-status-bg';
				break;
			case 'PENDIENTE':
				color = 'pendiente-status-bg';
				break;
			case 'ANULADO':
			case 'RECHAZADO':
			case 'FAIL':
				color = 'anulado-status-bg';
				break;
			case 'DESEMBOLSADO':
				color = 'desembolsado-status-bg';
				break;
		}
		return color;
	}
}
