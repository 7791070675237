import { Component, OnInit, Inject } from '@angular/core';
import LineaCreditoModel from '../../../../core/models/configuracion-solicitud-credito/lineas-credito.models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ef-lineas-creditos-detail',
  templateUrl: './lineas-creditos-detail.component.html',
  styleUrls: ['./lineas-creditos-detail.component.scss'],
})
export class LineasCreditosDetailComponent implements OnInit {
  loading = false;
  lineaCreditoDetail: LineaCreditoModel;

  constructor(
    public dialogRef: MatDialogRef<LineasCreditosDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.lineaCreditoDetail = data;
  }

  ngOnInit(): void {
    this.lineaCreditoDetail.abreviaturaContable = this.setAbreviatura();
  }

  setAbreviatura() {
    let abreviatura = '';
    switch (this.lineaCreditoDetail.abreviaturaContable) {
      case 'P':
        abreviatura = 'PEREIRA';
        break;
      case 'V':
        abreviatura = 'VILLAVICENCIO';
        break;
      case 'BU':
        abreviatura = 'BUCARAMANGA';
        break;
      case 'C':
        abreviatura = 'CUCUTA';
        break;
      case 'I':
        abreviatura = 'IBAGUE';
        break;
      default:
        break;
    }
    return abreviatura;
  }
}
