import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from '@angular/common/http';
import {PersonaNotificacionService} from '../persona-notificacion.service';
import {Observable} from 'rxjs';
import ListResponse from '../../../models/core/list-response.model';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class PersonaNotificacionServiceImpl extends ServiceImplService<any> implements PersonaNotificacionService {

	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'persona-notificacion/';
	}

  getProcesoFilter(filter: any): Observable<ListResponse<any>> {
    filter = _.omitBy(filter, [ _.isUndefined, _.isNull, _.isEmpty ]);
    return this.httpClient.get(this.getFullPath() + `proceso-filter`, { params: filter }).pipe(map((res: ListResponse<any>) => res));
  }

  getProcesos(): Observable<Array<any>> {
    return this.httpClient.get(this.getFullPath() + `procesos`).pipe(map((res: ListResponse<any>) => res.data));
  }


  getFilterExtended(filter: any): Observable<ListResponse<any>> {
    filter = _.omitBy(filter, [ _.isUndefined, _.isNull, _.isEmpty ]);
    return this.httpClient.get(this.getFullPath() + `extended`, { params: filter }).pipe(map((res: ListResponse<any>) => res));
  }

  getExtended(id: string): Observable<any> {
	  const path = `extended/${id}`
    return this.httpClient.get(this.getFullPath() + path).pipe(map((res: any) => res.data));
  }

  saveMasivo(data: any): Observable<number> {
    return this.httpClient.post(this.getFullPath() + `registro-masivo` , data).pipe(map((res: any) => res.data));
  }

}
