import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';


import { MenuToggleAnchorDirective } from './directives/menu-toggle-anchor.directive';
import { MenuToggleLinkDirective } from './directives/menu-toggle-link.directive';
import { MenuToggleDirective } from './directives/menu-toggle.directive';

import { ScrollingModule } from '@angular/cdk/scrolling';

import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ChipErrorMessageComponent } from './chip-error-message/chip-error-message.component';
import { DataTableComponent } from './data-table/data-table.component';
import { MenuSidenavComponent } from './menu-sidenav/menu-sidenav.component';
import { MenuToolbarComponent } from './menu-toolbar/menu-toolbar.component';
import { ModalServerErrorComponent } from './modal-server-error/modal-server-error.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { WebcamModule } from 'ngx-webcam';
import { SpaceUnderscorePipe } from '../core/pipes/space-underscore.pipe';
import { UnderscoreToSpacePipe } from '../core/pipes/underscore-to-space-double.pipe';
import { CapitalizeFirstLetterPipe } from '../core/pipes/CapitalizeFirstLetter.pipe';
import { CapitalizeJustFirstLetterPipe } from '../core/pipes/CapitalizeJustFirtsLetter.pipe';
import { AdministrativoInfoComponent } from './administrativo-info/administrativo-info.component';
import { AutocompleteAdministrativosComponent } from './autocomplete-administrativos/autocomplete-administrativos.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { ModuleValidatorDirective } from './directives/security-module.directive';
import { ActionValidatorDirective } from './directives/security.directive';
import { TooltipImageDirective } from './directives/tooltip-image.directive';
import { UppercaseDirective } from './directives/uppercase.directive';
import { UserActivityCheckerDirective } from './directives/user-activity-checker.directive';
import { EstudiosAddComponent } from './estudios/estudios-add/estudios-add.component';
import { EstudiosDetailComponent } from './estudios/estudios-detail/estudios-detail.component';
import { EstudiosEditComponent } from './estudios/estudios-edit/estudios-edit.component';
import { EstudiosListComponent } from './estudios/estudios-list/estudios-list.component';
import { ExperienciaAddComponent } from './experiencia/experiencia-add/experiencia-add.component';
import { ExperienciaDetailComponent } from './experiencia/experiencia-detail/experiencia-detail.component';
import { ExperienciaEditComponent } from './experiencia/experiencia-edit/experiencia-edit.component';
import { ExperienciaListComponent } from './experiencia/experiencia-list/experiencia-list.component';
import {
  DialogContentImgComponent,
  FotografiaComponent,
} from './fotografia/fotografia.component';
import { ImageTooltipComponent } from './image-tooltip/image-tooltip.component';
import { InputGenericComponent } from './input-generic/input-generic.component';
import { ModalCodeConfirmComponent } from './modal-code-confirm/modal-code-confirm.component';
import { ModalGenericComponent } from './modal-generic/modal-generic.component';
import { ValidateCellPhoneComponent } from './personas-components/validate-cell-phone/validate-cell-phone.component';
import { ValidateClienteNumeroDocumentoComponent } from './personas-components/validate-cliente-numero-documento/validate-cliente-numero-documento.component';
import { ValidateEmailComponent } from './personas-components/validate-email/validate-email.component';
import { ValidateFechaNacimientoComponent } from './personas-components/validate-fecha-nacimiento/validate-fecha-nacimiento.component';
import { RegimenSaludComponent } from './regimen-salud/regimen-salud.component';
import { SelectAutocompleteCargosComponent } from './select-autocomplete-cargos/select-autocomplete-cargos.component';
import { SelectAutocompleteGeneralComponent } from './select-autocomplete-general/select-autocomplete-general.component';
import { SelectCargosComponent } from './select-cargos/select-cargos.component';
import { SelectEstadosComponent } from './select-estados/select-estados.component';
import { SelectGenericComponent } from './select-generic/select-generic.component';
import { SnapshotPhotoComponent } from './snapshot-photo/snapshot-photo.component';
import { SolicitudCreditoClienteInfoEconomicaComponent } from './solicitud-credito-cliente-info-economica/solicitud-credito-cliente-info-economica.component';
import { SolicitudCreditoClienteInfoLaboralComponent } from './solicitud-credito-cliente-info-laboral/solicitud-credito-cliente-info-laboral.component';
import { SolicitudCreditoClienteInfoReferenciaComponent } from './solicitud-credito-cliente-info-referencia/solicitud-credito-cliente-info-referencia.component';
import { SolicitudCreditoClienteInfoComponent } from './solicitud-credito-cliente-info/solicitud-credito-cliente-info.component';
import { SolicitudCreditoInfoCreditoComponent } from './solicitud-credito-info-credito/solicitud-credito-info-credito.component';
import { SolicitudCreditoResumenComponent } from './solicitud-credito-resumen/solicitud-credito-resumen.component';


import { SelectBarrioComponent } from './ubicaciones-components/select-barrio/select-barrio.component';
import { SelectDepartamentoComponent } from './ubicaciones-components/select-departamento/select-departamento.component';
import { SelectLugarComponent } from './ubicaciones-components/select-lugar/select-lugar.component';
import { SelectMunicipioComponent } from './ubicaciones-components/select-municipio/select-municipio.component';
import { SelectPaisComponent } from './ubicaciones-components/select-pais/select-pais.component';
import { SelectRegionComponent } from './ubicaciones-components/select-region/select-region.component';
import { UbicacionGeograficaAddComponent } from './ubicaciones-components/ubicacion-geografica-add/ubicacion-geografica-add.component';


import { CurrencyMaskModule } from 'ng2-currency-mask';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PerfectScrollbarModule } from 'ngx-om-perfect-scrollbar';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NumeroALetrasPipe } from '../core/pipes/NumeroALetras.pipe';
import { SafeHtmlPipe } from '../core/pipes/safe-html.pipe';
import { SafeVideoPipe } from '../core/pipes/safe-video.pipe';
import { SinoPipe } from '../core/pipes/sino.pipe';
import { TipoIdentificacionPipe } from '../core/pipes/tipo-identificacion.pipe';
import { AcuerdoPagoInfoComponent } from './acuerdo-pago-shared/acuerdo-pago-info/acuerdo-pago-info.component';
import { AcuerdoPagoPlanAmortizacionComponent } from './acuerdo-pago-shared/acuerdo-pago-plan-amortizacion/acuerdo-pago-plan-amortizacion.component';
import { ModalHistorialAcuerdoPagoComponent } from './acuerdo-pago-shared/modal-historial-acuerdo-pago/modal-historial-acuerdo-pago.component';
import { AdjuntarDocumentosGenericComponent } from './adjuntar-documentos-generic/adjuntar-documentos-generic.component';
import { BarrioAddComponent } from './barrio-add/barrio-add.component';
import { ClienteInfoComponent } from './cliente-info/cliente-info.component';
import { ConceptoCuotaPrioridadAddComponent } from './concepto-cuota-prioridad-add/concepto-cuota-prioridad-add.component';
import { DebitoAutomaticoDebitosComponent } from './debito-automatico-debitos/debito-automatico-debitos.component';
import { DebitoAutomaticoListComponent } from './debito-automatico-list/debito-automatico-list.component';
import { DebitoAutomaticoPagosComponent } from './debito-automatico-pagos/debito-automatico-pagos.component';
import { DebitoOtpFormComponent } from './debito-otp-form/debito-otp-form.component';
import { DebitoQuestionsFormComponent } from './debito-questions-form/debito-questions-form.component';
import { DialogGenericSharedComponent } from './dialog-generic-shared/dialog-generic-shared.component';
import { DocumentosFaltantesAddComponent } from './documentos-faltantes/documentos-faltantes-add/documentos-faltantes-add.component';
import { DocumentosFaltantesListComponent } from './documentos-faltantes/documentos-faltantes-list/documentos-faltantes-list.component';
import { FiltrosReportesComponent } from './filtros-reportes/filtros-reportes.component';
import { FormInicialComponent } from './form-inicial/form-inicial.component';
import { FormOtpComponent } from './form-otp/form-otp.component';
import { CodeOtpComponent } from './code-otp/code-otp.component';
import { FormQuestionsComponent } from './form-questions/form-questions.component';
import { FormRulesCreditComponent } from './form-rules-credit/form-rules-credit.component';
import { GenerarReportesComponent } from './generar-reportes/generar-reportes.component';
import { HelpDropdownComponent } from './help-dropdown/help-dropdown.component';
import { LinkPagoDevolucionesComponent } from './link-pago-devoluciones/link-pago-devoluciones.component';
import { ModalLinkPagoComponent } from './links-pago/modal-link-pago/modal-link-pago.component';
import { LoadSignDocumentsComponent } from './load-sign-documents/load-sign-documents.component';
import { LugarAddComponent } from './lugar-add/lugar-add.component';
import { MaterialModule } from './material.module';
import { ModalDetalleCapacidadEndeudamientoComponent } from './modal-detalle-capacidad-endeudamiento/modal-detalle-capacidad-endeudamiento.component';
import { ModalEditFechaPrimerCuotaComponent } from './modal-edit-fecha-primer-cuota/modal-edit-fecha-primer-cuota.component';
import { ModalInfoTableComponent } from './modal-info-table/modal-info-table.component';
import { ModalInfoComponent } from './modal-info/modal-info.component';
import { ModalLineaCreditoSelectComponent } from './modal-linea-credito-select/modal-linea-credito-select.component';
import { ModalPdfsComponent } from './modal-pdfs/modal-pdfs.component';
import { ModalPromptComponent } from './modal-prompt/modal-prompt.component';
import { ModalRechazarSolicitudComponent } from './modal-rechazar-solicitud/modal-rechazar-solicitud.component';
import { ModalReservaCitaSoporteComponent } from './modal-reserva-cita-soporte/modal-reserva-cita-soporte.component';
import { ModalSinInstructivoComponent } from './modal-sin-instructivo/modal-sin-instructivo.component';
import { ModalValidationFormComponent } from './modal-validation-form/modal-validation-form.component';
import { SelectHorarioPreferenciaAdministrativoComponent } from './personas-components/select-horario-preferencia-administrativo/select-horario-preferencia-administrativo.component';
import { PlanPagoTrebolComponent } from './plan-pago-trebol/plan-pago-trebol.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { ReservaCitaSoporteComponent } from './reserva-cita-soporte/reserva-cita-soporte.component';
import { SelectAutocompleteClienteComponent } from './select-autocomplete-cliente/select-autocomplete-cliente.component';
import { SelectEscolaridadComponent } from './select-escolaridad/select-escolaridad.component';
import { SelectEstadoCivilComponent } from './select-estado-civil/select-estado-civil.component';
import { SelectEstratoComponent } from './select-estrato/select-estrato.component';
import { SelectGeneroPersonaComponent } from './select-genero-persona/select-genero-persona.component';
import { SelectGrupoSanguineoComponent } from './select-grupo-sanguineo/select-grupo-sanguineo.component';
import { SelectLinkPagoConceptosComponent } from './select-link-pago-conceptos/select-link-pago-conceptos.component';
import { SelectRegimenComponent } from './select-regimen/select-regimen.component';
import { SelectRhComponent } from './select-rh/select-rh.component';
import { SelectSolicitudesClienteComponent } from './select-solicitudes-cliente/select-solicitudes-cliente.component';
import { SelectTipoViviendaPersonaComponent } from './select-tipo-vivienda-persona/select-tipo-vivienda-persona.component';
import { SolicitudCreditoAprobadaResumenComponent } from './solicitud-credito-aprobada-resumen/solicitud-credito-aprobada-resumen.component';
import { SolicitudCreditoDocumentosAddComponent } from './solicitud-credito-documentos/solicitud-credito-documentos-add/solicitud-credito-documentos-add.component';
import { SolicitudCreditoDocumentosListComponent } from './solicitud-credito-documentos/solicitud-credito-documentos-list/solicitud-credito-documentos-list.component';
import { SolicitudCreditoInfoMedicaComponent } from './solicitud-credito-info-medica/solicitud-credito-info-medica.component';
import { SolicitudCreditoInfoComponent } from './solicitud-credito-info/solicitud-credito-info.component';
import { UbicacionGeograficaListComponent } from './ubicaciones-components/ubicacion-geografica-list/ubicacion-geografica-list.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { PlanPagoListComponent } from './plan-pago-list/plan-pago-list.component';
import { ModalCargarArchivoComponent } from './modal-cargar-archivo/modal-cargar-archivo.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ModalErrorComponent } from './modal-error/modal-error.component';
import { ModalDebitoAutomaticoSelectComponent } from './modal-debito-automatico-select/modal-debito-automatico-select.component';
import { ModalSolicitudCreditoSelectComponent } from './modal-solicitud-credito-select/modal-solicitud-credito-select.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ModalDescargaDocumentosDebitoComponent } from './modal-descarga-documentos-debito/modal-descarga-documentos-debito.component';
import { PlanPagoSolicitudComponent } from './plan-pago-solicitud/plan-pago-solicitud.component';
import { ModalHistorialPagosComponent } from './modal-historial-pagos/modal-historial-pagos.component';
import { AcuerdoPagoAprobadaResumenComponent } from './acuerdo-pago-aprobada-resumen/acuerdo-pago-aprobada-resumen.component';

let importsModule = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  NgxDatatableModule,
  WebcamModule,
  PdfViewerModule,
  ScrollingModule,
  CurrencyMaskModule,
  NgxMatSelectSearchModule,
  ImageCropperModule,
  CarouselModule,
  PerfectScrollbarModule,
  MaterialModule,
  MatDatepickerModule
]
@NgModule({
  declarations: [
    HelpDropdownComponent,
    DialogContentImgComponent,
    DebitoAutomaticoPagosComponent,
    DebitoAutomaticoDebitosComponent,
    DebitoAutomaticoListComponent,
    DebitoQuestionsFormComponent,
    DebitoOtpFormComponent,
    ConceptoCuotaPrioridadAddComponent,
    DocumentosFaltantesAddComponent,
    DocumentosFaltantesListComponent,
    LinkPagoDevolucionesComponent,
    LugarAddComponent,
    BarrioAddComponent,
    MenuToggleDirective,
    MenuToggleLinkDirective,
    MenuToggleAnchorDirective,
    ModuleValidatorDirective,
    UserActivityCheckerDirective,
    SpaceUnderscorePipe,
    UnderscoreToSpacePipe,
    CapitalizeFirstLetterPipe,
    CapitalizeJustFirstLetterPipe,
    TipoIdentificacionPipe,
    SafeHtmlPipe,
    SafeVideoPipe,
    UppercaseDirective,
    TooltipImageDirective,
    ActionValidatorDirective,
    ChipErrorMessageComponent,
    ModalServerErrorComponent,
    ModalErrorComponent,
    MenuToolbarComponent,
    MenuSidenavComponent,
    BreadcrumbComponent,
    DataTableComponent,
    DeleteModalComponent,
    ImageTooltipComponent,
    FotografiaComponent,
    SnapshotPhotoComponent,
    SelectDepartamentoComponent,
    SelectMunicipioComponent,
    SelectBarrioComponent,
    SelectAutocompleteGeneralComponent,
    ValidateClienteNumeroDocumentoComponent,
    ValidateEmailComponent,
    ValidateCellPhoneComponent,
    ValidateFechaNacimientoComponent,
    ModalCodeConfirmComponent,
    InputGenericComponent,
    SelectGenericComponent,
    SelectCargosComponent,
    SelectEstadosComponent,
    SelectPaisComponent,
    RegimenSaludComponent,
    UbicacionGeograficaAddComponent,
    SelectLugarComponent,
    SelectAutocompleteCargosComponent,
    SolicitudCreditoClienteInfoComponent,
    SolicitudCreditoClienteInfoLaboralComponent,
    SolicitudCreditoClienteInfoEconomicaComponent,
    SolicitudCreditoClienteInfoReferenciaComponent,
    SolicitudCreditoInfoCreditoComponent,
    SolicitudCreditoResumenComponent,
    AcuerdoPagoAprobadaResumenComponent,
    ModalGenericComponent,
    AdministrativoInfoComponent,
    AutocompleteAdministrativosComponent,
    EstudiosListComponent,
    EstudiosDetailComponent,
    EstudiosEditComponent,
    EstudiosAddComponent,
    ExperienciaListComponent,
    ExperienciaAddComponent,
    ExperienciaDetailComponent,
    ExperienciaEditComponent,
    SelectAutocompleteClienteComponent,
    SolicitudCreditoInfoComponent,
    FormInicialComponent,
    FormOtpComponent,
    CodeOtpComponent,
    FormQuestionsComponent,
    ModalPdfsComponent,
    FormRulesCreditComponent,
    LoadSignDocumentsComponent,
    ModalDetalleCapacidadEndeudamientoComponent,
    ModalRechazarSolicitudComponent,
    ModalEditFechaPrimerCuotaComponent,
    ModalInfoTableComponent,
    FiltrosReportesComponent,
    SelectRegionComponent,
    ModalPromptComponent,
    SelectSolicitudesClienteComponent,
    UbicacionGeograficaListComponent,
    ModalLinkPagoComponent,
    SelectLinkPagoConceptosComponent,
    GenerarReportesComponent,
    SinoPipe,
    AdjuntarDocumentosGenericComponent,
    ClienteInfoComponent,
    AcuerdoPagoInfoComponent,
    NumeroALetrasPipe,
    AcuerdoPagoPlanAmortizacionComponent,
    ModalHistorialAcuerdoPagoComponent,
    ModalInfoComponent,
    SelectHorarioPreferenciaAdministrativoComponent,
    SelectTipoViviendaPersonaComponent,
    SelectGrupoSanguineoComponent,
    SelectRhComponent,
    SelectRegimenComponent,
    SelectEscolaridadComponent,
    SelectEstratoComponent,
    SelectEstadoCivilComponent,
    SelectGeneroPersonaComponent,
    SolicitudCreditoInfoMedicaComponent,
    DialogGenericSharedComponent,
    RadioButtonComponent,
    PlanPagoTrebolComponent,
    ModalReservaCitaSoporteComponent,
    ReservaCitaSoporteComponent,
    ModalValidationFormComponent,
    SolicitudCreditoAprobadaResumenComponent,
    SolicitudCreditoDocumentosListComponent,
    SolicitudCreditoDocumentosAddComponent,
    ModalSinInstructivoComponent,
    ModalLineaCreditoSelectComponent,
    SpinnerComponent,
    PlanPagoListComponent,
    ModalCargarArchivoComponent,
    FileUploadComponent,
    ModalDebitoAutomaticoSelectComponent,
    ModalSolicitudCreditoSelectComponent,
    ModalDescargaDocumentosDebitoComponent,
    PlanPagoSolicitudComponent,
    ModalHistorialPagosComponent
  ],
  imports: [
    ...importsModule
  ],
  exports: [
    ModalReservaCitaSoporteComponent,
    ReservaCitaSoporteComponent,
    HelpDropdownComponent,
    DialogContentImgComponent,
    DebitoAutomaticoPagosComponent,
    DebitoAutomaticoDebitosComponent,
    DebitoAutomaticoListComponent,
    DebitoQuestionsFormComponent,
    DebitoOtpFormComponent,
    ConceptoCuotaPrioridadAddComponent,
    DocumentosFaltantesAddComponent,
    DocumentosFaltantesListComponent,
    LinkPagoDevolucionesComponent,
    MenuToggleDirective,
    ModalInfoComponent,
    SpaceUnderscorePipe,
    UnderscoreToSpacePipe,
    CapitalizeFirstLetterPipe,
    CapitalizeJustFirstLetterPipe,
    TipoIdentificacionPipe,
    SafeHtmlPipe,
    SafeVideoPipe,
    MenuToggleLinkDirective,
    MenuToggleAnchorDirective,
    ModuleValidatorDirective,
    UserActivityCheckerDirective,
    UppercaseDirective,
    TooltipImageDirective,
    ActionValidatorDirective,
    ChipErrorMessageComponent,
    MenuToolbarComponent,
    MenuSidenavComponent,
    DataTableComponent,
    FotografiaComponent,
    SelectAutocompleteGeneralComponent,
    SelectRegionComponent,
    SelectDepartamentoComponent,
    SelectMunicipioComponent,
    SelectBarrioComponent,
    ValidateClienteNumeroDocumentoComponent,
    ValidateEmailComponent,
    ValidateCellPhoneComponent,
    ValidateFechaNacimientoComponent,
    InputGenericComponent,
    SelectGenericComponent,
    SelectCargosComponent,
    SelectAutocompleteClienteComponent,
    AutocompleteAdministrativosComponent,
    SelectPaisComponent,
    RegimenSaludComponent,
    SelectLugarComponent,
    SelectAutocompleteCargosComponent,
    SolicitudCreditoClienteInfoComponent,
    SolicitudCreditoClienteInfoLaboralComponent,
    SolicitudCreditoClienteInfoEconomicaComponent,
    SolicitudCreditoClienteInfoReferenciaComponent,
    SolicitudCreditoInfoCreditoComponent,
    SolicitudCreditoResumenComponent,
    AcuerdoPagoAprobadaResumenComponent,
    ModalGenericComponent,
    AdministrativoInfoComponent,
    EstudiosListComponent,
    ExperienciaListComponent,
    SolicitudCreditoInfoComponent,
    FormInicialComponent,
    FormOtpComponent,
    CodeOtpComponent,
    FormQuestionsComponent,
    ModalPdfsComponent,
    FormRulesCreditComponent,
    LoadSignDocumentsComponent,
    FiltrosReportesComponent,
    ModalPromptComponent,
    SelectSolicitudesClienteComponent,
    ModalLinkPagoComponent,
    GenerarReportesComponent,
    SinoPipe,
    AdjuntarDocumentosGenericComponent,
    ClienteInfoComponent,
    AcuerdoPagoInfoComponent,
    AcuerdoPagoPlanAmortizacionComponent,
    SelectTipoViviendaPersonaComponent,
    SelectGrupoSanguineoComponent,
    SelectRhComponent,
    SelectRegimenComponent,
    SelectEscolaridadComponent,
    SelectEstratoComponent,
    SelectEstadoCivilComponent,
    SelectGeneroPersonaComponent,
    SolicitudCreditoInfoMedicaComponent,
    RadioButtonComponent,
    PlanPagoTrebolComponent,
    SolicitudCreditoAprobadaResumenComponent,
    SpinnerComponent,
    PlanPagoListComponent,
    SolicitudCreditoDocumentosListComponent,
    ModalCargarArchivoComponent,
    FileUploadComponent,
    ...importsModule,
    ModalDebitoAutomaticoSelectComponent,
    ModalSolicitudCreditoSelectComponent,
    ModalErrorComponent,
    ModalDescargaDocumentosDebitoComponent,
    PlanPagoSolicitudComponent,
    ModalHistorialPagosComponent
  ],
  providers: [DatePipe],
  entryComponents: [
    ModalReservaCitaSoporteComponent,
    HelpDropdownComponent,
    DialogContentImgComponent,
    DebitoAutomaticoPagosComponent,
    DebitoAutomaticoDebitosComponent,
    DebitoAutomaticoListComponent,
    DebitoQuestionsFormComponent,
    DebitoOtpFormComponent,
    ConceptoCuotaPrioridadAddComponent,
    DocumentosFaltantesAddComponent,
    DocumentosFaltantesListComponent,
    LinkPagoDevolucionesComponent,
    LugarAddComponent,
    BarrioAddComponent,
    ModalServerErrorComponent,
    ModalErrorComponent,
    DeleteModalComponent,
    ModalCodeConfirmComponent,
    UbicacionGeograficaAddComponent,
    UbicacionGeograficaListComponent,
    EstudiosAddComponent,
    EstudiosDetailComponent,
    EstudiosEditComponent,
    ExperienciaAddComponent,
    ExperienciaDetailComponent,
    ExperienciaEditComponent,
    ModalPdfsComponent,
    ModalDetalleCapacidadEndeudamientoComponent,
    ModalEditFechaPrimerCuotaComponent,
    ModalGenericComponent,
    ModalLinkPagoComponent,
    ModalInfoComponent,
  ],
})
export class SharedModule { }
