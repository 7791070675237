export const MY_DATE_FORMATS = {
	parse: {
		dateInput: 'dd/MM/yyyy'
	},
	display: {
		dateInput: 'dd/MM/yyyy',
		monthYearLabel: 'LLL y',
		dateA11yLabel: 'MMMM d, y',
		monthYearA11yLabel: 'MMMM y'
	}
};