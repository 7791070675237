<form novalidate autocomplete="off" class="w-full">
  <div>
    <h2 class="text-center" mat-dialog-title i18n>Seleccionar Solicitudes para Débito Automático</h2>
  </div>
  <mat-divider class="primary-border mrgn-b-xs"></mat-divider>
  <div *ngIf="!!message" class="mrgn-y-xs">
    <p>{{message}}</p>
  </div>
  <mat-dialog-content>
    <div  class="rounded-md shadow-lg pad-all-md mrgn-b-md">
      <div class="flex gap-3 mrgn-x-xs text-center">
        <strong class="w-[20%]">N° Solicitud</strong>
        <p class="my-auto w-[55%]">Línea Crédito</p>
        <p class="my-auto w-[15%]">Fecha</p>
        <span class="w-[10%]"></span>
      </div>
      <mat-divider class="mrgn-y-xs"></mat-divider>
        <mat-selection-list #solicitud (selectionChange)="selectionChange($event.options[0])" [disabled]="isDetail">
          <mat-list-option *ngFor="let solicitud of solicitudes; let isLast = last"
            [selected]="solicitudesControl?.value.includes(solicitud)" [value]="solicitud">
            <div class="flex gap-3">
              <strong class="w-[20%] my-auto">{{ solicitud.numeroSolicitud }}</strong>
              <p class="my-auto w-[60%]">
                {{ solicitud.lineaCredito ? solicitud.lineaCredito.nombre : 'EDUCATIVA : ' + solicitud.contratoAcademico }}
              </p>
              <p class="my-auto w-[20%]">{{ formatDate(solicitud.fecha) }}</p>
            </div>
            <mat-divider *ngIf="!isLast"></mat-divider>
          </mat-list-option>
        </mat-selection-list>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close(null)">
      Cancelar
    </button>
    <button mat-raised-button (click)="dialogRef.close(solicitudesControl.value)" class="btn-color-succes"
      [disabled]="!isDetail && solicitudesControl.invalid">
      Continuar
    </button>
  </mat-dialog-actions>
</form>
