import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DebitoAutomatico } from 'src/app/core/models/debito-automatico/debito-automatico.model';

@Component({
  selector: 'ef-modal-debito-automatico-select',
  templateUrl: './modal-debito-automatico-select.component.html',
  styleUrls: ['./modal-debito-automatico-select.component.scss']
})
export class ModalDebitoAutomaticoSelectComponent implements OnInit {

  isLoading: boolean;
  title: string;
  message: string;
  textButton: string;
  tipoHorario: boolean;
  hideButton: boolean;
  aceptarButton: boolean;
  formAdd: FormGroup;
  debitosAutomaticos: Array<DebitoAutomatico> = [];

  constructor(public dialogRef: MatDialogRef<ModalDebitoAutomaticoSelectComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastService: ToastrService,
    public formBuilder: FormBuilder) {
    this.isLoading = false;
    const { title, message, textButton } = data;
    this.title = title;
    this.message = message;
    this.textButton = textButton || 'Cerrar';
    this.hideButton = data.hideButton;
    this.aceptarButton = data.aceptarButton;
    this.debitosAutomaticos = data.debitosAutomaticos;
  }

  ngOnInit(): void {
    this.formAdd = this.formBuilder.group({
      debitoAutomatico: [null, [Validators.required]],
    });
    if (this.debitosAutomaticos.length == 1) {
      this.form.debitoAutomatico.setValue(this.debitosAutomaticos[0]);
    }
  }

  onFormSubmit() {
    if (navigator.onLine) {
      const formFilterValues = this.formAdd.getRawValue();
      const debitoAutomatico = formFilterValues.debitoAutomatico
      if (debitoAutomatico == null) {
        this.toastService.warning("Por favor selecciona una tarjeta");
        return;
      }
      
      this.dialogRef.close(debitoAutomatico);
    }
  }

  get form() {
    return this.formAdd.controls;
  }
}
