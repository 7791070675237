<div class="popup-card-width">
  <div class="gene-card-title">
    <div class="flex justify-center">
      <div class="flex flex-col">
        <h2 class="placeholder">Generar Documentos - {{'No. '+solicitud?.numeroSolicitud}}</h2>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <br>
  <mat-dialog-content>
    <div class="pad-all-md flex flex-wrap justify-around items-center">
      <button mat-raised-button class="gene-fab-flat-icon pad-all-md" mat-card-icon
        matTooltipPosition="above" matTooltip="Solicitud Credito" (click)="setAction('solicitud')">
        <mat-icon class="icon-aux">assignment_turned_in</mat-icon>
      </button>
      <button mat-raised-button class="gene-fab-flat-icon pad-all-md" mat-card-icon
        [disabled]="solicitud?.flagFileUpload && solicitud?.estado === 'RECHAZADO'" matTooltipPosition="above"
        matTooltip="Contrato Edufactoring" (click)="setAction('contratoCartaPagare')">
        <mat-icon class="icon-aux">chrome_reader_mode</mat-icon>
      </button>
      <button  *ngIf="solicitud?.cliente.flagDebitoAutomatico" mat-raised-button class="gene-fab-flat-icon pad-all-md" mat-card-icon
        [disabled]="solicitud?.flagFileUpload && solicitud?.estado === 'RECHAZADO'" matTooltipPosition="above"
        matTooltip="Autorizacion Débito Automático" (click)="setAction('debitoAutomatico')">
        <mat-icon class="icon-aux">credit_score</mat-icon>
      </button>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button mat-raised-button
      *ngIf="(solicitud?.estado === 'APROBADO' || solicitud?.estado === 'RECHAZADO') && solicitud?.flagFileUpload"
      color="primary" (click)="setAction('todos')">Descargar Todos
    </button>
    <button mat-raised-button color="primary" (click)="dialogRef.close(false)">Cerrar
    </button>
  </mat-dialog-actions>
</div>
