import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import PaisModel from 'src/app/core/models/ubicaciones/pais.model';
import DepartamentoModel from 'src/app/core/models/ubicaciones/departamento.model';
import RegionModel from 'src/app/core/models/ubicaciones/region.model';

@Component({
  selector: 'ef-ubicacion-geografica-list',
  templateUrl: './ubicacion-geografica-list.component.html',
  styleUrls: ['./ubicacion-geografica-list.component.scss']
})
export class UbicacionGeograficaListComponent implements OnInit {

  formAddUbicacion: FormGroup;
	pais: PaisModel;
	region: RegionModel;

	constructor(
		public formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<UbicacionGeograficaListComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.pais = data.pais;
	}

	ngOnInit() {
		this.formAddUbicacion = this.formBuilder.group({
			pais: [ '', [ Validators.required ] ],
			region: [ '' , [ Validators.required ] ],
			departamento: [ '' , [ Validators.required ] ],
			municipio: [ '' , [ Validators.required ]],
		});

		if (this.pais) {
			this.form.pais.setValue(this.pais);
			this.form.pais.disable();
			this.form.region.enable();
		}
	}

	setPais(event) {
		if (event != null) {
			this.form.region.setValue('');
			this.form.region.enable();
		}
	}

	setRegion(event) {
		if (event != null) {
			this.form.departamento.setValue('');
			this.form.departamento.enable();
		}
	}

	get form() {
		return this.formAddUbicacion.controls;
	}
}
