<div class="popup-card-width">
  <div class="text-center">
    <h2 mat-dialog-title
        i18n>Validar Contrato Académico</h2>
  </div>
  <mat-dialog-content>
    <div>
      <div class="flex">
        <mat-form-field>
          <input matInput
                 type="number"
                 [formControl]="numeroContratoControl"
                 class="form-control">
          <mat-placeholder class="placeholder">Número Contrato</mat-placeholder>
          <mat-error *ngIf="numeroContratoControl.touched && numeroContratoControl.errors">
            <mat-error *ngIf="numeroContratoControl.errors.required"
                       class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>

    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button"
            mat-raised-button
            (click)="dialogRef.close(null)">Cerrar
    </button>
    <button (click)="onFormSubmit()"
            type="submit"
            [disabled]="!numeroContratoControl.valid"
            mat-raised-button
            color="primary">Validar
    </button>
  </mat-dialog-actions>
</div>
