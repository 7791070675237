import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IDebitoAutomaticoProcessService } from "../debito-automatico-process.service.interface";
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CodeDA, DoByPassDA, FillFormDA, IdVisionBaseReponseDA, OtpDA, ResponseTakeExam }
  from "src/app/core/models/debito-automatico/trans-union-debito.model";
import * as _ from "lodash";
import { CargaDocumento, DebitoAutomatico } from "src/app/core/models/debito-automatico/debito-automatico.model";

@Injectable({
  providedIn: 'root'
})
export class DebitoAutomaticoProcessServiceImpl implements IDebitoAutomaticoProcessService {

  httpClient: HttpClient;
  apiUrl: string;
  resource: string;

  constructor(public http: HttpClient) {
    this.httpClient = http;
    this.apiUrl = environment.apiUrl;
    this.resource = 'debito-automatico-process/';
  }

  autorizarDebito(debitoAutomatico: DebitoAutomatico) {
	const path = this.getFullPath() + `/autorizar-debito`;
    return this.httpClient.post(path, debitoAutomatico)
      .pipe(map((res: any) => res));
  }

  getAutorizacionDebitoPdf(idDebito: number): Observable<BlobPart> {
    const path = this.getFullPath() + `debito/${idDebito}/autorizacion-debito-automatico/pdf`
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.httpClient
      .get(path, { headers, responseType: 'blob' });
  }

  getAutorizacionDebitoReport(idDebito: number) {
    return this.httpClient.get(this.getFullPath() + `/debito/${idDebito}/autorizacion-debito-automatico/report`)
      .pipe(map((res) => res));
  }

  getDocumentsIdVision(idCliente: number, idDebito: number, idSolicitud: number): Observable<IdVisionBaseReponseDA> {
    const endPoint = `trans-union/idvision/cliente/${idCliente}/debito/${idDebito}/solicitud/${idSolicitud}/get-documents`;
    return this.httpClient.post(this.getFullPath() + endPoint, {})
      .pipe(map((res: IdVisionBaseReponseDA) => res));
  }

  singDocumentsIdVision(idCliente: number, idDebito: number): Observable<IdVisionBaseReponseDA> {
    const endPoint = `trans-union/idvision/cliente/${idCliente}/debito/${idDebito}/sign-documents`;
    return this.httpClient.post(this.getFullPath() + endPoint, {})
      .pipe(map((res: IdVisionBaseReponseDA) => res));
  }

  doByPassIdVision(doByPassRequest: DoByPassDA): Observable<IdVisionBaseReponseDA> {
    const endPoint = `trans-union/idvision/dobypass`;
    return this.httpClient.post(this.getFullPath() + endPoint, doByPassRequest)
      .pipe(map((res: IdVisionBaseReponseDA) => res));
  }

  fillFormIdVision(fillFormRequest: FillFormDA): Observable<IdVisionBaseReponseDA> {
    const endPoint = `trans-union/idvision/fillform`;
    return this.httpClient.post(this.getFullPath() + endPoint, fillFormRequest)
      .pipe(map((res: IdVisionBaseReponseDA) => res));
  }

  sendCodeIdVision(sendCodeRequest: CodeDA): Observable<IdVisionBaseReponseDA> {
    const endPoint = `trans-union/idvision/sendcode`;
    return this.httpClient.post(this.getFullPath() + endPoint, sendCodeRequest)
      .pipe(map((res: IdVisionBaseReponseDA) => res));
  }

  sendNumberIdVision(sendNumberRequest: OtpDA): Observable<IdVisionBaseReponseDA> {
    const endPoint = `trans-union/idvision/sendnumber`;
    return this.httpClient.post(this.getFullPath() + endPoint, sendNumberRequest)
      .pipe(map((res: IdVisionBaseReponseDA) => res));
  }

  takeExamIdVision(takeExamRequest: ResponseTakeExam): Observable<IdVisionBaseReponseDA> {
    const endPoint = `trans-union/idvision/take-exam`;
    return this.httpClient.post(this.getFullPath() + endPoint, takeExamRequest)
      .pipe(map((res: IdVisionBaseReponseDA) => res));
  }

  loadDocumentsIdTransUnion(loadDocumentRequest: CargaDocumento) {
    const endPoint = `trans-union/idvision/load-documents`;
    return this.httpClient.put(this.getFullPath() + endPoint, loadDocumentRequest)
      .pipe(map((res) => res));
  }

	activarDebitoAutorizado(idSolicitud: number) {
		const url = `activar-debito-autorizado/solicitudId/${idSolicitud}`
		return this.httpClient.put(this.getFullPath() + url, {})
			.pipe(map(res => res))
	}

  protected getFullPath() {
    return this.apiUrl + this.resource;
  }

}
