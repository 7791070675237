export default class StringUtil {
	public static normalize(toNormalize: string): string {
		const from = 'ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç';
		const to = 'AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc';
		const mapping = {};

		for (let i = 0, j = from.length; i < j; i++) {
			mapping[from.charAt(i)] = to.charAt(i);
		}
		const ret = [];
		for (let i = 0, j = toNormalize.length; i < j; i++) {
			const c = toNormalize.charAt(i);
			if (mapping.hasOwnProperty(toNormalize.charAt(i))) {
				ret.push(mapping[c]);
			} else {
				ret.push(c);
			}
		}
		return String(ret.join(''));
	}
}
