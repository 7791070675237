<form [formGroup]="formFilter" novalidate class="md:flex text-center"
	  *ngIf="!externalPaging && form && isFilter" id="step3">
  <span class="full-wid md:grow"></span>
	<div class="mt-3 md:grow-0 text-center mrgn-t-sm">
		<mat-button-toggle-group aria-label="Estadi" class="margin-right" formControlName="statusFilter"
			(change)="applyFilter()" *ngIf="showFilterEstados">
			<mat-button-toggle [value]="null">Todos</mat-button-toggle>
			<mat-button-toggle value="ACTIVO">Activos</mat-button-toggle>
			<mat-button-toggle value="INACTIVO">Inactivos</mat-button-toggle>
		</mat-button-toggle-group>
	</div>
	<div class="full-wid md:grow  mrgn-t-sm">
		<mat-form-field type="text" appearance="fill" class="full-wid">
			<mat-label>Ingrese su búsqueda</mat-label>
			<input matInput autocomplete="off" formControlName="textFilter" (keyup)="applyFilter()">
			<mat-icon matSuffix>search</mat-icon>
		</mat-form-field>
	</div>
</form>


<ngx-datatable #table class="material mrgn-all-md" [columns]="columnNames" [columnMode]="'force'" [headerHeight]="50"
	[footerHeight]="50" [rowHeight]="rowHeight" [limit]="limit" [rowClass]="rowClass" [rows]='displayData'
	[scrollbarH]="true" [messages]="messages" [externalPaging]="externalPaging" [count]="count" (page)="setPage($event)"
	[offset]="pageNumber" *ngIf="externalPaging" [externalSorting]="externalPaging" (sort)="onSortClick($event)"
	[loadingIndicator]="loadingIndicator" [selected]="selected" [selectionType]="SelectionType.checkbox"
	[selectAllRowsOnPage]="false" (select)="onSelect($event)">
</ngx-datatable>

<ngx-datatable  id="step4" #table class="material mrgn-all-md" [columns]="columnNames" [columnMode]="'force'" [headerHeight]="50"
	[footerHeight]="50" [rowHeight]="rowHeight" [limit]="limit" [rows]='displayData' [scrollbarH]="true"
	[messages]="messages" *ngIf="!externalPaging">
</ngx-datatable>

<ng-template #estadoTemplate let-row="row" let-value="value" class="text-center">
	<span class="{{getColor(row.estado)}} status-label"> {{row.estado}}</span>
</ng-template>
