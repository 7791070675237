import { Injectable } from '@angular/core'; 
import { HttpClient } from '@angular/common/http';
import { ServiceImplService } from '../../service.impl.service';
import ProcesoPaso from 'src/app/core/models/configuracion-solicitud-credito/proceso-paso.model';
import { IPasoProceso } from '../proceso-paso.service.interface';
import { Observable } from 'rxjs';
import { EstadosHelper } from 'src/app/core/helpers/estados.helper';
import ProcesoModel from 'src/app/core/models/configuracion-solicitud-credito/proceso.models';

@Injectable({providedIn : 'root'})
export class PasoProcesoServiceImpl extends ServiceImplService<ProcesoPaso> implements IPasoProceso {
	constructor(
		public  http: HttpClient,
	) {
		super();
		this.httpClient = http;
		this.resource = 'proceso-paso/';
	}

	updateState(pasoId: number, procesoId: number, state: boolean) {
		const path = `${this.apiUrl}${this.resource}${pasoId}/${procesoId}`;
		const pasoEstado = EstadosHelper.getEstadoFromBoolean(state);
		return this.http.put(path, { pasoEstado });
	}

	getOrCreateDefaultPaso(proceso: ProcesoModel) {
		const path = `default`;
		const data = { ...proceso };
		return this.post(data, path);
	}

}
