import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AcuerdoPagoServiceImpl } from 'src/app/core/http/acuerdo-pago/Impl/acuerdo-pago.service.impl';

@Component({
	selector: 'ef-modal-historial-acuerdo-pago',
	templateUrl: './modal-historial-acuerdo-pago.component.html',
	styleUrls: [ './modal-historial-acuerdo-pago.component.scss' ]
})
export class ModalHistorialAcuerdoPagoComponent implements OnInit {
  historial;
	constructor(
    public acuerdoPagoService: AcuerdoPagoServiceImpl,
    public toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.historial = data;
  }

	ngOnInit(): void {
  }
}
