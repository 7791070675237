<mat-card-content class="jt-session-expired">
    <h2 class="text-center  mrgn-b-lg warn-text" i18n>La Sesión Expiro !</h2>
    <div class="login-logo text-center">
        <div class="icon-container mat-elevation-z2 rubberBand animated primary-bg">
            <mat-icon>lock</mat-icon>
        </div>
    </div>
    <h4 class="text-center" i18n>Tu sesión ha expirado por favor inicia sesión nuevamente</h4>

    <mat-dialog-actions align="end">
        <button class="mat-raised-button mat-accent" i18n (click)="close()">Cerrar</button>
    </mat-dialog-actions>
</mat-card-content>
