<form [formGroup]="formEditSarlaf" novalidate autocomplete="off" class="popup-card-width overflow-hidden">
    <div>
        <h2 mat-dialog-title i18n>Editar Configuración Sarlaft</h2>
    </div>
    <mat-dialog-content>
        <div>
            <div>
                <div class="grid md:grid-cols-2 gap-2">
                    <mat-form-field>
                        <mat-label class="placeholder">Nombre</mat-label>
                        <input matInput formControlName="nombre" />
                        <mat-error *ngIf="form.nombre.touched && form.nombre.errors">
                            <mat-error *ngIf="form.nombre.errors.required" class="required alert-error">
                                <span i18n>Este campo es requerido</span>
                            </mat-error>
                            <mat-error *ngIf="!form.nombre.errors.required" class="required alert-error">
                                <span i18n>{{ form.nombre.errors }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="mrgn-b-sm">
                        <mat-label class="placeholder">Codigo</mat-label>
                        <input matInput type="text" formControlName="codigo" class="form-control" />
                        <mat-error *ngIf="form.codigo.touched && form.codigo.errors">
                            <mat-error
                                *ngIf="formEditSarlaf.get('codigo').errors && formEditSarlaf.get('codigo').errors.hasOwnProperty('maxlength')">
                                <span i18n>La descripción debe tener como máximo 50 caracteres.</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field>
                    <mat-label class="placeholder">Descripcion</mat-label>
                    <textarea matInput formControlName="descripcion" (input)="calcularCaracteresEscritos($event)"></textarea>
                    <p class="mb-0">{{ caracteresEscritos }}/500</p>
                    <mat-error *ngIf="form.descripcion.touched && form.descripcion.errors">
                        <mat-error *ngIf="form.descripcion.errors.required" class="required alert-error">
                            <span i18n>Este campo es requerido</span>
                        </mat-error>
                        <mat-error *ngIf="formEditSarlaf.get('descripcion').errors && formEditSarlaf.get('descripcion').errors.hasOwnProperty('maxlength')">
                            <span i18n>La descripción debe tener como máximo 500 caracteres.</span>
                        </mat-error>
                        <mat-error *ngIf="formEditSarlaf.get('descripcion').errors && formEditSarlaf.get('descripcion').errors.hasOwnProperty('minlength')">
                            <span i18n>La descripción debe tener como minimo 10 caracteres.</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <div class="grid md:grid-cols-2 gap-3">
                    <div class="ml-2 grid">
                        <mat-label class="placeholder mr-2">Rechazado</mat-label>
                        <mat-slide-toggle formControlName="fRechazo" class="justify-center">
                            {{ form.fRechazo.value ? "SI" : "NO" }}
                        </mat-slide-toggle>
                    </div>
                    <div class="ml-2 grid">
                        <mat-label class="placeholder mr-2">Pendiente</mat-label>
                        <mat-slide-toggle formControlName="fPendiente" class="justify-center">
                            {{ form.fPendiente.value ? "SI" : "NO" }}
                        </mat-slide-toggle>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
        <button type="button" mat-raised-button (click)="dialogRef.close()">
            Cerrar
        </button>
        <button (click)="onFormSubmit()" type="submit" [disabled]="!formEditSarlaf?.valid || loading" mat-raised-button
            color="primary">
            Guardar
        </button>
    </mat-dialog-actions>
</form>
