<div class="popup-card-width">
    <div>
        <h2 mat-dialog-title i18n>Ver Detalle</h2>
    </div>
    <mat-divider class="primary-border"></mat-divider>
    <mat-dialog-content *ngIf="opcionDetail" class="mrgn-t-sm">
        <div class="flex flex-wrap justify-between">
            <div class="md:w-[30%] sm:w-[40%] xs:full-wid">
                <strong>Código: </strong>
            </div>
            <div class="md:w-[70%] sm:w-[60%] xs:full-wid">
                <span>{{opcionDetail.id}}</span>
            </div>
        </div>
        <div class="flex flex-wrap justify-between">
            <div class="md:w-[30%] sm:w-[40%] xs:full-wid">
                <strong>Nombre: </strong>
            </div>
            <div class="md:w-[70%] sm:w-[60%] xs:full-wid">
                <span>{{opcionDetail.nombre}}</span>
            </div>
        </div>
        <div class="flex flex-wrap justify-between">
            <div class="md:w-[30%] sm:w-[40%] xs:full-wid">
                <strong>Tipo Persona: </strong>
            </div>
            <div class="md:w-[70%] sm:w-[60%] xs:full-wid">
                <span>{{opcionDetail.tipoPersona}}</span>
            </div>
        </div>
        <div class="flex flex-wrap justify-between">
            <div class="md:w-[30%] sm:w-[40%] xs:full-wid">
                <strong>Estado: </strong>
            </div>
            <div class="md:w-[70%] sm:w-[60%] xs:full-wid">
                <span>{{opcionDetail.estado}}</span>
            </div>
        </div>
        <div class="flex flex-wrap justify-between">
            <div class="md:w-[30%] sm:w-[40%] xs:full-wid">
                <strong>Descripción: </strong>
            </div>
            <div class="md:w-[70%] sm:w-[60%] xs:full-wid">
                <span>{{opcionDetail.descripcion}}</span>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
        <button type="button" mat-raised-button (click)="dialogRef.close()">
            Cerrar
        </button>
    </mat-dialog-actions>
</div>
