import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { LineaCreditoInstructivoService } from 'src/app/core/http/configuracion-basica/impl/linea-credito-instructivo.service.impl';
import { DialogService } from 'src/app/core/services/dialog.service';

@Component({
  selector: 'ef-instructivos-add',
  templateUrl: './instructivos-add.component.html',
  styleUrls: ['./instructivos-add.component.scss']
})
export class InstructivosAddComponent implements OnInit {

  idLineaCredito!: number;
  selectedFile: File;
  filePreview: string | ArrayBuffer;
  base64File: string;

  @ViewChild('content') content: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<InstructivosAddComponent>,
    public dialogService: DialogService,
    
    public toast: ToastrService,
    public instructivoService: LineaCreditoInstructivoService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.idLineaCredito = data.idLineaCredito;
  }

  ngOnInit(): void {
  }

  onFileDropped(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    this.base64Parser(files[0]);

  }
  onFileChanged(event) {
    event.preventDefault();
    event.stopPropagation();
    this.base64Parser(event.target.files[0]);
  }

  base64Parser(file: any) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.base64File = reader.result.toString();
      const mimeType: string = this.base64File.split(',')[0].split(':')[1].split(';')[0];
      if (mimeType === 'application/pdf') {
        this.selectedFile = file;
      } else {
        this.toast.warning(
          'El archivo debe ser en formato pdf.',
          'Importante'
        );
        return;
      }
    };
  }

  onUpload() {
    
    let insertInstructivo = {
      descripcion: "Instructivo para Linea de Credito",
      nombre: this.selectedFile.name,
      extension: 'pdf',
      base64: String(this.base64File).split('data:application/pdf;base64,')[1],
      kbytes: this.calculateImageSize(this.base64File)
    };
    let insertRequest = {
      lineaCreditoId: this.idLineaCredito,
      document: insertInstructivo
    };
    this.instructivoService.saveInstructivo(insertRequest)
      .subscribe({
        next: res => {
          ;
          if (!res.data.idFile) {
            this.toast.error('No fue posible cargar el instructivo.', 'Error');
          } else {
            this.toast.success('El instructivo fue cargado exitosamente.');
            this.dialogRef.close(true);
          }
        },
        error: err => {
          ;
          if (err.error.errors) {
            for (const error of err.error.errors) {
              this.toast.error(error.message, 'Error');
            }
          } else {
            if (err.error.message) {
              this.toast.error(err.error.message, 'Error');
            }
          }
        }
      });
  }

  calculateImageSize(base64): any {
    let padding, inBytes, base64Length;
    if (base64.endsWith('==')) {
      padding = 2;
    } else if (base64.endsWith('=')) {
      padding = 1;
    } else {
      padding = 0;
    }
    base64Length = base64.length;
    inBytes = (base64Length / 4) * 3 - padding;
    let kbytes = inBytes / 1000;
    return kbytes;
  }


  preview() {
    this.dialogService.modalPdfs(
      'Instructivo',
      this.base64File,
      'aceptar',
      'Cerrar'
    );
  }

  clearFile() {
    this.selectedFile = null;
  }

  closeModal() {
    this.dialogRef.close();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragEnter(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
  }

  onDragLeave(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
  }
}
