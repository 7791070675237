import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import OpcionModel from '../../../../core/models/configuracion-solicitud-credito/opcion.models';
import { OpcionServiceImpl } from '../../../../core/http/configuracion-solicitud-credito/impl/opcion.service.impl';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ESTADOS_BASE } from 'src/app/core/constants/estados.constants';

@Component({
  selector: 'ef-opcion-edit',
  templateUrl: './opcion-edit.component.html',
  styleUrls: ['./opcion-edit.component.scss'],
})
export class OpcionEditComponent implements OnInit {
  formEdit: FormGroup;
  loading = false;
  mensajeError = false;
  opcion: OpcionModel;
  tipoPersona = ['CLIENTE', 'ADMINISTRATIVO', 'AMBOS'];
  caracteresEscritos: number = 0;
  nombreExistentes: string[];
  nombreActual: string;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<OpcionEditComponent>,
    private toast: ToastrService,
    private opcionService: OpcionServiceImpl,
    @Inject(MAT_DIALOG_DATA) public data: OpcionModel
  ) {
    this.opcion = data;
    this.nombreActual = this.opcion.nombre;
  }

  onFormSubmit() {
    if (navigator.onLine) {
      this.mensajeError = null;
      const opcionNuevo = Object.assign({}, this.formEdit.value);
      opcionNuevo.estado = ESTADOS_BASE.ACTIVO;
      const nombreActual = this.nombreExistentes.includes(opcionNuevo.nombre.toLowerCase());

      if (nombreActual) {
        this.toast.error('Este nombre ya está en uso');
        return;
      }


      this.opcionService
        .update(String(this.opcion.id), opcionNuevo)
        .subscribe(
          {
            next: (opcion: string) => {
              this.dialogRef.close(opcion);
            },
            error: (error) => {
              try {
                for (const field of error.error.errors) {
                  this.toast.error(field.message, 'Error');
                }
              } catch (e) {
                this.mensajeError = error.error.message;
              }
            }
          }
        );
    } else {
      this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
    }
  }

  ngOnInit(): void {
    this.formEdit = this.formBuilder.group({
      nombre: [this.opcion.nombre, [Validators.required, Validators.pattern(/^\S.*\S$/)]],
      descripcion: [this.opcion.descripcion, [Validators.required, Validators.minLength(10), Validators.maxLength(500), Validators.pattern(/^\S.*\S$/)]],
      estado: [this.opcion.estado],
      tipoPersona: [this.opcion.tipoPersona, [Validators.required]],
    });

    this.calcularCaracteresEscritos(this.opcion.descripcion);

    this.initForm();
    this.opcionService.filter({
      estado: ESTADOS_BASE.ACTIVO,

    })
      .subscribe({
        next: (rest) => {
          this.nombreExistentes = rest.data.map(el => el.nombre.toLowerCase());
          this.nombreExistentes = this.nombreExistentes.filter(el => el !== this.nombreActual.toLowerCase());
        }
      });
  }


  initForm() {
    this.loading = false;
  }

  get form() {
    return this.formEdit.controls;
  }

  subcribeTipoPersona() {
    this.formEdit
      .get('tipoPersona')
      .valueChanges.subscribe({
        next: (tipoPersona) => {
          this.tipoPersona = tipoPersona;
        }
      });
  }

  calcularCaracteresEscritos(texto: string): void {
    this.caracteresEscritos = texto?.length;
  }

  calcularCaracteresEscritosInput(event: Event): void {
    const texto = (event.target as HTMLTextAreaElement).value;
    this.calcularCaracteresEscritos(texto);
  }

}
