import { Injectable } from '@angular/core';
import { AbstractControl, Validators, FormControl, ValidatorFn } from '@angular/forms';

@Injectable()
export class CustomValidatorsHelper {
	static numeric(control: AbstractControl): { [key: string]: boolean } | null {
		if (control.value !== null && control.value !== undefined && isNaN(control.value)) {
			return { numeric: true };
		}

		return null;
	}

	static telephone(control: AbstractControl): { [key: string]: boolean } | null {
		if (
			control.value !== null &&
			control.value !== undefined &&
			String(control.value).length < 7 &&
			String(control.value).length >= 1
		) {
			return { telephone: true };
		}

		return null;
	}

	static mobile(control: AbstractControl): { [key: string]: boolean } | null {
		if (control.value !== undefined && String(control.value).length < 10 && String(control.value).length >= 1) {
			return { mobile: true };
		}

		return null;
	}

	/**
	 * this function allows validate an input type number that should be require, minimum value 0, and numeric Type
	 */
	static defaultNumericValidation() {
		return [ '', [ Validators.required, Validators.min(0), this.numeric ] ];
	}

	/**
	 * This function does not allow only white space in the input
	 */

	static minLengthAux(control: AbstractControl): { [key: string]: boolean } | null {
		if (control.value !== undefined && String(control.value).length < 20 && String(control.value).length >= 1) {
			return { minAux: true };
		}

		return null;
	}

	static validNumberDoc(control: AbstractControl): { [key: string]: boolean } | null {
		if (control.value !== undefined) {
			if (
				String(control.value).length < 1 &&
				String(control.value).length > 8 &&
				String(control.value).length !== 10
			) {
				return { numInvalid: true };
			}
		}

		return null;
	}

	/**
	 * This function does not allow only white space in the input
	 */
	static noWhitespaceValidator(control: FormControl) {
		const isWhitespace = (control.value || '').trim().length === 0;
		return isWhitespace ? { required: true } : null;
	}

	static validateSelect(control: FormControl) {
		if (control.value !== undefined && control.value != null) {
			const Object = control.value;
			if (Object.id || String(Object).length == 0) {
				return null;
			}
			return { valid: true };
		}
		return null;
	}

	static fileType(type: string) {
		return function(control: FormControl) {
			const file = control.value;
			if (file) {
				const extension = file.name.split('.').pop().toLowerCase();
				if (type.toLowerCase() !== extension.toLowerCase()) {
					return {
						fileType: true
					};
				}

				return null;
			}

			return null;
		};
	}

	static fileSize(size: number) {
		return function(control: FormControl) {
			const file = control.value;
			if (file && file.size > size) {
				return {
					fileSize: true
				};
			}

			return null;
		};
	}

	static equalsValidator(otherControl: AbstractControl): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | any => {
			const value: any = control.value;
			const otherValue: any = otherControl.value;
			if (value && otherValue) {
				if (value.hasOwnProperty('id') && otherValue.hasOwnProperty('id')) {
					if (value.id === otherValue.id) {
						return { equals: true };
					} else {
						return null;
					}
				} else {
					if (value && otherValue) {
						if (value === otherValue) {
							return { equals: true };
						} else {
							return null;
						}
					}
				}
			}
		};
	}

	static validRegExp(control: FormControl) {
		const resp = {};
		const lenMaxMin = control.value.length;
		const hasNumber = /(?=\d)/.test(control.value);
		const hasLower = /(?=[^a-z]*[a-z])/.test(control.value);
		const hasUpper = /(?=[^A-Z]*[A-Z])/.test(control.value);
		const hasEspc = /.*[|!"#$%&@/()=?¡'*+:;,.-]/.test(control.value);

		if (lenMaxMin >= 8 && lenMaxMin <= 16) {
			delete resp['lenMaxMin'];
		} else {
			resp['lenMaxMin'] = true;
		}
		if (!hasNumber) {
			resp['hasNumber'] = true;
		} else {
			delete resp['hasNumber'];
		}
		if (!hasLower) {
			resp['hasLower'] = true;
		} else {
			delete resp['hasLower'];
		}
		if (!hasUpper) {
			resp['hasUpper'] = true;
		} else {
			delete resp['hasUpper'];
		}
		if (!hasEspc) {
			resp['hasEspc'] = true;
		} else {
			delete resp['hasEspc'];
		}
		return resp;
	}
}
