import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import format from 'date-fns/format';

import { DebitoAutomatico } from '@core/models/debito-automatico/debito-automatico.model';
import { es } from 'date-fns/locale';
import { DebitoAutomaticoProcesoServiceImpl } from '@core/http/debito-automatico/impl/debito-automatico-proceso.service.impl';
import { DebitoAutomaticoProcesoModel } from '@core/models/debito-automatico/debito-automatico-proceso.model';
import { ESTADOS } from '@core/constants/estado.constants';
import { forkJoin } from 'rxjs';
import { SolicitudCreditoServiceImpl } from '@core/http/solicitud-credito/impl/solicitud-credito.service.impl';
import { EstadoSolicitudCreditoEnum } from '@core/enums/estado-solicitud-credito.enum';
import { ToastrService } from 'ngx-toastr';
import { SolicitudCreditoExtendedModel } from '@core/models/solicitud-credito/solicitud-credito-extended.model';

@Component({
  selector: 'ef-debito-automatico-detalle',
  templateUrl: './debito-automatico-detalle.component.html',
  styleUrls: ['./debito-automatico-detalle.component.scss']
})
export class DebitoAutomaticoDetalleComponent implements OnInit {
  card: DebitoAutomatico;
  solicitudes: SolicitudCreditoExtendedModel[] = [];
  isLoading: boolean = false;
  pendingState: String = 'PENDIENTE';

  constructor(
    public dialogRef: MatDialogRef<DebitoAutomaticoDetalleComponent>,
    public debitoAutProcesoSevice: DebitoAutomaticoProcesoServiceImpl,
    public solictudCreditoService: SolicitudCreditoServiceImpl,
    public toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) {
    this.card = data;
  }

  formatDate(month: number, year: number) {
    const auxDate = new Date();
    auxDate.setMonth(month);
    auxDate.setFullYear(year);
    return format(auxDate, 'MMM/yyyy', { locale: es });
  }

  ngOnInit(): void {
    const filterDebitoProceso = {
      idDebito: this.card.id,
      estado: ESTADOS.ACTIVO,
      proceso: 'SOLICITUD'
    };
    const filterSolicitudes = {
      idCliente: this.card.cliente.id,
      estados: [
        EstadoSolicitudCreditoEnum.DESEMBOLSADO,
        EstadoSolicitudCreditoEnum.APROBADO
      ]
    }

    if (this.card.estado === this.pendingState) return;

    this.isLoading = true;

    const subscriptions = [
      this.debitoAutProcesoSevice.filter(filterDebitoProceso),
      this.solictudCreditoService.filterExtended(filterSolicitudes)
    ]

    forkJoin(subscriptions)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          const debitoProcesoSolicitudesIds = res[0].data.map(
            (proceso: DebitoAutomaticoProcesoModel) => proceso.idProceso);
          this.solicitudes = res[1].data.filter(
            solicitud => debitoProcesoSolicitudesIds.includes(solicitud.id));
        },
        error: (err) => {
          if (err.error.errors) {
            for (const error of err.error.errors) {
              this.toast.error(error.message, 'Error');
            }
          } else {
            if (err.error.message) {
              this.toast.error(err.error.message, 'Error');
            }
          }
        }
      });
  }
}
