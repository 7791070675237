import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Title } from '@angular/platform-browser';
import { Auth0ServiceImpl } from 'src/app/core/http/security/impl/auth0.service.impl';
import { LocalStorageHelper } from 'src/app/core/helpers/local-storage.helper';
import { environment } from 'src/environments/environment';
import { LOCAL_STORAGE_CONSTANTS } from 'src/app/core/constants/local-storage.constants';

@Injectable()
export class SessionCheckerHelper {
	public observersSessionExpired: Array<any>;
	public observersUserInactivity: Array<any>;
	public limitSecondsInactivity: number;
	public limitSecondsBeforeNotifyInactivity: number;
	public counterInactivity: number;
	public intervalChecker;
	public previousTitle: string;

	constructor(
		public jwtHelper: JwtHelperService,
		public auth0Service: Auth0ServiceImpl,
		public localStorageService: LocalStorageHelper,
		public windowTitleService: Title
	) {
		this.observersSessionExpired = [];
		this.observersUserInactivity = [];
		this.limitSecondsInactivity = environment.limitSecondsInactivity;
		this.limitSecondsBeforeNotifyInactivity = environment.limitSecondsBeforeNotifyInactivity;
		this.counterInactivity = 0;
		this.previousTitle = windowTitleService.getTitle();
	}

	public clearInactivity() {
		this.counterInactivity = 0;
		this.windowTitleService.setTitle(this.previousTitle);
	}

	public onJwtSessionExpired(cb) {
		if (!this.intervalChecker) {
			this.intervalChecker = setInterval(this.checker.bind(this), environment.timeIntervalChecker);
		}
		this.observersSessionExpired.push(cb);
	}

	public onInactivityUser(cb) {
		if (!this.intervalChecker) {
			this.intervalChecker = setInterval(this.checker.bind(this), environment.timeIntervalChecker);
		}

		this.observersUserInactivity.push(cb);
	}

	public checker() {
		const { USER } = LOCAL_STORAGE_CONSTANTS;
		const existUserOnLocalStorage = this.localStorageService.existsItem('user');
		if (!this.auth0Service.isValidSession() && existUserOnLocalStorage) {
			return this.notifyObserversJWT.bind(this)();
		}

		if (this.auth0Service.isValidSession() && this.counterInactivity >= this.limitSecondsInactivity) {
			return this.notifyObserversInactivity.bind(this)();
		}

		if (this.auth0Service.isValidSession() && this.counterInactivity >= this.limitSecondsBeforeNotifyInactivity) {
			this.notifyInactivity.bind(this)();
		}

		this.counterInactivity++;
	}

	public notifyInactivity() {
		const secondRest = this.limitSecondsInactivity - this.counterInactivity;
		const message = `${secondRest}seg ⏳ para cerrar Sesion`;
		this.windowTitleService.setTitle(message);
	}

	public notifyObserversJWT() {
		this.observersSessionExpired.forEach((currentCallback) => {
			currentCallback();
		});
	}

	public notifyObserversInactivity() {
		this.windowTitleService.setTitle(this.previousTitle);
		this.observersUserInactivity.forEach((currentCallback) => {
			currentCallback();
		});
	}
}
