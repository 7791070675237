<div class="popup-card-width">
  <div class="bg-white shadow-lg rounded-lg overflow-hidden min-w-[400px]">

    <div class="px-4 py-2">
      <h2 class="font-bold text-lg mb-2">Detalle de Tarjeta</h2>
      <hr class="mb-2">
      <div  *ngIf="card.estado !== pendingState; else warningMessage"  class="text-gray-700 text-base">
        <span class="grid grid-cols-2">
          <div> Número: ***{{card.cardNumber}}</div>
          <img [src]="'https://'+card.cardUrlLogoPing | safeVideo" width="30" class="w-10 h-10">
        </span>
        <br>
        <span>{{card.cardHolderName || 'No Registra'}}</span>
        <br>
        <span>Expira en {{formatDate(card.cardExpiryMonth, card.cardExpiryYear) | titlecase}}</span>
        <br>
        <div class="px-4 py-2">
        </div>
      </div>
      <ng-template #warningMessage>
        <div class="rounded" style="background-color: rgba(242, 145, 0, 0.3);">
          <div class="flex">
            <mat-icon class="px-4 mr-1 pt-3" style="color: rgba(18, 37, 55, 0.8);">info</mat-icon>
            <p class="italic pt-3 text-sm pr-2" style="color: rgba(18, 37, 55, 0.8);">
              <strong>
                NOTA:<br>
              </strong>
              Esta autorización de débito no ha sido completada.
              Una vez sea terminada podrá ver la información relacionada a la tarjeta.
            </p>
          </div>
        </div>
      </ng-template>
      <h3 class="font-bold text-lg mt-4 mb-2">Datos Cliente</h3>
      <hr class="mb-2">
      <div class="text-gray-700 text-base">
        <span>{{card.cliente.nombreCompleto}}</span>
        <br>
        <span>Documento: {{card.cliente.numeroDocumento}}</span>
      </div>
    </div>

  </div>
  <div *ngIf="!(!isLoading && !solicitudes.length)" class="bg-white shadow-lg rounded-lg overflow-hidden min-w-[400px] mrgn-t-xs">
    <div class="px-4 py-2">
      <h2 class="font-bold text-lg mb-2">Solicitudes Asociadas</h2>
      <mat-spinner class="mx-auto" *ngIf="isLoading"></mat-spinner>
      <div *ngFor="let solicitud of solicitudes; let isLast = last" class="text-gray-700 text-base">
        <div class="flex gap-3 my-1">
          <span> <strong>{{solicitud.numeroSolicitud}}</strong></span>
          <span>{{solicitud.lineaCredito ? solicitud.lineaCredito.nombre : 'CRÉDITO EDUCATIVO N° '+ solicitud.contratoAcademico}}</span>
        </div>
        <mat-divider class="full-wid" *ngIf="!isLast"></mat-divider>
      </div>
    </div>
  </div>
  <div class="md:flex md:justify-end items-center flex-col md:flex-row w-full md:w-auto">
    <button (click)="dialogRef.close(false)" mat-raised-button class="btn-color-volver w-full md:w-auto !m-2">
      {{'cerrar' | titlecase}}
    </button>
  </div>
</div>
