import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LineaCreditoServiceImpl } from '../../../../core/http/configuracion-basica/impl/lineas-credito.service.impl';
import { EMPRESAS } from 'src/app/core/constants/empresa.constants';

@Component({
  selector: 'ef-lineas-creditos-add',
  templateUrl: './lineas-creditos-add.component.html',
  styleUrls: ['./lineas-creditos-add.component.scss'],
})
export class LineasCreditosAddComponent implements OnInit {
  formAdd: FormGroup;
  loading = false;
  mensajeError = false;
  fEducativoJamestown = false;
  abreviaturasContables: string[] = [
    'VILLAVICENCIO',
    'PEREIRA',
    'BUCARAMANGA',
    'CUCUTA',
    'IBAGUE',
  ];
  abrebiaturaRequerido: boolean = false;
  empresas: Array<any> = new Array<any>();

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<LineasCreditosAddComponent>,
    public lineaCreditoService: LineaCreditoServiceImpl,
    public toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.formAdd = this.formBuilder.group({
      nombre: [null, [Validators.required]],
      tasaInteres: [
        null,
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      descripcion: [null, [Validators.required]],
      requisitos: [null, [Validators.required]],
      cobertura: [null, [Validators.required]],
      abreviaturaContable: [null],
      fEducativoJamestown: [false, [Validators.required]],
      codigo: [null, [Validators.required]],
      empresa: [null]
    });
    this.subcribeFAcademicoJamestowwn();
    this.empresas = this.getEmpresas();
  }

  get form() {
    return this.formAdd.controls;
  }

  eventToggleChange(event) {
    this.fEducativoJamestown = event.checked;
  }

  onFormSubmit() {
    if (navigator.onLine) {
      this.mensajeError = null;
      const lineaCreditoNuevo = Object.assign({}, this.formAdd.value);
      let abreviaturaContable = lineaCreditoNuevo.empresa == EMPRESAS.XPACE_LEARNING_LLC ? null : lineaCreditoNuevo.abreviaturaContable;
      lineaCreditoNuevo.estado = 'ACTIVO';
      lineaCreditoNuevo.empresa = this.fEducativoJamestown ? lineaCreditoNuevo.empresa.replace(/\s/g, '_') : 'EDUFACTORING';
      lineaCreditoNuevo.fEducativoJamestown = this.fEducativoJamestown;
      lineaCreditoNuevo.abreviaturaContable =
        lineaCreditoNuevo.abreviaturaContable = !null
          ? this.getAbreviatura(abreviaturaContable)
          : null;
      if (
        this.fEducativoJamestown &&
        lineaCreditoNuevo.abreviaturaContable == null
      ) {
        this.toast.info('Debe escoger una abreviatura contable', 'Importante');
        return;
      }
      this.lineaCreditoService.save(lineaCreditoNuevo).subscribe(
        {
          next: (lineaCredito) => {
            this.dialogRef.close(lineaCredito);
          },
          error: (error) => {
            try {
              for (const field of error.error.errors) {
                this.toast.error(field.message, 'Error');
              }
            } catch (e) {
              this.mensajeError = error.error.message;
            }
          }
        }
      );
    } else {
      this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
    }
  }

  getAbreviatura(abreviaturaContable: string): string {
    let abreviatura = '';
    switch (abreviaturaContable) {
      case 'PEREIRA':
        abreviatura = 'P';
        break;
      case 'VILLAVICENCIO':
        abreviatura = 'V';
        break;
      case 'BUCARAMANGA':
        abreviatura = 'BU';
        break;
      case 'CUCUTA':
        abreviatura = 'C';
        break;
      case 'IBAGUE':
        abreviatura = 'I';
        break;
      default:
        break;
    }
    return abreviatura;
  }

  subcribeFAcademicoJamestowwn() {
    this.formAdd
      .get('fEducativoJamestown')
      .valueChanges.subscribe({
        next: (fEducativoJamestown) => {
          if (fEducativoJamestown) {
            this.abrebiaturaRequerido = true;
            this.formAdd
              .get('abreviaturaContable')
              .setValidators(Validators.required);
          }
        }
      });
  }

  getEmpresas(): Array<any> {
    let empresas = new Array<any>();
    for (let type in EMPRESAS) {
      empresas.push(EMPRESAS[type]);
    }
    var indiceElemento = empresas.indexOf('EDUFACTORING');

    if (indiceElemento !== -1) {
      empresas.splice(indiceElemento, 1);
    }

    return empresas;
  }
}
