import { ServiceImplService } from '../../service.impl.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LinkPagoServiceInterface } from '../link-pago.service.interface';
import { LinkPago } from '../../../models/solicitud-credito/link-pago.model';

@Injectable({
	providedIn: 'root'
})
export class LinkPagoServiceImpl extends ServiceImplService<any> implements LinkPagoServiceInterface {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'link-pago/';
	}

	refundPayLink(body: any) {
		return this.httpClient.post(`${this.getFullPath()}devolver/pago`, body);
	}

	generatePayLink(linkPago: LinkPago) {
		return this.httpClient.post(`${this.getFullPath()}generar/enlace`, linkPago);
	}

	generatePayLinkAcuerdoPago(linkPago: LinkPago) {
		return this.httpClient.post(`${this.getFullPath()}generar/enlace/acuerdo-pago`, linkPago);
	}
}
