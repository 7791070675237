<div class="flex flex-col">
  <h2 *ngIf="title" class="placeholder">{{title}}</h2>
  <mat-divider *ngIf="title"></mat-divider>
  <div>
    <form [formGroup]="formLP" class="mrgn-t-sm flex flex-col">
      <div class="flex flex-col">
        <div class="flex flex-col">
          <mat-form-field>
            <input matInput currencyMask formControlName="monto" class="form-control" required>
            <mat-placeholder class="placeholder">Valor</mat-placeholder>
            <span matPrefix>$&nbsp;</span>
            <mat-error *ngIf="form.monto.errors">
              <mat-error *ngIf="form.monto.errors.required" class="required alert-error">
                <span i18n>Este campo es requerido</span>
              </mat-error>
              <mat-error *ngIf="form.monto.errors.min" class="required alert-error">
                <span i18n>El valor no puede ser 0 (cero) o menor al saldo mínimo</span>
              </mat-error>
              <mat-error *ngIf="form.monto.errors.max" class="required alert-error">
                <span i18n>El valor no puede ser mayor al valor total de las cuotas</span>
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="flex flex-col">
          <ef-select-generic [isDisabled]="!isCuotaInicial" [title]="'Aplica Valor de Seguro'" [items]="['SI', 'NO']"
            formControlName="valorSeguro">
          </ef-select-generic>
        </div>

        <div class="flex flex-col">
          <div class="flex items-center" *ngIf="aplicaDescuentoView">
            <label class="text"> Aplica Descuento </label>
          </div>
          <div class="flex items-center" *ngIf="aplicaDescuentoView">
            <mat-slide-toggle formControlName="claseVirtual" (change)="eventToggleChange($event)" class="text">
              {{aplicaDescuento ? 'SI' : 'NO'}}
            </mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="flex items-center" *ngIf="!isSolicitudCreditoDescuento">
        <div class="flex flex-col">
          <mat-form-field class="w-full">
            <input matInput currencyMask formControlName="monto" class="form-control w-full" required>
            <mat-placeholder class="placeholder">Valor</mat-placeholder>
            <span matPrefix>$&nbsp;</span>
            <mat-error *ngIf="form.monto.errors">
              <mat-error *ngIf="form.monto.errors.required" class="required alert-error">
                <span i18n>Este campo es requerido</span>
              </mat-error>
              <mat-error *ngIf="form.monto.errors.min" class="required alert-error">
                <span i18n>El valor no puede ser 0</span>
              </mat-error>
              <mat-error *ngIf="form.monto.errors.max" class="required alert-error">
                <span i18n>El valor no puede ser mayor al valor de cuota o cuotas</span>
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="flex justify-between items-center" *ngIf="aplicaDescuento">
        <mat-form-field class="w-1/3">
          <input matInput currencyMask formControlName="valorDescuento" class="form-control" required>
          <mat-placeholder class="placeholder">Valor Descuento</mat-placeholder>
          <span matPrefix>$&nbsp;</span>
          <mat-error *ngIf="form.valorDescuento.errors">
            <mat-error *ngIf="form.valorDescuento.errors.required" class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
            <mat-error *ngIf="form.valorDescuento.errors.min" class="required alert-error">
              <span i18n>El valor no puede ser 0</span>
            </mat-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-2/3">
          <textarea formControlName="motivoDescuento" matInput #message maxlength="256" placeholder="Escribe aqui..."></textarea>
          <mat-hint align="start"><strong>Motivo Descuento</strong> </mat-hint>
          <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
        </mat-form-field>
      </div>


      
      <div class="flex justify-between items-center">
        <ef-select-generic class="w-1/5" [title]="'Moneda'" [items]="['COP']" formControlName="currency">
        </ef-select-generic>
        <ef-select-link-pago-conceptos class="w-3/4" *ngIf="!isTaquilla" formControlName="linkPagoConcepto" (concepto)="change($event)">
        </ef-select-link-pago-conceptos>
      </div>
      <section class="alert alert-warning" *ngIf="conceptoSeleccionado && conceptoSeleccionado?.descripcion != null">
        <div class="flex justify-center items-center">
          <mat-icon class="icon-aux">warning</mat-icon>
        </div>
        <div class="flex justify-center items-center">
          <strong class="text">{{'Descripcion Concepto: ' + conceptoSeleccionado.nombre}}</strong>
        </div>
        <br>
        <div class="flex justify-center items-center">
          <p class="text">{{conceptoSeleccionado.descripcion}}</p>
        </div>
      </section>
      <mat-form-field style="width: 100%;">
        <textarea formControlName="descripcion" matInput #message maxlength="250"
          placeholder="Escribe aqui..."></textarea>
        <mat-hint align="start"><strong>Descripción del link de pago</strong> </mat-hint>
        <mat-hint align="end">{{message.value.length}} / 250</mat-hint>
      </mat-form-field>
    </form>
    <mat-card *ngIf="isSolicitudCreditoDescuento">
      <div class="gene-card-title">
        <div class="flex justify-center items-center">
          <div class="flex flex-col">
            <h5>{{ 'Resumen Link Pago' }}</h5>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div class="flex flex-wrap justify-between items-center">
        <div class="w-full text-center pad-all-xs">
          <p>{{'Valor Cuota: '}}{{formLP.getRawValue().monto | currency:'COP ':'symbol':'1.0-0'}}</p>
        </div>
      </div>

      <div class="flex flex-wrap justify-between items-center">
        <div class="w-full text-center">
          <p>{{'Valor Seguro: '}}{{ valorSeguro| currency:'COP ':'symbol':'1.0-0'}}</p>
        </div>
      </div>

      <div class="flex flex-wrap justify-between items-center">
        <div class="w-full text-center pad-all-xs">
          <p>{{'Valor Descuento: '}}{{formLP.getRawValue().valorDescuento| currency:'COP ':'symbol':'1.0-0'}}</p>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="flex flex-wrap justify-between items-center">
        <div class="w-full text-center pad-all-sm">
          <p>{{'Pago Total: '}}{{formLP.getRawValue().monto - formLP.getRawValue().valorDescuento |
            currency:'COP ':'symbol':'1.0-0'}}</p>
        </div>
      </div>
    </mat-card>
    <div class="mrgn-b-xs pad-b-none text-right">
      <button class="cancel-button" mat-raised-button (click)="dialogRef.close(false)">
        {{cancel | titlecase}}
      </button>
      <button class="accept-button" [disabled]="formLP.invalid" mat-raised-button (click)="aceptar()">
        {{accept | titlecase}}
      </button>
    </div>
  </div>
</div>