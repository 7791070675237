<form [formGroup]="formAddConceptoPrioridad" novalidate autocomplete="off" class="popup-card-width">
    <div>
      <h2 mat-dialog-title i18n>Nuevo Concepto Prioridad</h2>
    </div>
    <mat-dialog-content>
      <div>
        <div class="flex flex-wrap gap-[5%]">
          <mat-form-field class="mrgn-b-sm" class="w-[95%]">
            <input matInput type="number" formControlName="prioridad" class="form-control" required>
            <mat-placeholder class="placeholder">Prioridad</mat-placeholder>
            <mat-error *ngIf="form.prioridad.touched && form.prioridad.errors">
              <mat-error *ngIf="form.prioridad.errors.required" class="required alert-error">
                <span i18n>Este campo es requerido</span>
              </mat-error>
              <mat-error *ngIf="form.prioridad.errors.numeric" class="required alert-error">
                <span i18n>Este campo debe ser numérico</span>
              </mat-error>
              <mat-error *ngIf="!form.prioridad.errors.required" class="required alert-error">
                <span i18n>{{form.prioridad.errors}}</span>
              </mat-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="mrgn-b-sm" class="w-[95%]">
            <input matInput type="text" formControlName="concepto" class="form-control" required>
            <mat-placeholder class="placeholder">Concepto</mat-placeholder>
            <mat-error *ngIf="form.concepto.touched && form.concepto.errors">
              <mat-error *ngIf="form.concepto.errors.required" class="required alert-error">
                <span i18n>Este campo es requerido</span>
              </mat-error>
              <mat-error *ngIf="!form.concepto.errors.required" class="required alert-error">
                <span i18n>{{form.concepto.errors}}</span>
              </mat-error>
            </mat-error>
          </mat-form-field>

            <ef-select-generic class="w-[95%]" formControlName="formControl" [items]="formControls" [title]="'Form_Control'">
            </ef-select-generic>
            <mat-error *ngIf="form.formControl.touched && form.formControl.errors">
              <mat-error *ngIf="form.formControl.errors.required" class="required alert-error">
                <span i18n>Este campo es requerido</span>
              </mat-error>
              <mat-error *ngIf="!form.formControl.errors.required" class="required alert-error">
                <span i18n>{{form.formControl.errors}}</span>
              </mat-error>
            </mat-error>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
      <button type="button" mat-raised-button (click)="dialogRef.close(false)">Cancelar
      </button>
      <button type="submit" [disabled]="!formAddConceptoPrioridad.valid" (click)="onFormSubmit()" mat-raised-button
        color="primary">Crear
      </button>
    </mat-dialog-actions>
  </form>
