<h2 class="title">Información Requerida</h2>

<div class="container--overflow" *ngIf="isFormArrayInmuebles">
  <div *ngFor="let formData of formDataGroup; let i = index">
    <div class="subtitle">
      <h4>Inmueble {{ i + 1 }}</h4>
    </div>
    <div class="container" style="margin-bottom: 1rem;">
      <div class="container--list" *ngFor="let field of formData; let i = index">
        <div class="item-name" *ngIf="field.name">
          {{ formatFieldName(field.name) }}:
        </div>
        <div class="item-icon" *ngIf="!field.inmueble">
          <mat-icon *ngIf="!field.valid" style="color:#791621; float: right;">
            cancel
          </mat-icon>
          <mat-icon *ngIf="field.valid" style="color:#28a745; float: right;">
            check_circle
          </mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container--overflow" *ngIf="isFormArrayReferencias">
  <h3>Referencias Personales</h3>
  <div *ngFor="let referenciaPersonal of referenciasPersonalesDataGroup; let i = index">
    <div class="subtitle">
      <h4>Referencia {{ i + 1 }}</h4>
    </div>
    <div class="container" style="margin-bottom: 1rem;">
      <div class="container--list" *ngFor="let field of referenciaPersonal; let i = index">
        <div class="item-name">
          {{ formatFieldName(field.name) }}:
        </div>
        <div class="item-icon">
          <mat-icon *ngIf="!field.valid" style="color:#791621; float: right;">
            cancel
          </mat-icon>
          <mat-icon *ngIf="field.valid" style="color:#28a745; float: right;">
            check_circle
          </mat-icon>
        </div>
      </div>
    </div>
  </div>
  <h3>Referencias Familiares</h3>
  <div *ngFor="let referenciaFamiliar of referenciasFamiliaresDataGroup; let i = index">
    <div class="subtitle">
      <h4>Referencia {{ i + 1 }}</h4>
    </div>
    <div class="container" style="margin-bottom: 2rem;">
      <div class="container--list" *ngFor="let field of referenciaFamiliar; let i = index">
        <div class="item-name">
          {{ formatFieldName(field.name) }}:
        </div>
        <div class="item-icon">
          <mat-icon *ngIf="!field.valid" style="color:#791621; float: right;">
            cancel
          </mat-icon>
          <mat-icon *ngIf="field.valid" style="color:#28a745; float: right;">
            check_circle
          </mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="container" *ngIf="!isFormArrayInmuebles && !isFormArrayReferencias">
  <div class="container--list" *ngFor="let field of formData; let i = index">

    <div class="item-name" *ngIf="field.name">
      {{ formatFieldName(field.name) }}:
    </div>
    <div class="item-icon" *ngIf="!field.inmueble">
      <mat-icon *ngIf="!field.valid" style="color:#791621; float: right;">
        cancel
      </mat-icon>
      <mat-icon *ngIf="field.valid" style="color:#28a745; float: right;">
          check_circle
      </mat-icon>
    </div>
  </div>
</div>

<div class="button-cancel">
  <button mat-raised-button class="btn-color-cancel" (click)="onCancelClick()">
    Cancelar
  </button>
</div>

