import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'spaceUnderscore'
})

@Injectable({
  providedIn: 'root'
})
export class SpaceUnderscorePipe implements PipeTransform {
	transform(value: string): any {
		if (value) {
			return value.replace(/_/g, ' ');
		}
	}
}
