<form [formGroup]="formEditPlanCredito" novalidate autocomplete="off" class="popup-card-width">
  <div>
    <h2 mat-dialog-title i18n>Editar Plan Credito</h2>
  </div>
  <mat-dialog-content class="mx-1">
    <div class="grid md:grid-cols-2 gap-4">
      <div class="mt-2">
        <mat-form-field>
          <input matInput type="text" formControlName="nombre" class="form-control">
          <mat-placeholder class="placeholder">Nombre</mat-placeholder>
          <mat-error *ngIf="form.nombre.touched && form.nombre.errors">
            <mat-error *ngIf="form.nombre.errors.required" class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
            <mat-error *ngIf="!form.nombre.errors.required" class="required alert-error">
              <span i18n>{{form.nombre.errors}}</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="pt-2">
          <mat-label class="placeholder">Cálculo Valor</mat-label>
          <mat-select formControlName="calculoValor" required>
            <mat-option *ngFor="let type of calculosValores" [value]="type">
              {{ type | spaceUnderscore }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.calculoValor.errors">
            <mat-error *ngIf="form.calculoValor.errors.required" class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="isValorFijo">
        <mat-form-field>
          <input matInput type="number" formControlName="numeroCuota" class="form-control">
          <mat-placeholder class="placeholder">Numero De Cuotas</mat-placeholder>
          <mat-error *ngIf="form.numeroCuota.touched && form.numeroCuota.errors">
            <mat-error *ngIf="form.numeroCuota.errors.required" class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
            <mat-error *ngIf="!form.numeroCuota.errors.required" class="required alert-error">
              <span i18n>{{form.numeroCuota.errors}}</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="isValorFijo">
        <mat-form-field>
          <input matInput currencyMask formControlName="valorFinanciado" class="form-control">
          <mat-placeholder class="placeholder">Valor Financiado</mat-placeholder>
          <mat-error *ngIf="form.valorFinanciado.touched && form.valorFinanciado.errors">
            <mat-error *ngIf="form.valorFinanciado.errors.required" class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="isValorFijo">
        <mat-form-field>
          <input matInput currencyMask formControlName="valorMensual" class="form-control">
          <mat-placeholder class="placeholder">Valor Mensual</mat-placeholder>
          <mat-error *ngIf="form.valorMensual.touched && form.valorMensual.errors">
            <mat-error *ngIf="form.valorMensual.errors.required" class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>

    </div>
    <div class="grid grid-cols-2">
      <div>
        <div>
          <strong> Plan Especial </strong>
        </div>
        <div>
          <mat-slide-toggle formControlName="flagPlanEspecial" (change)="eventToggleChange($event)">
            {{form.flagPlanEspecial.value ? 'SI' : 'NO'}}
          </mat-slide-toggle>
        </div>
        <div>
        </div>
      </div>

      <div>
        <div>
          <strong> Estado </strong>
        </div>
        <div>
          <mat-slide-toggle formControlName="estado" class="mrgn-b-sm">
            {{form.estado.value ? 'Activo' : 'Inactivo'}}
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mt-4 text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close()">Cancelar
    </button>
    <button (click)="onFormSubmit()" type="submit" [disabled]="!formEditPlanCredito.valid || loading" mat-raised-button
      color="primary">Guardar
    </button>
  </mat-dialog-actions>
</form>
