<form [formGroup]="formEdit" novalidate autocomplete="off" class="popup-card-width">
  <div>
    <h2 mat-dialog-title i18n>Editar Descuento</h2>
  </div>
  <mat-dialog-content>
    <div>
      <div>
        <mat-form-field>
          <mat-label class="placeholder">Nombre</mat-label>
          <input matInput formControlName="nombre" />
          <mat-error *ngIf="form.nombre.touched && form.nombre.errors">
            <mat-error *ngIf="form.nombre.errors.required" class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
            <mat-error *ngIf="!form.nombre.errors.required" class="required alert-error">
              <span i18n>{{ form.nombre.errors }}</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label class="placeholder" required>Tipo</mat-label>
          <mat-select formControlName="tipo" required>
            <mat-option *ngFor="let type of tipos" [value]="type">
              {{ type | spaceUnderscore }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.tipo.errors">
            <mat-error *ngIf="form.tipo.errors.required" class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label class="placeholder">Descripcion</mat-label>
          <textarea matInput type="text" formControlName="descripcion" class="form-control" placeholder="Descripción"
            (input)="calcularCaracteresEscritos($event)">
                </textarea>
          <p class="mb-0">{{ caracteresEscritos }}/500</p>
          <mat-error *ngIf="form.descripcion.touched && form.descripcion.errors">
            <mat-error *ngIf="form.descripcion.errors.required" class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
            <mat-error *ngIf="!form.descripcion.errors.required" class="required alert-error">
              <span i18n>{{ form.descripcion.errors }}</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
        <div>
          <mat-slide-toggle formControlName="estado" class="mrgn-b-sm">
            {{ form.estado.value ? "Activo" : "Inactivo" }}
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close()">
      Cancelar
    </button>
    <button (click)="onFormSubmit()" [disabled]="!formEdit.valid" mat-raised-button class="accept-button">
      Actualizar
    </button>
  </mat-dialog-actions>
</form>