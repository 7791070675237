<div class="popup-card-width">
  <div class="gene-card-title">
    <div class="flex justify-center">
      <div class="flex flex-col">
        <h2 class="placeholder">Advertencia</h2>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <br>
  <mat-dialog-content>
    <div class="font-size" [innerHTML]="mensaje">
    </div>
    <div>

      <h4></h4>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button"
            class="accept-button"
            mat-raised-button
            (click)="dialogRef.close(true)">
      Aceptar
    </button>
  </mat-dialog-actions>
</div>
