<div class="popup-card-width">
    <div>
        <h2 mat-dialog-title i18n>Ver Detalle</h2>
    </div>
    <mat-divider class="primary-border"></mat-divider>
    <mat-dialog-content *ngIf="historialConsultaSarlaftDetail" class="mrgn-t-sm">
        <div class="flex flex-wrap justify-between">
            <div class="full-wid sm:w-[40%] md:w-[30%]">
                <strong>Fecha de consulta: </strong>
            </div>
            <div class="full-wid sm:w-[60%] md:w-[70%]">
                <span>{{ historialConsultaSarlaftDetail?.fechaCreacion }}</span>
            </div>
        </div>
        <div class="flex flex-wrap justify-between">
            <div class="full-wid sm:w-[40%] md:w-[30%]">
                <strong>Consultado por: </strong>
            </div>
            <div class="full-wid sm:w-[60%] md:w-[70%]">
                <span>{{personalAdministrativo?.nombreCompleto}}</span>
            </div>
        </div>
        <div class="flex flex-wrap justify-between">
            <div class="full-wid sm:w-[40%] md:w-[30%]">
                <strong>Tipo Documento: </strong>
            </div>
            <div class="full-wid sm:w-[60%] md:w-[70%]">
                <span>{{historialConsultaSarlaftDetail.tipoDocumento}}</span>
            </div>
        </div>
        <div class="flex flex-wrap justify-between">
            <div class="full-wid sm:w-[40%] md:w-[30%]">
                <strong>Documento: </strong>
            </div>
            <div class="full-wid sm:w-[60%] md:w-[70%]">
                <span>{{historialConsultaSarlaftDetail.documento}}</span>
            </div>
        </div>
        <div class="flex flex-wrap justify-between">
            <div class="full-wid sm:w-[40%] md:w-[30%]">
                <strong>Decisión: </strong>
            </div>
            <div class="full-wid sm:w-[60%] md:w-[70%]">
                <span>{{historialConsultaSarlaftDetail.decision}}</span>
            </div>
        </div>
        <div class="flex flex-wrap justify-between">
            <div class="full-wid sm:w-[40%] md:w-[30%]">
                <strong>Estado: </strong>
            </div>
            <div class="full-wid sm:w-[60%] md:w-[70%]">
                <span>{{historialConsultaSarlaftDetail.estado}}</span>
            </div>
        </div>
        <div class="flex flex-wrap justify-between">
            <div class="full-wid sm:w-[50%] md:w-[30%]">
                <strong>Tercero Encontrado:</strong>
            </div>
            <div class="full-wid sm:w-[60%] md:w-[70%]">
                <span>{{historialConsultaSarlaftDetail.fTercero ? 'SI' : 'NO'}}</span>
            </div>
        </div>
        <div class="flex flex-wrap justify-between">
            <div class="full-wid sm:w-[40%] md:w-[30%]">
                <strong>Observación: </strong>
            </div>
            <div class="full-wid sm:w-[60%] md:w-[70%]">
                <span>{{historialConsultaSarlaftDetail?.observacion}}</span>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mt-2 text-right">
        <button type="button" mat-raised-button (click)="dialogRef.close()">
            Cerrar
        </button>
        <button [disabled]="!historialConsultaSarlaftDetail.fTercero" mat-raised-button  class="accept-button margin-none" (click)="downloadPDF()">
            Descargar PDF
        </button>
    </mat-dialog-actions>
</div>
