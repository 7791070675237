import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClienteServiceInterface } from '../cliente.service.interface';
import {
	FillFormDA,
	OtpDA,
	CodeDA,
	ResponseTakeExam
} from '../../../models/debito-automatico/trans-union-debito.model';
import { DebitoAutomatico } from '../../../models/debito-automatico/debito-automatico.model';

@Injectable({
	providedIn: 'root'
})
export class ClienteServiceImpl extends ServiceImplService<any> implements ClienteServiceInterface {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'cliente/';
	}

	validateNumeroDocumento(numeroDocumento: any): Observable<any> {
		const path = `validar-numero-documento`;
		let myParams = new HttpParams().set('numeroDocumento', numeroDocumento);
		return this.httpClient.get(this.getFullPath() + path, { params: myParams });
	}

	getReportAutorizacionDebito(debitoAutomaticoId: number): Observable<any> {
		const path = `debito/${debitoAutomaticoId}/autorizacion-debito-automatico/pdf`;
		return this.executeGetPDF(path);
	}

	getExperienciaAcademico(titularId: number): Observable<any> {
		const path = `validar-experiencia/${titularId}`;
		return this.httpClient.get(this.getFullPath() + path);
	}

	getAutorizacionDebitoReport(debitoAutomaticoId: number): Observable<any> {
		const path = `debito/${debitoAutomaticoId}/autorizacion-debito-automatico/report`;
		return this.httpClient.get(`${this.getFullPath() + path}`);
	}
}
