import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'ef-modal-opciones-credito',
  templateUrl: './modal-opciones-credito.component.html',
  styleUrls: ['./modal-opciones-credito.component.scss']
})
export class ModalOpcionesCreditoComponent implements OnInit {



  constructor(public dialogRef: MatDialogRef<ModalOpcionesCreditoComponent>) {
  }

  ngOnInit() {
  }

  setAction(action: string) {
    this.dialogRef.close(action);
  }

}
