<div>
	<h2 i18n mat-dialog-title>Editar Rol</h2>
</div>
<mat-dialog-content>
	<div>
		<form [formGroup]="formAdd" autocomplete="off" novalidate class="popup-card-width">
			<mat-form-field>
				<mat-label class="placeholder">Nombre</mat-label>
				<input class="form-control" formControlName="name" matInput type="text">
			</mat-form-field>
			<mat-form-field>
				<mat-label class="placeholder">Descripción</mat-label>
				<input class="form-control" formControlName="description" matInput type="text">
			</mat-form-field>
			<mat-form-field>
				<mat-label class="placeholder">Security Id</mat-label>
				<input class="form-control" formControlName="idRolesAuth0" matInput type="text">
			</mat-form-field>
		</form>
		<div *ngIf="permisos.length > 0" class="popup-card-width">
			<mat-form-field>
				<mat-placeholder class="placeholder">Buscar permiso</mat-placeholder>
				<input [formControl]="search" class="form-control" matInput type="text">
				<mat-icon matSuffix>search</mat-icon>
			</mat-form-field>

			<mat-selection-list #shoes (selectionChange)="selectionChange($event.options)">
				<mat-list-option [ngClass]="{'list-item': shoe?.description.length > 50}"
                 *ngFor="let shoe of $search | async"
								 [selected]="permissionControl.value && validObject(shoe)"
								 [value]="shoe">
					{{shoe.name}} | {{shoe.description}}
				</mat-list-option>
			</mat-selection-list>
		</div>
		<div *ngIf="permisos.length === 0" class="text-center">
			Cargando...
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
	<button (click)="dialogRef.close(false)" mat-raised-button type="button">Cancelar
	</button>
	<button (click)="onFormSubmit()" [disabled]="formAdd.invalid" color="primary" mat-raised-button type="submit">
		Actualizar
	</button>
</mat-dialog-actions>
