import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ServiceImplService } from '../../service.impl.service';
import { IDebitoAutomatico } from '../debito-automatico.service.interface';

import { PagoToken, DebitoAutomatico } from '../../../models/debito-automatico/debito-automatico.model';
import { map } from 'rxjs/operators';
import ListResponse from '../../../models/core/list-response.model';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class DebitoAutomaticoServiceImpl extends ServiceImplService<any> implements IDebitoAutomatico {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'debito-automatico/';
	}

	debitarPagoToken(body: PagoToken) {
		return this.http.post(`${this.getFullPath()}debitar/pago-token`, body);
	}

	extended(id: number) {
		return this.http.get(`${this.getFullPath()}${id}/extended`);
	}

	eliminarTarjeta(debitoAutomaticoId: number, motivo: string) {
		return this.http.put(`${this.getFullPath()}eliminar/tarjeta`, { debitoAutomaticoId, motivo });
	}

	listarTarjetas(clienteId: number) {
		return this.http.get(`${this.getFullPath()}${clienteId}/listar-tarjetas`).pipe(map((res: any) => res.data));
	}

	filtroExtendido(filter: any): Observable<ListResponse<DebitoAutomatico>> {
		filter = _.omitBy(filter, [ _.isUndefined, _.isNull, _.isEmpty ]);
		return this.httpClient
			.get(this.getFullPath() + `filtro/extendido`, { params: filter })
			.pipe(map((res: ListResponse<any>) => res));
	}

	getReportAutorizacionDebito(idDebito: number, clienteId: number, solicitudId: number): Observable<any> {
		const path = `debito/${idDebito}/cliente/${clienteId}/solicitud/${solicitudId}/autorizacion-debito-automatico/firmado`;
		return this.executeGetPDF(path);
	}
}
