<div class="popup-card-width">
  <div>
    <h2 class="text-center" mat-dialog-title i18n>Descargar Documentos de Autorización Débito</h2>
  </div>
  <mat-divider class="primary-border mrgn-b-xs"></mat-divider>
  <mat-dialog-content *ngIf="!isLoading; else spinner" class="pad-y-md">
    <p>
      Seleccione la solicitud de la cual desea descargar el documento
    </p>
    <mat-accordion>
      <mat-expansion-panel  *ngFor="let item of solicitudes">
        <mat-expansion-panel-header>
          <mat-panel-title>{{item.numeroSolicitud}}</mat-panel-title>
          <mat-panel-description>
            <strong class="text-black">
              {{item.lineaCredito ? item.lineaCredito.nombre : 'CRÉDITO EDUCATIVO - ' + item.contratoAcademico}}
            </strong>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-dialog-actions class="mrgn-b-xs pad-b-none text-center">
          <button type="button" mat-stroked-button (click)="downloadDocument(item.id)" color="primary">
            Descargar Documento
          </button>
        </mat-dialog-actions>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-dialog-content>
  <ng-template #spinner>
    <div class="pad-t-md">
          <mat-spinner class="m-auto"></mat-spinner>
    </div>
  </ng-template>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button" mat-raised-button [mat-dialog-close]>
      Aceptar
    </button>
  </mat-dialog-actions>
</div>
