import {Component, Inject, OnInit} from '@angular/core';
import CargoAdministrativo from 'src/app/core/models/configuracion-basica/cargo-administrativo-model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CargoAdministrativoServiceImpl} from 'src/app/core/http/configuracion-basica/impl/cargo-administrativo.service.impl';

@Component({
  selector: 'ef-cargo-administrativo-detail',
  templateUrl: './cargo-administrativo-detail.component.html',
  styleUrls: ['./cargo-administrativo-detail.component.scss']
})
export class CargoAdministrativoDetailComponent implements OnInit {
  loading = false;
  cargoAdministrativoDetail: CargoAdministrativo;

  constructor(
    public dialogRef: MatDialogRef<CargoAdministrativoDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.cargoAdministrativoDetail = data;
  }

  ngOnInit() {}
}
