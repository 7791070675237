import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { finalize, tap } from 'rxjs';
import { SolicitudCreditoServiceImpl } from 'src/app/core/http/solicitud-credito/impl/solicitud-credito.service.impl';

@Component({
  selector: 'ef-modal-rechazar-solicitud',
  templateUrl: './modal-rechazar-solicitud.component.html',
  styleUrls: ['./modal-rechazar-solicitud.component.scss']
})
export class ModalRechazarSolicitudComponent {

  solicitud: any;
  flag: any;
  isLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModalRechazarSolicitudComponent>,
    public solicitudCreditoService: SolicitudCreditoServiceImpl,

    public toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.solicitud = this.data.solicitud;
    this.flag = this.data.flag;

  }

  onDeleteSubmit() {
    this.isLoading = true;

    this.solicitudCreditoService
      .rechazar({
        currentQueue: this.solicitud.currentQueue,
        nextStep: this.solicitud.procesoActual,
        noViable: this.flag,
        observacion: 'Solicitud rechazada por el cliente.',
        tipoRechazo: 'RECHAZADO_POR_CLIENTE',
        solicitudCreditoId: this.solicitud.id
      })
      .subscribe({
        next: (data: any) => {

          this.toast.success(data.message);
          this.dialogRef.close(true);
        },
        error: () => {
          this.toast.error('Ocurrio un error al intentar rechazar la solicitud de credito', 'Error');
        },
        complete: () => {
          this.isLoading = false;
        }
      });
  }
}
