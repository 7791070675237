import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PersonaServiceImpl } from 'src/app/core/http/gestion-usuarios/impl/persona.service.impl';


@Component({
  selector: 'ef-actualizar-datos-persona',
  templateUrl: './actualizar-datos-persona.component.html',
  styleUrls: ['./actualizar-datos-persona.component.scss']
})
export class ActualizarDatosPersonaComponent implements OnInit {


  emailActual: string;
  emailNuevo: string;
  numeroDocumentoActual: string;
  numeroDocumentoNuevo: string;

  constructor(
    public toast: ToastrService,
    
    public dialogRef: MatDialogRef<ActualizarDatosPersonaComponent>,
    public personaService: PersonaServiceImpl,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.emailActual = data.emailActual;
    this.numeroDocumentoActual = data.numeroDocumentoActual;
  }

  ngOnInit() {
  }

  onFormSubmit() {
    if (this.numeroDocumentoNuevo == null && this.emailNuevo == null) {
      this.toast.warning('Por favor ingrese un campo a modificar', 'Importante');
      return;
    }

    

    const actulizarDatosPersona = {
      emailActual: this.emailActual,
      emailNuevo: this.emailNuevo !== '' ? this.emailNuevo : null,
      numeroDocumentoActual: this.numeroDocumentoActual,
      numeroDocumentoNuevo: this.numeroDocumentoNuevo !== '' ? this.numeroDocumentoNuevo : null
    };
    this.personaService
      .actualizarDatos(actulizarDatosPersona)
      .subscribe(
        {
          next: () => {
            ;
            this.toast.success('Actualización Exitosa');
            this.dialogRef.close(true);
          },
          error: (err) => {
            ;
            if (err.error.errors) {
              for (const error of err.error.errors) {
                this.toast.error(error.message, 'Error');
              }
            }
            if (err.error.message) {
              this.toast.error(err.error.message, 'Error');
            }
          }
        }
      );

  }

  setNumeroDocumento(event) {
    this.numeroDocumentoNuevo = event;
  }

  setEmail(event) {
    this.emailNuevo = event;
  }
}
