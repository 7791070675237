<div class="popup-card-width">
  <div class="gene-card-title">
    <div class="flex justify-center">
      <div class="flex flex-col">
        <h2 class="placeholder">Cambiar Numero Contrato Academico</h2>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <br>
  <mat-dialog-content>

    <div class="flex justify-center mb-4">
      <div class="flex flex-col">
        <h4 class="placeholder">Contrato Actual</h4>
      </div>
    </div>

    <div class="grid md:grid-cols-4 gap-2 mb-4">

      <div class="text-center flex flex-col">
        <strong>Nombre Completo Titular</strong>
        <p>{{contrato?.titular.nombreCompleto}}</p>
      </div>

      <div class="text-center flex flex-col">
        <strong>Numero Documento Titular</strong>
        <p>{{contrato?.titular.numeroDocumento}}</p>
      </div>

      <div class="text-center flex flex-col">
        <strong>Numero Contrato</strong>
        <p>{{contrato?.numeroContrato}}</p>
      </div>

      <div class="text-center flex flex-col">
        <strong>Estado Contrato</strong>
        <p>{{contrato?.estado}}</p>
      </div>

    </div>


    <div class="flex justify-center">
      <div class="flex flex-col">
        <h4 class="placeholder">Contrato Nuevo</h4>
      </div>
    </div>

    <div class="flex justify-center items-center gap-[15px]">
      <div class="flex flex-col w-[30%]">
        <mat-form-field>
          <input #inputSearch matInput
                 [formControl]="contratoControl"
                 type="number"
                 autocomplete="off"
                 class="form-control text-center">
          <mat-placeholder class="placeholder">Numero Contrato Nuevo</mat-placeholder>
          <mat-error *ngIf="contratoControl.errors">
            <mat-error *ngIf="contratoControl.errors.required"
                       class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
            <mat-error *ngIf="contratoControl.errors.numeric"
                       class="required alert-error">
              <span i18n>Este campo debe ser numérico</span>
            </mat-error>
            <mat-error *ngIf="contratoControl.errors.min"
                       class="required alert-error">
              <span i18n>Este campo no puede ser negativo</span>
            </mat-error>
          </mat-error>
        </mat-form-field>

      </div>

      <div class="flex flex-col w-[10%]">
        <button class="label-filter-button" mat-raised-button mat-button-sm (click)="clear()">
          <span i18n>Limpiar</span>
        </button>
      </div>

      <div class="flex flex-col w-[10%]">
        <button class="label-filter-button" mat-raised-button mat-button-sm color="primary" (click)="validate()">
          <span i18n>Validar</span>
          <mat-icon class="mrgn-l-md gene-text-xl">search</mat-icon>
        </button>
      </div>
    </div>

    <div class="pad-y-lg flex md:flex-col gap-[15px]"
         *ngIf="contratoNuevo">

      <div class="text-center flex flex-col">
        <strong>Nombre Completo Titular</strong>
        <p>{{contratoNuevo?.titular.nombreCompleto}}</p>
      </div>

      <div class="text-center flex flex-col">
        <strong>Numero Documento Titular</strong>
        <p>{{contratoNuevo?.titular.numeroDocumento}}</p>
      </div>

      <div class="text-center flex flex-col">
        <strong>Numero Contrato</strong>
        <p>{{contratoNuevo?.numeroContrato}}</p>
      </div>

      <div class="text-center flex flex-col">
        <strong>Estado Contrato</strong>
        <p>{{contratoNuevo?.estado}}</p>
      </div>

    </div>


  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button mat-raised-button
            color="primary"
            (click)="dialogRef.close(false)">Cancelar
    </button>
    <button mat-raised-button
            color="primary"
            [disabled]="contratoNuevo == null"
            (click)="onFormSubmit()">Guardar
    </button>
  </mat-dialog-actions>
</div>
