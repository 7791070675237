<div style="width: 760px;">
    <h2 class="text-center" mat-dialog-title i18n>Plan Amortizacion Detalle</h2>
  
    <mat-dialog-content>
      <div class="flex flex-wrap justify-center items-center jt-table-container">
        <div class="text-center" *ngIf="isMessage">
          <mat-icon class="jt-large-icon">mood_bad</mat-icon>
          <p>{{ messageEmpty }}</p>
        </div>
         <div class="w-full" >
          <ef-data-table [columnNames]="columnNames" [data]="amortizacionDetalle" [showFilter]="false" *ngIf="isTable"
            [limit]="getPageSize()" [externalPaging]="true" [count]="getTotalElements()" [pageNumber]="filter.pageNumber">
          </ef-data-table>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
      <button type="button" mat-raised-button (click)="dialogRef.close()">
        Cerrar
      </button>
    </mat-dialog-actions>
  </div>
