import { HttpClient } from '@angular/common/http';
import ProcesoPasoOpcion from 'src/app/core/models/configuracion-solicitud-credito/proceso-paso-opcion.model';
import { ServiceImplService } from '../../service.impl.service';
import { IProcesoPasoOpcion } from '../proceso-paso-opcion.interface';
import { Injectable } from '@angular/core';
import { EstadosHelper } from 'src/app/core/helpers/estados.helper';
import ProcesoPasoOpcionExtended from 'src/app/core/models/configuracion-solicitud-credito/proceso-paso-opcion-extended';
import OpcionModel from 'src/app/core/models/configuracion-solicitud-credito/opcion.models';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProcesoServiceImpl } from './proceso.service.impl';
import { OpcionServiceImpl } from './opcion.service.impl';
import ProcesoModel from 'src/app/core/models/configuracion-solicitud-credito/proceso.models';

@Injectable({providedIn: 'root'})
export class ProcesoPasoOpcionServiceImpl extends ServiceImplService<ProcesoPasoOpcion> implements IProcesoPasoOpcion {

	constructor(public http: HttpClient, public procesoServiceImpl: ProcesoServiceImpl, public opcionServiceImpl: OpcionServiceImpl) {
		super();
		this.httpClient = http;
		this.resource = 'proceso-paso-opcion/';
	}

	updateState(pasoId: number, procesoId: number, state: boolean) {
		const path = `${this.apiUrl}${this.resource}${pasoId}/${procesoId}`;
		const pasoEstado = EstadosHelper.getEstadoFromBoolean(state);
		return this.http.put(path, { pasoEstado });
	}

	getOrCreateDefaultPaso(proceso: ProcesoModel) {
		const path = `default`;
		const data = { ...proceso };
		return this.post(data, path);
	}

	public loadOpcionesAndOpcionesProceso(procesoId: number, pasoId: number) {
		const subscribers = [this.filter({ idProceso: procesoId, idPaso: pasoId, estado: "ACTIVO" }), this.opcionServiceImpl.filter(
			{ estado: "ACTIVO" }
		)];

		return forkJoin(subscribers);
	}

	getOpcionesProceso(procesoId: any, pasoId: any) {
		return this.loadOpcionesAndOpcionesProceso(procesoId, pasoId).pipe(
			map(([opciones, opcionesProceso]) => {
				let opcionesProcesoAux = (opciones.data as unknown) as Array<ProcesoPasoOpcionExtended>;
				let opcionesAll = (opcionesProceso.data as unknown) as Array<OpcionModel>;
				opcionesProcesoAux.forEach((opcionProg: any) => {
					const opcionAux: any = opcionesAll.find((opcion) => opcion.id === opcionProg.unidad);
					if (opcionAux) {
						opcionProg['nombre'] = opcionAux.nombre;
						opcionProg['descripcion'] = opcionAux.descripcion;
					}
				});
				return opcionesProcesoAux;
			})
		);
	}



}
