import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { SessionExpiredComponent } from 'src/app/security/session-expired/session-expired.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalServerErrorComponent } from 'src/app/shared/modal-server-error/modal-server-error.component';
import { DeleteModalComponent } from 'src/app/shared/delete-modal/delete-modal.component';
import { SnapshotPhotoComponent } from 'src/app/shared/snapshot-photo/snapshot-photo.component';
import { BarrioAddComponent } from '../../shared/barrio-add/barrio-add.component';
import { ModalCodeConfirmComponent } from 'src/app/shared/modal-code-confirm/modal-code-confirm.component';
import { UbicacionGeograficaAddComponent } from 'src/app/shared/ubicaciones-components/ubicacion-geografica-add/ubicacion-geografica-add.component';
import { UbicacionGeograficaListComponent } from 'src/app/shared/ubicaciones-components/ubicacion-geografica-list/ubicacion-geografica-list.component';
import { ModalGenericComponent } from 'src/app/shared/modal-generic/modal-generic.component';
import { PlanCreditoAddComponent } from 'src/app/modules/configuracion-solicitud-credito/planes-creditos/plan-credito-add/plan-credito-add.component';
import { LugarAddComponent } from 'src/app/shared/lugar-add/lugar-add.component';
import { ModalPdfsComponent } from 'src/app/shared/modal-pdfs/modal-pdfs.component';
import { ModalDetalleCapacidadEndeudamientoComponent } from 'src/app/shared/modal-detalle-capacidad-endeudamiento/modal-detalle-capacidad-endeudamiento.component';
import { ModalRechazarSolicitudComponent } from 'src/app/shared/modal-rechazar-solicitud/modal-rechazar-solicitud.component';
import { ModalEditFechaPrimerCuotaComponent } from 'src/app/shared/modal-edit-fecha-primer-cuota/modal-edit-fecha-primer-cuota.component';
import { RolAddComponent } from '../../modules/security/roles/rol-add/rol-add.component';
import { ModalInfoTableComponent } from 'src/app/shared/modal-info-table/modal-info-table.component';
import { DebitoAutomaticoComponent } from '../../modules/oficina-virtual/debito-automatico/debito-automatico.component';
import { PermisoAddComponent } from '../../modules/security/permisos/permiso-add/permiso-add.component';
import { ModalPromptComponent } from 'src/app/shared/modal-prompt/modal-prompt.component';
import {
  DebitoAutomatico,
  DetalleDAD,
} from '../models/debito-automatico/debito-automatico.model';
import { DebitoAutomaticoDetallePagosComponent } from '../../modules/debito-automatico/debito-automatico-detalle-pagos/debito-automatico-detalle-pagos.component';
import { ModalLinkPagoComponent } from 'src/app/shared/links-pago/modal-link-pago/modal-link-pago.component';
import { ModalHistorialAcuerdoPagoComponent } from 'src/app/shared/acuerdo-pago-shared/modal-historial-acuerdo-pago/modal-historial-acuerdo-pago.component';
import { ModalInfoComponent } from 'src/app/shared/modal-info/modal-info.component';
import { ConceptoCuotaPrioridadAddComponent } from 'src/app/shared/concepto-cuota-prioridad-add/concepto-cuota-prioridad-add.component';
import ConceptoCuotaPrioridad from 'src/app/core/models/acuerdo-pago/concepto-cuota-prioridad.model';
import { LinkPagoDetailComponent } from 'src/app/modules/procesos-administrativos/taquilla-link-pago/link-pago-detail/link-pago-detail.component';
import { PersonaNotificacionAddComponent } from 'src/app/modules/configuracion-basica/personas-notificacion/persona-notificacion-add/persona-notificacion-add.component';
import { ModalInsertListaNegraComponent } from 'src/app/modules/gestion-usuarios/cliente/modal-insert-lista-negra/modal-insert-lista-negra.component';
import { PersonaNotificacionDetailComponent } from 'src/app/modules/configuracion-basica/personas-notificacion/persona-notificacion-detail/persona-notificacion-detail.component';
import { ActualizarDatosPersonaComponent } from 'src/app/modules/gestion-usuarios/actualizar-datos-persona/actualizar-datos-persona.component';
import { CargoAdministrativoAddComponent } from 'src/app/modules/configuracion-basica/cargos-administrativos/cargo-administrativo-add/cargo-administrativo-add.component';
import { CargoAdministrativoAddCompleteComponent } from 'src/app/modules/configuracion-basica/cargos-administrativos/cargo-administrativo-add-complete/cargo-administrativo-add-complete.component';
import { CargoAdministrativoDetailComponent } from 'src/app/modules/configuracion-basica/cargos-administrativos/cargo-administrativo-detail/cargo-administrativo-detail.component';
import { CargoAdministrativoEditComponent } from 'src/app/modules/configuracion-basica/cargos-administrativos/cargo-administrativo-edit/cargo-administrativo-edit.component';
import { PlanCreditoEditComponent } from 'src/app/modules/configuracion-solicitud-credito/planes-creditos/plan-credito-edit/plan-credito-edit.component';
import { ParametrosGeneralesAddComponent } from 'src/app/modules/configuracion-basica/parametros-generales/parametros-generales-add/parametros-generales-add.component';
import { ParametrosGeneralesEditComponent } from 'src/app/modules/configuracion-basica/parametros-generales/parametros-generales-edit/parametros-generales-edit.component';
import { ConceptoCuotaPrioridadEditComponent } from 'src/app/modules/configuracion-acuerdo-pago/concepto-prioridad/concepto-cuota-prioridad-edit/concepto-cuota-prioridad-edit.component';
import { ConceptoCuotaPrioridadDetailComponent } from 'src/app/modules/configuracion-acuerdo-pago/concepto-prioridad/concepto-cuota-prioridad-detail/concepto-cuota-prioridad-detail.component';
import { ModalValidarContratoAcademicoComponent } from 'src/app/modules/gestion-de-solicitud/solicitud-credito-asistida/modal-validar-contrato-academico/modal-validar-contrato-academico.component';
import { ModalOpcionesCreditoComponent } from 'src/app/modules/gestion-de-solicitud/solicitud-credito-asistida/modal-opciones-credito/modal-opciones-credito.component';
import { SolicitudCreditoObservacionesAddComponent } from 'src/app/modules/gestion-de-solicitud/solicitud-credito-asistida/solicitud-credito-observaciones-add/solicitud-credito-observaciones-add.component';
import { SolicitudCreditoObservacionesDetailComponent } from 'src/app/modules/gestion-de-solicitud/solicitud-credito-asistida/solicitud-credito-observaciones-detail/solicitud-credito-observaciones-detail.component';
import { SolicitudCreditoDocumentosComponent } from 'src/app/modules/gestion-de-solicitud/solicitud-credito-asistida/solicitud-credito-documentos/solicitud-credito-documentos.component';
import { ModalEditContratoAcademicoComponent } from 'src/app/modules/gestion-de-solicitud/solicitud-credito-asistida/modal-edit-contrato-academico/modal-edit-contrato-academico.component';
import { DocumentosFaltantesAddComponent } from 'src/app/shared/documentos-faltantes/documentos-faltantes-add/documentos-faltantes-add.component';
import { ModalEnvioNotificacionComponent } from 'src/app/modules/gestion-de-solicitud/gestion-aprobacion-manual/gestion-solicitud-aprobacion-manual/modal-envio-notificacion/modal-envio-notificacion.component';
import { ModalAprobarRechazarSolicitudComponent } from 'src/app/modules/gestion-de-solicitud/gestion-aprobacion-manual/gestion-solicitud-aprobacion-manual/modal-aprobar-rechazar-solicitud/modal-aprobar-rechazar-solicitud.component';
import { CargarDocumentosDialogComponent } from 'src/app/modules/gestion-de-solicitud/acuerdo-pago/cargar-documentos-dialog/cargar-documentos-dialog.component';
import { AcuerdoPagoRechazarComponent } from 'src/app/modules/gestion-de-solicitud/acuerdo-pago/acuerdo-pago-rechazar/acuerdo-pago-rechazar.component';
import { AcuerdoPagoPlanAmortizacionDetalleComponent } from 'src/app/modules/gestion-de-solicitud/acuerdo-pago/acuerdo-pago-plan-amortizacion-detalle/acuerdo-pago-plan-amortizacion-detalle.component';
import { AcuerdoPagoAprobarDialogComponent } from 'src/app/modules/gestion-de-solicitud/acuerdo-pago/acuerdo-pago-aprobar-dialog/acuerdo-pago-aprobar-dialog.component';
import { RolEditComponent } from 'src/app/modules/security/roles/rol-edit/rol-edit.component';
import { UsuarioEditComponent } from 'src/app/modules/security/usuarios/usuario-edit/usuario-edit.component';
import { DebitoAutomaticoDetalleComponent } from 'src/app/modules/debito-automatico/debito-automatico-detalle/debito-automatico-detalle.component';
import { GestionDescuentosAddComponent } from '../../modules/configuracion-acuerdo-pago/gestion-descuentos/gestion-descuentos-add/gestion-descuentos-add.component';
import { GestionDescuentosEditComponent } from '../../modules/configuracion-acuerdo-pago/gestion-descuentos/gestion-descuentos-edit/gestion-descuentos-edit.component';
import { GestionDescuentosDetailComponent } from '../../modules/configuracion-acuerdo-pago/gestion-descuentos/gestion-descuentos-detail/gestion-descuentos-detail.component';
import { GestionDescuentosConfigAddComponent } from '../../modules/configuracion-acuerdo-pago/gestion-descuentos/gestion-descuentos-config/gestion-descuentos-config-add/gestion-descuentos-config-add.component';
import { GestionDescuentosConfigEditComponent } from '../../modules/configuracion-acuerdo-pago/gestion-descuentos/gestion-descuentos-config/gestion-descuentos-config-edit/gestion-descuentos-config-edit.component';
import { GestionDescuentosConfigDetailComponent } from '../../modules/configuracion-acuerdo-pago/gestion-descuentos/gestion-descuentos-config/gestion-descuentos-config-detail/gestion-descuentos-config-detail.component';
import { PreguntasMedicasAddComponent } from 'src/app/modules/configuracion-solicitud-credito/preguntas-medicas/preguntas-medicas-add/preguntas-medicas-add.component';
import { PreguntasMedicasDetailComponent } from 'src/app/modules/configuracion-solicitud-credito/preguntas-medicas/preguntas-medicas-detail/preguntas-medicas-detail.component';
import { PreguntasMedicasEditComponent } from 'src/app/modules/configuracion-solicitud-credito/preguntas-medicas/preguntas-medicas-edit/preguntas-medicas-edit.component';
import { EnfermedadesAddComponent } from 'src/app/modules/configuracion-solicitud-credito/enfermedades/enfermedades-add/enfermedades-add.component';
import { EnfermedadesDetailComponent } from 'src/app/modules/configuracion-solicitud-credito/enfermedades/enfermedades-detail/enfermedades-detail.component';
import { EnfermedadesEditComponent } from 'src/app/modules/configuracion-solicitud-credito/enfermedades/enfermedades-edit/enfermedades-edit.component';
import { DialogGenericSharedComponent } from 'src/app/shared/dialog-generic-shared/dialog-generic-shared.component';
import { LineasCreditosAddComponent } from '../../modules/configuracion-solicitud-credito/lineas-creditos/lineas-creditos-add/lineas-creditos-add.component';
import { LineasCreditosDetailComponent } from '../../modules/configuracion-solicitud-credito/lineas-creditos/lineas-creditos-detail/lineas-creditos-detail.component';
import { LineasCreditosEditComponent } from '../../modules/configuracion-solicitud-credito/lineas-creditos/lineas-creditos-edit/lineas-creditos-edit.component';
import { PlanCreditoConfigAddComponent } from '../../modules/configuracion-solicitud-credito/planes-creditos/plan-credito-config/plan-credito-config-add/plan-credito-config-add.component';
import { PlanCreditoConfigDetailComponent } from '../../modules/configuracion-solicitud-credito/planes-creditos/plan-credito-config/plan-credito-config-detail/plan-credito-config-detail.component';
import { ClienteDetailComponent } from 'src/app/modules/estado-cuenta/cliente-detail/cliente-detail.component';
import { PlanPagoSolicitudComponent } from 'src/app/shared/plan-pago-solicitud/plan-pago-solicitud.component';
import { PlanPagoAcuerdoComponent } from 'src/app/modules/estado-cuenta/plan-pago-acuerdo/plan-pago-acuerdo.component';
import { HistoricoPagosComponent } from 'src/app/modules/estado-cuenta/historico-pagos/historico-pagos.component';
import { ModalReservaCitaSoporteComponent } from "src/app/shared/modal-reserva-cita-soporte/modal-reserva-cita-soporte.component";
import { SolicitudAsistidaDesembolsoComponent } from 'src/app/modules/gestion-de-solicitud/solicitud-credito-asistida/solicitud-asistida/solicitud-asistida-desembolso/solicitud-asistida-desembolso.component';
import { AcuerdoPagoObservacionesDetailComponent } from '../../modules/gestion-de-solicitud/acuerdo-pago/acuerdo-pago-observaciones/acuerdo-pago-observaciones-detail/acuerdo-pago-observaciones-detail.component';
import { AcuerdoPagoObservacionesAddComponent } from 'src/app/modules/gestion-de-solicitud/acuerdo-pago/acuerdo-pago-observaciones/acuerdo-pago-observaciones-add/acuerdo-pago-observaciones-add.component';
import { ModalValidationFormComponent } from 'src/app/shared/modal-validation-form/modal-validation-form.component';
import { GestionTipoDocumentosEditComponent } from 'src/app/modules/configuracion-solicitud-credito/gestion-tipo-documentos/gestion-tipo-documentos-edit/gestion-tipo-documentos-edit.component';
import { SolicitudCreditoDocumentosAddComponent } from 'src/app/shared/solicitud-credito-documentos/solicitud-credito-documentos-add/solicitud-credito-documentos-add.component';
import { GestionTipoDocumentosAddComponent } from 'src/app/modules/configuracion-solicitud-credito/gestion-tipo-documentos/gestion-tipo-documentos-add/gestion-tipo-documentos-add.component';
import { InstructivosAddComponent } from 'src/app/modules/configuracion-solicitud-credito/lineas-creditos/instructivos-add/instructivos-add.component';
import { ModalSinInstructivoComponent } from 'src/app/shared/modal-sin-instructivo/modal-sin-instructivo.component';
import { ModalLineaCreditoSelectComponent } from 'src/app/shared/modal-linea-credito-select/modal-linea-credito-select.component';
import { GestionPasosDetailComponent } from 'src/app/modules/configuracion-solicitud-credito/gestion-pasos/gestion-pasos-detail/gestion-pasos-detail.component';
import { GestionPasosEditComponent } from 'src/app/modules/configuracion-solicitud-credito/gestion-pasos/gestion-pasos-edit/gestion-pasos-edit.component';
import { GestionPasosAddComponent } from 'src/app/modules/configuracion-solicitud-credito/gestion-pasos/gestion-pasos-add/gestion-pasos-add.component';
import {
  GestionFirmantesAddComponent
} from '../../modules/configuracion-firma-electronica/gestion-firmantes/gestion-firmantes-add/gestion-firmantes-add.component';
import {
  GestionFirmantesEditComponent
} from '../../modules/configuracion-firma-electronica/gestion-firmantes/gestion-firmantes-edit/gestion-firmantes-edit.component';
import { DetailsPasoOpcionComponent } from 'src/app/modules/configuracion-solicitud-credito/proceso/proceso-config/components-setup/details-paso-opcion/details-paso-opcion.component';
import ProcesoPasoExtended from '../models/configuracion-solicitud-credito/proceso-paso-extended';
import { OpcionAddComponent } from 'src/app/modules/configuracion-solicitud-credito/opcion/opcion-add/opcion-add.component';
import { ProcesoAddComponent } from 'src/app/modules/configuracion-solicitud-credito/proceso/proceso-add/proceso-add.component';
import { OpcionVistaAddComponent } from 'src/app/modules/configuracion-solicitud-credito/opcion/opcion-vista/opcion-vista-add/opcion-vista-add.component';
import { ProcesoDetailComponent } from 'src/app/modules/configuracion-solicitud-credito/proceso/proceso-detail/proceso-detail.component';
import { OpcionVistaDetailComponent } from 'src/app/modules/configuracion-solicitud-credito/opcion/opcion-vista/opcion-vista-detail/opcion-vista-detail.component';
import { OpcionDetailComponent } from 'src/app/modules/configuracion-solicitud-credito/opcion/opcion-detail/opcion-detail.component';
import { ProcesoEditComponent } from 'src/app/modules/configuracion-solicitud-credito/proceso/proceso-edit/proceso-edit.component';
import { OpcionVistaEditComponent } from 'src/app/modules/configuracion-solicitud-credito/opcion/opcion-vista/opcion-vista-edit/opcion-vista-edit.component';
import { OpcionEditComponent } from 'src/app/modules/configuracion-solicitud-credito/opcion/opcion-edit/opcion-edit.component';
import { OpcionProcesoAddComponent } from 'src/app/modules/configuracion-solicitud-credito/proceso/proceso-config/components/opcion-proceso-add/opcion-proceso-add.component';
import ProcesoModel from '../models/configuracion-solicitud-credito/proceso.models';
import { PasoProcesoAddComponent } from 'src/app/modules/configuracion-solicitud-credito/proceso/proceso-config/components/paso-proceso-add/paso-proceso-add.component';
import { GestionPlantillasParametroEditComponent } from '../../modules/configuracion-firma-electronica/gestion-plantillas/gestion-plantillas-parametro-edit/gestion-plantillas-parametro-edit.component';
import { ModalCargarArchivoComponent } from 'src/app/shared/modal-cargar-archivo/modal-cargar-archivo.component';
import { GestionPlantillasFirmanteAddComponent } from '../../modules/configuracion-firma-electronica/gestion-plantillas/gestion-plantillas-firmante-add/gestion-plantillas-firmante-add.component';
import { RolDuplicarComponent } from 'src/app/modules/security/roles/rol-duplicar/rol-duplicar.component';
import { ModalErrorComponent } from 'src/app/shared/modal-error/modal-error.component';
import { MotorConsultaAddComponent } from 'src/app/modules/configuracion-solicitud-credito/motor-consulta/motor-consulta-add/motor-consulta-add.component';
import { MotorConsultaDetailComponent } from 'src/app/modules/configuracion-solicitud-credito/motor-consulta/motor-consulta-detail/motor-consulta-detail.component';
import { MotorConsultaEditComponent } from 'src/app/modules/configuracion-solicitud-credito/motor-consulta/motor-consulta-edit/motor-consulta-edit.component';
import { ModalDebitoAutomaticoSelectComponent } from 'src/app/shared/modal-debito-automatico-select/modal-debito-automatico-select.component';
import { ConfiguracionListasSarlaftAddComponent } from 'src/app/modules/configuracion-solicitud-credito/configuracion-listas-sarlaft/configuracion-listas-sarlaft-add/configuracion-listas-sarlaft-add.component';
import { ConfiguracionListasSarlaftDetailComponent } from 'src/app/modules/configuracion-solicitud-credito/configuracion-listas-sarlaft/configuracion-listas-sarlaft-detail/configuracion-listas-sarlaft-detail.component';
import { ConfiguracionListasSarlaftEditComponent } from 'src/app/modules/configuracion-solicitud-credito/configuracion-listas-sarlaft/configuracion-listas-sarlaft-edit/configuracion-listas-sarlaft-edit.component';
import { ModalSolicitudCreditoSelectComponent } from 'src/app/shared/modal-solicitud-credito-select/modal-solicitud-credito-select.component';
import { SolicitudCreditoExtendedModel } from '../models/solicitud-credito/solicitud-credito-extended.model';
import { LineasMotorConsultaDetailComponent } from 'src/app/modules/configuracion-solicitud-credito/lineas-creditos/lineas-motor-consulta/lineas-motor-consulta-detail/lineas-motor-consulta-detail.component';
import { LineasMotorConsultaAddComponent } from 'src/app/modules/configuracion-solicitud-credito/lineas-creditos/lineas-motor-consulta/lineas-motor-consulta-add/lineas-motor-consulta-add.component';
import { HistorialConsultaSarlaftDetailComponent } from 'src/app/modules/historial-consulta-sarlaft/historial-consulta-sarlaft-detail/historial-consulta-sarlaft-detail.component';
import { HistorialConsultaSarlaftAddComponent } from 'src/app/modules/historial-consulta-sarlaft/historial-consulta-sarlaft-add/historial-consulta-sarlaft-add.component';
import { ModalAprobarRechazarConsultaSarlaftComponent } from 'src/app/modules/historial-consulta-sarlaft/modal-aprobar-rechazar-consulta-sarlaft/modal-aprobar-rechazar-consulta-sarlaft.component';
import { ModalDescargaDocumentosDebitoComponent } from 'src/app/shared/modal-descarga-documentos-debito/modal-descarga-documentos-debito.component';
import { CodeOtpComponent } from 'src/app/shared/code-otp/code-otp.component';


@Injectable()
export class DialogService {
  defaultOptions = {
    disableClose: true,
  };

  constructor(public matDialog: MatDialog) {}

  assignOptions(newInformation) {
    return Object.assign({}, this.defaultOptions, newInformation);
  }

  sessionExpiredDialog() {
    let dialogRef: MatDialogRef<SessionExpiredComponent>;
    dialogRef = this.matDialog.open(
      SessionExpiredComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

  infoModalTable(title: string, elements: any) {
    let dialogRef: MatDialogRef<ModalInfoTableComponent>;
    dialogRef = this.matDialog.open(
      ModalInfoTableComponent,
      this.assignOptions({
        data: { title, elements },
      })
    );
    return dialogRef.afterClosed();
  }

  showServerErrorDialog(errorResponse: HttpErrorResponse) {
    let dialogRef: MatDialogRef<ModalServerErrorComponent>;
    dialogRef = this.matDialog.open(ModalServerErrorComponent, {
      data: { errorResponse },
      hasBackdrop: false,
      width: '80vw',
    });
    dialogRef.updatePosition({ top: '15%' });
    return dialogRef.afterClosed();
  }

  showModalError(title: string, message: string) {
    let dialogRef: MatDialogRef<ModalErrorComponent>;
    dialogRef = this.matDialog.open(ModalErrorComponent,
       this.assignOptions({
        data: { title, message },
      })
    );
    return dialogRef.afterClosed();
  }

  deleteDialog(
    objectId: number,
    deleteMessage: string,
    deleteMethod: any,
    fnPrevalidation?: Function
  ) {
    let dialogRef: MatDialogRef<DeleteModalComponent>;
    dialogRef = this.matDialog.open(
      DeleteModalComponent,
      this.assignOptions({
        data: { objectId, deleteMethod, deleteMessage, fnPrevalidation },
      })
    );
    return dialogRef.afterClosed();
  }

  addSnapshotPhoto() {
    let dialogRef: MatDialogRef<SnapshotPhotoComponent>;
    dialogRef = this.matDialog.open(
      SnapshotPhotoComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

	modalGeneric(
		title,
		message,
		height:string | number = 'fit-content',
		width = 560,
		accept = 'si',
		cancel = 'no',
		pdf = false,
		info = false,
		video = false,
		confirmacion = false
	) {
		let dialogRef: MatDialogRef<ModalGenericComponent>;
		dialogRef = this.matDialog.open(
			ModalGenericComponent,
			this.assignOptions({
				data: { title, message, accept, cancel, pdf, info, video, confirmacion },
				height: height,
				width: width
			})
		);
		return dialogRef.afterClosed();
	}

  modalPrompt(
    title,
    titleField,
    message,
    accept = 'si',
    cancel = 'no'
  ) {
    let dialogRef: MatDialogRef<ModalPromptComponent>;
    dialogRef = this.matDialog.open(
      ModalPromptComponent,
      this.assignOptions({
        data: { title, titleField, message, accept, cancel },
      })
    );
    return dialogRef.afterClosed();
  }

  modalPdfs(title, message, accept = 'si', cancel = 'no', hideAccept?) {
    let dialogRef: MatDialogRef<ModalPdfsComponent>;
    dialogRef = this.matDialog.open(
      ModalPdfsComponent,
      this.assignOptions({
        data: { title, message, accept, cancel, hideAccept },
      })
    );
    return dialogRef.afterClosed();
  }

  addNewParametroGeneralDialog() {
    let dialogRef: MatDialogRef<ParametrosGeneralesAddComponent>;
    dialogRef = this.matDialog.open(
      ParametrosGeneralesAddComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

  addNewTipoDocumentoDialog() {
    let dialogRef: MatDialogRef<GestionTipoDocumentosAddComponent>;
    dialogRef = this.matDialog.open(
      GestionTipoDocumentosAddComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

  addNewAcuerdoPagoDescuentoDialog() {
    let dialogRef: MatDialogRef<GestionDescuentosAddComponent>;
    dialogRef = this.matDialog.open(
      GestionDescuentosAddComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

  addNewAcuerdoPagoDescuentoDetalleDialog(acuerdoPagoDescuentoId) {
    let dialogRef: MatDialogRef<GestionDescuentosConfigAddComponent>;
    dialogRef = this.matDialog.open(
      GestionDescuentosConfigAddComponent,
      this.assignOptions({
        data: { acuerdoPagoDescuentoId },
      })
    );
    return dialogRef.afterClosed();
  }

  showModalValidateContratoAcademico() {
    let dialogRef: MatDialogRef<ModalValidarContratoAcademicoComponent>;
    dialogRef = this.matDialog.open(
      ModalValidarContratoAcademicoComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

  showModalOptionsCredito() {
    let dialogRef: MatDialogRef<ModalOpcionesCreditoComponent>;
    dialogRef = this.matDialog.open(
      ModalOpcionesCreditoComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

  addNewPlanCreditoDialog() {
    let dialogRef: MatDialogRef<PlanCreditoAddComponent>;
    dialogRef = this.matDialog.open(
      PlanCreditoAddComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

  addNewConfiguracionPersonaNotificacionDialog() {
    let dialogRef: MatDialogRef<PersonaNotificacionAddComponent>;
    dialogRef = this.matDialog.open(
      PersonaNotificacionAddComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

  addNewObservacionDialog(solicitudId) {
    let dialogRef: MatDialogRef<SolicitudCreditoObservacionesAddComponent>;
    dialogRef = this.matDialog.open(
      SolicitudCreditoObservacionesAddComponent,
      this.assignOptions({
        data: { solicitudId },
      })
    );
    return dialogRef.afterClosed();
  }

  addNewObservacionAcuerdoPagoDialog(idAcuerdoPago) {
    let dialogRef: MatDialogRef<AcuerdoPagoObservacionesAddComponent>;
    dialogRef = this.matDialog.open(
      AcuerdoPagoObservacionesAddComponent,
      this.assignOptions({
        data: { idAcuerdoPago },
      })
    );
    return dialogRef.afterClosed();
  }

  addNewDocumentosFaltantesDialog(solicitudId) {
    let dialogRef: MatDialogRef<DocumentosFaltantesAddComponent>;
    dialogRef = this.matDialog.open(
      DocumentosFaltantesAddComponent,
      this.assignOptions({
        data: { solicitudId },
      })
    );
    return dialogRef.afterClosed();
  }

  addNewSolicitudCreditoDocumentosDialog(solicitudId, isFirmaManual, tipoDocumento = '') {
    let dialogRef: MatDialogRef<SolicitudCreditoDocumentosAddComponent>;
    dialogRef = this.matDialog.open(
      SolicitudCreditoDocumentosAddComponent,
      this.assignOptions({
        data: { solicitudId, isFirmaManual, tipoDocumento},
      })
    );
    return dialogRef.afterClosed();
  }

  addNewInstructivoLineaCreditoDialog(idLineaCredito) {
    let dialogRef: MatDialogRef<InstructivosAddComponent>;
    dialogRef = this.matDialog.open(
      InstructivosAddComponent,
      this.assignOptions({
        data: { idLineaCredito },
      })
    );
    return dialogRef.afterClosed();
  }


  envioNotificacion(solicitudAprobhacionManualId, cliente) {
    let dialogRef: MatDialogRef<ModalEnvioNotificacionComponent>;
    dialogRef = this.matDialog.open(
      ModalEnvioNotificacionComponent,
      this.assignOptions({
        data: {
          solicitudAprobhacionManualId: solicitudAprobhacionManualId,
          cliente: cliente,
        },
      })
    );
    return dialogRef.afterClosed();
  }

  showModalAprobarRechazarSolicitudAprobacionManual(
    isAprobar,
    solicitudAprobhacionManualId
  ) {
    let dialogRef: MatDialogRef<ModalAprobarRechazarSolicitudComponent>;
    dialogRef = this.matDialog.open(
      ModalAprobarRechazarSolicitudComponent,
      this.assignOptions({
        data: {
          solicitudAprobhacionManualId: solicitudAprobhacionManualId,
          isAprobar: isAprobar,
        },

        width: 600,
      })
    );
    return dialogRef.afterClosed();
  }

  showDetailObservacion(observacion) {
    let dialogRef: MatDialogRef<SolicitudCreditoObservacionesDetailComponent>;
    dialogRef = this.matDialog.open(
      SolicitudCreditoObservacionesDetailComponent,
      this.assignOptions({
        data: { observacionDetail: observacion },
      })
    );
    return dialogRef.afterClosed();
  }


  configFintechaAddOpcionProcesoDialog(paso: number | null, proceso: number) {
		let dialogRef: MatDialogRef<PasoProcesoAddComponent>;
		dialogRef = this.matDialog.open(
			PasoProcesoAddComponent,
			this.assignOptions({
				data: {
					paso,
					proceso,
				},
			})
		);

		return dialogRef.afterClosed();
	}


  showDetailObservacionAcuerdo(observacion) {
    let dialogRef: MatDialogRef<AcuerdoPagoObservacionesDetailComponent>;
    dialogRef = this.matDialog.open(
      AcuerdoPagoObservacionesDetailComponent,
      this.assignOptions({
        data: { observacionDetail: observacion },
      })
    );
    return dialogRef.afterClosed();
  }

  addListaNegra(cliente) {
    let dialogRef: MatDialogRef<ModalInsertListaNegraComponent>;
    dialogRef = this.matDialog.open(
      ModalInsertListaNegraComponent,
      this.assignOptions({
        data: { cliente: cliente },
      })
    );
    return dialogRef.afterClosed();
  }

  showDetailPersonalNotificacion(proceso) {
    let dialogRef: MatDialogRef<PersonaNotificacionDetailComponent>;
    dialogRef = this.matDialog.open(
      PersonaNotificacionDetailComponent,
      this.assignOptions({
        data: { proceso: proceso },
      })
    );
    return dialogRef.afterClosed();
  }

  datailPlanLineaCredito(planLineaCredito) {
    let dialogRef: MatDialogRef<PlanCreditoConfigDetailComponent>;
    dialogRef = this.matDialog.open(
      PlanCreditoConfigDetailComponent,
      this.assignOptions({
        data: planLineaCredito,
      })
    );
    return dialogRef.afterClosed();
  }

  datailLineaMotorConsulta(lineaMotorConsulta) {
    let dialogRef: MatDialogRef<LineasMotorConsultaDetailComponent>;
    dialogRef = this.matDialog.open(
      LineasMotorConsultaDetailComponent,
      this.assignOptions({
        data: lineaMotorConsulta,
      })
    );
    return dialogRef.afterClosed();
  }

  addNewBarrioDialog(pais?, departamento?, municipio?) {
    let dialogRef: MatDialogRef<BarrioAddComponent>;
    dialogRef = this.matDialog.open(
      BarrioAddComponent,
      this.assignOptions({
        data: { pais, departamento, municipio },
      })
    );
    return dialogRef.afterClosed();
  }

  addNewLugarDailog() {
    let dialogRef: MatDialogRef<LugarAddComponent>;
    dialogRef = this.matDialog.open(LugarAddComponent, this.defaultOptions);
    return dialogRef.afterClosed();
  }

  addNewCargoAdministrativoDialog(proceso?: string) {
    let dialogRef: MatDialogRef<CargoAdministrativoAddComponent>;
    dialogRef = this.matDialog.open(
      CargoAdministrativoAddComponent,
      this.assignOptions({
        data: proceso,
      })
    );
    return dialogRef.afterClosed();
  }

  listUbicacionDialog(pais, departamento) {
    let dialogRef: MatDialogRef<UbicacionGeograficaListComponent>;
    dialogRef = this.matDialog.open(
      UbicacionGeograficaListComponent,
      this.assignOptions({
        data: { pais, departamento },
      })
    );
    return dialogRef.afterClosed();
  }

  addNewUbicacionDialog(pais, region, departamento) {
    let dialogRef: MatDialogRef<UbicacionGeograficaAddComponent>;
    dialogRef = this.matDialog.open(
      UbicacionGeograficaAddComponent,
      this.assignOptions({
        data: { pais, region, departamento },
      })
    );
    return dialogRef.afterClosed();
  }

  editParametrosGeneralesDialog(idParametroGeneral) {
    let dialogRef: MatDialogRef<ParametrosGeneralesEditComponent>;
    dialogRef = this.matDialog.open(
      ParametrosGeneralesEditComponent,
      this.assignOptions({
        data: { idParametroGeneral },
      })
    );
    return dialogRef.afterClosed();
  }

  editTipoDocumentoDialog(idTipoDocumento) {
    let dialogRef: MatDialogRef<GestionTipoDocumentosEditComponent>;
    dialogRef = this.matDialog.open(
      GestionTipoDocumentosEditComponent,
      this.assignOptions({
        data: { idTipoDocumento },
      })
    );
    return dialogRef.afterClosed();
  }

  editAcuerdoPagoDescuentoDialog(descuento) {
    let dialogRef: MatDialogRef<GestionDescuentosEditComponent>;
    dialogRef = this.matDialog.open(
      GestionDescuentosEditComponent,
      this.assignOptions({
        data: { descuento },
      })
    );
    return dialogRef.afterClosed();
  }

  editAcuerdoPagoDescuentoDetalleDialog(descuentoDetalle) {
    let dialogRef: MatDialogRef<GestionDescuentosConfigEditComponent>;
    dialogRef = this.matDialog.open(
      GestionDescuentosConfigEditComponent,
      this.assignOptions({
        data: { descuentoDetalle },
      })
    );
    return dialogRef.afterClosed();
  }

  editPlanCredito(planCredito) {
    let dialogRef: MatDialogRef<PlanCreditoEditComponent>;
    dialogRef = this.matDialog.open(
      PlanCreditoEditComponent,
      this.assignOptions({
        data: { planCredito },
      })
    );
    return dialogRef.afterClosed();
  }

  openCodeConfirm(value: string, type: string) {
    let dialogRef: MatDialogRef<ModalCodeConfirmComponent>;
    dialogRef = this.matDialog.open(
      ModalCodeConfirmComponent,
      this.assignOptions({
        data: { value, type },
      })
    );
    return dialogRef.afterClosed();
  }

  showOpcionesDocumentos(solicitud) {
    let dialogRef: MatDialogRef<SolicitudCreditoDocumentosComponent>;
    dialogRef = this.matDialog.open(
      SolicitudCreditoDocumentosComponent,
      this.assignOptions({
        data: { solicitud },
        width: 600,
      })
    );
    return dialogRef.afterClosed();
  }

  rechazarSolicitudCredito(solicitud, flag) {
    let dialogRef: MatDialogRef<ModalRechazarSolicitudComponent>;
    dialogRef = this.matDialog.open(ModalRechazarSolicitudComponent, {
      data: { solicitud: solicitud, flag: flag },
    });
    return dialogRef.afterClosed();
  }

  modalEditCambioNumeroContratoAcademico(solicitud) {
    let dialogRef: MatDialogRef<ModalEditContratoAcademicoComponent>;
    dialogRef = this.matDialog.open(ModalEditContratoAcademicoComponent, {
      data: { solicitud: solicitud },
    });
    return dialogRef.afterClosed();
  }

  modalEditFechaPrimeraCuota(solicitud) {
    let dialogRef: MatDialogRef<ModalEditFechaPrimerCuotaComponent>;
    dialogRef = this.matDialog.open(ModalEditFechaPrimerCuotaComponent, {
      data: { solicitud: solicitud },
    });
    return dialogRef.afterClosed();
  }

  showDetallesCapacidadEndeudamiento(sumaryResponse) {
    let dialogRef: MatDialogRef<ModalDetalleCapacidadEndeudamientoComponent>;
    dialogRef = this.matDialog.open(
      ModalDetalleCapacidadEndeudamientoComponent,
      {
        data: { sumaryResponse: sumaryResponse },
      }
    );
    return dialogRef.afterClosed();
  }

  actualizarDatosPersona(numeroDocumentoActual, emailActual) {
    let dialogRef: MatDialogRef<ActualizarDatosPersonaComponent>;
    dialogRef = this.matDialog.open(
      ActualizarDatosPersonaComponent,
      this.assignOptions({
        data: { numeroDocumentoActual, emailActual },
      })
    );
    return dialogRef.afterClosed();
  }

  addNewCargoAdministrativoCompleteDialog() {
    let dialogRef: MatDialogRef<CargoAdministrativoAddCompleteComponent>;
    dialogRef = this.matDialog.open(
      CargoAdministrativoAddCompleteComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

  showDetailCargoAdministrativoDialog(cargoAdmvo) {
    let dialogRef: MatDialogRef<CargoAdministrativoDetailComponent>;
    dialogRef = this.matDialog.open(
      CargoAdministrativoDetailComponent,
      this.assignOptions({
        data: cargoAdmvo,
      })
    );
    return dialogRef.afterClosed();
  }

  detailAcuerdoPagoDescuentoDetalleDialog(descuentoDetalle) {
    let dialogRef: MatDialogRef<GestionDescuentosConfigDetailComponent>;
    dialogRef = this.matDialog.open(
      GestionDescuentosConfigDetailComponent,
      this.assignOptions({
        data: descuentoDetalle,
      })
    );
    return dialogRef.afterClosed();
  }

  detailAcuerdoPagoDescuentoDialog(descuento) {
    let dialogRef: MatDialogRef<GestionDescuentosDetailComponent>;
    dialogRef = this.matDialog.open(
      GestionDescuentosDetailComponent,
      this.assignOptions({
        data: descuento,
      })
    );
    return dialogRef.afterClosed();
  }

  showEditCargoAdministrativoDialog(cargoAdmvo) {
    let dialogRef: MatDialogRef<CargoAdministrativoEditComponent>;
    dialogRef = this.matDialog.open(
      CargoAdministrativoEditComponent,
      this.assignOptions({
        data: cargoAdmvo,
      })
    );
    return dialogRef.afterClosed();
  }

  modalDebitoAutomatico(height = 360, needRegion = false) {
    let dialogRef: MatDialogRef<DebitoAutomaticoComponent>;
    dialogRef = this.matDialog.open(
      DebitoAutomaticoComponent,
      this.assignOptions({
        data: { needRegion },
        height: height,
        width: 600,
      })
    );
    return dialogRef.afterClosed();
  }

  addNewPermission() {
    let dialogRef: MatDialogRef<PermisoAddComponent>;
    dialogRef = this.matDialog.open(
      PermisoAddComponent,
      this.assignOptions({})
    );
    return dialogRef.afterClosed();
  }

  addNewRol() {
    let dialogRef: MatDialogRef<RolAddComponent>;
    dialogRef = this.matDialog.open(RolAddComponent, this.assignOptions({}));
    return dialogRef.afterClosed();
  }

  editRol(id: number) {
    let dialogRef: MatDialogRef<RolEditComponent>;
    dialogRef = this.matDialog.open(
      RolEditComponent,
      this.assignOptions({ data: { id } })
    );
    return dialogRef.afterClosed();
  }

  duplicarRol(rol: any) {
		let dialogRef: MatDialogRef<RolDuplicarComponent>;
		dialogRef = this.matDialog.open(
			RolDuplicarComponent,
			this.assignOptions({ data: { rol } })
		);
		return dialogRef.afterClosed();
	}

  editUser(id: number) {
    let dialogRef: MatDialogRef<UsuarioEditComponent>;
    dialogRef = this.matDialog.open(
      UsuarioEditComponent,
      this.assignOptions({ data: { id } })
    );
    return dialogRef.afterClosed();
  }

  detailDebitoAutomatico(card: DebitoAutomatico) {
    let dialogRef: MatDialogRef<DebitoAutomaticoDetalleComponent>;
    dialogRef = this.matDialog.open(
      DebitoAutomaticoDetalleComponent,
      this.assignOptions({ data: { ...card } })
    );
    return dialogRef.afterClosed();
  }

  detallePagoDebitoAutomatico(detalle: DetalleDAD) {
    let dialogRef: MatDialogRef<DebitoAutomaticoDetallePagosComponent>;
    dialogRef = this.matDialog.open(
      DebitoAutomaticoDetallePagosComponent,
      this.assignOptions({ data: { ...detalle }, width: 700 })
    );
    return dialogRef.afterClosed();
  }

  modalLinkPago(
    isSolicitudCreditoDescuento,
    valorSeguro,
    title,
    cuotas,
    height = "fit-content",
    width = 800,
    accept = 'Si',
    cancel = 'no',
    isTaquilla = false,
    conceptoPago = null,
    isPagoInicial = false,
    saldoMinimo = null,
    saldoTotal = null,
    isTotal = null
  ) {
    let dialogRef: MatDialogRef<ModalLinkPagoComponent>;
    dialogRef = this.matDialog.open(
      ModalLinkPagoComponent,
      this.assignOptions({
        data: {
          valorSeguro,
          title,
          cuotas,
          accept,
          cancel,
          isTaquilla,
          conceptoPago,
          isPagoInicial,
          isSolicitudCreditoDescuento,
          saldoMinimo,
          saldoTotal,
          isTotal
        },
        height,
        width,
      })
    );
    return dialogRef.afterClosed();
  }

  modalAdjuntarDocumentos(data) {
    let dialogRef: MatDialogRef<CargarDocumentosDialogComponent>;
    dialogRef = this.matDialog.open(
      CargarDocumentosDialogComponent,
      this.assignOptions({ data: data })
    );
    return dialogRef.afterClosed();
  }

  acuerdoPagoRechazar(
    id,
    estado,
    contratoAcademico,
    contratoEdufactoring,
    nombreTitular,
    valorFinanciado
  ) {
    let dialogRef: MatDialogRef<AcuerdoPagoRechazarComponent>;
    dialogRef = this.matDialog.open(
      AcuerdoPagoRechazarComponent,
      this.assignOptions({
        data: {
          id,
          estado,
          contratoAcademico,
          contratoEdufactoring,
          nombreTitular,
          valorFinanciado,
        },
      })
    );
    return dialogRef.afterClosed();
  }

  hitorialAcuerdoPagoByCliente(row) {
    let dialogRef: MatDialogRef<ModalHistorialAcuerdoPagoComponent>;
    dialogRef = this.matDialog.open(
      ModalHistorialAcuerdoPagoComponent,
      this.assignOptions({
        data: row,
      })
    );
    return dialogRef.afterClosed();
  }

  showPlanAmortizacionDetalle(filter) {
    let dialogRef: MatDialogRef<AcuerdoPagoPlanAmortizacionDetalleComponent>;
    dialogRef = this.matDialog.open(
      AcuerdoPagoPlanAmortizacionDetalleComponent,
      this.assignOptions({
        data: filter,
      })
    );
    return dialogRef.afterClosed();
  }

  infoModal(
    title: string,
    message: string,
    textButton: string = null,
    hideButton: boolean = false,
    aceptarButton = false
  ) {
    let dialogRef: MatDialogRef<ModalInfoComponent>;
    dialogRef = this.matDialog.open(
      ModalInfoComponent,
      this.assignOptions({
        data: {
          title,
          message,
          textButton,
          hideButton,
          aceptarButton,
        },
      })
    );
    return dialogRef.afterClosed();
  }

  infoModalLineaCreditoSelect(
    title: string,
    message: string,
    textButton: string = null,
    hideButton: boolean = false,
    aceptarButton = false,
    lineasCredito: any
  ) {
    let dialogRef: MatDialogRef<ModalLineaCreditoSelectComponent>;
    dialogRef = this.matDialog.open(
      ModalLineaCreditoSelectComponent,
      this.assignOptions({
        data: {
          title,
          message,
          textButton,
          hideButton,
          aceptarButton,
          lineasCredito
        },
      })
    );
    return dialogRef.afterClosed();
  }

  infoModalDebitoAutomaticoSelect(
    title: string,
    message: string,
    textButton: string = null,
    hideButton: boolean = false,
    aceptarButton = false,
    debitosAutomaticos: any
  ) {
    let dialogRef: MatDialogRef<ModalDebitoAutomaticoSelectComponent>;
    dialogRef = this.matDialog.open(
      ModalDebitoAutomaticoSelectComponent,
      this.assignOptions({
        data: {
          title,
          message,
          textButton,
          hideButton,
          aceptarButton,
          debitosAutomaticos
        },
      })
    );
    return dialogRef.afterClosed();
  }

  showModalAprobarAcuerdoPago(
    numeroAcuerdoPago: number,
    preAprobar: boolean = false
  ) {
    let dialogRef: MatDialogRef<AcuerdoPagoAprobarDialogComponent>;
    dialogRef = this.matDialog.open(
      AcuerdoPagoAprobarDialogComponent,
      this.assignOptions({
        data: { numeroAcuerdoPago, preAprobar },
      })
    );
    return dialogRef.afterClosed();
  }

  addConceptoPrioridadDialog() {
    let dialogRef: MatDialogRef<ConceptoCuotaPrioridadAddComponent>;
    dialogRef = this.matDialog.open(
      ConceptoCuotaPrioridadAddComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

  editConceptoPrioridadDialog(conceptoPrioridad: ConceptoCuotaPrioridad) {
    let dialogRef: MatDialogRef<ConceptoCuotaPrioridadEditComponent>;
    dialogRef = this.matDialog.open(
      ConceptoCuotaPrioridadEditComponent,
      this.assignOptions({ data: { conceptoPrioridad } })
    );
    return dialogRef.afterClosed();
  }

  detailConceptoPrioridadDialog(conceptoPrioridad: ConceptoCuotaPrioridad) {
    let dialogRef: MatDialogRef<ConceptoCuotaPrioridadDetailComponent>;
    dialogRef = this.matDialog.open(
      ConceptoCuotaPrioridadDetailComponent,
      this.assignOptions({ data: conceptoPrioridad })
    );
    return dialogRef.afterClosed();
  }

  showDialogDetailTaquillaLinkPago(row) {
    let dialogRef: MatDialogRef<LinkPagoDetailComponent>;
    dialogRef = this.matDialog.open(
      LinkPagoDetailComponent,
      this.assignOptions({
        data: row,
      })
    );
    return dialogRef.afterClosed();
  }

  addNewPreguntasMedicasDialog() {
    let dialogRef: MatDialogRef<PreguntasMedicasAddComponent>;
    dialogRef = this.matDialog.open(
      PreguntasMedicasAddComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

  addNewLineasCreditosDialog() {
    let dialogRef: MatDialogRef<LineasCreditosAddComponent>;
    dialogRef = this.matDialog.open(
      LineasCreditosAddComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

  addNewProcesoDialog() {
    let dialogRef: MatDialogRef<ProcesoAddComponent>;
    dialogRef = this.matDialog.open(
      ProcesoAddComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

  addNewOpcionDialog() {
    let dialogRef: MatDialogRef<OpcionAddComponent>;
    dialogRef = this.matDialog.open(
      OpcionAddComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

  addNewHistorialConsultaSarlaftDialog() {
    let dialogRef: MatDialogRef<HistorialConsultaSarlaftAddComponent>;
    dialogRef = this.matDialog.open(
      HistorialConsultaSarlaftAddComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

  addNewMotorConsultaDialog() {
    let dialogRef: MatDialogRef<MotorConsultaAddComponent>;
    dialogRef = this.matDialog.open(
      MotorConsultaAddComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

  addNewOpcionVistaDialog(opcionId: number) {
    let dialogRef: MatDialogRef<OpcionVistaAddComponent>;
    dialogRef = this.matDialog.open(
      OpcionVistaAddComponent,
      this.assignOptions({
        data: opcionId,
      })
    );
    return dialogRef.afterClosed();
  }

  showDetailPreguntasMedicasDialog(enfermedad) {
    let dialogRef: MatDialogRef<PreguntasMedicasDetailComponent>;
    dialogRef = this.matDialog.open(
      PreguntasMedicasDetailComponent,
      this.assignOptions({
        data: enfermedad,
        width: '480px',
      })
    );
    return dialogRef.afterClosed();
  }

  showDetailLineasCreditosDialog(lineaCredito) {
    let dialogRef: MatDialogRef<LineasCreditosDetailComponent>;
    dialogRef = this.matDialog.open(
      LineasCreditosDetailComponent,
      this.assignOptions({
        data: lineaCredito,
        width: '480px',
      })
    );
    return dialogRef.afterClosed();
  }

  showDetailProcesoDialog(proceso) {
    let dialogRef: MatDialogRef<ProcesoDetailComponent>;
    dialogRef = this.matDialog.open(
      ProcesoDetailComponent,
      this.assignOptions({
        data: proceso,
      })
    );
    return dialogRef.afterClosed();
  }

  showDetailOpcionDialog(opcion) {
    let dialogRef: MatDialogRef<OpcionDetailComponent>;
    dialogRef = this.matDialog.open(
      OpcionDetailComponent,
      this.assignOptions({
        data: opcion,
      })
    );
    return dialogRef.afterClosed();
  }

  showDetailHistorialConsultaSarlaftDialog(historialConsultaSarlaft) {
    let dialogRef: MatDialogRef<HistorialConsultaSarlaftDetailComponent>;
    dialogRef = this.matDialog.open(
      HistorialConsultaSarlaftDetailComponent,
      this.assignOptions({
        data: historialConsultaSarlaft,
      })
    );
    return dialogRef.afterClosed();
  }

  showDetailMotorConsultaDialog(motorConsulta) {
    let dialogRef: MatDialogRef<MotorConsultaDetailComponent>;
    dialogRef = this.matDialog.open(
      MotorConsultaDetailComponent,
      this.assignOptions({
        data: motorConsulta,
      })
    );
    return dialogRef.afterClosed();
  }

  showDetailOpcionVistaDialog(opcionVista) {
    let dialogRef: MatDialogRef<OpcionVistaDetailComponent>;
    dialogRef = this.matDialog.open(
      OpcionVistaDetailComponent,
      this.assignOptions({
        data: opcionVista,
      })
    );
    return dialogRef.afterClosed();
  }

  addNewEnfermedadDialog() {
    let dialogRef: MatDialogRef<EnfermedadesAddComponent>;
    dialogRef = this.matDialog.open(
      EnfermedadesAddComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

  addNewPlanLineaCreditoDialog(planCreditoId) {
    let dialogRef: MatDialogRef<PlanCreditoConfigAddComponent>;
    dialogRef = this.matDialog.open(
      PlanCreditoConfigAddComponent,
      this.assignOptions({
        data: planCreditoId,
      })
    );
    return dialogRef.afterClosed();
  }

  addNewLineaMotorConsultaDialog(motorConsultaId) {
    let dialogRef: MatDialogRef<LineasMotorConsultaAddComponent>;
    dialogRef = this.matDialog.open(
      LineasMotorConsultaAddComponent,
      this.assignOptions({
        data: motorConsultaId,
      })
    );
    return dialogRef.afterClosed();
  }

  showDetailEnfermedadDialog(enfermedad) {
    let dialogRef: MatDialogRef<EnfermedadesDetailComponent>;
    dialogRef = this.matDialog.open(
      EnfermedadesDetailComponent,
      this.assignOptions({
        data: enfermedad,
        width: '520px',
      })
    );
    return dialogRef.afterClosed();
  }

  editPreguntasMedicasDialog(enfermedad) {
    let dialogRef: MatDialogRef<PreguntasMedicasEditComponent>;
    dialogRef = this.matDialog.open(
      PreguntasMedicasEditComponent,
      this.assignOptions({
        data: enfermedad,
      })
    );
    return dialogRef.afterClosed();
  }

  editLineasCreditosDialog(lineaCredito) {
    let dialogRef: MatDialogRef<LineasCreditosEditComponent>;
    dialogRef = this.matDialog.open(
      LineasCreditosEditComponent,
      this.assignOptions({
        data: lineaCredito,
      })
    );
    return dialogRef.afterClosed();
  }

  editProcesoDialog(proceso) {
    let dialogRef: MatDialogRef<ProcesoEditComponent>;
    dialogRef = this.matDialog.open(
      ProcesoEditComponent,
      this.assignOptions({
        data: proceso,
      })
    );
    return dialogRef.afterClosed();
  }

  editOpcionDialog(opcion) {
    let dialogRef: MatDialogRef<OpcionEditComponent>;
    dialogRef = this.matDialog.open(
      OpcionEditComponent,
      this.assignOptions({
        data: opcion,
      })
    );
    return dialogRef.afterClosed();
  }

  editMotorConsultaDialog(motorConsulta) {
    let dialogRef: MatDialogRef<MotorConsultaEditComponent>;
    dialogRef = this.matDialog.open(
      MotorConsultaEditComponent,
      this.assignOptions({
        data: motorConsulta,
      })
    );
    return dialogRef.afterClosed();
  }

  editOpcionVistaDialog(opcionVista) {
    let dialogRef: MatDialogRef<OpcionVistaEditComponent>;
    dialogRef = this.matDialog.open(
      OpcionVistaEditComponent,
      this.assignOptions({
        data: opcionVista,
      })
    );
    return dialogRef.afterClosed();
  }

  editEnfermedadDialog(enfermedad) {
    let dialogRef: MatDialogRef<EnfermedadesEditComponent>;
    dialogRef = this.matDialog.open(
      EnfermedadesEditComponent,
      this.assignOptions({
        data: enfermedad,
      })
    );
    return dialogRef.afterClosed();
  }

  dialogGeneric(
    title,
    message,
    height = 220,
    width = 560,
    accept = 'Si',
    cancel = 'No'
  ) {
    let dialogRef: MatDialogRef<DialogGenericSharedComponent>;
    dialogRef = this.matDialog.open(
      DialogGenericSharedComponent,
      this.assignOptions({
        data: { title, message, accept, cancel },
        height,
        width,
      })
    );
    return dialogRef.afterClosed();
  }

  clienteDetail(
    fotoCliente,
    ubicacion,
    barrio,
    isDetail,
    cliente
  ) {
    let dialogRef: MatDialogRef<ClienteDetailComponent>;
    dialogRef = this.matDialog.open(
      ClienteDetailComponent,
      this.assignOptions({
        data: { fotoCliente, ubicacion, barrio, isDetail, cliente},
      })
    );
    return dialogRef.afterClosed();
  }

  planPagoSolicitud(item, isDetail) {
    let dialogRef: MatDialogRef<PlanPagoSolicitudComponent>;
    dialogRef = this.matDialog.open(
      PlanPagoSolicitudComponent,
      this.assignOptions({
        data: {item, isDetail},
      })
    );
    return dialogRef.afterClosed();
  }

  planPagoAcuerdo(item, isDetail) {
    let dialogRef: MatDialogRef<PlanPagoAcuerdoComponent>;
    dialogRef = this.matDialog.open(
      PlanPagoAcuerdoComponent,
      this.assignOptions({
        data: {item, isDetail},
      })
    );
    return dialogRef.afterClosed();
  }

  historialPagos(item, isSolicitud) {
    let dialogRef: MatDialogRef<HistoricoPagosComponent>;
    dialogRef = this.matDialog.open(
      HistoricoPagosComponent,
      this.assignOptions({
        data: {item, isSolicitud},
      })
    );
    return dialogRef.afterClosed();
  }

  modalReservaCitaSoporte() {
		let dialogRef: MatDialogRef<ModalReservaCitaSoporteComponent>;
		dialogRef = this.matDialog.open(
			ModalReservaCitaSoporteComponent,
			{
				panelClass: 'custom-dialog'
			}
		);
		return dialogRef.afterClosed();
	}

  desembolsarCreditoDialog(solicitudId, valorTotal, fechaPrimeraCuota) {
    let dialogRef: MatDialogRef<SolicitudAsistidaDesembolsoComponent>;
    dialogRef = this.matDialog.open(
      SolicitudAsistidaDesembolsoComponent,
      this.assignOptions({
        data: {solicitudId, valorTotal, fechaPrimeraCuota}
      })
    );
    return dialogRef.afterClosed();
  }

  modalValidationForm(form, controles) {
		let dialogRef: MatDialogRef<ModalValidationFormComponent>;
		dialogRef = this.matDialog.open(
			ModalValidationFormComponent,
			this.assignOptions({
				width: "650px",
				data: {
          form,
          controles
				},
			})
		);
		return dialogRef.afterClosed();
	}

  showModalSinInstructivo(lineaPlanCreditoSelect, isCliente) {
    let dialogRef: MatDialogRef<ModalSinInstructivoComponent>;
    dialogRef = this.matDialog.open(
      ModalSinInstructivoComponent,
      this.assignOptions({
        data: {lineaPlanCreditoSelect, isCliente}
      })
    );
    return dialogRef.afterClosed();
  }

 addNewPasoDialog() {
		let dialogRef: MatDialogRef<GestionPasosAddComponent>;
		dialogRef = this.matDialog.open(
			GestionPasosAddComponent,
			this.defaultOptions
		);
		return dialogRef.afterClosed();

	}

  editPasoDialog(pasoId) {
    let dialogRef: MatDialogRef<GestionPasosEditComponent>;
    dialogRef = this.matDialog.open(
      GestionPasosEditComponent,
      this.assignOptions({
        data: pasoId,
      })
    );
    return dialogRef.afterClosed();
  }

  showDetailPasoDialog(pasoId) {
    let dialogRef: MatDialogRef<GestionPasosDetailComponent>;
    dialogRef = this.matDialog.open(
      GestionPasosDetailComponent,
      this.assignOptions({
        data: pasoId,
      })
    );
    return dialogRef.afterClosed();
  }

  addNewFirmante() {
    let dialogRef: MatDialogRef<GestionFirmantesAddComponent>;
    dialogRef = this.matDialog.open(
      GestionFirmantesAddComponent,
      this.defaultOptions
    );
    return dialogRef.afterClosed();
  }

  editFirmante(idFirmante: any) {
    let dialogRef: MatDialogRef<GestionFirmantesEditComponent>;
    dialogRef = this.matDialog.open(
      GestionFirmantesEditComponent,
      this.assignOptions({
        data: { idFirmante },
      })
    );
    return dialogRef.afterClosed();
  }


  configFintechEditPasoOpcionProceso(
		pasoProceso: ProcesoPasoExtended,
		type: string,
    ordenList: Array<number> = [],
    prioridadList: Array<number> = [],

	) {
		  let dialogRef: MatDialogRef<DetailsPasoOpcionComponent>;
		dialogRef = this.matDialog.open(
			DetailsPasoOpcionComponent,
			this.assignOptions({
        width: "400px",
				data: { pasoProceso, type, ordenList, prioridadList },
			})
		);
		return dialogRef.afterClosed();

	}




  configFintechPasoProcesoDialog(proceso: ProcesoModel, ordenList: Array<number>, nombrePasosList: Array<string>) {
		let dialogRef: MatDialogRef<PasoProcesoAddComponent>;
		dialogRef = this.matDialog.open(
			PasoProcesoAddComponent,
			this.assignOptions({
				data: { proceso, ordenList, nombrePasosList}
			})
		);

		return dialogRef.afterClosed();
	}


	configFintechOpcionProcesoDialog(proceso: ProcesoModel) {
		let dialogRef: MatDialogRef<OpcionProcesoAddComponent>;
		dialogRef = this.matDialog.open(
			OpcionProcesoAddComponent,
			this.assignOptions({
				data: { proceso },
			})
		);

		return dialogRef.afterClosed();
	}



  configFintechAddOpcionProcesoDialog(pasoProceso: number | null, proceso: number, prioridadList: Array<number>, nombreOpcionesList: Array<string>) {
		let dialogRef: MatDialogRef<OpcionProcesoAddComponent>;
		dialogRef = this.matDialog.open(
			OpcionProcesoAddComponent,
			this.assignOptions({
				data: {
					pasoProceso,
					proceso,
          prioridadList,
          nombreOpcionesList
				},
			})
		);

		return dialogRef.afterClosed();
	}

  showModalEditPlantillaParametro(data: any, proceso: string) {
		let dialogRef: MatDialogRef<GestionPlantillasParametroEditComponent>;
		dialogRef = this.matDialog.open(
			GestionPlantillasParametroEditComponent,
			this.assignOptions({
				data: { data, proceso }
			})
		);
		return dialogRef.afterClosed();
	}

  showModalCargaArchivo(opcion: any, proceso = null, dataEdit = null) {
		let dialogRef: MatDialogRef<ModalCargarArchivoComponent>;
		dialogRef = this.matDialog.open(
			ModalCargarArchivoComponent,
			this.assignOptions({
				data: { opcion: opcion, proceso: proceso, dataEdit: dataEdit },
			})
		);
		return dialogRef.afterClosed();
	}

  addNewplantillaFirmante(idPlantilla: any, listObject: any) {
		let dialogRef: MatDialogRef<GestionPlantillasFirmanteAddComponent>;
		dialogRef = this.matDialog.open(
			GestionPlantillasFirmanteAddComponent,
			this.assignOptions({
				data: { idPlantilla, listObject },
			})
		);
		return dialogRef.afterClosed();
	}

  addNewConfSarlaftDialog() {
		let dialogRef: MatDialogRef<ConfiguracionListasSarlaftAddComponent>;
		dialogRef = this.matDialog.open(
			ConfiguracionListasSarlaftAddComponent,
			this.defaultOptions
		);
		return dialogRef.afterClosed();

	}

  editConfSarlaftDialog(configSarlaftId) {
    let dialogRef: MatDialogRef<ConfiguracionListasSarlaftEditComponent>;
    dialogRef = this.matDialog.open(
      ConfiguracionListasSarlaftEditComponent,
      this.assignOptions({
        data: configSarlaftId,
      })
    );
    return dialogRef.afterClosed();
  }

  showConfSarlaftDialog(pasoId) {
    let dialogRef: MatDialogRef<ConfiguracionListasSarlaftDetailComponent>;
    dialogRef = this.matDialog.open(
      ConfiguracionListasSarlaftDetailComponent,
      this.assignOptions({
        data: pasoId,
      })
    );
    return dialogRef.afterClosed();
  }

  updateStateConsultaSarlaftDialog(historialConsultaSarlaft) {
    let dialogRef: MatDialogRef<ModalAprobarRechazarConsultaSarlaftComponent>;
    dialogRef = this.matDialog.open(
      ModalAprobarRechazarConsultaSarlaftComponent,
      this.assignOptions({
        data: historialConsultaSarlaft,
      })
    );
    return dialogRef.afterClosed();
  }

  showSelectSolicitudesCreditoDialog(
    solicitudes: Array<SolicitudCreditoExtendedModel>,
    isDetail: boolean = false,
    message: string = null
  ) {
    let dialogRef: MatDialogRef<ModalSolicitudCreditoSelectComponent>;
    dialogRef = this.matDialog.open(
      ModalSolicitudCreditoSelectComponent,
      this.assignOptions({ data:  {
          solicitudes,
          isDetail,
          message
        },
        width:
        "620px",
      })
    )
    return dialogRef.afterClosed();
  }

  showModalDescargaDocumentosDebito(idDebito: number, idCliente: number) {
    let dialogRef: MatDialogRef<ModalDescargaDocumentosDebitoComponent>;
    dialogRef = this.matDialog.open(
      ModalDescargaDocumentosDebitoComponent,
      this.assignOptions({
        data: {
          idDebito,
          idCliente
        },
      })
    );
    return dialogRef.afterClosed();
  }

  modalCodigoOTP(info: any) {
    let dialogRef: MatDialogRef<CodeOtpComponent>;
    dialogRef = this.matDialog.open(
      CodeOtpComponent,
      this.assignOptions({
        data: {
          info
        },
        width:
        "620px",
      })
    );
    return dialogRef.afterClosed();
  }

}
