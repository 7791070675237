import { Injectable } from "@angular/core";
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from "@angular/common/http";
import { IPlantillaParametroServiceInterface } from '../plantilla-parametro.service.interface';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: "root" })
export class PlantillaParametroServiceImpl extends ServiceImplService<any> implements IPlantillaParametroServiceInterface {
	constructor(public  http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = "plantilla-parametro/";
	}

	getExtended(id: number): any {
		const path = `${id}/extended`;
		return this.executeGet(path).pipe(map((res: any) => res.data));
	}

	removePlantillaParametro(idPlantillaParametro: any): Observable<string> {
		const path = `${idPlantillaParametro}/inactivar`;
		return this.httpClient.put(this.getFullPath() + path, null).pipe(map((res: any) => res));
	}

	getAtributosNombre(proceso: string): any {
		const path = `proceso/${proceso}/obtener-parametros-class`;
		return this.executeGet(path).pipe(map((res: any) => res.data));
	}
}
