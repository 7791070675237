<mat-dialog-content class="min-w-full md:min-w-[80vw]">
    <div class="gene-card-title">
        <div class="flex">
            <h3>{{ 'Información del Cliente' }}</h3>
        </div>
        <mat-divider></mat-divider>
    </div>

    <br>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-2">
        <div class="pad-photo">
            <img class="photo-persona" [src]="getFotoCliente()" alt="">
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2 md:col-span-2">
            <div>
                <strong>Nombre Completo</strong>
                <p class="mrgn-b-xs">{{cliente?.nombreCompleto}}</p>
            </div>

            <div>
                <strong>Tipo Documento</strong>
                <p class="mrgn-b-xs">{{cliente?.tipoIdentificacion}}</p>
            </div>

            <div>
                <strong>Número Documento</strong>
                <p class="mrgn-b-xs">{{cliente?.numeroDocumento}}</p>
            </div>

            <div>
              <strong>País Expedición</strong>
              <p class="mrgn-b-xs" *ngIf="isDetail">{{ ubicacion ? ubicacion.pais.nombre : 'NO REGISTRA'}}</p>
              <p class="mrgn-b-xs" *ngIf="!isDetail">{{cliente?.paisExpedicion.nombre}}</p>
          </div>

          <div>
              <strong>Departamento Expedición</strong>
              <p class="mrgn-b-xs" *ngIf="isDetail">{{ ubicacion ? ubicacion.departamento.departamento : 'NO REGISTRA'}}</p>
              <p class="mrgn-b-xs" *ngIf="!isDetail">{{cliente?.departamentoExpedicion.departamento}}
              </p>
          </div>

          <div>
              <strong>Ciudad Expedición</strong>
              <p class="mrgn-b-xs" *ngIf="isDetail">{{ ubicacion ? ubicacion.municipio.municipio : 'NO REGISTRA'}}</p>
              <p class="mrgn-b-xs" *ngIf="!isDetail">{{cliente?.municipioExpedicion.municipio}}</p>
          </div>
        </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-2">
        <div>
            <strong>Fecha Expedición</strong>
            <p class="mrgn-b-xs"> {{cliente?.fechaExpedicion | date:'dd/MM/yyyy'}}</p>
        </div>

        <div>
            <strong>Fecha Nacimiento</strong>
            <p class="mrgn-b-xs"> {{cliente?.fechaNacimiento | date:'dd/MM/yyyy'}}</p>
        </div>

        <div *ngIf="cliente?.fechaVencimiento">
            <strong>Fecha Vencimiento</strong>
            <p class="mrgn-b-xs"> {{cliente?.fechaVencimiento | date:'dd/MM/yyyy'}}</p>
        </div>

        <div>
            <strong>Nacionalidad</strong>
            <p class="mrgn-b-xs">{{cliente?.nacionalidad}}</p>
        </div>

        <div>
            <strong>Estado Civil</strong>
            <p class="mrgn-b-xs">{{cliente?.estadoCivil}}</p>
        </div>

        <div>
            <strong>País Residencia</strong>
            <p class="mrgn-b-xs" *ngIf="isDetail && barrio">{{barrio.pais.nombre}}</p>
            <p class="mrgn-b-xs" *ngIf="!isDetail">{{cliente?.paisResidencia.nombre}}</p>
        </div>

        <div>
            <strong>Departamento Residencia</strong>
            <p class="mrgn-b-xs" *ngIf="isDetail && barrio">{{barrio.departamento.departamento}}</p>
            <p class="mrgn-b-xs" *ngIf="!isDetail">{{cliente?.departamentoResidencia.departamento}}
            </p>
        </div>

        <div>
            <strong>Municipio Residencia</strong>
            <p class="mrgn-b-xs" *ngIf="isDetail && barrio">{{barrio.municipio.municipio}}</p>
            <p class="mrgn-b-xs" *ngIf="!isDetail">{{cliente?.municipioResidencia.municipio}}</p>
        </div>

        <div>
            <strong>Barrio</strong>
            <p class="mrgn-b-xs" *ngIf="isDetail && barrio">{{barrio.barrio.nombre}}</p>
            <p class="mrgn-b-xs" *ngIf="!isDetail">{{cliente?.barrioResidencia.nombre}}</p>
        </div>

        <div>
            <strong>Dirección</strong>
            <p class="mrgn-b-xs">{{cliente?.direccion}}</p>
        </div>

        <div>
            <strong>Email</strong>
            <p class="mrgn-b-sm">{{cliente?.email}}</p>
        </div>

        <div>
            <strong>Celular</strong>
            <p class="mrgn-b-sm">{{cliente?.celular}}</p>
        </div>

        <div>
            <strong>Teléfono</strong>
            <p class="mrgn-b-sm">{{cliente?.telefono}}</p>
        </div>

        <div>
            <strong>Estrato</strong>
            <p class="mrgn-b-sm">{{cliente?.estrato ? cliente?.estrato:'NO REGISTRA'}}</p>
        </div>

        <div>
            <strong>Régimen Salud</strong>
            <p class="mrgn-b-sm">{{cliente?.regimenSalud != null ?
                cliente?.regimenSalud.nombre:'NO REGISTRA'}}</p>
        </div>

    </div>

    <mat-dialog-actions class="w-full">
        <div class="md:flex md:!justify-end items-center flex-col md:flex-row w-full md:w-auto col-span-2">
            <button type="button" class="w-full md:w-auto !m-2 btn-color-volver" mat-raised-button
                (click)="dialogRef.close(false)">
                {{'Cancelar'}}
            </button>
            <button (click)="dialogRef.close(true)" mat-raised-button class="w-full md:w-auto !m-2 accept-button">
                {{'Aceptar'}}
            </button>
        </div>
    </mat-dialog-actions>
</mat-dialog-content>
