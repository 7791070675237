<div class="popup-card-width">
  <div>
    <h2 mat-dialog-title i18n>Información del Descuento Detalle</h2>
  </div>
  <mat-divider class="primary-border mb-4"></mat-divider>
  <mat-dialog-content>
    <div class="flex flex-wrap justify-between">
      <div class="sm:w-[40%] full-wid md:w-[30%]">
        <strong>Fecha:</strong>
      </div>
      <div class="sm:w-[60%] full-wid md:w-[70%]">
        <span>{{
          descuentoDetalle.createdDate
        }}</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-between">
      <div class="sm:w-[40%] full-wid md:w-[30%]">
        <strong>Nombre:</strong>
      </div>
      <div class="sm:w-[60%] full-wid md:w-[70%]">
        <span>{{
          descuentoDetalle.acuerdoPagoDescuento?.nombre
        }}</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-between">
      <div class="sm:w-[40%] full-wid md:w-[30%]">
        <strong>Tipo:</strong>
      </div>
      <div class="sm:w-[60%] full-wid md:w-[70%]">
        <span>{{ descuentoDetalle.acuerdoPagoDescuento?.tipo | spaceUnderscore }}</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-between">
      <div class="sm:w-[40%] full-wid md:w-[30%]">
        <strong>Estado:</strong>
      </div>
      <div class="sm:w-[60%] full-wid md:w-[70%]">
        <span>{{ descuentoDetalle?.estado }}</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-between">
      <div class="sm:w-[40%] full-wid md:w-[30%]">
        <strong>Concepto:</strong>
      </div>
      <div class="sm:w-[60%] full-wid md:w-[70%]">
        <span>{{
          descuentoDetalle.conceptoCuotaPrioridad?.concepto
        }}</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-between">
      <div class="sm:w-[40%] full-wid md:w-[30%]">
        <strong>Aplicación:</strong>
      </div>
      <div class="sm:w-[60%] full-wid md:w-[70%]">
        <span>{{ descuentoDetalle.aplicacion | spaceUnderscore }}</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-between">
      <div class="sm:w-[40%] full-wid md:w-[30%]">
        <strong>Porcentaje:</strong>
      </div>
      <div class="sm:w-[60%] full-wid md:w-[70%]">
        <span>{{ descuentoDetalle.porcentaje }}</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-between">
      <div class="sm:w-[40%] full-wid md:w-[30%]">
        <strong>Descripcion:</strong>
      </div>
      <div class="sm:w-[60%] full-wid md:w-[70%]">
        <span>{{
          descuentoDetalle.acuerdoPagoDescuento?.descripcion
        }}</span>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close()">
      Cerrar
    </button>
  </mat-dialog-actions>
</div>
