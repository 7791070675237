import {ServiceImplService} from '../../service.impl.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import UbicacionGeograficaModel from '../../../models/ubicaciones/ubicacion-geografica.model';
import RegionModel from '../../../models/ubicaciones/region.model';
import {UbicacionGeograficaServiceInterface} from '../ubicacion-geografica.service.interface';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UbicacionGeograficaServiceImpl extends ServiceImplService<UbicacionGeograficaModel>
  implements UbicacionGeograficaServiceInterface {
  constructor(public http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'ubicaciones/';
  }

  getExtended(ubicacionId): Observable<any> {
    const path = `ubicacion-extended/${ubicacionId}`;
    return this.executeGet(path).pipe(map((res: any) => res.data));
  }

  getRegiones(idPais): Observable<RegionModel[]> {
    const path = `${idPais}/regiones`;
    return this.executeGet(path);
  }

}
