<div class="popup-card-width" [style.width.%]="100">
    <div>
        <h2 mat-dialog-title i18n>Información Línea de Crédito</h2>
    </div>
    <mat-divider class="primary-border"></mat-divider>
    <mat-dialog-content *ngIf="planLineaCreditoDetail.lineaCredito" class="py-2">
      <div class="full-wid flex space-between center">
        <div class="md:w-1/3 sm:w-full">
          <strong>Código: </strong>
        </div>
        <div class="md:w-2/3 sm:w-full">
          <span>{{planLineaCreditoDetail.lineaCredito.codigo}}</span>
        </div>
      </div>
      <div class="full-wid flex space-between center">
        <div class="md:w-1/3 sm:w-full">
          <strong>Nombre: </strong>
        </div>
        <div class="md:w-2/3 sm:w-full">
          <span>{{planLineaCreditoDetail.lineaCredito.nombre}}</span>
        </div>
      </div>
      <div class="full-wid flex space-between center">
        <div class="md:w-1/3 sm:w-full">
          <strong>Académico Jamestown: </strong>
        </div>
        <div class="md:w-2/3 sm:w-full">
          <span>{{planLineaCreditoDetail.lineaCredito.fEducativoJamestown ? 'SI' : 'NO'}}</span>
        </div>
      </div>
      <div class="full-wid flex space-between center">
        <div class="md:w-1/3 sm:w-full">
          <strong>Abreviatura Contable: </strong>
        </div>
        <div class="md:w-2/3 sm:w-full">
          <span>{{planLineaCreditoDetail.lineaCredito.abreviaturaContable ?
            planLineaCreditoDetail.lineaCredito.abreviaturaContable : "NO REGISTRA"}}</span>
        </div>
      </div>
      <div class="full-wid flex space-between center">
        <div class="md:w-1/3 sm:w-full">
          <strong>Tasa Interés: </strong>
        </div>
        <div class="md:w-2/3 sm:w-full">
          <span>{{planLineaCreditoDetail.lineaCredito.tasaInteres + "%"}}</span>
        </div>
      </div>
      <div class="full-wid flex space-between center">
        <div class="md:w-1/3 sm:w-full">
          <strong>Cobertura: </strong>
        </div>
        <div class="md:w-2/3 sm:w-full">
          <span>{{planLineaCreditoDetail.lineaCredito.cobertura}}</span>
        </div>
      </div>
      <div class="full-wid flex space-between center">
        <div class="md:w-1/3 sm:w-full">
          <strong>Requisitos: </strong>
        </div>
        <div class="md:w-2/3 sm:w-full">
          <span>{{planLineaCreditoDetail.lineaCredito.requisitos}}</span>
        </div>
      </div>
      <div class="full-wid flex space-between center">
        <div class="md:w-1/3 sm:w-full">
          <strong>Estado: </strong>
        </div>
        <div class="md:w-2/3 sm:w-full">
          <span>{{planLineaCreditoDetail.lineaCredito.estado}}</span>
        </div>
      </div>
      <div class="full-wid flex space-between center">
        <div class="md:w-1/3 sm:w-full">
          <strong>Descripción: </strong>
        </div>
        <div class="md:w-2/3 sm:w-full">
          <span>{{planLineaCreditoDetail.lineaCredito.descripcion}}</span>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
        <button type="button" mat-raised-button (click)="dialogRef.close()">
            Cerrar
        </button>
    </mat-dialog-actions>
</div>
