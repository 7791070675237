import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { TitleCasePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class MyDateAdapter extends NativeDateAdapter {
    
	format(date: Date, displayFormat: Object): string {
        let titlePipe: TitleCasePipe = new TitleCasePipe();
		var formatString = displayFormat === 'input' ? 'MMM DD, YYYY' : 'MMM dd, yyyy';
        return titlePipe.transform(format(date, formatString, { locale: es }));
	}
}
