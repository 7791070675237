import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import ConfiguracionListaSarlaftModel from 'src/app/core/models/configuracion-solicitud-credito/configuracion-lista-sarlaft.model';

@Component({
  selector: 'ef-configuracion-listas-sarlaft-detail',
  templateUrl: './configuracion-listas-sarlaft-detail.component.html',
  styleUrls: ['./configuracion-listas-sarlaft-detail.component.scss']
})
export class ConfiguracionListasSarlaftDetailComponent{

  configListSarlaft: ConfiguracionListaSarlaftModel;

  constructor(
    public dialogRef: MatDialogRef<ConfiguracionListasSarlaftDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.configListSarlaft = data;
  }

}
