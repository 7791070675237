import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import PreguntasMedicas from 'src/app/core/models/configuracion-solicitud-credito/preguntas-medicas.model';

@Component({
  selector: 'ef-preguntas-medicas-detail',
  templateUrl: './preguntas-medicas-detail.component.html',
  styleUrls: ['./preguntas-medicas-detail.component.scss']
})
export class PreguntasMedicasDetailComponent implements OnInit {

  loading = false;
  preguntasMedicasDetail: PreguntasMedicas;

  constructor(
    public dialogRef: MatDialogRef<PreguntasMedicasDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.preguntasMedicasDetail = data;
  }

  ngOnInit(): void {
  }

}
