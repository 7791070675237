import { Component, OnInit } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {WebcamImage, WebcamInitError} from 'ngx-webcam';

@Component({
  selector: 'ef-snapshot-photo',
  templateUrl: './snapshot-photo.component.html',
  styleUrls: ['./snapshot-photo.component.scss']
})
export class SnapshotPhotoComponent  {

  isPhoto = false;

  public errors: WebcamInitError[] = [];

  public videoOptions: MediaTrackConstraints = {
    //width: {ideal: 300},
    //height: {ideal: 320}
  };

  public webcamImage: WebcamImage = null;
  public trigger: Subject<void> = new Subject<void>();

  constructor(public dialogRef: MatDialogRef<SnapshotPhotoComponent>,
              public toast: ToastrService) {
  }

  discardPhoto() {
    this.isPhoto = false;
  }

  savePhoto() {
    this.dialogRef.close(this.webcamImage)
  }

  public triggerSnapshot(): void {
    this.trigger.next();
    this.isPhoto = true;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }



}
