import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServiceImplService } from "../../service.impl.service";
import { SolicitudTipoFirmaServiceInterface } from "../solicitud-tipo-firma-service.interface";
import { SolicitudTipoFirmaModel } from "src/app/core/models/solicitud-credito/solicitud-tipo-firma.model";
import { IdentificarTipoFirmaDto } from "src/app/core/models/solicitud-credito/dtos/identificar-tipo-firma.dto";
import { Observable, map } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class SolicitudTipoFirmaServiceImpl extends ServiceImplService<SolicitudTipoFirmaModel>
  implements SolicitudTipoFirmaServiceInterface {

	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'solicitud-tipo-firma/';
	}

	validarTipoFirmaSolicitud(solicitudId: number): Observable<IdentificarTipoFirmaDto> {
		const path = `${this.getFullPath()}${solicitudId}/identificar-metodo-firma`;
		return this.httpClient.get(path).pipe(map((res: any) => res.data));
	}
}
