import { Component, OnInit, Inject } from '@angular/core';
import { FirmanteServiceImpl } from '../../../../core/http/configuracion-firma-electronica/impl/firmante.service.impl';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PlantillaFirmanteServiceImpl } from 'src/app/core/http/configuracion-firma-electronica/impl/plantilla-firmante.service.impl';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import FirmanteModel from '../../../../core/models/configuracion-firma-electronica/firmante.model';
import { SISTEMA } from '../../../../core/constants/sistemas.contants';

@Component({
  selector: 'ef-gestion-plantillas-firmante-add',
  templateUrl: './gestion-plantillas-firmante-add.component.html',
  styleUrls: ['./gestion-plantillas-firmante-add.component.scss']
})
export class GestionPlantillasFirmanteAddComponent implements OnInit {

  firmantes: Array<any> = [];
	formAdd!: FormGroup;
	loading = false;
	mensajeError:any = false;
	idPlantilla: any= 0;
  listObject: any = [];
  loaded: boolean = false;


  constructor(
		public  firmanteService: FirmanteServiceImpl,
		public  formBuilder: FormBuilder,
		public   dialogRef: MatDialogRef<GestionPlantillasFirmanteAddComponent>,

		public  toasterService: ToastrService,
		public  plantillaFirmanteService: PlantillaFirmanteServiceImpl,
		@Inject(MAT_DIALOG_DATA) public   data: any
	) {
		if (data) {
			this.idPlantilla = data.idPlantilla;
      data.listObject.forEach((el:any) =>{
        if(el.estado == 'ACTIVO'){
          this.listObject.push(el.firmante)}
        }
		)}
	}

  ngOnInit() {
		this.firmantes = this.getFirmantes();
		this.formAdd = this.formBuilder.group({
			firmante: [null, [Validators.required]],
		});
	}

	getFirmantes(): any {
		let firmantes: Array<FirmanteModel> = [];
		let filter = {
			estado: "ACTIVO",
			sistemaId: SISTEMA['EDUFACTORING']
		}
		this.firmanteService.filter(filter).subscribe({
			next: (res:any) => {
				res.data.forEach((element:any) => {
          const isDuplicate = this.listObject.some(item => this.areObjectsEqual(item, element));
          if(!isDuplicate){
            firmantes.push(element);

          }
				});
			},
		});
		return firmantes;
	}

  areObjectsEqual(data1: any, data2: any): boolean {
    return data1.id === data2.id;
  }

	get form() {
		return this.formAdd.controls;
	}

	onFormSubmit() {
		if (navigator.onLine) {
			this.mensajeError = null;
			const newData = Object.assign({}, this.formAdd.value);
			let insert = {
				estado: "ACTIVO",
				idFirmante: newData.firmante.id,
				idPlantilla: Number(this.idPlantilla),
			};

			this.plantillaFirmanteService
				.save(insert)

				.subscribe(
					(data:any) => {
						this.dialogRef.close(data);
					}
				);
		} else {
			this.toasterService.error(
				"Por favor, revisa tu conexión a internet",
				"Error"
			);
		}
	}

}
