import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Auth0ServiceImpl } from '../../../core/http/security/impl/auth0.service.impl';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ef-modal-link-pago',
  templateUrl: './modal-link-pago.component.html',
  styleUrls: ['./modal-link-pago.component.scss']
})
export class ModalLinkPagoComponent implements OnInit {
  @Input() title: string;
  @Input() accept;
  @Input() cancel;
  @Input() isTaquilla = false;
  @Input() isPagoInicial = false;
  @Input() concepto = null;
  isSolicitudCreditoDescuento = false;
  conceptoSeleccionado = null;
  valorSeguro = 0;
  aplicaDescuento = false;
  valorCuotas = 0;
  dataSeguro: any;
  aplicaDescuentoView = false;
  isCuotaInicial = false;
  saldoMinimo;
  saldoTotal;
  isTotal;

  cuotas = [];
  formLP: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModalLinkPagoComponent>,
    public formBuilder: FormBuilder,
    public authenticationService: Auth0ServiceImpl,
    public toast: ToastrService,
    public datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.accept = data.accept;
    this.cancel = data.cancel;
    this.cuotas = data.cuotas;
    this.isTaquilla = data.isTaquilla;
    this.concepto = data.conceptoPago;
    this.isPagoInicial = data.isPagoInicial;
    this.dataSeguro = data;
    this.valorSeguro = data.valorSeguro;
    this.isSolicitudCreditoDescuento = data.isSolicitudCreditoDescuento;
    this.saldoMinimo = data.saldoMinimo;
    this.saldoTotal = data.saldoTotal;
    this.isTotal = data.isTotal;
  }

  ngOnInit(): void {
    this.pagarCuotasSolicitud();
    this.aplicaDescuentoView = this.authenticationService.hasAccess('aplica-descuento-link-pago-admin:view');
    this.subscribeValorSeguro();
  }

  get form() {
    return this.formLP.controls;
  }

  pagarCuotasSolicitud() {
    let valor = 0;
    if (this.isPagoInicial) {
      valor = this.cuotas[0] * this.cuotas.length;
    } else {
      valor = this.obtenerValorCuotas();
    }
    this.valorCuotas = valor;
    let valorMax = this.saldoTotal ? this.saldoTotal : valor;
    this.formLP = this.formBuilder.group({
      currency: ['COP'],
      descripcion: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(250)]],
      motivoDescuento: [null],
      importeImpuestoVat: [0],
      linkPagoConcepto: [this.concepto, Validators.required],
      valorSeguro: ['NO', Validators.required],
      monto: [0, [Validators.required, Validators.min(this.saldoMinimo), Validators.max(valorMax)]],
      valorDescuento: [0],
      planAmortizacionIds: [null],
      fechasPago: [null]
    });
    this.formLP.get('monto').setValue(valor);
    this.formLP.get('planAmortizacionIds').setValue(this.cuotas.map((el) => el.id));
    this.formLP.get('fechasPago').setValue(this.cuotas.map((el) => this.formatDate(el.fechaPago)));
  }

  change(concepto) {
    this.conceptoSeleccionado = concepto;
  }

  subscribeValorSeguro() {
    this.formLP.get('valorSeguro').valueChanges.subscribe({
      next: (valorSeguro) => {
        if (valorSeguro == 'SI') {
          this.valorSeguro = this.dataSeguro.valorSeguro;
        }
        if (valorSeguro == 'NO') {
          this.valorSeguro = 0;
        }
      }
    });
  }


  obtenerValorCuotas() {
    let valor = 0;
    for (let cuota of this.cuotas) {
      if (cuota.numeroCuota == 1) {
        this.isCuotaInicial = true;
      }
      if (this.isTotal != null && !this.isTotal) {
        valor = this.saldoMinimo;
      } else if (this.isTotal != null && this.isTotal) {
        valor = this.saldoTotal;
      }
      if (this.isTotal == null) {
        if (cuota.valorPagado && cuota.valorDescuento) {
          valor += (cuota.valorCuota - cuota.valorPagado - cuota.valorDescuento);
        } else {
          valor += cuota.valorCuota;
        }
      }
    }
    return valor;
  }

  eventToggleChange(event) {
    this.aplicaDescuento = event.checked
    if (this.aplicaDescuento) {
      this.formLP.get('motivoDescuento').setValidators([Validators.required, Validators.minLength(8)]);
      this.formLP.get('valorDescuento').setValidators([Validators.required, Validators.min(1), Validators.max(this.valorCuotas)]);
    } else {
      this.formLP.get('motivoDescuento').clearValidators();
      this.formLP.get('valorDescuento').clearValidators();
    }
  }

  aceptar() {
    if (this.formLP.getRawValue().monto < this.formLP.getRawValue().valorDescuento) {
      this.toast.warning('El valor de la cuota no puede ser inferior al valor del descuento', '! Importante ¡')
      return;
    }

    if (this.formLP.getRawValue().monto < this.valorSeguro) {
      this.toast.warning('El valor de la cuota no puede ser inferior al valor del seguro', '! Importante ¡')
      return;
    }
    let valorTotal = this.formLP.getRawValue().monto + this.valorSeguro - this.formLP.getRawValue().valorDescuento
    this.formLP.get('monto').setValue(valorTotal);
    this.formLP.get('valorSeguro').setValue(this.valorSeguro)
    this.dialogRef.close(this.formLP.value)
  }

  formatDate(date: any): string {
    const fecha = new Date(date[0], date[1] - 1, date[2]);
    const fechaString = this.datePipe.transform(fecha, 'yyyy-MM-dd');
    return fechaString;
  }
}
