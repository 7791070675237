import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PersonaServiceImpl } from 'src/app/core/http/gestion-usuarios/impl/persona.service.impl';
import Persona from 'src/app/core/models/personas/models/persona.model';
import { ColorService } from 'src/app/core/services/color-state.service';

@Component({
	selector: 'ef-link-pago-detail',
	templateUrl: './link-pago-detail.component.html',
	styleUrls: ['./link-pago-detail.component.scss']
})
export class LinkPagoDetailComponent implements OnInit {
	linkPago: any;
	persona: Persona;

	constructor(
		public dialogRef: MatDialogRef<LinkPagoDetailComponent>,
		public personaService: PersonaServiceImpl,
		public toast: ToastrService,
		public color: ColorService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.linkPago = data;
	}

	ngOnInit(): void {
		if (this.linkPago.personaId != null) {
			this.personaService.get(this.linkPago.personaId).subscribe({
				next: (personaResponse) => {
					if (!!personaResponse) {
						this.persona = personaResponse;
					}
				}
			});
		}
	}

	copiarInformacion(info) {
		const textArea = document.createElement('textarea');
		textArea.style.opacity = '0';
		let text = info.paymentUrl;
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		document.execCommand('copy');
		document.body.removeChild(textArea);
		this.toast.success('Se ha copiado al portapapeles');
	}
}
