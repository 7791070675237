<form [formGroup]="formAddUbicacion" novalidate autocomplete="off" class="popup-card-width">
    <div>
      <h2 mat-dialog-title i18n>Verificar Ubicaciones Geograficas</h2>
    </div>
    <mat-dialog-content>

      <div>
        <div class="flex gap-[15px]">
          <div class="flex flex-col">
            <ef-select-pais [label]="'Pais'" [width]="100" [isAll]="true" (onSelectPais)="setPais($event)"
              [isRequired]="true" formControlName="pais">
            </ef-select-pais>
          </div>
        </div>
        <div class="flex gap-[15px]">
          <div class="flex flex-col">
            <ef-select-region [isRequired]="true" [width]="100" [label]="'Region'" (onSelectRegion)="setRegion($event)"
              formControlName="region" [pais]="form.pais.value">
            </ef-select-region>
          </div>
        </div>

        <div class="flex gap-[15px]">
          <div class="flex flex-col">
            <ef-select-departamento [isRequired]="true" [width]="100" [label]="'Departamento'"
              formControlName="departamento" [region]="form.region.value" [pais]="form.pais.value" [flagRegion]="true">
            </ef-select-departamento>
          </div>
        </div>

        <div class="flex gap-[15px]">
          <div class="flex flex-col">
            <ef-select-municipio [isRequired]="true" [width]="100" class="form-control mrgn-b-sm" [label]="'Municipio'"
              formControlName="municipio" [enableAddForm]="form.departamento.value"
              [region]="form.region.value" [pais]="form.pais.value"
              [departamento]="form.departamento.value" [flagRegion]="true">
            </ef-select-municipio>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
      <button type="button" mat-raised-button (click)="dialogRef.close(false)">Cancelar
      </button>
    </mat-dialog-actions>
  </form>
