import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EstadosHelper } from 'src/app/core/helpers/estados.helper';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { GestionPasoServiceImpl } from 'src/app/core/http/configuracion-basica/impl/gestion-pasos.service.impl';
import { PasoProcesoServiceImpl } from 'src/app/core/http/configuracion-solicitud-credito/impl/proceso-paso.service.impl';
import GestionPaso from 'src/app/core/models/configuracion-solicitud-credito/gestion-pasos.model';

@Component({
	selector: 'ef-paso-proceso-add',
	templateUrl: './paso-proceso-add.component.html',
})
export class PasoProcesoAddComponent implements OnInit {
	pasos: Array<GestionPaso>;
	isLoading: boolean = false;
	formConfigPasoProceso!: FormGroup;
	pickedPaso: FormControl = new FormControl();
	ordenList: Array<number>;
	nombrePasosList: Array<string>;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public formBuilder: FormBuilder,
		public pasoServiceImpl: GestionPasoServiceImpl,
		public pasoProcesoServiceImpl: PasoProcesoServiceImpl,
		public dialogRef: MatDialogRef<PasoProcesoAddComponent>,
		public toasterService: ToastrService,
	) {
		this.isLoading = false;
		this.pasos = [];
		this.ordenList = data.ordenList;
		this.nombrePasosList = data.nombrePasosList;
	}

	initForm() {
		this.formConfigPasoProceso = this.formBuilder.group({
			paso: [null, Validators.required],
			orden: [0, [Validators.min(0), Validators.required]],
			estado: [false]
		});
	}

	get form() { return this.formConfigPasoProceso.controls; }

	onFormSubmit() {
		const orden = this.formConfigPasoProceso.get('orden')?.value;
		if (this.ordenList.includes(orden)) {
			this.toasterService.error("Este orden ya existe")
			return;
		}


		const pasoP: any = {
			idPaso: this.formConfigPasoProceso.get('paso')?.value.id,
			orden: this.formConfigPasoProceso.get('orden')?.value,
			idProceso: this.data.proceso.id
		};
		pasoP['estado'] = 'ACTIVO';
		this.pasoProcesoServiceImpl.post(pasoP)
			.subscribe({
				next: (response: any) => {
					this.toasterService.success('Paso asignado correctamente');
					this.dialogRef.close();
				}
			});
	}

	ngOnInit() {
		this.isLoading = true;
		this.initForm();
		this.pasoServiceImpl.getAll()
			.pipe(
				finalize(() => {
					this.isLoading = false
				})
			)
			.subscribe({
				next: pasos => {
					this.pasos = pasos.filter(paso => !!EstadosHelper.isActivo(paso.estado) &&
						!(this.nombrePasosList.includes(paso.nombre)))
				}
			})
	}

}
