import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CargoAdministrativoServiceImpl } from 'src/app/core/http/configuracion-basica/impl/cargo-administrativo.service.impl';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CargoAdministrativoAddComponent } from '../cargo-administrativo-add/cargo-administrativo-add.component';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';

import { ESTADOS } from '../../../../core/constants/estado.constants';
import { PROCESOS_CARGO } from '../../../../core/constants/proceso-cargo.constants';

@Component({
  selector: 'ef-cargo-administrativo-add-complete',
  templateUrl: './cargo-administrativo-add-complete.component.html',
  styleUrls: ['./cargo-administrativo-add-complete.component.scss']
})
export class CargoAdministrativoAddCompleteComponent implements OnInit {

  formAddCargoAdministrativo: FormGroup;
  loading = false;
  mensajeError = false;
  procesos: Array<string> = Object.values(PROCESOS_CARGO);


  constructor(
    public formBuilder: FormBuilder,
    public cargoAdministrativoService: CargoAdministrativoServiceImpl,
    public toast: ToastrService,

    public dialogRef: MatDialogRef<CargoAdministrativoAddComponent>
  ) {
    ;
  }

  ngOnInit() {
    this.formAddCargoAdministrativo = this.formBuilder.group({
      nombre: [
        '',
        [
          Validators.required,
          CustomValidatorsHelper.noWhitespaceValidator
        ]
      ],
      escala: [null, Validators.min(0)],
      proceso: [null, Validators.required],
      flagUserSecurity: [false]
    });
  }

  get form() {
    return this.formAddCargoAdministrativo.controls;
  }

  onFormSubmit() {

    this.mensajeError = null;
    const formValues = Object.assign(
      {},
      this.formAddCargoAdministrativo.value
    );
    formValues.estado = 'ACTIVO';
    this.cargoAdministrativoService
      .save(formValues)
      .subscribe(
        {
          next: cargoAdminvo => {
            ;
            this.dialogRef.close(cargoAdminvo);
          },
          error: error => {
            ;
            try {
              for (const field of error.error.errors) {
                this.toast.error(field.message, 'Error');
              }
            } catch (e) {
            }
          }
        }
      );
  }
}
