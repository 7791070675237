<div class="popup-card-width">
  <div>
    <h2 class="text-center" mat-dialog-title i18n>{{title}}</h2>
  </div>
  <mat-divider class="primary-border mrgn-b-xs"></mat-divider>
  <mat-dialog-content class="pad-t-md">
    <div class="flex justify-around items-center" [innerHTML]="message"></div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button *ngIf="!hideButton" type="button" mat-raised-button (click)="dialogRef.close()" [disabled]="isLoading">
      {{textButton}}
    </button>
    <button *ngIf="aceptarButton" type="button" mat-raised-button (click)="dialogRef.close(true)" [disabled]="isLoading">
      {{'Aceptar'}}
    </button>
  </mat-dialog-actions>
</div>
