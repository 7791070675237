import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TipoDocumentoSarlaftEnum } from 'src/app/core/enums/tipo-documento-sarlaft-enum';
import { FileHelper } from 'src/app/core/helpers/file.helper';
import { ConsultaSarlaftProcessServiceImpl } from '@core/http/historial-consulta-sarlaft/impl/consulta-sarlaft-process.service.impl';
import HistorialConsultaSarlaftModel from 'src/app/core/models/historial-consulta-sarlaft/historial-consulta-sarlaft.model';
import { CustomValidatorsHelper } from '@core/helpers/customValidators.helper';

@Component({
  selector: 'ef-historial-consulta-sarlaft-add',
  templateUrl: './historial-consulta-sarlaft-add.component.html',
  styleUrls: ['./historial-consulta-sarlaft-add.component.scss']
})
export class HistorialConsultaSarlaftAddComponent implements OnInit, OnDestroy {
  formAdd: FormGroup;
  caracteresEscritos: number = 0;
  historialConsultaSarlafts: HistorialConsultaSarlaftModel[];
  loading = false;
  mensajeError = false;
  id: number;
  tipoDocumentos: Array<string> = new Array<string>();
  base64File: string | null = null;
  fileName: string = 'No ha seleccionado ningún archivo';
  @ViewChild('fileInput') fileInput: ElementRef;
  private consultaSubscription: Subscription;
  private onDestroy = new Subject<void>();
  decision = ['APROBADO', 'RECHAZADO'];

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<HistorialConsultaSarlaftAddComponent>,
    private consultaSarlaftProcessService: ConsultaSarlaftProcessServiceImpl,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.formAdd = this.formBuilder.group({
      documento: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      tipoDocumento: [null, [Validators.required]],
      nombre: [null, [Validators.required, Validators.pattern('^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ\\s]+$')]],
      observacion: [null, [CustomValidatorsHelper.noWhitespaceValidator, Validators.minLength(10), Validators.maxLength(1000)]],
      decision: [this.decision[0], [Validators.required]]
    });

    this.tipoDocumentos = this.getTipos();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  getTipos(): Array<string> {
    return Object.keys(TipoDocumentoSarlaftEnum);
  }

  fileChangeEvent(event: any) {
    let file = event.target.files[0];
    if (file) {
      const fileSizeKB = file.size / 1024;
      if (file.type !== 'application/pdf') {
        this.toast.warning('Por favor solo seleccionar archivos en formato PDF', 'Importante');
        this.clearFile();
        return;
      }

      this.fileName = file.name;
      FileHelper.toBase64(file).then((data: string) => {
        this.base64File = data;
      }).catch(error => {
        this.toast.error('Error al convertir el archivo a base64', 'Error');
        this.clearFile();
      });
    }
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  clearFile() {
    this.fileName = 'No ha seleccionado ningún archivo';
    this.base64File = null;
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  calcularCaracteresEscritos(event: Event): void {
    const texto = (event.target as HTMLTextAreaElement).value;
    this.caracteresEscritos = texto.length;
  }

  onFormSubmit() {
    if (!navigator.onLine) {
      this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
      return;
    }

    if (!this.base64File) {
      this.toast.error('Debe seleccionar y cargar un archivo válido', 'Importante');
      return;
    }

    if (this.formAdd.valid && !this.loading) {
      this.loading = true;

      const data = {
        decision: this.formAdd.value.decision,
        documento: this.formAdd.value.documento,
        nombre: this.formAdd.value.nombre,
        report: {
          bucket: "LEGAL_CHECK_RESPONSE_MANUAL",
          certificado: null,
          createdDate: new Date().toISOString(),
          data: null,
          error: true,
          errores: null,
          estadoDocumento: "VIGENTE",
          extension: "pdf",
          fechaConsulta: new Date().toISOString(),
          nombre: this.formAdd.value.nombre,
          numeroIdentificacion: this.formAdd.value.documento,
          rangoEdad: null,
          reporte: String(this.base64File).split('data:application/pdf;base64,')[1],
          status: "finished",
          tiempoRespuesta: 0,
          tipoIdentificacion: this.formAdd.value.tipoDocumento,
          updatedDate: new Date().toISOString()
        },
        tipoDocumento: this.formAdd.value.tipoDocumento,
        observacion: this.formAdd.value.observacion,
        fTercero: true

      };

      this.consultaSubscription = this.consultaSarlaftProcessService.consultaSarlaftManual(data).pipe(
        finalize(() => {
          this.loading = false;
        })
      ).subscribe({
        next: (historialConsultaSarlaft) => {
          this.dialogRef.close(historialConsultaSarlaft);
        },
        error: (error) => {
          this.loading = false;
          if (error.status === 413) {
            this.toast.error('El tamaño del archivo es demasiado grande para ser procesado por el servidor', 'Error 413');
          } else {
            const errorMessage = error.error.message || 'Ha ocurrido un error';
            this.toast.error(errorMessage, 'Error');
          }
        }
      });
    } else {
      this.toast.error('Por favor, completa el formulario correctamente', 'Error');
    }
  }

  cerrarModal() {
    if (!this.loading) {
      this.dialogRef.close();
    }
  }
}
