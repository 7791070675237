<mat-form-field [style.width.%]="width">
  <input [required]="isRequired" [readonly]="isDisabled" type="text" matInput [matAutocomplete]="autocompletePais"
    [formControl]="paisControl" (click)="onTouched()" />
  <mat-placeholder class="placeholder">{{label}}</mat-placeholder>
  <a *ngIf="enableIcon" (click)="copyPais()" matSuffix mat-icon-button matTooltipPosition="above"
    matTooltip="Autocompletar">
    <mat-icon>file_copy</mat-icon>
  </a>
  <a *ngIf="enableAdd" (click)="addNewUbicacionDialog($event)" matSuffix mat-icon-button matTooltipPosition="above"
    matTooltip="Nueva Ubicacion">
    <mat-icon>add_circle</mat-icon>
  </a>
  <mat-autocomplete #autocompletePais="matAutocomplete" (optionSelected)="onPaisChange($event)"
    [displayWith]="displayFn">
    <mat-option *ngFor="let paisItem of filteredPaises | async" [value]="paisItem">
      {{paisItem.nombre}}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="paisControl.errors">
    <mat-error *ngIf="paisControl.errors.required" class="required alert-error">
      <span i18n>Este campo es requerido</span>
    </mat-error>
    <mat-error *ngIf="paisControl.errors.valid" class="required alert-error">
      <span i18n>Seleccione un pais</span>
    </mat-error>
  </mat-error>
</mat-form-field>
<ng-content></ng-content>
