<form [formGroup]="formAddLugar" novalidate autocomplete="off" class="popup-card-width">
  <div>
    <h2 mat-dialog-title i18n>Nuevo Lugar</h2>
  </div>
  <mat-dialog-content>
    <div>
      <div>
        <mat-form-field>
          <mat-placeholder class="placeholder">Tipo</mat-placeholder>
          <mat-select formControlName="tipo" required>
            <mat-option *ngFor="let tipo of tipos" [value]="tipo">
              {{tipo}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <ef-select-pais
          [label]="'Pais'"
          [width]="100"
          [isRequired]="true"
          [enableAdd]="true"
          (ubicacionNew)="setUbicacionNew($event)"
          formControlName="pais">
        </ef-select-pais>

        <ef-select-departamento [width]="100"
                                [isRequired]="true"
                                [label]="'Departamento'"
                                (ubicacionNew)="setUbicacionNew($event)"
                                formControlName="departamento" [enableIcon]="true" [pais]="form.pais.value">
        </ef-select-departamento>

        <ef-select-municipio [width]="100"
                             [isRequired]="true"
                             [pais]="form.pais.value"
                             (ubicacionNew)="setUbicacionNew($event)"
                             [label]="'Municipio Residencia'" formControlName="municipio"
                             [enableIcon]="true" [departamento]="form.departamento.value">
        </ef-select-municipio>

        <ef-select-barrio [width]="100"
                          [isRequired]="true"
                          [label]="'Barrio'"
                          formControlName="barrio" [pais]="form.pais.value"
                          [departamento]="form.departamento.value" [municipio]="form.municipio.value">
        </ef-select-barrio>

        <mat-form-field class="mrgn-b-sm">
          <input matInput type="text" formControlName="direccion" class="form-control space-top">
          <mat-placeholder class="placeholder">Direcci&oacute;n</mat-placeholder>
        </mat-form-field>

        <mat-form-field class="mrgn-b-sm">
          <input matInput type="text" formControlName="nombre" class="form-control space-top">
          <mat-placeholder class="placeholder">Nombre</mat-placeholder>
          <mat-error *ngIf="form.nombre.touched && form.nombre.errors">
            <mat-error *ngIf="form.nombre.errors.required" class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
            <mat-error *ngIf="!form.nombre.errors.required" class="required alert-error">
              <span i18n>{{form.nombre.errors}}</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button (click)="dialogRef.close()" type="button" mat-raised-button>
      Cancelar
    </button>
    <button
      [disabled]="!formAddLugar.valid"
      (click)="guardarLugar()"
      mat-raised-button color="primary">
      Crear
    </button>
  </mat-dialog-actions>
</form>
