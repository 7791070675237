import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { Observable, map } from 'rxjs';
import { ServiceImplService } from '../../service.impl.service';
import { Injectable } from '@angular/core';
import { ProcesoInterface } from '../proceso.service.interface';
import ProcesoModel from 'src/app/core/models/configuracion-solicitud-credito/proceso.models';

@Injectable({
  providedIn: 'root',
})
export class ProcesoServiceImpl
  extends ServiceImplService<ProcesoModel>
  implements ProcesoInterface
{
  constructor(private http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'proceso/';
  }


  getFilter(filter: any): Observable<any> {
		filter = _.omitBy(filter, [ _.isUndefined, _.isNull, _.isEmpty ]);
		return this.httpClient
			.get(this.getFullPath() , { params: filter })
			.pipe(map((res: any) => res));
	}

  getExtended(id: string): Observable<any> {
	  const path = `${id}/pasos-opciones`;
    return this.httpClient.get(this.getFullPath() + path).pipe(map((res: any) => res.data));
  }

}
