<div class="popup-card-width">
  <div class="gene-card-title">
    <div class="flex flex-row justify-start items-center">
      <div class="flex flex-col">
        <h3>{{ 'Cargar Documentos Faltantes' }}</h3>
      </div>
      <span class="flex-grow"></span>
      <mat-card-actions class="mat-pad-none margin-none">
        <button mat-raised-button mat-button-sm color="primary" (click)="addItem()">
          <span i18n>Agregar Archivo</span>
          <mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon>
        </button>
      </mat-card-actions>
    </div>
    <mat-divider></mat-divider>
  </div>

  <form [formGroup]="formAddFiles" novalidate autocomplete="off">
    <mat-dialog-content class="pad-all-md">
      <div formArrayName="files" *ngFor="let item of files.controls; let i = index;">
        <div [formGroupName]="i">

          <div class="flex flex-row justify-around items-center space-x-4 py-1">

            <div class="flex flex-col w-3/10">
              <mat-form-field class="pad-t-aux">
                <mat-label class="placeholder">Tipo Documento</mat-label>
                <mat-select formControlName="tipoDocumento" required>
                  <mat-option *ngFor="let item of tipos" [value]="item">
                    {{item | spaceUnderscore}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="flex flex-col w-1/4">
              <mat-form-field>
                <textarea matInput autocomplete="off" cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="3"
                  maxlength="1000" formControlName="descripcion"></textarea>
                <mat-label class="placeholder">Descripción</mat-label>
              </mat-form-field>
            </div>

            <div class="flex flex-col w-2/5">
              <input type="file" accept="application/pdf" (change)='fileChangeEvent($event, item)' />
            </div>

            <div class="flex flex-col w-1/20">
              <a (click)="remove(i)" matSuffix mat-icon-button>
                <mat-icon>delete</mat-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </form>

  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close(false)">Cancelar
    </button>
    <button (click)="onFormSubmit()" type="submit" [disabled]="!formAddFiles.valid" mat-raised-button
      color="primary">Crear
    </button>
  </mat-dialog-actions>
</div>
