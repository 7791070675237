import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ESTADOS_BASE } from 'src/app/core/constants/estados.constants';
import OpcionVistaModel from 'src/app/core/models/configuracion-solicitud-credito/opcion-vista.models';
import { OpcionVistaServiceImpl } from 'src/app/core/http/configuracion-solicitud-credito/impl/opcion-vista.service.impl';
import OpcionModel from 'src/app/core/models/configuracion-solicitud-credito/opcion.models';


@Component({
  selector: 'ef-opcion-vista-edit',
  templateUrl: './opcion-vista-edit.component.html',
  styleUrls: ['./opcion-vista-edit.component.scss'],
})

export class OpcionVistaEditComponent implements OnInit {
  formEdit: FormGroup;
  loading = false;
  mensajeError = false;
  opcionVista: OpcionVistaModel;
  opciones: OpcionModel[];
  rutaExistentes: string[];
  ordenExistentes: number[];
  rutaActual: string;
  ordenActual: number;
  caracteresEscritos: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<OpcionVistaEditComponent>,
    private toast: ToastrService,
    private opcionVistaService: OpcionVistaServiceImpl,
    @Inject(MAT_DIALOG_DATA) public data: OpcionVistaModel,
  ) {
    this.opcionVista = data;
    this.rutaActual = this.opcionVista.rutaPath;
    this.ordenActual = this.opcionVista.orden;

  }

  onFormSubmit() {
    if (navigator.onLine) {
      this.mensajeError = null;
      const opcionVistaNuevo = Object.assign({}, this.formEdit.value);
      opcionVistaNuevo.estado = ESTADOS_BASE.ACTIVO;
      const rutaActual = this.rutaExistentes.includes(opcionVistaNuevo.rutaPath.toLowerCase())
      const ordenActual = this.ordenExistentes.includes(opcionVistaNuevo.orden)
      if (rutaActual) {
        this.toast.error('Esta ruta ya esta en uso');
        return;
      }

      if (ordenActual) {
        this.toast.error('Este orden ya esta en uso');
        return;
      }
      this.opcionVistaService
        .update(String(this.opcionVista.id), opcionVistaNuevo)
        .subscribe(
          {
            next: (opcionVista: string) => {
              this.dialogRef.close(opcionVista);
            },
            error: (error) => {
              try {
                for (const field of error.error.errors) {
                  this.toast.error(field.message, 'Error');
                }
              } catch (e) {
                this.mensajeError = error.error.message;
              }
            }
          }
        );
    } else {
      this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
    }
  }



  ngOnInit(): void {
    this.formEdit = this.formBuilder.group({
      orden: [this.opcionVista.orden, [Validators.required]],
      rutaPath: [this.opcionVista.rutaPath, [Validators.required, Validators.pattern(/^\S.*\S$/)]],
      descripcion: [this.opcionVista.descripcion, [Validators.required, Validators.minLength(10), Validators.maxLength(500), Validators.pattern(/^\S.*\S$/)]],
      estado: [this.opcionVista.estado],
      id: [this.opcionVista.id, [Validators.required]],
      idOpcion: [this.opcionVista.idOpcion, [Validators.required]],
    });

    this.calcularCaracteresEscritos(this.opcionVista.descripcion);




    this.initForm();
    this.opcionVistaService.filter({
      estado: ESTADOS_BASE.ACTIVO,
      idOpcion: this.opcionVista.idOpcion
    })
      .subscribe({
        next: (rest) => {
          this.rutaExistentes = rest.data.map(el => el.rutaPath.toLowerCase());
          this.rutaExistentes = this.rutaExistentes.filter(el => el != this.rutaActual.toLowerCase())
          this.ordenExistentes = rest.data.map(el => el.orden);
          this.ordenExistentes = this.ordenExistentes.filter(el => el != this.ordenActual)
        }
      })
  }

  initForm() {
    this.loading = false;
  }

  get form() {
    return this.formEdit.controls;
  }

  calcularCaracteresEscritos(texto: string): void {
    this.caracteresEscritos = texto.length;
  }

  calcularCaracteresEscritosInput(event: Event): void {
    const texto = (event.target as HTMLTextAreaElement).value;
    this.calcularCaracteresEscritos(texto);
  }


}
