import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FirmanteServiceImpl } from '../../../../core/http/configuracion-firma-electronica/impl/firmante.service.impl';
import { SISTEMA } from '../../../../core/constants/sistemas.contants';

@Component({
	selector: 'jt-matricula-especial-add',
	templateUrl: './gestion-firmantes-add.component.html',
	styleUrls: ['./gestion-firmantes-add.component.scss']
})
export class GestionFirmantesAddComponent implements OnInit {
	formAddFirmante!: FormGroup;

	constructor(
		public formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<GestionFirmantesAddComponent>,
		public firmanteService: FirmanteServiceImpl
	) {
	}

	ngOnInit() {
		this.formAddFirmante = this.formBuilder.group({
			nombre: [null, [Validators.required]],
			estado: ['ACTIVO'],
			sistemaId: [SISTEMA['EDUFACTORING']]
		});
	}

	get form() {
		return this.formAddFirmante.controls;
	}

	onFormSubmit() {
		const firmanteNuevo = this.formAddFirmante.getRawValue();
		this.firmanteService
			.save(firmanteNuevo)
			.subscribe({
				next: (firmante) => this.dialogRef.close(firmante)
			});
	}
}
