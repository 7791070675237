import { LOCAL_STORAGE_CONSTANTS } from '../constants/local-storage.constants';
import { LocalStorageHelper } from './local-storage.helper';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class SecurityHelper {
	public static REGEX_REMOVE_PROTOCOL = /^https?:\/\//;

	public whitelist: Array<string>;

	public blacklist: Array<string>;

	constructor(public localStorageHelper: LocalStorageHelper) {
		const whitelist = environment.apiUrl.replace(SecurityHelper.REGEX_REMOVE_PROTOCOL, '');

		if (whitelist) {
			this.whitelist = [whitelist.split('/')[0]];
			this.blacklist = [whitelist.concat(environment.loginPath)];
			console.log(this.whitelist);
			console.log(this.blacklist);

		}
	}

	public getToken() {
		const token = this.localStorageHelper.getItem(LOCAL_STORAGE_CONSTANTS.TOKEN);
		return token ? token.replace(/^"(.*)"$/, '$1') : undefined;
	}

	public getWhitelist() {
		return this.whitelist;
	}

	public getBlackList() {
		return this.blacklist;
	}
}
