import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import ConceptoPrioridad from 'src/app/core/models/acuerdo-pago/concepto-cuota-prioridad.model';

@Component({
  selector: 'ef-concepto-cuota-prioridad-detail',
  templateUrl: './concepto-cuota-prioridad-detail.component.html',
  styleUrls: ['./concepto-cuota-prioridad-detail.component.scss']
})
export class ConceptoCuotaPrioridadDetailComponent implements OnInit {

  conceptoPrioridad: ConceptoPrioridad

  constructor(
    public dialogRef: MatDialogRef<ConceptoCuotaPrioridadDetailComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.conceptoPrioridad = data;
  }

  ngOnInit(): void {
    
  }

}
