<mat-card>
  <mat-card-header class="flex flex-col justify-center items-center" >
    <mat-card-title>
      {{'Cargar Documentos' | uppercase}}{{nombreCompleto!=null?' - '+nombreCompleto:''}}
    </mat-card-title>
    <mat-divider></mat-divider>
  </mat-card-header>
  <mat-card-content>
    <div class="mt-2 flex flex-col justify-center items-end">
      <button class="flex justify-center items-center" mat-fab color="primary" (click)="addItem()"
        matTooltip="Agregar Nuevo archivo">
        <mat-icon>note_add</mat-icon>
      </button>
    </div>
    <form [formGroup]="formAddFiles" novalidate autocomplete="off">
      <div formArrayName="files" *ngFor="let item of files.controls; let i = index;">
        <div [formGroupName]="i">
          <mat-divider *ngIf="i > 0"></mat-divider>
          <div class="flex flex-wrap">
            <div class="w-1/2">
              <mat-form-field class="w-full">
                <mat-label>Tipo Documento</mat-label>
                <mat-select formControlName="tipoDocumento" required>
                  <mat-option *ngFor="let item of tipos" [value]="item">
                    {{item | spaceUnderscore}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          
            <div class="w-full">
              <mat-form-field class="w-full">
                <textarea matInput autocomplete="off" cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="3"
                  maxlength="1000" formControlName="descripcion"></textarea>
                <mat-label>Descripción</mat-label>
              </mat-form-field>
            </div>
          
            <div class="w-5/6 text-center">
              <input type="file" accept="application/pdf" (change)='fileChangeEvent($event, item)' />
            </div>
          
            <div class="w-1/6 text-center">
              <a (click)="remove(i)" matSuffix mat-icon-button>
                <mat-icon>delete</mat-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
