import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import ConfiguracionListaSarlaftModel from 'src/app/core/models/configuracion-solicitud-credito/configuracion-lista-sarlaft.model';
import { ServiceImplService } from '../../service.impl.service';
import { ConfiguracionListaSarlaftInterface } from '../config-list-sarlaft.service.interface';

@Injectable({
  providedIn: 'root',
})
export class ConfiguracionListaSarlafServiceImpl
  extends ServiceImplService<ConfiguracionListaSarlaftModel>
  implements ConfiguracionListaSarlaftInterface
{
  constructor(private http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'config-lista-sarlaft/';
  }
 
}
