<div class="popup-card-width">
    <div>
        <h2 mat-dialog-title i18n>Información de Configuración Sarlaft</h2>
    </div>
    <mat-divider class="mb-4 primary-border"></mat-divider>
    <mat-dialog-content *ngIf="configListSarlaft">
        <div class="grid md:grid-cols-4">
            <strong class="">
                Nombre:
            </strong>
            <span class="col-span-3">
                {{ configListSarlaft.nombre }}
            </span>
        </div>
        <div class="grid md:grid-cols-4">
            <strong>
                Codigo:
            </strong>
            <span class="col-span-3">
                {{ configListSarlaft.codigo }}
            </span>
        </div>
        <div class="grid md:grid-cols-4">
            <strong>
                Estado:
            </strong>
            <span class="col-span-3">
                {{ configListSarlaft.estado }}
            </span>
        </div>
        <div class="grid md:grid-cols-4">
            <strong>
                Rechazo:
            </strong>
            <span class="col-span-3">
                {{ configListSarlaft.fRechazo ? 'SI': 'NO' }}
            </span>
        </div>
        <div class="grid md:grid-cols-4">
            <strong>
                Pendiente:
            </strong>
            <span class="col-span-3">
                {{ configListSarlaft.fPendiente ? 'SI': 'NO' }}
            </span>
        </div>
        <div class="grid md:grid-cols-4">
            <strong>
                Descripcion:
            </strong>
            <span class="col-span-3">
                {{ configListSarlaft.descripcion }}
            </span>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
        <button type="button" mat-raised-button (click)="dialogRef.close()">
            Cerrar
        </button>
    </mat-dialog-actions>
</div>