import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/main/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'security',
    loadChildren: () => import('./security/security.module').then((m) => m.SecurityModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./new_base/base.module').then((m) => m.BaseModule),
  },
  {
    path: '**',
    redirectTo: '/main/dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    //  { preloadingStrategy: PreloadAllModules }
     )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
