<form [formGroup]="formAdd" novalidate autocomplete="off" class="popup-card-width">
    <h2 mat-dialog-title i18n>Nuevo Proceso</h2>
    <mat-dialog-content>
        <div>
            <div class="grid md:grid-cols-2 gap-2">
                <mat-form-field>
                    <mat-label class="placeholder">Nombre</mat-label>
                    <input matInput formControlName="nombre" required />
                    <mat-error *ngIf="formAdd.get('nombre').touched && formAdd.get('nombre').errors">
                        <mat-error *ngIf="formAdd.get('nombre').errors.required" class="required alert-error">
                            <span i18n>Este campo es requerido</span>
                        </mat-error>
                        <mat-error *ngIf="form.nombre.touched && form.nombre.errors?.pattern">
                            Este campo no puede estar vacío o contener solo espacios en blanco.
                        </mat-error> 
                    </mat-error>
                </mat-form-field>
                <div>
                    <mat-form-field appearance="legacy" class="pad-top-aux full-wid">
                        <mat-label class="placeholder">Aplica</mat-label>
                        <mat-select formControlName="aplica" required>
                            <mat-option *ngFor="let item of aplicaEnum" [value]="item">
                                {{ item }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formAdd.get('aplica').touched && formAdd.get('aplica').errors">
                            <mat-error *ngIf="formAdd.get('aplica').errors.required" class="required alert-error">
                                <span i18n>Este campo es requerido</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <mat-form-field class="mt-2">
                <textarea matInput formControlName="descripcion"  (input)="calcularCaracteresEscritos($event)" > </textarea>
                <p class="mb-0">{{ caracteresEscritos }}/500</p>
                <mat-label class="placeholder">Descripción</mat-label>
                <mat-error *ngIf="formAdd.get('descripcion').touched && formAdd.get('descripcion').errors">
                
                    <mat-error *ngIf="formAdd.get('descripcion').errors.required" class="required alert-error">
                        <span i18n>Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="form.descripcion.touched && form.descripcion.errors?.pattern">
                        Este campo no puede estar vacío o contener solo espacios en blanco.
                    </mat-error>
                    <mat-error *ngIf="formAdd.get('descripcion').errors && formAdd.get('descripcion').errors.hasOwnProperty('maxlength')">
                        <span i18n>La descripción debe tener máximo 500 caracteres.</span>
                    </mat-error>
                    <mat-error
                        *ngIf="formAdd.get('descripcion').errors && formAdd.get('descripcion').errors.hasOwnProperty('minlength')">
                        <span i18n>La descripción debe tener minimo 10 caracteres.</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none">
        <div class="text-right">
            <button type="button" mat-raised-button (click)="dialogRef.close()">
                Cancelar
            </button>
            <button (click)="onFormSubmit()" [disabled]="!formAdd.valid" mat-raised-button
                class="accept-button margin-none">
                Crear
            </button>
        </div>
    </mat-dialog-actions>
</form>
