<form [formGroup]="formEdit" novalidate autocomplete="off" class="popup-card-width">
    <div>
        <h2 mat-dialog-title i18n>Editar Tipo Documento</h2>
    </div>
    <mat-dialog-content>
        <div>
            <div class="flex flex-col">
                <mat-form-field>
                    <mat-label class="placeholder">Nombre</mat-label>
                    <input matInput formControlName="nombre">
                    <mat-error *ngIf="form.nombre.touched && form.nombre.errors">
                        <mat-error *ngIf="form.nombre.errors.required" class="required alert-error">
                            <span i18n>Este campo es requerido</span>
                        </mat-error>
                        <mat-error *ngIf="!form.nombre.errors.required" class="required alert-error">
                            <span i18n>{{form.nombre.errors}}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label class="placeholder">Descripción</mat-label>
                    <textarea matInput type="text" formControlName="descripcion" class="form-control"
                    placeholder="Descripción" (input)="calcularCaracteresEscritos($event)">
                </textarea>
                <p class="mb-0">{{ caracteresEscritos }}/500</p>
                    <mat-error *ngIf="form.descripcion.touched && form.descripcion.errors">
                        <mat-error *ngIf="form.descripcion.errors.required" class="required alert-error">
                            <span i18n>Este campo es requerido</span>
                        </mat-error>
                        <mat-error *ngIf="!form.descripcion.errors.required" class="required alert-error">
                            <span i18n>{{form.descripcion.errors}}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="mrgn-b-xs pad-b-none">
        <div class="flex justify-end items-end">
            <button type="button" mat-raised-button (click)="dialogRef.close()">Cancelar
            </button>
            <button (click)="onFormSubmit()" [disabled]="!formEdit.valid" mat-raised-button class="accept-button">Actualizar
            </button>
        </div>
    </mat-dialog-actions>
</form>
