import { ESTADOS } from '../constants/estado.constants';

export class EstadosHelper {
	public static isActivo(estado: string) {
		return estado === ESTADOS.ACTIVO;
	}

	public static getEstadoFromBoolean(booleanValue: boolean) {
		return booleanValue ? ESTADOS.ACTIVO : ESTADOS.INACTIVO;
	}

	public static getSiNoFromBoolean(booleanValue: boolean) {
		return booleanValue ? 'SI' : 'NO';
	}
}
