<div class="popup-card-width">
  <div>
    <h2 mat-dialog-title i18n>Información de tipo de contratación</h2>
  </div>
  <mat-divider class="primary-border"></mat-divider>
  <mat-dialog-content *ngIf="cargoAdministrativoDetail" class="py-2">
    <div class="flex flex-wrap space-between">
      <div class="sm:w-[40%] xs:w-full md:w-[30%]">
        <strong>Nombre:</strong>
      </div>
      <div class="sm:w-[60%] xs:w-full md:w-[70%]">
        <span>{{ cargoAdministrativoDetail.nombre }}</span>
      </div>
    </div>
    <div class="flex flex-wrap space-between">
      <div class="sm:w-[40%] xs:w-full md:w-[30%]">
        <strong>Escala:</strong>
      </div>
      <div class="sm:w-[60%] xs:w-full md:w-[70%]">
        <span>{{ cargoAdministrativoDetail.escala }}</span>
      </div>
    </div>
    <div class="flex flex-wrap space-between">
      <div class="sm:w-[40%] xs:w-full md:w-[30%]">
        <strong>Proceso:</strong>
      </div>
      <div class="sm:w-[60%] xs:w-full md:w-[70%]">
        <span>{{ cargoAdministrativoDetail.proceso }}</span>
      </div>
    </div>
    <div class="flex flex-wrap space-between">
      <div class="sm:w-[40%] xs:w-full md:w-[30%]">
        <strong>Usuario Auth0:</strong>
      </div>
      <div class="sm:w-[60%] xs:w-full md:w-[70%]">
        <mat-icon *ngIf="cargoAdministrativoDetail.flagUserSecurity"
        >check</mat-icon
        >
        <mat-icon *ngIf="!cargoAdministrativoDetail.flagUserSecurity"
        >close</mat-icon
        >
      </div>
    </div>
    <div class="flex flex-wrap space-between">
      <div class="sm:w-[40%] xs:w-full md:w-[30%]">
        <strong>Estado:</strong>
      </div>
      <div class="sm:w-[60%] xs:w-full md:w-[70%]">
        <span>{{ cargoAdministrativoDetail.estado }}</span>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button
      type="button"
      mat-raised-button
      (click)="dialogRef.close()">
      Cerrar
    </button>
  </mat-dialog-actions>
</div>
