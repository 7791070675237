<perfect-scrollbar>
  <div class="popup-card-width">
    <mat-dialog-content>
      <mat-card class="mat-card">
        <mat-card-title>
          Detalle Link de Pago
        </mat-card-title>
        <div class="full-wid">
          <div>
            <p>
              <strong>Estado: </strong><span
                class="{{color.getColor(linkPago.estadoLinkPago)}} status-label">{{linkPago.estadoLinkPago}}</span>
            </p>
            <p *ngIf="linkPago.estadoLinkPago === 'RECHAZADO'">
              <strong>Motivo Rechazo: </strong> {{linkPago.motivoRechazo != null ? linkPago.motivoRechazo :
              'NO REGISTRA'}}
            <p>
          </div>

          <div class="flex gap-2">
            <p class="md:w-[45%] sm:w-full">
              <strong>Moneda: </strong>{{linkPago.orderCurrency}}
            </p>
            <p class="md:w-[45%] sm:w-full">
              <strong>Valor: </strong>{{linkPago.orderAmount | currency:'COP':'symbol':'1.0-0'}}
            </p>
          </div>

          <div>
            <p>
              <strong>Copiar Link de Pago</strong>
              <button class="mrgn-all-xs" mat-raised-button mat-icon-button mat-button-sm matTooltip="Copiar"
                matTooltipPosition="above" (click)="copiarInformacion(linkPago)">
                <mat-icon>content_copy</mat-icon>
              </button>
            </p>
          </div>
          <div>
            <p>
              <strong>Descripción: </strong>{{linkPago.orderDescription | spaceUnderscore}}
            </p>
          </div>
        </div>
      </mat-card>

      <mat-card *ngIf="persona">
        <mat-card-title>
          Información de la Persona
        </mat-card-title>
        <div class="full-wid">
          <div>
            <p>
              <strong>Nombre: </strong>{{persona.nombreCompleto}}
            </p>
          </div>
          <div>
            <p>
              <strong>Número de Documento: </strong>{{persona.numeroDocumento}}
            </p>
          </div>
          <div>
            <p>
              <strong>Correo Electrónico: </strong>{{persona.email}}
            </p>
          </div>
        </div>
      </mat-card>

    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-all-xs text-right">
      <button mat-raised-button [mat-dialog-close] cdkFocusInitial>Cerrar</button>
    </mat-dialog-actions>
  </div>
</perfect-scrollbar>
