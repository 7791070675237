import {DepartamentoServiceInterface} from '../departamento.service.interface';
import {ServiceImplService} from '../../service.impl.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PaisServiceInterface} from '../pais.service.interface';
import PaisModel from '../../../models/ubicaciones/pais.model';
import {Observable} from 'rxjs';
import BarrioModel from '../../../models/ubicaciones/barrio.model';
import MunicipioModel from '../../../models/ubicaciones/municipio.model';
import DepartamentoModel from '../../../models/ubicaciones/departamento.model';


@Injectable({
  providedIn: 'root'
})
export class PaisServiceImpl extends ServiceImplService<PaisModel> implements PaisServiceInterface {
  constructor(public http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'pais/';
  }

  getPaisesActivos(): Observable<any> {
    const path = `paises-activos`;
    return this.executeGet(path);
  }

  getDepartamentos(idPais): Observable<any> {
    const path = `${idPais}/departamentos`;
    return this.executeGet(path);
  }

}
