import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { SessionCheckerHelper } from 'src/app/core/helpers/sessionChecker.helper';

@Directive({
	selector: '[efUserActivityChecker]'
})
export class UserActivityCheckerDirective {
	constructor(public sessionChecker: SessionCheckerHelper) {}

	@HostListener('window:mousemove', [ '$event' ])
	onMove(ev: MouseEvent) {
		this.sessionChecker.clearInactivity();
	}

	@HostListener('window:keypress', [ '$event' ])
	onKeyPress(ev: KeyboardEvent) {
		this.sessionChecker.clearInactivity();
	}

	@HostListener('window:click')
	onClick() {
		this.sessionChecker.clearInactivity();
	}
}
