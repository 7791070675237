export enum RazonesSolicitudAprobacionManualRechazadaEnum {

  Documentos_Faltantes_incompletos_o_no_validados = 'Documentos_Faltantes_incompletos_o_no_validados',
  Edad_no_contemplada = 'Edad_no_contemplada',
  No_tiene_paz_y_salvo_de_las_obligaciones = 'No_tiene_paz_y_salvo_de_las_obligaciones',
  No_está_laboralmente_ACTIVO = 'No_está_laboralmente_ACTIVO',
  Cliente_con_cuentas_embargadas = 'Cliente_con_cuentas_embargadas',
  Falsedad_en_la_documentación = 'Falsedad_en_la_documentación',
  Disentimiento_del_Cliente = 'Disentimiento_del_Cliente'
}
