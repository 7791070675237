import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Login} from '../../../models/security/login.model';
import {ServiceImplService} from '../../service.impl.service';
import {ISecurityUserService} from '../security-user.service.interface';
import {map} from 'rxjs/operators';

@Injectable()
export class SecurityUserServiceImpl extends ServiceImplService<Login> implements ISecurityUserService {
  constructor(public http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'security-user-auth/';
  }

  verPermisos(username) {
    return this.httpClient
      .get(`${this.getFullPath()}usuario/permisos/${username}`)
      .pipe(map((res: any) => res.data));
  }

  asignarPermisos(body) {
  }
}
