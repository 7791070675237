import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import { ConfiguracionListaSarlafServiceImpl } from 'src/app/core/http/configuracion-solicitud-credito/impl/configuracion-lista-sarlaft.service.impl';
import ConfiguracionListaSarlaftModel from 'src/app/core/models/configuracion-solicitud-credito/configuracion-lista-sarlaft.model';

@Component({
  selector: 'ef-configuracion-listas-sarlaft-edit',
  templateUrl: './configuracion-listas-sarlaft-edit.component.html',
  styleUrls: ['./configuracion-listas-sarlaft-edit.component.scss']
})
export class ConfiguracionListasSarlaftEditComponent implements OnInit {

  formEditSarlaf: FormGroup;
  loading: boolean;
  configSarlaft: ConfiguracionListaSarlaftModel;
  mensajeError = false;
  estados = ['ACTIVO', 'INACTIVO']
  caracteresEscritos: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ConfiguracionListasSarlaftEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastrService,
    public configListaSarlaftService: ConfiguracionListaSarlafServiceImpl,
  ) {
    this.configSarlaft = data;
   }

  ngOnInit(): void {
    this.loading = false;
    this.formEditSarlaf = this.formBuilder.group({
      nombre: [this.configSarlaft.nombre, [Validators.required, CustomValidatorsHelper.noWhitespaceValidator]],
      codigo: [this.configSarlaft.codigo, [Validators.maxLength(50)]],
      descripcion: [this.configSarlaft.descripcion, [Validators.required, Validators.minLength(10), Validators.maxLength(500)]],
      fPendiente: [this.configSarlaft.fPendiente],
      fRechazo: [this.configSarlaft.fRechazo]
    });
  }

  get form() {
    return this.formEditSarlaf.controls;
  }

  onFormSubmit() {
    
    this.mensajeError = null;
    const formValue = Object.assign(
      {},
      this.formEditSarlaf.value
    );
    const dataEdit = {
      descripcion: formValue.descripcion,
      nombre: formValue.nombre,
      codigo: formValue.codigo,
      estado: this.configSarlaft.estado,
      fRechazo: formValue.fRechazo,
      fPendiente: formValue.fPendiente
    }
    this.configListaSarlaftService
      .update(String(this.configSarlaft.id), dataEdit)
      .subscribe(
        {
          next: response => {
            ;
            this.dialogRef.close(response)
          },
          error: error => {
            ;
            try {
              for (const field of error.error.errors) {
                this.toast.error(field.message, 'Error');
              }
            } catch (e) {
              this.mensajeError = e.error.message;
            }
          }
        }
      );
  }

  calcularCaracteresEscritos(event: Event): void {
    const texto = (event.target as HTMLTextAreaElement).value;
    this.caracteresEscritos = texto.length;
  }
}
