import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
 
import { MatDialogRef } from '@angular/material/dialog'; 
import { SolicitudCreditoServiceImpl } from 'src/app/core/http/solicitud-credito/impl/solicitud-credito.service.impl';

@Component({
	selector: 'ef-modal-validar-contrato-academico',
	templateUrl: './modal-validar-contrato-academico.component.html',
	styleUrls: [ './modal-validar-contrato-academico.component.scss' ]
})
export class ModalValidarContratoAcademicoComponent implements OnInit {
	numeroContratoControl = new FormControl(null, [ Validators.required ]);

	constructor(
		public formBuilder: FormBuilder,
		public toast: ToastrService,
		
		public solicitudCreditoService: SolicitudCreditoServiceImpl,
		public dialogRef: MatDialogRef<ModalValidarContratoAcademicoComponent>
	) {}

	ngOnInit(): void {}

	onFormSubmit() {
		
		this.solicitudCreditoService.getContratoAcademico(this.numeroContratoControl.value, 'PENDIENTE').subscribe(
			(res) => {
				;
				if (res != null) {
					this.dialogRef.close(res);
				} else {
					this.dialogRef.close(null);
					this.toast.warning(
						'No existe ningun contrato academico en ESTADO PENDIENTE con ese numero',
						'Importante'
					);
				}
			},
			() => {
				;
				this.toast.error('Ocurrio un error al intentar validar el numero de contraro academico', 'Error');
				this.dialogRef.close(false);
			}
		);
	}
}
