import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PreguntasMedicasServiceImpl } from 'src/app/core/http/configuracion-solicitud-credito/impl/preguntas-medicas.service.impl';

@Component({
  selector: 'ef-preguntas-medicas-add',
  templateUrl: './preguntas-medicas-add.component.html',
  styleUrls: ['./preguntas-medicas-add.component.scss']
})
export class PreguntasMedicasAddComponent implements OnInit {

  formAdd: FormGroup;
  loading = false;
  mensajeError = false;
  fRechazoAutomatico = false;

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PreguntasMedicasAddComponent>,
    public PreguntasMedicasService: PreguntasMedicasServiceImpl,
    public toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.formAdd = this.formBuilder.group({
      pregunta: [null, [Validators.required]],
      orden: [null, [Validators.required]],
      fRechazoAutomatico: [false]
    });
  }

  get form() {
    return this.formAdd.controls;
  }

  eventToggleChange(event) {
    this.fRechazoAutomatico = event.checked;
  }
  onFormSubmit() {
    if (navigator.onLine) {
      this.mensajeError = null;
      const PreguntasMedicasNuevo = Object.assign({}, this.formAdd.value);
      PreguntasMedicasNuevo.estado = 'ACTIVO';
      this.PreguntasMedicasService.save(PreguntasMedicasNuevo).subscribe(
        {
          next: (PreguntasMedicas) => {
            this.dialogRef.close(PreguntasMedicas);
          },
          error: (error) => {
            try {
              for (const field of error.error.errors) {
                this.toast.error(field.message, 'Error');
              }
            } catch (e) {
              this.mensajeError = error.error.message;
            }
          }
        }
      );
    } else {
      this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
    }
  }

}
