<div class="popup-card-width">
<div class="gene-card-title">
  <div class="flex justify-center">
    <div>
      <h2 class="placeholder">Configuracion Personal para Notificaciones</h2>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<br>

<mat-dialog-content>
  <div class="full-wid">
    <div class="text-center">
      <strong>Proceso</strong>
      <p>{{proceso | spaceUnderscore}}</p>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-content>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let item  of items">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <strong class="text-color">{{item.persona.nombreCompleto + ' - ' + item.nombreRegion}}</strong>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="flex gap-4">
          <div class="w-[30%]">
            <div class="pad-photo">
              <img class="photo-persona" [src]="getFotoCliente(item)" alt="">
            </div>
          </div>

          <div class="pad-y-lg w-[60%]">
            <p mat-line class="text-aux"><strong>Nombre Completo: </strong>{{item.persona.nombreCompleto}}</p>
            <p mat-line class="text-aux"><strong>Email: </strong>{{item.persona.email}}</p>
            <p mat-line class="text-aux"><strong>Celular: </strong>{{item.persona.celular}}</p>
            <p mat-line class="text-aux"><strong>Region: </strong>{{item.nombreRegion}}</p>

            <div class="text-right">
              <a  (click)="remove(item)"
                  class="text-left"
                  matTooltipPosition="above"
                  matTooltip="Eliminar"
                  matSuffix mat-icon-button>
                <mat-icon>delete</mat-icon>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>
<mat-dialog-actions class="mrgn-b-xs pad-b-none text-center">
  <button (click)="dialogRef.close(null)"
          mat-raised-button
          color="primary">Aceptar
  </button>
</mat-dialog-actions>
</div>
