import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { PreguntasMedicasService } from '../preguntas-medicas-service.interface';

@Injectable({
	providedIn: 'root'
})
export class PreguntasMedicasServiceImpl extends ServiceImplService<any> implements PreguntasMedicasService {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'preguntas-medicas/';
	}

}
