import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'ef-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss']
})
export class ModalInfoComponent implements OnInit {
  isLoading: boolean;
  title: string;
  message: string;
  textButton: string;
  tipoHorario: boolean;
  hideButton: boolean;
  aceptarButton: boolean;

  constructor(public dialogRef: MatDialogRef<ModalInfoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.isLoading = false;
    const { title, message, textButton } = data;
    this.title = title;
    this.message = message;
    this.textButton = textButton || 'Cerrar';
    this.hideButton = data.hideButton;
    this.aceptarButton = data.aceptarButton;
  }

  ngOnInit() {}
}
