import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConsultaSarlaftProcessServiceImpl } from '@core/http/historial-consulta-sarlaft/impl/consulta-sarlaft-process.service.impl';
import HistorialConsultaSarlaftModel from '@core/models/historial-consulta-sarlaft/historial-consulta-sarlaft.model';
import { ToastrService } from 'ngx-toastr';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import { SpaceUnderscorePipe } from 'src/app/core/pipes/space-underscore.pipe';
import { ModalAprobarRechazarSolicitudComponent } from 'src/app/modules/gestion-de-solicitud/gestion-aprobacion-manual/gestion-solicitud-aprobacion-manual/modal-aprobar-rechazar-solicitud/modal-aprobar-rechazar-solicitud.component';

@Component({
  selector: 'ef-modal-aprobar-rechazar-consulta-sarlaft',
  templateUrl: './modal-aprobar-rechazar-consulta-sarlaft.component.html',
  styleUrls: ['./modal-aprobar-rechazar-consulta-sarlaft.component.scss']
})
export class ModalAprobarRechazarConsultaSarlaftComponent implements OnInit {

  formAdd: FormGroup;
  loading = false;
  mensajeError = false;
  isAprobar = false;
  caracteresEscritos: number = 0;
  consultaSarlaft: HistorialConsultaSarlaftModel;

  constructor(
    public formBuilder: FormBuilder,
    public toast: ToastrService,
    public pipeService: SpaceUnderscorePipe,
    private consultaSarlaftProcessService: ConsultaSarlaftProcessServiceImpl,
    public dialogRef: MatDialogRef<ModalAprobarRechazarSolicitudComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.consultaSarlaft = data;
    this.isAprobar = data.action;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.formAdd = this.formBuilder.group({
      observacion: ['', [CustomValidatorsHelper.noWhitespaceValidator, Validators.minLength(10), Validators.maxLength(1000)]],
    });
  }

  get form() {
    return this.formAdd.controls;
  }

  onFormSubmit() {
    const formValue = this.formAdd.value;
    let insertRequest = {
      observacion: formValue.observacion,
      historialConsultaSarlaftId: this.consultaSarlaft.id
    };
    if (this.isAprobar) {
      this.consultaSarlaftProcessService.putAprobarConsulta(insertRequest)
        .subscribe(
          {
            next: (res) => {
              this.dialogRef.close(true);
            },
            error: (error) => {
              this.toast.error('Ocurrido un error al intentar aprobar la solicitud', 'Error');
              try {
                for (let field of error.error.errors) {
                  this.toast.error(field.message, field.field);
                }
              } catch (e) {
              }
            }
          });

    } else {
      this.consultaSarlaftProcessService.putRechazarConsulta(insertRequest)
        .subscribe(
          {
            next: (res) => {
              this.dialogRef.close(true);
            },
            error: (error: HttpErrorResponse) => {
              this.toast.error('Ha ocurrido un error al intentar rechazar la solicitud', 'Error');
              try {
                for (let field of error.error.errors) {
                  this.toast.error(field.message, field.field);
                }
              } catch (e) {
              }
            }
          });
    }

  }

  calcularCaracteresEscritos(event: Event): void {
    const texto = (event.target as HTMLTextAreaElement).value;
    this.caracteresEscritos = texto.length;
  }

}
