import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import MotorConsultaModel from 'src/app/core/models/configuracion-solicitud-credito/motor-consulta.models';

@Component({
  selector: 'ef-motor-consulta-detail',
  templateUrl: './motor-consulta-detail.component.html',
  styleUrls: ['./motor-consulta-detail.component.scss']
})
export class MotorConsultaDetailComponent implements OnInit {

  loading = false;
  motorConsultaDetail: MotorConsultaModel;

  constructor(
    public dialogRef: MatDialogRef<MotorConsultaDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.motorConsultaDetail = data;
  }

  ngOnInit(): void { 
  }

}
