import {Injectable} from '@angular/core';
import {IBarrioService} from '../barrio.service.interface';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ServiceImplService} from '../../service.impl.service';
import BarrioModel from '../../../models/ubicaciones/barrio.model';


@Injectable()
export class BarrioServiceImpl extends ServiceImplService<BarrioModel> implements IBarrioService {

  constructor(public http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'barrios/';
  }

  getBarrioMunicipioDepartamento(idBarrio): Observable<any> {
    const path = `${idBarrio}/barrioExtended`;
    return this.executeGet(path);
  }

}
