import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LugarServiceImpl } from 'src/app/core/http/ubicaciones/impl/lugar.service.impl';
import { ToastrService } from 'ngx-toastr';
import Lugar from 'src/app/core/models/ubicaciones/lugar.model';
import { TipoLugar } from 'src/app/core/enums/tipo-lugar.enum';

import { UbicacionGeograficaServiceImpl } from 'src/app/core/http/ubicaciones/impl/ubicacion-geografica.service.impl';

@Component({
  selector: 'ef-lugar-add',
  templateUrl: './lugar-add.component.html',
  styleUrls: ['./lugar-add.component.scss']
})
export class LugarAddComponent implements OnInit {

  tipos: Array<string> = [];

  formAddLugar: FormGroup;

  loading = false;

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<LugarAddComponent>,
    public ubicacionService: UbicacionGeograficaServiceImpl,
    public lugarService: LugarServiceImpl,
    
    public toasterService: ToastrService) {
  }

  ngOnInit() {

    this.tipos = this.getTiposLugar();
    this.formAddLugar = this.formBuilder.group({
      tipo: [''],
      pais: [null, [Validators.required]],
      departamento: [null, [Validators.required]],
      municipio: [null, [Validators.required]],
      nombre: ['', Validators.required],
      barrio: [null, [Validators.required]],
      direccion: ['']
    });

  }

  get form() {
    return this.formAddLugar.controls;
  }

  guardarLugar() {
    const formValues = Object.assign({}, this.formAddLugar.value);
    const lugarNuevo = <Lugar>{
      tipo: formValues.tipo,
      ubicacionGeograficaId: formValues.municipio.idUbicacion,
      nombre: formValues.nombre,
      estado: 'ACTIVO',
      barrioId: formValues.barrio.id,
      direccion: formValues.direccion
    };
    
    this.lugarService.save(lugarNuevo)
      .subscribe(
        {
          next: (lugar) => {
            ;
            this.dialogRef.close(lugar)
          },
          error: (error) => {
            ;
            try {
              for (let field of error.error.errors) {
                this.toasterService.error(field.message, 'Error')
              }
            } catch (e) {
            }
          }
        });
  }

  getTiposLugar(): Array<string> {
    let tiposLugar = new Array<string>();
    for (let tipo in TipoLugar) {
      tiposLugar.push(TipoLugar[tipo])
    }
    return tiposLugar;
  }

  setUbicacionNew(ubicacionId) {
    
    this.ubicacionService.getExtended(ubicacionId)
      .subscribe(
        {
          next: (res: any) => {
            ;
            this.formAddLugar.get('pais').setValue(res.pais);
            setTimeout(() => {
              this.formAddLugar.get('departamento').setValue(res.departamento);
              this.formAddLugar.get('municipio').setValue(res.municipio);
            }, 500);
          }
        }
      );

  }

}
