<form [formGroup]="formEditCargoAdministrativo" novalidate autocomplete="off" class="popup-card-width">
  <div class="flex flex-col items-center">
    <h1 mat-dialog-title i18n>Editar Cargo Administrativo</h1>
  </div>
  <mat-dialog-content>
    <div class="grid md:grid-cols-2 gap-2">
      <div class="full-wid">
        <div class="full-wid">
          <mat-form-field class="mrgn-b-sm">
            <input matInput type="text" formControlName="nombre" class="form-control" placeholder="Nombre" />
            <mat-error *ngIf="form.nombre.touched && form.nombre.errors">
              <mat-error *ngIf="form.nombre.errors.required" class="required alert-error">
                <span i18n>Este campo es requerido</span>
              </mat-error>
              <mat-error *ngIf="!form.nombre.errors.required" class="required alert-error">
                <span i18n>{{ form.nombre.errors }}</span>
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div>
        <div class="full-wid">
          <mat-form-field class="margin-selects">
            <mat-label class="placeholder" required>Proceso</mat-label>
            <mat-select formControlName="proceso" required>
              <mat-option *ngFor="let type of procesos" [value]="type">
                {{ type }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.proceso.errors">
              <mat-error *ngIf="form.proceso.errors.required" class="required alert-error">
                <span i18n>Este campo es requerido</span>
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="grid md:grid-cols-3 mt-4">
      <div class="full-wid">
        <mat-form-field class="mrgn-b-sm">
          <input matInput type="number" formControlName="escala" class="form-control" placeholder="Escala" />
          <mat-error *ngIf="form.escala.touched && form.escala.errors">
            <mat-error *ngIf="form.escala.errors.min" class="required alert-error">
              <span i18n>La escala debe ser mayor a cero</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex flex-col items-center">
        <div>Usuario Auth0</div>
        <div>
          <mat-slide-toggle formControlName="flagUserSecurity">
            {{ form.flagUserSecurity.value ? 'SI':'NO' }}
          </mat-slide-toggle>
        </div>
      </div>
      <div class="flex flex-col items-center">
        <div>{{ 'Estado' }}</div>
        <div>
          <mat-slide-toggle matInput formControlName="estado">
            {{ form.estado.value ? 'ACTIVO':'INACTIVO' }}
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="text-right mt-4">
    <button type="button" mat-raised-button (click)="dialogRef.close()">
      Cerrar
    </button>
    <button (click)="onFormSubmit()" type="submit" [disabled]="!formEditCargoAdministrativo.valid || loading"
      mat-raised-button color="primary">
      Guardar
    </button>
  </mat-dialog-actions>
</form>