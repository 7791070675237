<form [formGroup]="formEdit" novalidate autocomplete="off" class="popup-card-width">
    <h2 mat-dialog-title i18n>Editar Línea Crédito</h2>
    <mat-dialog-content>
      <div class="full-wid mrgn-b-xs">

        <div class="full-wid flex gap-3">
          <div class="md:w-[35%] xs:w-full">
            <mat-form-field>
              <mat-label class="placeholder">Código</mat-label>
              <input type="text" matInput formControlName="codigo" required />
              <mat-error *ngIf="form.codigo.touched && form.codigo.errors">
                <mat-error *ngIf="form.codigo.errors.required" class="required alert-error">
                  <span i18n>Este campo es requerido</span>
                </mat-error>
                <mat-error *ngIf="!form.codigo.errors.required" class="required alert-error">
                  <span i18n>{{ form.codigo.errors }}</span>
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="md:w-[60%] xs:w-full">
            <mat-form-field>
              <mat-label class="placeholder">Nombre</mat-label>
              <input matInput formControlName="nombre" required />
              <mat-error *ngIf="form.nombre.touched && form.nombre.errors">
                <mat-error *ngIf="form.nombre.errors.required" class="required alert-error">
                  <span i18n>Este campo es requerido</span>
                </mat-error>
                <mat-error *ngIf="!form.nombre.errors.required" class="required alert-error">
                  <span i18n>{{ form.nombre.errors }}</span>
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="full-wid flex gap-3">
          <div class="md:w-[35%] xs:w-full">
            <mat-form-field class="mrgn-t-md">
              <mat-label class="placeholder">Tasa de Interés</mat-label>
              <input type="number" matInput formControlName="tasaInteres" min="0" max="100" required />
              <mat-icon matSuffix>%</mat-icon>
              <mat-error *ngIf="form.tasaInteres.touched && form.tasaInteres.errors">
                <mat-error *ngIf="form.tasaInteres.errors.required" class="required alert-error">
                  <span i18n>Este campo es requerido</span>
                </mat-error>
                <mat-error *ngIf="form.tasaInteres.errors.max" class="required alert-error">
                  <span i18n>Este campo no debe ser mayor a 100</span>
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="md:w-[60%] xs:w-full">
            <mat-form-field class="mrgn-t-md">
              <mat-label class="placeholder">Cobertura</mat-label>
              <input matInput formControlName="cobertura" required />
              <mat-error *ngIf="form.cobertura.touched && form.cobertura.errors">
                <mat-error *ngIf="form.cobertura.errors.required" class="required alert-error">
                  <span i18n>Este campo es requerido</span>
                </mat-error>
                <mat-error *ngIf="!form.cobertura.errors.required" class="required alert-error">
                  <span i18n>{{ form.cobertura.errors }}</span>
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-form-field class="mrgn-t-md">
          <textarea matInput autocomplete="off" cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="6"
            maxlength="1000" formControlName="requisitos" required></textarea>
          <mat-label class="placeholder">Requisitos</mat-label>
          <mat-error *ngIf="form.requisitos.touched && form.requisitos.errors">
            <mat-error *ngIf="form.requisitos.errors.required" class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="mrgn-t-md">
          <textarea matInput autocomplete="off" cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="6"
            maxlength="1000" formControlName="descripcion" required></textarea>
          <mat-label class="placeholder">Descripción</mat-label>
          <mat-error *ngIf="form.descripcion.touched && form.descripcion.errors">
            <mat-error *ngIf="form.descripcion.errors.required" class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
        <div class="full-wid flex gap-3">
          <div class="flex flex-col items-center md:w-[35%] xs:w-full">
              <p class="placeholder dialog-label-size">Educativo EasyHub</p>
              <mat-slide-toggle formControlName="fEducativoJamestown" (change)="eventToggleChange($event)">
                {{form.fEducativoJamestown.value ? 'SI' : 'NO'}}
              </mat-slide-toggle>
          </div>
          <div class="mrgn-t-md md:w-[60%] xs:w-full">
            <mat-form-field class="full-wid" *ngIf="form.fEducativoJamestown.value">
              <mat-label class="placeholder">Empresa</mat-label>
              <mat-select formControlName="empresa" required>
                <mat-option *ngFor="let type of empresas" [value]="type">
                  {{ type | spaceUnderscore}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="mrgn-t-md">
          <mat-form-field class="full-wid" *ngIf="form.fEducativoJamestown.value && form.empresa.value == 'BL GROUP SAS'">
            <mat-label class="placeholder">Abreviatura Contable</mat-label>
            <mat-select formControlName="abreviaturaContable" required>
              <mat-option *ngFor="let type of abreviaturasContables" [value]="type">
                {{ type }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.abreviaturaContable.errors">
              <mat-error *ngIf="form.abreviaturaContable.errors.required" class="required alert-error">
                <span i18n>Este campo es requerido</span>
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>

      </div>
    </mat-dialog-content>

    <mat-dialog-actions class="mrgn-b-xs pad-b-none">
        <div class="text-right">
            <button type="button" mat-raised-button (click)="dialogRef.close()">
                Cancelar
            </button>
            <button (click)="onFormSubmit()" [disabled]="!formEdit.valid" mat-raised-button class="accept-button">
                Actualizar
            </button>
        </div>
    </mat-dialog-actions>
</form>
