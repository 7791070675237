<div>
  <h2 mat-dialog-title
      i18n>Tomar fotografía</h2>
</div>

<mat-dialog-content>
  <div>
    <div *ngIf="errors.length>0">
      <h4>Error Messages:</h4>
      <ul *ngFor="let error of errors">
        <li>{{error | json}}</li>
      </ul>
    </div>
    <div class="content-camara">
      <webcam *ngIf="!isPhoto"
              [trigger]="triggerObservable"
              [height]="320"
              [videoOptions]="videoOptions"
              (imageCapture)="handleImage($event)"
              [allowCameraSwitch]="true"
              [imageQuality]="1"
              [mirrorImage]="'never'"
              (initError)="handleInitError($event)">
      </webcam>
      <img *ngIf="isPhoto" [src]="webcamImage.imageAsDataUrl"/>
    </div>
  </div>
</mat-dialog-content>
<div class="mrgn-all-xs btn-select" *ngIf="!isPhoto">
  <button mat-raised-button
          mat-button-sm
          matTooltipPosition="above"
          color="warn"
          (click)="dialogRef.close()">
    <span i18n>Cancelar</span>

    <mat-icon class="gene-text-xl">delete</mat-icon>
  </button>
  <button mat-raised-button
          mat-button-sm
          color="primary"
          class="btn-confirmar"
          (click)="triggerSnapshot();">
    <span i18n>Capturar</span>
    <mat-icon class="gene-text-xl">photo_camera</mat-icon>
  </button>
</div>
<div class="mrgn-all-xs btn-select" *ngIf="isPhoto">
  <button
    mat-raised-button
    mat-button-sm
    matTooltipPosition="above"
    color="warn"
    (click)="discardPhoto();">
    <span i18n>Descartar</span>
    <mat-icon class="gene-text-xl">clear</mat-icon>
  </button>
  <button mat-raised-button
          mat-button-sm
          matTooltipPosition="above"
          color="primary"
          class="btn-confirmar"
          (click)="savePhoto();">
    <span i18n>Guardar</span>
    <mat-icon class="gene-text-xl">save</mat-icon>
  </button>
</div>





