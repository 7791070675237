import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';

import { NgxSpinnerModule } from 'ngx-spinner';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { SecurityHelper } from './core/helpers/security.helper';

@NgModule({
	declarations: [ AppComponent ],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		HttpClientModule,
		NgxSpinnerModule,
		ToastrModule.forRoot(),
		JwtModule.forRoot({
			jwtOptionsProvider: {
				provide: JWT_OPTIONS,
				useFactory: (securityHelper: SecurityHelper) => {
					return {
						tokenGetter: () => {
							return securityHelper.getToken();
						},
						whitelistedDomains: securityHelper.getWhitelist(),
						blacklistedRoutes: securityHelper.getBlackList()
					};
				},
				deps: [SecurityHelper]
			}
		}),
		CoreModule
	],
	providers: [],
	bootstrap: [ AppComponent ],
	schemas: [],
})
export class AppModule {

}
