<div class="popup-card-width" [ngClass]="{'expanding-class': showContent2, 'initial-class': showContent1}">
	<h2 mat-dialog-title i18n *ngIf="showContent1">¿Necesitas soporte?</h2>
    <mat-divider class="primary-border" *ngIf="showContent1"></mat-divider>
	<mat-dialog-content *ngIf="showContent1">
		<p>Estás a punto de reservar una cita de soporte con el personal de la <b>Dirección Nacional de Sistemas.</b></p>
		<p>Por favor, ten en cuenta lo siguiente:</p>
		<ul>
			<li>Las citas de soporte tienen una duración de <b>30 minutos.</b></li>
			<li>Debes reservar la cita con al menos <b>2 horas</b> de anticipación.</li>
		</ul>
		<p>¡Recuerda elegir el personal que mejor se adapte a tus necesidades! Estamos listos para ayudarte en lo que requieras.</p>
    </mat-dialog-content>

	<mat-dialog-content *ngIf="showContent2">
		<embed [src]="url | safeVideo" style="width: 100%; height: 100%;">
	</mat-dialog-content>

	<mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
        <button type="button" mat-raised-button class="close-button" (click)="dialogRef.close()">
            Cerrar
        </button>
		<button type="button" mat-raised-button class="continue-button" *ngIf="showContent1" (click)="continue()">
            Continuar
        </button>
    </mat-dialog-actions>


</div>
