import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SolicitudAprobacionManualServiceImpl } from 'src/app/core/http/solicitud-aprobacion-manual/impl/solicitud-aprobacion-manual.service.impl';

@Component({
  selector: 'ef-modal-envio-notificacion',
  templateUrl: './modal-envio-notificacion.component.html',
  styleUrls: ['./modal-envio-notificacion.component.scss']
})
export class ModalEnvioNotificacionComponent implements OnInit {

  formAddEnvioMail: FormGroup;
  loading = false;
  mensajeError = false;

  solicitudAprobhacionManualId: any;
  cliente: any;


  constructor(
    public formBuilder: FormBuilder,
    public toast: ToastrService,
    
    public solicitudAprobacionManual: SolicitudAprobacionManualServiceImpl,
    public dialogRef: MatDialogRef<ModalEnvioNotificacionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.solicitudAprobhacionManualId = data.solicitudAprobhacionManualId;
    this.cliente = data.cliente;
  }

  ngOnInit(): void {
    this.formAddEnvioMail = this.formBuilder.group({
      asunto: ['', Validators.required],
      mensaje: ['', Validators.required]
    });
  }

  get form() {
    return this.formAddEnvioMail.controls;
  }

  onFormSubmit() {
    
    this.mensajeError = null;
    const formValues = this.formAddEnvioMail.value;
    const insert = {
      asunto: formValues.asunto,
      mensaje: formValues.mensaje,
      solicitudAprobacionManualId: this.solicitudAprobhacionManualId
    };
    this.solicitudAprobacionManual.envioMail(insert)
      .subscribe(
        {
          next: (res) => {
            ;
            this.dialogRef.close(res);
          },
          error: (error) => {
            ;
            this.toast.error('Ocurrido un error al enviar la notificacion', 'Error');
            try {
              for (let field of error.error.errors) {
                this.toast.error(field.message, field.field);
              }
            } catch (e) {
            }
          }
        }
      );
  }

}
