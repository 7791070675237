import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
// import { ToastrService } from 'ngx-toastr';
import { LoadingSpinnerService } from '../services/loading-spinner.service';

@Injectable()
export class LoaderSpinnerInterceptor implements HttpInterceptor {
  private _unsubscribe = new Subject<void>();

  constructor(
    private loaderService: LoadingSpinnerService,
  ) {



  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Se detecta si se debe omitir el spinner
    const skipSpinnerHeader = request.headers.get('Skip-Spinner');
    if (skipSpinnerHeader && skipSpinnerHeader === 'true') {
      request = request.clone({
        // Se elimina el header para que no genere problema de CORS
        headers: request.headers.delete('Skip-Spinner')
      });
      return next.handle(request);
    }
    setTimeout(() => {
      this.loaderService.isLoading.next(this.loaderService.isLoading.getValue() + 1);
    }, 10);

    // Peticiones
    return next.handle(request).pipe(
      map((event: any) => {
        return event;
      }),
      finalize(() => {
        this._unsubscribe.next();
        this._unsubscribe.complete();
        setTimeout(() => {
          this.loaderService.isLoading.next(this.loaderService.isLoading.getValue() - 1);
        }, 1);
      })
    );
  }
}
