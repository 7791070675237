<div novalidate>
    <div class="flex justify-center">
        <div class="flex flex-col">
            <h2 class="placeholder">{{isAprobar ? 'Aprobar Consulta' : 'Rechazar Consulta'}}</h2>
        </div>
    </div>
    <mat-divider class="mb-2"></mat-divider>
    <div class="mb-4">
        <p class="text-center mx-5">¿Está seguro que desea <strong>{{isAprobar ? 'Aprobar' : 'Rechazar'}}</strong>
            la consulta sarlaft <br> del usuario con número de documento <strong>{{consultaSarlaft.documento}}</strong>?
        </p>
    </div>
    <mat-dialog-content>
        <div class="flex flex-col mt-5">
            <form action="" [formGroup]="formAdd">
                <div class="flex w-full">
                    <mat-form-field class="mrgn-b-sm w-full">
                        <textarea matInput type="text" formControlName="observacion" class="form-control"
                            placeholder="Observación" (input)="calcularCaracteresEscritos($event)">
                    </textarea>
                        <mat-error *ngIf="form.observacion.touched && form.observacion.errors">
                            <mat-error *ngIf="form.observacion.errors.required" class="required alert-error">
                                <span i18n>Este campo es requerido</span>
                            </mat-error>
                            <mat-error
                                *ngIf="formAdd.get('observacion').errors && formAdd.get('observacion').errors.hasOwnProperty('maxlength')">
                                <span i18n>La descripción debe tener como máximo 1000 caracteres.</span>
                            </mat-error>
                            <mat-error
                                *ngIf="formAdd.get('observacion').errors && formAdd.get('observacion').errors.hasOwnProperty('minlength')">
                                <span i18n>La descripción debe tener como minimo 10 caracteres.</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <p class="mb-4 flex justify-end text-sm">{{ caracteresEscritos }}/1000</p>
            </form>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right mb-4">
        <button type="button" mat-raised-button (click)="dialogRef.close()">Cancelar
        </button>
        <button [disabled]="formAdd.invalid" type="submit" mat-raised-button color="primary"
            (click)="onFormSubmit()">{{isAprobar ? 'Aprobar' : 'Rechazar'}}
        </button>
    </mat-dialog-actions>
</div>