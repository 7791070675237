<form [formGroup]="formAdd" novalidate autocomplete="off" class="popup-card-width">
    <h2 mat-dialog-title i18n>Asociar Nueva Línea de Crédito</h2>
    <mat-dialog-content>
        <div class="flex flex-wrap">
            <div class="mx-auto flex justify-between items-start" id="helpEducativoJamestown">
                <mat-checkbox formControlName="fEducativoJamestown" class="mx-1">
                    ¿Es crédito educativo Jamestown?
                </mat-checkbox>
                <mat-icon matTooltip="¿Qué es este campo?" [style.cursor]="'pointer'" class="mx-1"
                    (click)="fieldExplanation(0, '#helpEducativoJamestown')">help</mat-icon>
            </div>
            <mat-form-field class="w-full mt-3 mb-0">
                <mat-label class="placeholder" required>Línea de Crédito</mat-label>
                <mat-select formControlName="lineaCredito" required>
                    <mat-option *ngFor="let type of lineasCreditos" [value]="type">
                        {{ type.nombre | titlecase }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.lineaCredito.errors">
                    <mat-error *ngIf="form.lineaCredito.errors.required" class="required alert-error">
                        <span i18n>Este campo es requerido</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div *ngIf="showDetalle" class="mt-3 flex flex-wrap border border-gray-300 rounded-sm pb-2 mx-1">
            <h3 class="w-full text-white py-2 text-center mb-2" style="background-color: #8e8e8e;" i18n>
                Detalle de la Línea de Crédito
            </h3>
            <div class="w-1/2 flex flex-wrap">
                <span class="w-full font-bold text-center">Código</span>
                <span class="w-full text-center">{{lineaCreditoDetalle.codigo}}</span>
            </div>
            <div class="w-1/2 flex flex-wrap">
                <span class="w-full font-bold text-center">Nombre</span>
                <span class="w-full text-center">{{lineaCreditoDetalle.nombre | titlecase}}</span>
            </div>
            <div class="w-1/2 flex flex-wrap mt-2">
                <span class="w-full font-bold text-center">Tasa de Interés</span>
                <span class="w-full text-center">{{lineaCreditoDetalle.tasaInteres + "%"}}</span>
            </div>
            <div class="w-1/2 flex flex-wrap mt-2">
                <span class="w-full font-bold text-center">Educativo Jamestown</span>
                <span class="w-full text-center">{{lineaCreditoDetalle.fEducativoJamestown ? "Si" : "No"}}</span>
            </div>
            <div class="w-full mt-2 flex flex-wrap" *ngIf="abreviatura != null">
                <span class="w-full font-bold text-center">Abreviatura Contable</span>
                <span class="w-full text-center">{{abreviatura}}</span>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="mt-3 mb-1 mx-0 pad-b-none">
        <div class="text-right">
            <button type="button" mat-raised-button (click)="dialogRef.close()">
                Cancelar
            </button>
            <button (click)="onFormSubmit()" [disabled]="!formAdd.valid" mat-raised-button class="accept-button mx-0">
                Asociar
            </button>
        </div>
    </mat-dialog-actions>
</form>
