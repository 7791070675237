import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanActivateChild} from '@angular/router';
import {ROUTES_CONST} from '../constants/routes.constants';
import {Auth0ServiceImpl} from "../http/security/impl/auth0.service.impl";
import {LocalStorageHelper} from "../helpers/local-storage.helper";
import {Observable} from "rxjs";
import {LOCAL_STORAGE_CONSTANTS} from "../constants/local-storage.constants";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    @Inject(Auth0ServiceImpl)
    public autenticationService: Auth0ServiceImpl,
    public localStorageHelper: LocalStorageHelper,
    public router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let canActivate = this.autenticationService.isValidSession();
    if (!canActivate) {
      this.localStorageHelper.addItem(LOCAL_STORAGE_CONSTANTS.REDIRECT_URL, state.url);
      this.router.navigate([ROUTES_CONST.LOGIN.path]);
    }
    if (next.data.action) {
      const actionMenu = next.data.action;
      canActivate = this.autenticationService.hasAccess(actionMenu);
      if (!canActivate) {
        this.router.navigate([ROUTES_CONST.FORBIDDEN.path]);
      }
    }
    return canActivate;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
