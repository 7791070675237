import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { format } from 'date-fns';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SolicitudCreditoServiceImpl } from 'src/app/core/http/solicitud-credito/impl/solicitud-credito.service.impl';
import { ToastrService } from 'ngx-toastr';

import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MyDateAdapter } from 'src/app/core/util/my-date-adapter.util';
import { MY_DATE_FORMATS } from 'src/app/core/constants/my-date-formats.constants';

@Component({
  selector: 'ef-modal-edit-fecha-primer-cuota',
  templateUrl: './modal-edit-fecha-primer-cuota.component.html',
  styleUrls: ['./modal-edit-fecha-primer-cuota.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MyDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_DATE_FORMATS
    }
  ]
})
export class ModalEditFechaPrimerCuotaComponent implements OnInit {

  solicitud: any;
  maxDate;
  fechaControl = new FormControl(null, Validators.required);

  constructor(public dialogRef: MatDialogRef<ModalEditFechaPrimerCuotaComponent>,
    public solicitudCreditoService: SolicitudCreditoServiceImpl,
    public toast: ToastrService,
    
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.solicitud = data.solicitud;
  }

  ngOnInit() {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 90);
  }

  onFormSubmit() {
    let bean = {
      fecha: format(this.fechaControl.value, 'yyyy-MM-dd'),
      solicitudId: this.solicitud.id
    };

    

    this.solicitudCreditoService.editarFechaPrimeraCuota(bean)
      .subscribe(
        {
          next: (res) => {
            ;
            this.dialogRef.close();
            this.toast.success('La Fecha de la primera cuota ha sido actualizada');
          },
          error: (err) => {
            ;
            if (err.error.errors) {
              for (const error of err.error.errors) {
                this.toast.error(error.message, 'Error');
              }
            } else {
              if (err.error.message) {
                this.toast.error(err.error.message, 'Error');
              }
            }
          }
        }
      );
  }
}
