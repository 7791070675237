import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';

import { Auth0ServiceImpl } from './http/security/impl/auth0.service.impl';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LocalStorageHelper } from 'src/app/core/helpers/local-storage.helper';
import { SecurityHelper } from './helpers/security.helper';
import { DialogService } from './services/dialog.service';
import { MatDialogModule } from '@angular/material/dialog';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ErrorHandlerInterceptor } from './interceptors/error-handler.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { CustomValidatorsHelper } from './helpers/customValidators.helper';
import { EstadosHelper } from 'src/app/core/helpers/estados.helper';
import { SessionCheckerHelper } from './helpers/sessionChecker.helper';
import { BarrioServiceImpl } from './http/ubicaciones/impl/barrio.service.impl';
import { MatButtonModule } from '@angular/material/button';
import { SecurityRolServiceImpl } from './http/security/impl/security-rol.service.impl';
import { SecurityUserServiceImpl } from './http/security/impl/security-user.service.impl';
import { SecurityPermissionServiceImpl } from './http/security/impl/security-permissions.service.impl';
import { SecurityRolPermisoServiceImpl } from './http/security/impl/security-rol-permiso.service.impl';
import { SecurityUserRolServiceImpl } from './http/security/impl/security-user-rol.service.impl copy';
import { SharedModule } from '../shared/shared.module';
import { ClienteServiceImpl } from './http/gestion-usuarios/impl/cliente.service.impl';
import { HttpConfigInterceptor } from './interceptors/httpconfig.interceptor';
import { LoaderSpinnerInterceptor } from './interceptors/loader-spinner.interceptor';

@NgModule({
	declarations: [
	],
	imports: [
		CommonModule,
		HttpClientModule,
		MatDialogModule,
		MatButtonModule,
		SharedModule,
	],
	providers: [
		LocalStorageHelper,
		SecurityHelper,
		CustomValidatorsHelper,
		SessionCheckerHelper,
		EstadosHelper,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorHandlerInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpConfigInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoaderSpinnerInterceptor,
			multi: true
		},
		Auth0ServiceImpl,
		AuthGuard,
		DialogService,
		BarrioServiceImpl,
		SecurityRolServiceImpl,
		SecurityUserServiceImpl,
		SecurityPermissionServiceImpl,
		SecurityRolPermisoServiceImpl,
		SecurityUserRolServiceImpl,
		ClienteServiceImpl,
	],
	entryComponents: [
	]
})
export class CoreModule { }
