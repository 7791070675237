import { Directive, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Auth0ServiceImpl } from 'src/app/core/http/security/impl/auth0.service.impl';

@Directive({ selector: '[efActionValidate]' })
export class ActionValidatorDirective {
	constructor(
		@Inject(Auth0ServiceImpl) public authService: Auth0ServiceImpl,
		public templateRef: TemplateRef<any>,
		public viewContainer: ViewContainerRef
	) {}

	@Input()
	set efActionValidate(action: string) {
		if (this.authService.hasAccess(action)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}
