<form [formGroup]="formAddEnvioMail" novalidate class="popup-card-width">
  <div>
    <h2 mat-dialog-title i18n>Enviar Notificación</h2>
  </div>
  <mat-dialog-content>
    <div>
      <div class="flex">
        <div class="flex flex-col w-full">
          <strong>Para: </strong>
          <p mat-line class="text-aux"><strong>Nombre: </strong>{{cliente?.nombreCompleto}}</p>
          <p mat-line class="text-aux"><strong>CC: </strong>{{cliente?.numeroDocumento}}</p>
          <p mat-line class="text-aux"><strong>Email: </strong>{{cliente?.email}}</p>
        </div>
      </div>

      <div class="flex">
        <div class="flex flex-col w-full">
          <mat-form-field>
            <input matInput type="text" formControlName="asunto" class="form-control">
            <mat-placeholder class="placeholder">Asunto</mat-placeholder>
            <mat-error *ngIf="form.asunto.touched && form.asunto.errors">
              <mat-error *ngIf="form.asunto.errors.required" class="required alert-error">
                <span i18n>Este campo es requerido</span>
              </mat-error>
              <mat-error *ngIf="!form.asunto.errors.required" class="required alert-error">
                <span i18n>{{form.asunto.errors}}</span>
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="flex">
        <mat-form-field>
          <textarea matInput autocomplete="off" cdkTextareaAutosize cdkAutosizeMinRows="10" cdkAutosizeMaxRows="10"
            maxlength="1000" formControlName="mensaje"></textarea>
          <mat-label class="placeholder">Mensaje</mat-label>
          <mat-error *ngIf="form.mensaje.touched && form.mensaje.errors">
            <mat-error *ngIf="form.mensaje.errors.required" class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>
      </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close()">Cancelar
    </button>
    <button (click)="onFormSubmit()" type="submit" [disabled]="!formAddEnvioMail.valid" mat-raised-button
      color="primary">Enivar
    </button>
  </mat-dialog-actions>
</form>