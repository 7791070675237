import { Component, OnInit, Inject, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CalculoCreditoServiceImpl } from 'src/app/core/http/solicitud-credito/impl/calculo-credito.service.impl';
import { TableColumn } from '@swimlane/ngx-datatable';
import { LinkPagoConceptoServiceImpl } from 'src/app/core/http/gestion-usuarios/impl/link-pago-concepto.service.impl';
import { LinkPagoServiceImpl } from 'src/app/core/http/gestion-usuarios/impl/link-pago.service.impl';
import { DialogService } from 'src/app/core/services/dialog.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ef-plan-pago-solicitud',
  templateUrl: './plan-pago-solicitud.component.html',
  styleUrls: ['./plan-pago-solicitud.component.scss']
})
export class PlanPagoSolicitudComponent implements OnInit {

  item;
  cuotas: any;
  isPlanAmortizacion: boolean;
  valorMensual: any;
  planPago: any;
  planAmortizacion: any;
  columnNames: Array<TableColumn>;

  @ViewChild('amortizacionTemplate', { static: true })
  amortizacionTemplate: TemplateRef<any>;

  @ViewChild('saldoTemplate', { static: true })
  saldoTemplate: TemplateRef<any>;

  @ViewChild('valorTotalTemplate', { static: true })
  valorTotalTemplate: TemplateRef<any>;

  @ViewChild('interesCorrienteTemplate', { static: true })
  interesCorrienteTemplate: TemplateRef<any>;

  @ViewChild('capitalTemplate', { static: true })
  capitalTemplate: TemplateRef<any>;

  isComponentAmortizacionSolicitud = true;
  conceptoPagoSolicitud: any;
  isDetail: boolean;

  constructor(public dialogRef: MatDialogRef<PlanPagoSolicitudComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public calculoCreditoService: CalculoCreditoServiceImpl,
    public linkPagoConceptoService: LinkPagoConceptoServiceImpl,
    public linkPagoService: LinkPagoServiceImpl,
    public dialogService: DialogService,
    public toast: ToastrService,
  ) {
    this.item = data.item;
    this.isDetail = data.isDetail;
  }

  ngOnInit(): void {
    this.getConceptoAsociadoSolicitud();
  }

  getConceptoAsociadoSolicitud() {
    this.linkPagoConceptoService
      .filter({
        empresa: 'EDUFACTORING',
        conceptoAsociado: 'PAGO_CUOTA_CREDITO_EDUFACTORING',
      })
      .subscribe({
        next: (conceptos) => (this.conceptoPagoSolicitud = conceptos.data)
      });
  }


  GenerarLinkPago(cuotas, isSolicitud) {
    let valorSeguro = 0;
    let valor = 0;
    for (let cuota of cuotas) {
      valor += (cuota.valorCuota - cuota.valorPagado - cuota.valorDescuento);
      valorSeguro += cuota.seguroVida;
    }
    if (valor < this.item.saldoMinimo) {
      this.toast.warning(`El Valor del pago $${valor} es menor que el saldo mínimo $${this.item.saldoMinimo}`);
      return;
    }
    if (valor > this.item.saldoTotal) {
      this.toast.warning(`El Valor del pago $${valor} es mayor que el saldo total $${this.item.saldoTotal}`);
      return;
    }
    this.dialogService
      .modalLinkPago(isSolicitud, valorSeguro,
        'GENERAR LINK DE PAGO', cuotas, "fit-content", 800, 'Si', 'No', true, this.conceptoPagoSolicitud)
      .subscribe({
        next: (info: any) => {
          if (info) {
            let linkPago: any;
            linkPago = {
              concepto: this.conceptoPagoSolicitud[0].nombre,
              currency: info.currency,
              descripcion: this.conceptoPagoSolicitud[0].nombre + ' - ' + info.descripcion,
              importeImpuestoVat: info.importeImpuestoVat,
              linkPagoConceptoId: this.conceptoPagoSolicitud[0].id,
              monto: info.monto,
              valorDescuento: info.valorDescuento,
              valorSeguro: info.valorSeguro,
              motivoDescuento: info.motivoDescuento,
              solicitudCreditoId: this.item.solicitudCreditoExtended.id,
              solicitudPlanAmortizacionIds: info.planAmortizacionIds,
              estadoCuenta: true,
              fechasPago: info.fechasPago,
              linkPagoConceptoAsociado: this.conceptoPagoSolicitud[0].conceptoAsociado
            };
            this.generarLinkPagoSolicitud(linkPago);
          }
        }
      });
  }

  generarLinkPagoSolicitud(linkPago) {

    this.linkPagoService.generatePayLink(linkPago).subscribe(
      {
        next: (enlace: any) => {
          this.showModalLinkPago(enlace);
        },
        error: (err) => {
          this.errorLinkPago(err);
        }
      }
    );
  }

  showModalLinkPago(enlace) {
    this.dialogService
      .modalGeneric(
        'LINK DE PAGO',
        `<p class="pad-all-md">Este es su link de pago <a class="primary-text" href="${enlace.data.url}" target="_blank">
								${enlace.data.url}</a></p>`,
        250,
        800,
        'Aceptar',
        'Cerrar',
        false,
        true
      )
      .subscribe({
        next: () => {
          this.dialogRef.close();
        }
      });
  }

  errorLinkPago(err) {
    if (err.error.errors) {
      for (const error of err.error.errors) {
        this.toast.error(error.message, 'Error');
      }
    }
    if (err.error.message) {
      this.toast.error(err.error.message, 'Error');
    }
  }
}
