import { Component, OnInit, Inject } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'ef-modal-validation-form',
  templateUrl: './modal-validation-form.component.html',
  styleUrls: ['./modal-validation-form.component.scss']
})
export class ModalValidationFormComponent implements OnInit {

  formData: any[] = [];
  form: any;
  controles: any;
  formDataGroup: any[] = [];
  isFormArrayInmuebles: boolean = false;
  isFormArrayReferencias: boolean = false;
  referenciasFamiliaresDataGroup: any[] = [];
  referenciasPersonalesDataGroup: any[] = [];

  constructor(
    public   dialogRef: MatDialogRef<ModalValidationFormComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.form = data.form;
    this.controles = data.controles;

    if (this.form instanceof FormArray) {
      this.isFormArrayInmuebles = true;
      for (let i = 0; i < this.form.length; i++) {
        this.formData = [];
        const formGroup = this.form.at(i) as FormGroup;
        Object.keys(formGroup.controls).forEach((controlName) => {
          const control = formGroup.get(controlName);
          this.formData.push({
            name: controlName,
            valid: control.valid,
          });
        });
        this.formDataGroup.push(this.formData.filter(item => item.name !== 'id'));
      }
    } else if (Array.isArray(this.form) && this.form.length === 2) {
      this.isFormArrayReferencias = true;
      for (let i = 0; i < this.form.length; i++) {
        for (let j = 0; j < this.form[i].controls.length; j++) {
          const formGroup = this.form[i].controls[j] as FormGroup;
          let referenciasFamiliaresData: any[] = [];
          let referenciasPersonalesData: any[] = [];
          Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (i === 0) {
              referenciasPersonalesData.push({
                name: this.formatFieldName(controlName),
                valid: control.valid,
              });
            } else {
              referenciasFamiliaresData.push({
                name: this.formatFieldName(controlName),
                valid: control.valid,
              });
            }
          });
          if (referenciasPersonalesData.length !== 0) {
            this.referenciasPersonalesDataGroup.push(referenciasPersonalesData.filter(item => item.name !== 'Id'));
          }
          if (referenciasFamiliaresData.length !== 0) {
            this.referenciasFamiliaresDataGroup.push(referenciasFamiliaresData.filter(item => item.name !== 'Id'));
          }
        }
      }
    }
    else {
      Object.keys(this.form.controls).forEach((controlName) => {
        if (this.controles.includes(controlName)) {
          const control = this.form.controls[controlName];
          this.formData.push({
            name: controlName,
            valid: control.valid,
          });
        }
      });
    }
   }

   formatFieldName(cadena: string): string {
    const expresionRegular = /([a-z])([A-Z])/g
    const resultado = cadena.replace(expresionRegular, '$1 $2');
    return resultado.charAt(0).toUpperCase() + resultado.slice(1);
  }

  ngOnInit(): void {
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

}
