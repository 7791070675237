import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'ef-solicitud-credito-documentos',
	templateUrl: './solicitud-credito-documentos.component.html',
	styleUrls: [ './solicitud-credito-documentos.component.scss' ]
})
export class SolicitudCreditoDocumentosComponent implements OnInit {
	solicitud: any;
	documentosDownload;
	constructor(
		public dialogRef: MatDialogRef<SolicitudCreditoDocumentosComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.solicitud = data.solicitud;
	}

	ngOnInit() {}

	setAction(action: string) {
		this.dialogRef.close(action);
	}
}
