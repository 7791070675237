import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BarrioServiceImpl } from 'src/app/core/http/ubicaciones/impl/barrio.service.impl';
import { ToastrService } from 'ngx-toastr';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import UbicacionGeograficaModel from 'src/app/core/models/ubicaciones/ubicacion-geografica.model';
import BarrioModel from 'src/app/core/models/ubicaciones/barrio.model';
import { PaisServiceImpl } from 'src/app/core/http/ubicaciones/impl/pais.service.impl';

@Component({
	selector: 'ef-barrio-add',
	templateUrl: './barrio-add.component.html',
	styleUrls: [ './barrio-add.component.scss' ]
})
export class BarrioAddComponent implements OnInit {
	formAddBarrio: FormGroup;

	loading = false;

	mensajeError = false;

	pais: any;
	municipio: any;
	departamento: any;
	peticion: any;

	constructor(
		public formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<BarrioAddComponent>,
		public barrioService: BarrioServiceImpl,
		public paisServiceImpl: PaisServiceImpl,
		public toasterService: ToastrService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.municipio = data.municipio;
		this.pais = data.pais;
		this.departamento = data.departamento;
	}

	ngOnInit() {
		this.formAddBarrio = this.formBuilder.group({
			comuna: [ '' ],
			nombre: [ '', [ Validators.required, CustomValidatorsHelper.noWhitespaceValidator ] ],
			departamento: [ null, [ Validators.required ] ],
			municipio: [ null, [ Validators.required ] ]
		});

		if (this.pais && this.municipio && this.departamento) {
			this.form.departamento.setValue(this.departamento);
			this.form.departamento.disable();
			this.form.municipio.setValue(this.municipio);
			this.form.municipio.disable();
		}

		this.peticion = this.paisServiceImpl.getPaisesActivos();
	}

	get form() {
		return this.formAddBarrio.controls;
	}

	onFormSubmit() {
		if (navigator.onLine) {
			if (this.formAddBarrio.valid) {
				this.loading = true;
				this.mensajeError = null;
				const formValues = Object.assign({}, this.formAddBarrio.getRawValue());
				const ubicacion = new UbicacionGeograficaModel();
				ubicacion.id = formValues.municipio.idUbicacion;

				const barrioNew = <BarrioModel>{
					nombre: formValues.nombre,
					comuna: formValues.comuna,
					ubicacion: ubicacion,
					estado: 'ACTIVO'
				};
				this.barrioService.save(barrioNew).subscribe(
					(barrio) => {
						this.dialogRef.close(barrio);
					},
					(error) => {
						try {
							for (const field of error.error.errors) {
								this.toasterService.error(field.message, 'Error');
							}
						} catch (e) {}
					}
				);
			}
		}
	}
}
