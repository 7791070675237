import { Component, OnInit, forwardRef, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { LinkPagoConceptoServiceImpl } from 'src/app/core/http/gestion-usuarios/impl/link-pago-concepto.service.impl';

@Component({
	selector: 'ef-select-link-pago-conceptos',
	templateUrl: './select-link-pago-conceptos.component.html',
	styleUrls: ['./select-link-pago-conceptos.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectLinkPagoConceptosComponent),
			multi: true
		}
	],
	encapsulation: ViewEncapsulation.None
})
export class SelectLinkPagoConceptosComponent implements ControlValueAccessor, OnInit {
	@Input() width = 100;
	@Input() isRequired = false;
	@Input() multi = false;
	@Input() procesos = [];
	@Output() concepto = new EventEmitter<any>();


	item: any;
	items: any[] = [];
	isDisabled = false;

	onChange = (_: any) => { };
	onTouched = () => { };

	constructor(public linkPagoConceptoService: LinkPagoConceptoServiceImpl) { }

	get value(): any {
		return this.item;
	}

	set value(v: any) {
		this.item = v;
		this.onTouched();
		this.onChange(v);
	}

	writeValue(obj: any): void {
		if (obj) {
			this.item = obj || '';
		} else {
			this.item = '';
		}
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	ngOnInit(): void {
		this.linkPagoConceptoService.filter({ empresa: 'EDUFACTORING' }).subscribe({
			next: (conceptos) => {
				this.items = conceptos.data;
			}
		});
	}

	changeConcepto(concepto) {
		this.concepto.emit(concepto);
	}
}
