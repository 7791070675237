import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SecurityRolServiceImpl } from 'src/app/core/http/security/impl/security-rol.service.impl';
import { SecurityUserRolServiceImpl } from 'src/app/core/http/security/impl/security-user-rol.service.impl';

@Component({
	selector: 'ef-usuario-edit',
	templateUrl: './usuario-edit.component.html',
	styleUrls: ['./usuario-edit.component.scss']
})
export class UsuarioEditComponent implements OnInit {
	formAdd: FormGroup;
	roles: any[] = [];
	search = new FormControl();
	rolControl = new FormControl();

	$search;

	constructor(
		public formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<UsuarioEditComponent>,
		
		public toast: ToastrService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public rolService: SecurityRolServiceImpl,
		public userRolService: SecurityUserRolServiceImpl
	) { }

	get form() {
		return this.formAdd.controls;
	}

	validObject(rol) {
		const validados = this.rolControl.value.filter((el) => el.name === rol.name);
		return validados.length > 0;
	}

	ngOnInit() {
		this.formAdd = this.formBuilder.group({
			name: [{ value: '', disabled: true }],
			email: [{ value: '', disabled: true }],
			idSecurity: [{ value: '', disabled: true }]
		});
		this.loadInfoRol();
		
		this.rolService.getAll().subscribe(
			{
				next: (data) => {
					;
					this.roles = data;
					this.$search = this.search.valueChanges.pipe(
						startWith(null),
						debounceTime(200),
						switchMap((res: string) => {
							if (!res) {
								return of(this.roles);
							}
							res = res.toLowerCase();
							return of(this.roles.filter((x) => x.name.toLowerCase().indexOf(res) >= 0));
						})
					);
				},
				error: (err) => {
					;
					this.toast.error(err.error.message || 'Error en la carga de los permisos.');
				}
			}
		);
	}

	loadInfoRol() {
		this.userRolService.rolessByUserlId(this.data.id).subscribe({
			next: (info: any) => {
				this.rolControl.setValue(info.data.roles);
				this.formAdd.get('name').setValue(info.data.usuario.name);
				this.formAdd.get('email').setValue(info.data.usuario.email);
				this.formAdd.get('idSecurity').setValue(info.data.usuario.idSecurity);
			}
		});
	}

	selectionChange(option: any) {
		const value = this.rolControl.value || [];
		const findItem = value.findIndex((el: any) => el.name === option[0].value.name);
		if (findItem >= 0) {
			value.splice(findItem, 1);
		} else {
			value.push(option[0].value);
		}
		this.rolControl.setValue(value);
	}

	onFormSubmit() {
		if (this.formAdd.invalid || this.rolControl.value === null || this.rolControl.value.length === 0) {
			this.toast.warning('Debe incluir nombre, descripción y elegir almenos un permiso.');
			return;
		}
		
		const rolesAux: any[] = this.rolControl.value;
		this.userRolService.actualizarRolesByUser(this.data.id, rolesAux.map((el) => el.id)).subscribe({
			next: (data) => {
				this.toast.success('Se modificaron exitósamente todos los roles al usuario.');
				this.dialogRef.close();
			}
		});
	}
}
