import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AcuerdoPagoAdjuntoServiceImpl } from 'src/app/core/http/acuerdo-pago/Impl/acuerdo-pago-adjunto.service.impl';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { AdjuntarDocumentosGenericComponent } from 'src/app/shared/adjuntar-documentos-generic/adjuntar-documentos-generic.component';

@Component({
	selector: 'ef-cargar-documentos-dialog',
	templateUrl: './cargar-documentos-dialog.component.html',
	styleUrls: [ './cargar-documentos-dialog.component.scss' ]
})
export class CargarDocumentosDialogComponent implements OnInit {
	tipos: Array<string>;
	formAddFiles: FormGroup;
	files: FormArray;
	numeroAcuerdoPago: number;
	acuerdoPagoId: number;

	@ViewChild(AdjuntarDocumentosGenericComponent) adjuntarDocumentos: AdjuntarDocumentosGenericComponent;

	constructor(
		public dialogRef: MatDialogRef<CargarDocumentosDialogComponent>,
		public acuerdoPagoAdjuntoService: AcuerdoPagoAdjuntoServiceImpl,
		public toast: ToastrService,
		public spinner: SpinnerService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.numeroAcuerdoPago = data.numeroAcuerdoPago;
		this.acuerdoPagoId = data.acuerdoPagoId;
	}

	ngOnInit(): void {}

	onFormSubmit() {
		this.formAddFiles = this.adjuntarDocumentos.formAddFiles;
		this.files = this.adjuntarDocumentos.files;
		if (this.files.value.length > 0 && this.files.valid) {
			let documents = new Array();
			this.files.getRawValue().forEach((item) => {
				let insert = {
					acuerdoPagoId: this.acuerdoPagoId,
					tipoDocumento: item.tipoDocumento,
					descripcion: item.descripcion,
					nombre: item.tipoDocumento,
					extension: 'pdf',
					base64: String(item.base64).split('data:application/pdf;base64,')[1],
					kbytes: this.calculateImageSize(item.base64)
				};
				documents.push(insert);
			});
			
			this.acuerdoPagoAdjuntoService.cargarDocumentos(this.numeroAcuerdoPago, documents).subscribe(
				(res: any) => {
					if (res.success) {
						this.toast.success(`${res.count} archivos fueron cargados exitosamente`);
					}
					this.dialogRef.close(true);
					
				},
				(err) => {
					
					this.toast.error(`Hubo errores al guardar los archivos`);
					if (err.error.errors) {
						for (const error of err.error.errors) {
							this.toast.error(error.message, 'Error');
						}
					} else {
						if (err.error.message) {
							this.toast.error(err.error.message, 'Error');
						}
					}
				}
			);
		} else {
			this.toast.info('Por favor llene todos los campos');
		}
	}

	calculateImageSize(base64): any {
		let padding, inBytes, base64Length;
		if (base64.endsWith('==')) {
			padding = 2;
		} else if (base64.endsWith('=')) {
			padding = 1;
		} else {
			padding = 0;
		}
		base64Length = base64.length;
		inBytes = base64Length / 4 * 3 - padding;
		let kbytes = inBytes / 1000;
		return kbytes;
	}
}
