import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from '@angular/common/http';
import { OpcionVistaInterface } from '../opcion-vista.service.interface';
import OpcionVistaModel from 'src/app/core/models/configuracion-solicitud-credito/opcion-vista.models';

@Injectable({
  providedIn: 'root',
})
export class OpcionVistaServiceImpl
  extends ServiceImplService<OpcionVistaModel>
  implements OpcionVistaInterface
{
  constructor(private http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'opcion-vista/';
  }
}
