import { Injectable, Injector } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageHelper } from 'src/app/core/helpers/local-storage.helper';
import { LOCAL_STORAGE_CONSTANTS } from 'src/app/core/constants/local-storage.constants';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(public injector: Injector, public jwtHelper: JwtHelperService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const localStorageHelper: LocalStorageHelper = this.injector.get(LocalStorageHelper);
		const sedes = localStorageHelper.getItem(LOCAL_STORAGE_CONSTANTS.SEDE_ACTUAL);
		if (sedes) {
			return next.handle(
				req.clone({
					headers: req.headers.append('sedes', JSON.parse(sedes).join())
				})
			);
		}

		return next.handle(req);
	}
}
