import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { FormControl, Validators } from '@angular/forms';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import { SolicitudCreditoServiceImpl } from 'src/app/core/http/solicitud-credito/impl/solicitud-credito.service.impl';

@Component({
  selector: 'ef-modal-edit-contrato-academico',
  templateUrl: './modal-edit-contrato-academico.component.html',
  styleUrls: ['./modal-edit-contrato-academico.component.scss']
})
export class ModalEditContratoAcademicoComponent implements OnInit {

  solicitud: any;
  contrato: any;
  contratoNuevo: any;
  contratoControl = new FormControl(null, [Validators.required, Validators.min(0), CustomValidatorsHelper.numeric]);

  constructor(public dialogRef: MatDialogRef<ModalEditContratoAcademicoComponent>,
    public solicitudCreditoService: SolicitudCreditoServiceImpl,
    public toast: ToastrService,
    
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.solicitud = data.solicitud;
  }

  ngOnInit() {
    
    this.solicitudCreditoService.getContratoAcademico(this.solicitud?.contratoAcademico, null)
      .subscribe(
        {
          next: (res) => {
            ;
            this.contrato = res;
          },
          error: (err) => {
            ;
            this.toast.error('Ocurrio un error al consultar el contrato academico actual', 'Error');
          }
        });
  }

  onFormSubmit() {
    let bean = {
      numeroContratoAcademico: this.contratoControl.value,
      solicitudCreditoId: this.solicitud.id
    };

    

    this.solicitudCreditoService.editNumeroContrato(bean)
      .subscribe(
        {
          next: (res) => {
            ;
            this.dialogRef.close();
            this.toast.success('Numero De Contrato Academico Actualizado');
          },
          error: (err) => {
            ;
            if (err.error.errors) {
              for (const error of err.error.errors) {
                this.toast.error(error.message, 'Error');
              }
            } else {
              if (err.error.message) {
                this.toast.error(err.error.message, 'Error');
              }
            }
          }
        }
      );
  }


  validate() {
    
    this.solicitudCreditoService.getContratoAcademico(this.contratoControl.value, null)
      .subscribe(
        {
          next: (res) => {
            ;
            if (res) {
              this.contratoNuevo = res;
            } else {
              this.toast.warning('No fue posible encontrar un contrato academico con el numero diligenciado', 'Importante');
            }
          },
          error: (err) => {
            ;
            this.toast.error('Ocurrio un error al consultar el contrato academico actual', 'Error');
          }
        });
  }

  clear() {
    this.contratoControl.setValue('');
    this.contratoNuevo = null;
  }

}
