import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { ParametroGeneralService } from 'src/app/core/http/configuracion-basica/impl/parametro-general.service.impl';


@Component({
  selector: 'jt-modal-reserva-cita-soporte',
  templateUrl: './modal-reserva-cita-soporte.component.html',
  styleUrls: ['./modal-reserva-cita-soporte.component.scss']
})
export class ModalReservaCitaSoporteComponent implements OnInit {

  showContent1: boolean = true;
  showContent2: boolean = false;
  url!: string;

  constructor(
	public dialogRef: MatDialogRef<ModalReservaCitaSoporteComponent>,
  public parametroGeneral: ParametroGeneralService,
  ) { }

  ngOnInit(): void {
    this.parametroGeneral.get('LINK_PAGINA_RESERVA_CITA_SOPORTE').subscribe({next: (data) => {
      this.url = data.valor;
    }})
  }

  continue() {
	this.showContent1 = false;
	this.showContent2 = true;
	}

}
