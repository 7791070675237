import { Component, OnInit, Inject } from '@angular/core';
import { LineaCreditoServiceImpl } from '../../../../../core/http/configuracion-basica/impl/lineas-credito.service.impl';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PlanLineaCreditoServiceImpl } from '../../../../../core/http/configuracion-basica/impl/plan-linea-credito.service.impl';
import introJs from 'intro.js/intro.js';
import { PLAN_CREDITO_CONFIG_ADD_STEPS } from './plan-credito-config-add.steps';

@Component({
  selector: 'ef-plan-credito-config-add',
  templateUrl: './plan-credito-config-add.component.html',
  styleUrls: ['./plan-credito-config-add.component.scss'],
})
export class PlanCreditoConfigAddComponent implements OnInit {
  formAdd: FormGroup;
  lineasCreditos: Array<any> = [];
  planCreditoId;
  showDetalle: boolean = false;
  lineaCreditoDetalle: any;
  abreviatura: any = null;
  mensajeError: any;

  constructor(
    public lineaCreditoService: LineaCreditoServiceImpl,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PlanCreditoConfigAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public toastrService: ToastrService,
    public planLineaCreditoService: PlanLineaCreditoServiceImpl
  ) {
    this.planCreditoId = Number(data);
  }

  ngOnInit(): void {
    this.formAdd = this.formBuilder.group({
      fEducativoJamestown: [false, [Validators.required]],
      lineaCredito: [null, [Validators.required]],
    });
    this.subscribeFEducativoJamestown();
    this.subscribeLineaCredito();
    this.getLineCredito(false);
  }

  subscribeLineaCredito() {
    this.formAdd.get('lineaCredito').valueChanges.subscribe({
      next: (lineaCredito) => {
        this.showDetalle = true;
        this.lineaCreditoDetalle = lineaCredito;
        if (lineaCredito.abreviaturaContable) {
          this.abreviatura = this.setAbreviatura(
            lineaCredito.abreviaturaContable
          );
        } else {
          this.abreviatura = null;
        }
      }
    });
  }

  subscribeFEducativoJamestown() {
    this.formAdd
      .get('fEducativoJamestown')
      .valueChanges.subscribe({
        next: (fEducativoJamestown) => {
          this.getLineCredito(fEducativoJamestown);
          this.showDetalle = false;
        }
      });
  }

  getLineCredito(fEducativoJamestown) {
    this.lineaCreditoService
      .filter({
        fEducativoJamestown: fEducativoJamestown,
        estado: 'ACTIVO',
      })
      .subscribe({
        next: (res) => {
          if (res.count <= 0) {
            this.toastrService.warning('No hay lineas de creditos creadas');
          }
          this.lineasCreditos = res.data;
        },
      });
  }

  setAbreviatura(abreviaturaContable: string): string {
    let abreviatura = '';
    switch (abreviaturaContable) {
      case 'P':
        abreviatura = 'PEREIRA';
        break;
      case 'V':
        abreviatura = 'VILLAVICENCIO';
        break;
      case 'BU':
        abreviatura = 'BUCARAMANGA';
        break;
      case 'C':
        abreviatura = 'CUCUTA';
        break;
      case 'I':
        abreviatura = 'IBAGUE';
        break;
      default:
        break;
    }
    return abreviatura;
  }

  get form() {
    return this.formAdd.controls;
  }

  onFormSubmit() {
    if (navigator.onLine) {
      this.mensajeError = null;
      const lineaCreditoNuevo = Object.assign({}, this.formAdd.value);
      let newData = {
        estado: 'ACTIVO',
        idPlanCredito: this.planCreditoId,
        idLineaCredito: lineaCreditoNuevo.lineaCredito.id,
      };
      this.planLineaCreditoService.save(newData).subscribe(
        {
          next: (lineaCredito) => {
            this.dialogRef.close(lineaCredito);
          },
          error: (error) => {
            try {
              for (const field of error.error.errors) {
                this.toastrService.error(field.message, 'Error');
              }
            } catch (e) {
              this.mensajeError = error.error.message;
            }
          }
        }
      );
    } else {
      this.toastrService.error(
        'Por favor, revisa tu conexión a internet',
        'Error'
      );
    }
  }

  fieldExplanation(fieldNumber, id) {
    let intro = introJs();
    let steps;
    steps = [PLAN_CREDITO_CONFIG_ADD_STEPS[fieldNumber]];
    steps[0].element = id;
    intro
      .setOptions({
        doneLabel: 'Continuar',
        prevLabel: 'Anterior',
        nextLabel: 'Siguiente',
        steps: steps,
      })
      .start();
  }
}
