<mat-card>
    <h1 mat-dialog-title *ngIf="errorResponse?.status != 403" class="!mb-2 primary">Ocurrió un error inesperado</h1>
    <h1 mat-dialog-title *ngIf="errorResponse?.status === 403" class="!mb-2">No tienes permisos</h1>
    <mat-divider class="mb-2"></mat-divider>
    <mat-dialog-content *ngIf="errorResponse?.status != 403">
            <p class="my-2">Ha ocurrido un error, por favor contacte con el administrador del sistema.</p>
            <p *ngIf="errorResponse?.error.failedMicroService">Se ha detectado una falla en el microservicio: <strong>{{errorResponse.error.failedMicroService | capitalizeFirstLetter}}</strong></p>
            <mat-accordion *ngIf="errorResponse?.error.debugMessage || errorResponse?.error.message">
                <mat-expansion-panel class="mb-4">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Click aquí para ver más detalles
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <div class="debug-message">
                            {{errorResponse?.error.debugMessage || errorResponse?.error.message}}
                        </div>
                    </ng-template>
                </mat-expansion-panel>
            </mat-accordion>
    </mat-dialog-content>
    <mat-dialog-content *ngIf="errorResponse?.status === 403">
        Usted no tiene permisos para realizar este proceso. Por favor contacte a la Dirección de Sistemas para
        validar este inconveniente y poder solucionarlo.

        <mat-card class="mt-3">
          <h4 class="mb-2"> Permiso faltante</h4>
          <div>
            {{ errorResponse?.error.permission }}
          </div>
        </mat-card>

      </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none">
        <div class="flex justify-end items-end">
            <button type="button" mat-raised-button class="primary-bg" (click)="dialogRef.close()">
                Cerrar
            </button>
        </div>
    </mat-dialog-actions>
</mat-card>
