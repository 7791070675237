<mat-form-field [style.width.%]="width">
  <!--[disabled]="isDisabled"-->
    <input [required]="isRequired" [readonly]="isDisabled" type="text" matInput
      [matAutocomplete]="autocompletRegiones" [formControl]="regionControl" (click)="onTouched()" />
    <mat-placeholder class="placeholder">{{label}}</mat-placeholder>
    <mat-autocomplete #autocompletRegiones="matAutocomplete" (optionSelected)="onRegionChange($event)"
      [displayWith]="displayFn">
      <mat-option *ngFor="let item of filteredRegiones | async" [value]="item">
        {{item.region}}
      </mat-option>
    </mat-autocomplete>
   
  <mat-error *ngIf="regionControl.errors">
    <mat-error *ngIf="regionControl.errors.required" class="required alert-error">
      <span i18n>Este campo es requerido</span>
    </mat-error>
    <mat-error *ngIf="regionControl.errors.valid" class="required alert-error text-erro">
      <span i18n>Seleccione una region</span>
    </mat-error>
  </mat-error>
</mat-form-field>
<ng-content></ng-content>