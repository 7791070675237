import {Component, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TableColumn} from '@swimlane/ngx-datatable';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'ef-modal-info-table',
  templateUrl: './modal-info-table.component.html',
  styleUrls: ['./modal-info-table.component.scss']
})
export class ModalInfoTableComponent implements OnInit {

  isLoading: boolean;
  title: string;
  elements: Array<any>;
  columnNames: Array<TableColumn>;

  mensajeEmpty = 'No se encontraron resultados';

  @ViewChild('valor', { static: true }) valor: TemplateRef<any>;

  constructor(public dialogRef: MatDialogRef<ModalInfoTableComponent>,
              
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.isLoading = false;
    this.elements = this.data.elements;
    this.title = this.data.title;
  }

  ngOnInit() {
    if (this.title === 'Resumen Inmuebles') {
      this.columnNames = [
        {name: 'Nombre', prop: 'nombre', cellClass: 'text-center'},
        {name: 'Direccion', prop: 'direccion', cellClass: 'text-center'},
        {name: 'Valor', cellTemplate: this.valor, cellClass: 'text-center'},
        {name: 'Tipo Contrato', prop: 'tipoContrato', cellClass: 'text-center'},
        {name: 'Fecha Final', prop: 'fechaFinal', cellClass: 'text-center'},
        {name: 'Ciudad', prop: 'municipio.municipio', cellClass: 'text-center'},
        {name: 'Estado', prop: 'estado', cellClass: 'text-center'}
      ];
    }

    if (this.title === 'Inmuebles') {
      this.columnNames = [
        {name: 'Nombre', prop: 'nombre', cellClass: 'text-center'},
        {name: 'Direccion', prop: 'direccion', cellClass: 'text-center'},
        {name: 'Valor', cellTemplate: this.valor, cellClass: 'text-center'},
        {name: 'Tipo Contrato', prop: 'tipoContrato', cellClass: 'text-center'},
        {name: 'Fecha Final', prop: 'fechaFinal', cellClass: 'text-center'},
        {name: 'Ciudad', prop: 'ubicacionGeografica.municipio.municipio', cellClass: 'text-center'},
        {name: 'Estado', prop: 'estado', cellClass: 'text-center'}
      ];
    }

    this.isLoading = true;
    
    setTimeout(() => {
      this.isLoading = false;
      ;
      this.elements = [...this.elements];
    }, 2000);

  }
}


