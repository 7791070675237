<div class="popup-card-width" [style.width.%]="100">
  <div>
    <h2 mat-dialog-title i18n>Información Pregunta Medica</h2>
  </div>
  <mat-divider class="primary-border"></mat-divider>
  <mat-dialog-content *ngIf="preguntasMedicasDetail" class="mrgn-t-xs">
    <div>
      <div class="flex flex-wrap justify-between gap-3">
        <strong class="sm:w-[40%] xs:w-full md:w-[30%]">
          Pregunta Medica:
        </strong>
        <span class="sm:w-[55%] xs:w-full md:w-[65%]">
          {{ preguntasMedicasDetail.pregunta }}
        </span>
      </div>
    </div>
    <div>
      <div class="flex flex-wrap justify-between gap-3">
        <strong class="sm:w-[40%] xs:w-full md:w-[30%]">
          Orden:
        </strong>
        <span class="sm:w-[55%] xs:w-full md:w-[65%]">
          {{ preguntasMedicasDetail.orden }}
        </span>
      </div>
    </div>
    <div>
      <div class="flex flex-wrap justify-between gap-3">
        <strong class="sm:w-[40%] xs:w-full md:w-[30%]">
          Rechazo Automatico:
        </strong>
        <span class="sm:w-[55%] xs:w-full md:w-[65%]">
          {{ preguntasMedicasDetail.fRechazoAutomatico ? 'SI' : 'NO'}}
        </span>
      </div>
    </div>
    <div>
      <div class="flex flex-wrap justify-between gap-3">
        <strong class="sm:w-[40%] xs:w-full md:w-[30%]">
          Estado:
        </strong>
        <span class="sm:w-[55%] xs:w-full md:w-[65%]">
          {{ preguntasMedicasDetail.estado }}
        </span>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button
      type="button"
      mat-raised-button
      (click)="dialogRef.close()">
      Cerrar
    </button>
  </mat-dialog-actions>
</div>
