import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { DialogService } from '../services/dialog.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
	constructor(public dialogService: DialogService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		return next.handle(req).pipe(
			catchError((err: any) => {
				if (
					err instanceof HttpErrorResponse &&
					(err.status >= 500 || err.status === 403) &&
					!req.url.includes('auth0') &&
          !req.url.includes('/v1/solicitud-credito/trans-union/idvision/signdocuments')
				) {
					this.dialogService.showServerErrorDialog(err);
				}

				return throwError(err);
			})
		);
	}
}
