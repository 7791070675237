import { Injectable } from "@angular/core";
import { ServiceImplService } from "../../service.impl.service";
import MotorConsultaModel from "src/app/core/models/configuracion-solicitud-credito/motor-consulta.models";
import { MotorConsultaInterface } from "../motor-consulta.service.interface";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: 'root',
  })
  export class MotorConsultaServiceImpl
    extends ServiceImplService<MotorConsultaModel>
    implements MotorConsultaInterface
  {
    constructor(private http: HttpClient) {
      super();
      this.httpClient = http;
      this.resource = 'motor-consulta/';
    }
  }
  