<h1 mat-dialog-title *ngIf="title">{{ title }}</h1>
<mat-dialog-content>
    <div class="flex justify-center"><mat-icon class="icon-error !w-full text-center mb-3">error</mat-icon></div>
    <p>{{message}}</p>
</mat-dialog-content>

<mat-dialog-actions class="mrgn-b-xs pad-b-none">
    <div class="flex justify-end items-end">
        <button type="button" mat-raised-button color="primary" (click)="dialogRef.close()">
            CERRAR
        </button>
    </div>
</mat-dialog-actions>
