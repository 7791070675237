import {
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import DepartamentoModel from 'src/app/core/models/ubicaciones/departamento.model';
import { Observable } from 'rxjs';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import PaisModel from 'src/app/core/models/ubicaciones/pais.model';
import RegionModel from 'src/app/core/models/ubicaciones/region.model';
import { PaisServiceImpl } from 'src/app/core/http/ubicaciones/impl/pais.service.impl';
import { DepartamentoServiceImpl } from 'src/app/core/http/ubicaciones/impl/departamento.service.impl';
import { map } from 'rxjs/operators';
import MunicipioModel from 'src/app/core/models/ubicaciones/municipio.model';
import { DialogService } from 'src/app/core/services/dialog.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';

@Component({
	selector: 'ef-select-departamento',
	templateUrl: './select-departamento.component.html',
	styleUrls: ['./select-departamento.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectDepartamentoComponent),
			multi: true
		}
	],
	encapsulation: ViewEncapsulation.None
})
export class SelectDepartamentoComponent implements ControlValueAccessor, OnInit, OnChanges {
	departamento: DepartamentoModel;

	departamentos: Array<DepartamentoModel> = [];

	filteredDepartamentos: Observable<DepartamentoModel[]>;

	departamentoControl = new FormControl(null, CustomValidatorsHelper.validateSelect);

	@Input() public pais: PaisModel | any;

	@Input() public region: RegionModel;

	@Input() width: any;

	@Input() label: any;

	@Input() isRequired = false;

	@Input() enableIcon = false;

	@Input() enableAdd = false;

	@Input() enableAddForm = false;

	@Input() flagRegion = false;

	@Input() public departamentoDefault: DepartamentoModel;

	@Output() onCopyDepartamento = new EventEmitter();

	@Output() ubicacionNew = new EventEmitter();

	@Output() public onSelectDepartamento = new EventEmitter();

	isDisabled = false;

	onTouched = () => { };

	constructor(
		public toasterService: ToastrService,
		public paisService: PaisServiceImpl,
		public dialogService: DialogService,
		public departamentoServiceImpl: DepartamentoServiceImpl
	) { }

	ngOnInit(): void {
		this.subscribeDepartamentos();
	}

	subscribeDepartamentos() {
		this.filteredDepartamentos = this.departamentoControl.valueChanges.pipe(
			map((value: any) => {
				if (_.isString(value)) {
					this.departamento = undefined;
					this.propagateChange(this.departamento);
					this.onSelectDepartamento.emit(this.departamento);
				}

				if (value && value.departamento) {
					if (value.departamento !== undefined) {
						value = value.departamento;
					} else {
						value = value;
					}
				}
				return this._filter(value);
			})
		);
	}

	onChangePais() {
		if (this.pais) {
			this.paisService.getDepartamentos(this.pais.id).subscribe({
				next: (departamentos: any) => {
					this.departamentos = departamentos.data;
					if (this.departamentos.length == 1) {
						this.writeValue(this.departamentos[0]);
						this.propagateChange(this.departamento);
						this.onTouched();
					}
					this.subscribeDepartamentos();
					this.enableAdd = true;
					this.departamentoControl.enable();
				}
			});
		}
	}

	onChangeRegion() {
		if (this.region) {
			this.departamentoServiceImpl.getDepartamentoRegion(this.pais.id, this.region.codigo).subscribe({
				next: (departamentos: any) => {
					this.departamentos = departamentos.data;
					if (this.departamentos.length == 1 && !this.flagRegion) {
						this.writeValue(this.departamentos[0]);
						this.propagateChange(this.departamento);
						this.onTouched();
					}
					this.subscribeDepartamentos();
					this.enableAdd = true;
					this.enableAddForm = true;
					this.departamentoControl.enable();
				},
				error: (err: any) => {
					this.toasterService.warning('No se pudieron cargar los departamentos');
				}
			});
		}
	}

	onDepartamentoChange(event) {
		const newDepartamento = <DepartamentoModel>event.option.value;
		this.onChange(event, newDepartamento);
		this.onSelectDepartamento.emit(this.departamento);
	}

	onChange(e: Event, value: any) {
		this.departamento = value;
		this.propagateChange(this.departamento);
		this.onTouched();
	}

	get value(): any {
		return this.departamento;
	}

	set value(v: any) {
		if (v !== this.departamento) {
			this.departamento = v;
		}
	}

	propagateChange = (_: any) => { };

	writeValue(departamento: any) {
		this.departamento = departamento;
		this.departamentoControl.setValue(departamento);
	}

	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	displayFn(departamento?: DepartamentoModel): string | undefined {
		return departamento ? departamento.departamento : undefined;
	}

	public _filter(value: string): Array<DepartamentoModel> {
		if (value) {
			const filterValue = value.toLowerCase();
			return this.departamentos.filter((departamento) => {
				return departamento.departamento.toLowerCase().includes(filterValue);
			});
		}
		return this.departamentos;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.flagRegion) {
			const regionChange = changes.region;
			if (typeof regionChange !== 'undefined') {
				const anteriorRegion = regionChange.previousValue;
				const nuevaRegion = regionChange.currentValue;
				if (anteriorRegion) {
					this.writeValue(null);
					this.departamentoControl.setValue(null);
					this.propagateChange(this.departamento);
				}
				if (!nuevaRegion && !regionChange.isFirstChange()) {
					this.departamentos = [];
					this.departamentoControl.disable();
					this.enableAdd = false;
				} else if (anteriorRegion !== nuevaRegion) {
					this.departamentos = [];
					this.enableAdd = false;
					this.departamentoControl.disable();
					this.region = nuevaRegion;
					this.onChangeRegion();
				}
			}
		} else {
			const paisChange = changes.pais;
			const anteriorPais = paisChange.previousValue;
			const nuevoPais = paisChange.currentValue;
			if (anteriorPais) {
				this.writeValue(null);
				this.departamentoControl.setValue(null);
				this.propagateChange(this.departamento);
			}
			if (!nuevoPais && !paisChange.isFirstChange()) {
				this.departamentos = [];
				this.departamentoControl.disable();
				this.enableAdd = false;
			} else if (anteriorPais !== nuevoPais) {
				this.departamentos = [];
				this.enableAdd = false;
				this.departamentoControl.disable();
				this.pais = nuevoPais;
				this.onChangePais();
			}
		}
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	copyDepartamento() {
		this.onCopyDepartamento.emit(true);
	}

	addNewUbicacionDialog() {
		if (this.flagRegion) {
			this.departamentoControl.disable();
			this.dialogService.addNewUbicacionDialog(this.pais, this.region, null).subscribe({
				next: (ubicacionId) => {
					this.departamentoControl.enable();
					if (ubicacionId) {
						this.ubicacionNew.emit(ubicacionId);
					}
				}
			});
		} else {
			this.departamentoControl.disable();
			this.dialogService.listUbicacionDialog(this.pais, null).subscribe({
				next: (ubicacionId) => {
					this.departamentoControl.enable();
					if (ubicacionId) {
						this.ubicacionNew.emit(ubicacionId);
					}
				}
			});
		}


	}
}
