<form
  [formGroup]="formAdd"
  novalidate
  autocomplete="off"
  class="popup-card-width"
>
  <div>
    <h2 mat-dialog-title i18n>Agregar Nueva Pregunta Medica</h2>
  </div>
  <mat-dialog-content>
    <div>
      <div>
        <mat-form-field>
          <mat-label class="placeholder">Pregunta Medica</mat-label>
          <input matInput formControlName="pregunta" [style.width.px]=37/>
          <mat-error *ngIf="form.pregunta.touched && form.pregunta.errors">
            <mat-error
              *ngIf="form.pregunta.errors.required"
              class="required alert-error"
            >
              <span i18n>Este campo es requerido</span>
            </mat-error>
            <mat-error
              *ngIf="!form.pregunta.errors.required"
              class="required alert-error"
            >
              <span i18n>{{ form.pregunta.errors }}</span>
            </mat-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label class="placeholder">Orden</mat-label>
          <input type="number" matInput formControlName="orden"/>
          <mat-error
            *ngIf="form.orden.touched && form.orden.errors"
          >
            <mat-error
              *ngIf="form.orden.errors.required"
              class="required alert-error"
            >
              <span i18n>Este campo es requerido</span>
            </mat-error>
            <mat-error
              *ngIf="!form.orden.errors.required"
              class="required alert-error"
            >
              <span i18n>{{ form.orden.errors }}</span>
            </mat-error>
          </mat-error>
        </mat-form-field>

        <div class="pad-y-md">
          <div>
            <div>
              <strong> Rechazo Automatico </strong>
            </div>
            <div>
              <mat-slide-toggle formControlName="fRechazoAutomatico" (change)="eventToggleChange($event)">
                {{form.fRechazoAutomatico.value ? 'SI' : 'NO'}}
              </mat-slide-toggle>
            </div>
          </div>
        </div>

      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="mrgn-b-xs pad-b-none">
    <div class="text-right">
      <button type="button" mat-raised-button (click)="dialogRef.close()">
        Cancelar
      </button>
      <button
        (click)="onFormSubmit()"
        [disabled]="!formAdd.valid"
        mat-raised-button
        class="accept-button"
      >
        Crear
      </button>
    </div>
  </mat-dialog-actions>
</form>
