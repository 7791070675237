import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ESTADOS_BASE } from 'src/app/core/constants/estados.constants';
import { MotorConsultaServiceImpl } from 'src/app/core/http/configuracion-solicitud-credito/impl/motor-consulta.service.impl';
import MotorConsultaModel from 'src/app/core/models/configuracion-solicitud-credito/motor-consulta.models';

@Component({
  selector: 'ef-motor-consulta-edit',
  templateUrl: './motor-consulta-edit.component.html',
  styleUrls: ['./motor-consulta-edit.component.scss']
})
export class MotorConsultaEditComponent implements OnInit {

  formEdit: FormGroup;
  loading = false;
  mensajeError = false;
  motorConsulta: MotorConsultaModel;
  algoritmo = ['AQMGO_1', 'AQMGO_2'];
  caracteresEscritos: number = 0;
  nombreExistentes: string[];
  nombreActual: string;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<MotorConsultaEditComponent>,
    private toast: ToastrService,
    private motorConsultaService: MotorConsultaServiceImpl,
    @Inject(MAT_DIALOG_DATA) public data: MotorConsultaModel
  ) {
    this.motorConsulta = data;
    this.nombreActual = this.motorConsulta.nombre;
  }

  onFormSubmit() {
    if (navigator.onLine) {
      this.mensajeError = null;
      const motorConsultaNuevo = Object.assign({}, this.formEdit.value);
      motorConsultaNuevo.estado = ESTADOS_BASE.ACTIVO;
      const nombreActual = this.nombreExistentes.includes(motorConsultaNuevo.nombre.toLowerCase());

      if (nombreActual) {
        this.toast.error('Este nombre ya está en uso');
        return;
      }


      this.motorConsultaService
        .update(String(this.motorConsulta.id), motorConsultaNuevo)
        .subscribe(
          {
            next: (motorConsulta: string) => {
              this.dialogRef.close(motorConsulta);
            },
            error: (error) => {
              try {
                for (const field of error.error.errors) {
                  this.toast.error(field.message, 'Error');
                }
              } catch (e) {
                this.mensajeError = error.error.message;
              }
            }
          }
        );
    } else {
      this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
    }
  }

  ngOnInit(): void {
    this.formEdit = this.formBuilder.group({
      nombre: [this.motorConsulta.nombre, [Validators.required, Validators.pattern(/^\S.*\S$/)]],
      descripcion: [this.motorConsulta.descripcion, [Validators.required, Validators.minLength(10), Validators.maxLength(1000), Validators.pattern(/^\S.*\S$/)]],
      estado: [this.motorConsulta.estado],
      algoritmo: [this.motorConsulta.algoritmo, [Validators.required]],
    });

    this.initForm();
    this.motorConsultaService.filter({
      estado: ESTADOS_BASE.ACTIVO,

    })
      .subscribe({
        next: (rest) => {
          this.nombreExistentes = rest.data.map(el => el.nombre.toLowerCase());
          this.nombreExistentes = this.nombreExistentes.filter(el => el !== this.nombreActual.toLowerCase());
        }
      });
  }


  initForm() {
    this.loading = false;
  }

  get form() {
    return this.formEdit.controls;
  }

  subcribealgoritmo() {
    this.formEdit
      .get('algoritmo')
      .valueChanges.subscribe({
        next: (algoritmo) => {
          this.algoritmo = algoritmo;
        }
      });
  }

  limitarCaracteresNombre(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.value.length === 150 && event.key !== 'Backspace') {
      this.toast.warning('Has alcanzado el límite máximo de 150 caracteres en el nombre.');
      event.preventDefault();
    }
  }

  limitarCaracteresDescripcion(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.value.length === 1000 && event.key !== 'Backspace') {
      this.toast.warning('Has alcanzado el límite máximo de 1000 caracteres en la descripción.');
      event.preventDefault();
    }
  }

}
