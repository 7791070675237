import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ServiceImplService } from '../../service.impl.service';
import { map, switchMap } from 'rxjs/operators';
import ListResponse from '../../../models/core/list-response.model';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { ITrebol } from '../trebol.service.interface';

@Injectable({
  providedIn: 'root'
})
export class TrebolServiceImpl extends ServiceImplService<any> implements ITrebol {
  constructor(
    public http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'trebol/';
  }

  getEstadoCuenta(cedula: number) {
    return this.http.get(`${this.getFullPath()}estadoCuenta/${cedula}`);
  }

  getReportEstadoCuentaSolicitud(numeroDocumento, numeroSolicitud): any {
    const path = `${numeroDocumento}/solicitud/${numeroSolicitud}/trebol-reporte/pdf`;
    return this.executeGetPDF(path);
  }

  getReportEstadoCuentaAcuerdo(numeroDocumento, numeroAcuerdo): any {
    const path = `${numeroDocumento}/acuerdo/${numeroAcuerdo}/trebol-reporte/pdf`;
    return this.executeGetPDF(path);
  }

  getPlanPago(cedula: string, numero: string, xtotal: string, params: any) {
    return this.http.get(`${this.getFullPath()}planPagos/cedula/${cedula}/numero/${numero}/xtotal/${xtotal}`, { params });
  }

  getPlanPagoInicialFromTrebol(numeroDocumento: number, numeroSolicitud: number) {
    return this.getEstadoCuenta(numeroDocumento)
        .pipe(
          map(res => res['data']),
          map(data => {
            const estadoSolicitud = data.filter(el => (el.solicitudCreditoExtended != null &&
              (el.solicitudCreditoExtended.numeroSolicitud === numeroSolicitud))
            )
            if (estadoSolicitud.length) {
              return estadoSolicitud[0];
            } else {
              throw new Error('Ha ocurrido un error al consultar el Plan de Pago Incial.')
            }
          })
        );
  }
}
