import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import PaisModel from 'src/app/core/models/ubicaciones/pais.model';
import { Observable } from 'rxjs';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import { PaisServiceImpl } from 'src/app/core/http/ubicaciones/impl/pais.service.impl';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog.service';

@Component({
	selector: 'ef-select-pais',
	templateUrl: './select-pais.component.html',
	styleUrls: ['./select-pais.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectPaisComponent),
			multi: true
		}
	],
	encapsulation: ViewEncapsulation.None
})
export class SelectPaisComponent implements ControlValueAccessor, OnInit {

	public pais: PaisModel | any;

	public paises: Array<PaisModel> = [];

	filteredPaises: Observable<PaisModel[]>;

	paisControl = new FormControl(null, CustomValidatorsHelper.validateSelect);

	@Output() public onSelectPais = new EventEmitter();

	@Input() width: any;

	@Input() label: any;

	@Input() isRequired = false;

	@Input() enableIcon = false;

	@Input() isAll = false;

	@Output() onCopyPais = new EventEmitter();

	@Output() ubicacionNew = new EventEmitter();

	isDisabled = false;

	@Input() enableAdd = false;

	onTouched = () => { };

	constructor(public paisServiceImpl: PaisServiceImpl, public dialogService: DialogService) { }

	ngOnInit(): void {
		if (this.isAll) {
			this.paisServiceImpl.getAll().subscribe({
				next: (paises: any) => {
					this.paises = paises;
					if (this.paises.length == 1) {
						this.writeValue(this.paises[0]);
						this.onSelectPais.emit(this.paises[0]);
						this.propagateChange(this.paises[0]);
						this.onTouched();
					}
				}
			});
		} else {
			this.paisServiceImpl.getPaisesActivos().subscribe({
				next: (paises: any) => {
					this.paises = paises.data;
					if (this.paises.length == 1) {
						this.writeValue(this.paises[0]);
						this.onSelectPais.emit(this.paises[0]);
						this.propagateChange(this.paises[0]);
						this.onTouched();
					}
				}
			});
		}

		this.filteredPaises = this.paisControl.valueChanges.pipe(
			map((value: any) => {
				if (_.isString(value)) {
					this.pais = undefined;
					this.propagateChange(this.pais);
					this.onSelectPais.emit(this.pais);
				}
				if (value != null && value.nombre) {
					if (value.nombre !== undefined) {
						value = value.nombre;
					} else {
						value = value;
					}
					this.onSelectPais.emit(this.pais);
				}
				return this._filter(value);
			})
		);
	}

	onPaisChange(event) {
		const newPais = <PaisModel>event.option.value;
		this.onChange(event, newPais);
		this.onSelectPais.emit(event.option.value);
	}

	onChange(e: Event, value: any) {
		this.pais = value;
		this.propagateChange(this.pais);
		this.onTouched();
	}

	get value(): any {
		return this.pais;
	}

	set value(v: any) {
		if (v !== this.pais) {
			this.pais = v;
		}
	}

	propagateChange = (_: any) => { };

	writeValue(pais: any) {
		this.pais = pais;
		this.paisControl.setValue((this.pais as any));
	}

	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	displayFn(pais?: PaisModel): string | undefined {
		return pais ? pais.nombre : undefined;
	}

	public _filter(value: string): Array<PaisModel> {
		if (value) {
			const filterValue = value.toLowerCase();
			return this.paises.filter((pais) => {
				return pais.nombre.toLowerCase().includes(filterValue);
			});
		}
		return this.paises;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	copyPais() {
		this.onCopyPais.emit(true);
	}

	addNewUbicacionDialog(e: any) {
		this.paisControl.disable();
		let country = typeof this.pais !== 'undefined' ? this.pais : null;
		this.dialogService.listUbicacionDialog(country, null).subscribe({
			next: (ubicacionId) => {
				this.paisControl.enable();
				if (ubicacionId) {
					this.ubicacionNew.emit(ubicacionId);
				}
			}
		});
	}
}
