import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import ListResponse from '../../../models/core/list-response.model';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';
import {DocumentoFaltanteServiceInterface} from '../documento-faltante.service.interface';
import {SolicitudAprobacionManualServiceInterface} from '../solicitud-aprobacion-manual.service.interface';

@Injectable({
  providedIn: 'root'
})
export class SolicitudAprobacionManualServiceImpl extends ServiceImplService<any>
  implements SolicitudAprobacionManualServiceInterface {

  constructor(public http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'solicitud-aprobacion-manual/';
  }

  getFilterExtended(filter: any): Observable<ListResponse<any>> {
    filter = _.omitBy(filter, [ _.isUndefined, _.isNull, _.isEmpty ]);
    return this.httpClient.get(this.getFullPath() + `filterExtended`, { params: filter }).pipe(map((res: ListResponse<any>) => res));
  }

  getExtended(id: string): Observable<any> {
    const path = `extended/${id}`
    return this.httpClient.get(this.getFullPath() + path).pipe(map((res: any) => res.data));
  }

  getReports(id: string): Observable<any> {
    const path = `/${id}/file-reports-resumen`
    return this.httpClient.get(this.getFullPath() + path).pipe(map((res: any) => res.data));
  }

  envioMail(data: any): Observable<any> {
    return this.httpClient.post(this.getFullPath() + `envio/mail` , data).pipe(map((res: any) => res));
  }

  aprobarSolicitud(data: any): Observable<any> {
    return this.httpClient.put(this.getFullPath() + `aprobar/solicitud` , data).pipe(map((res: any) => res));
  }

  recharzarSolicitud(data: any): Observable<any> {
    return this.httpClient.put(this.getFullPath() + `rechazar/solicitud` , data).pipe(map((res: any) => res));
  }

}
