import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AcuerdoPagoDescuentoDetalleServiceImpl } from '../../../../../core/http/configuracion-basica/impl/acuerdo-pago-descuento-detalle.service.impl';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from '../../../../../core/services/spinner.service';
import { ConceptoCuotaPrioridadServiceImpl } from 'src/app/core/http/acuerdo-pago/Impl/concepto-cuota-prioridad.impl';

@Component({
  selector: 'ef-gestion-descuentos-config-add',
  templateUrl: './gestion-descuentos-config-add.component.html',
  styleUrls: ['./gestion-descuentos-config-add.component.scss'],
})
export class GestionDescuentosConfigAddComponent implements OnInit {
  formAdd: FormGroup;
  loading = false;
  mensajeError = false;
  acuerdoPagoDescuentoId: any;
  aplicaciones = ['IGUAL', 'MENOR_IGUAL', 'MENOR', 'MAYOR_IGUAL', 'MAYOR'];
  conceptos: Array<any> = [];

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<GestionDescuentosConfigAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public acuerdoPagoDescuentoDetalleService: AcuerdoPagoDescuentoDetalleServiceImpl,
    public conceptoCuotaPrioridadService: ConceptoCuotaPrioridadServiceImpl,
    public toast: ToastrService
  ) {
    this.acuerdoPagoDescuentoId = data.acuerdoPagoDescuentoId;
  }

  ngOnInit(): void {
    this.formAdd = this.formBuilder.group({
      porcentaje: [null, [Validators.required]],
      aplicacion: [null, [Validators.required]],
      conceptoCuotaPrioridad: [null, [Validators.required]],
    });
    this.loadConceptos();
  }

  get form() {
    return this.formAdd.controls;
  }

  onFormSubmit() {
    if (navigator.onLine) {
      this.mensajeError = null;
      const descuentoNuevo = Object.assign({}, this.formAdd.value);
      const descuentoDetalle = {
        conceptoCuotaPrioridadId: descuentoNuevo.conceptoCuotaPrioridad.id,
        estado: 'ACTIVO',
        acuerdoPagoDescuentoId: this.acuerdoPagoDescuentoId,
        porcentaje: descuentoNuevo.porcentaje,
        aplicacion: descuentoNuevo.aplicacion
      }
      this.acuerdoPagoDescuentoDetalleService.save(descuentoDetalle).subscribe(
        {
          next: (descuento) => {
            this.dialogRef.close(descuento);
          },
          error: (error) => {
            try {
              for (const field of error.error.errors) {
                this.toast.error(field.message, 'Error');
              }
            } catch (e) {
              this.mensajeError = error.error.message;
            }
          }
        }
      );
    } else {
      this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
    }
  }

  loadConceptos() {
    
    this.conceptoCuotaPrioridadService.filter({ estado: 'ACTIVO' }).subscribe(
      {
        next: (res) => {
          this.conceptos = res.data;
          ;
        },
        error: (err) => {
          ;
          if (err.error.errors) {
            for (const error of err.error.errors) {
              this.toast.error(error.message, 'Error');
            }
          } else {
            if (err.error.message) {
              this.toast.error(err.error.message, 'Error');
            }
          }
        }
      }
    );
  }
}
