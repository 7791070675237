<form [formGroup]="formConfigOpcionProceso" novalidate autocomplete="off" class="popup-card-width">
	<div>
		<h2 mat-dialog-title i18n>Asignar Opción</h2>
	</div>
	<mat-dialog-content>
		<div>
			<div class="flex flex-col full-wid">
				<mat-form-field>
					<mat-placeholder>Opciones Disponibles</mat-placeholder>
					<mat-select formControlName="opcion">
						<mat-option *ngFor="let opcion of opciones" [value]="opcion">
							{{opcion.nombre}}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="form.opcion.touched && form.opcion.errors">
						<mat-error *ngIf="form.opcion.errors?.required" class="required alert-error">
							<span i18n>Este campo es requerido</span>
						</mat-error>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="flex flex-col full-wid">
				<mat-form-field>
					<input matInput type="number" formControlName="prioridad">
					<mat-placeholder>Prioridad</mat-placeholder>
					<mat-error *ngIf="form.prioridad.touched && form.prioridad.errors">
						<mat-error *ngIf="form.prioridad.errors?.required" class="required alert-error">
							<span i18n>Este campo es requerido</span>
						</mat-error>
						<mat-error *ngIf="form.prioridad.errors.numeric" class="required alert-error">
							<span i18n>Este campo debe ser numérico</span>
						</mat-error>
					</mat-error>
				</mat-form-field>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions class="mrgn-b-xs pad-b-none text-right mt-2">
		<button type="button" mat-raised-button (click)="dialogRef.close()">Cancelar
		</button>
		<button (click)="onFormSubmit()" mat-raised-button color="primary">Crear
		</button>
	</mat-dialog-actions>
</form>