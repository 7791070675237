import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from '@angular/common/http';
import { PlanLineaCreditoInterface } from '../plan-linea-credito.service.interface';

@Injectable({
  providedIn: 'root',
})
export class PlanLineaCreditoServiceImpl
  extends ServiceImplService<any>
  implements PlanLineaCreditoInterface
{
  constructor(public http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'plan-linea-credito/';
  }
}
