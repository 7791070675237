import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import ListResponse from '../models/core/list-response.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { IService } from './service.interface';

@Injectable({
	providedIn: 'root'
})
export class ServiceImplService<T> implements IService<T> {
	httpClient: HttpClient;
	apiUrl: string;
	resource: string;

	constructor() {
		this.apiUrl = environment.apiUrl;
	}

	getAll(): Observable<Array<any>> {
		return this.httpClient.get(this.getFullPath()).pipe(map((res: ListResponse<any>) => res.data));
	}

	get(id: string): Observable<any> {
		return this.httpClient.get(this.getFullPath() + id).pipe(map((res: any) => res.data));
	}

	save(data: any): Observable<number> {
		return this.httpClient.post(this.getFullPath(), data).pipe(map((res: any) => res.data));
	}

	update(id: string, data: any): Observable<string> {
		return this.httpClient.put(this.getFullPath() + id, data).pipe(map((res: any) => res));
	}

	delete(id: string): Observable<string> {
		return this.httpClient.delete(this.getFullPath() + id).pipe(map((res: any) => res.data));
	}

	post(data: any, path?: string): Observable<any> {
		if (!path) {
			path = '';
		}
		return this.httpClient.post(this.getFullPath() + path, data).pipe(map((res: any) => res.data));
	}

	filter(filter: any): Observable<ListResponse<any>> {
		filter = _.omitBy(filter, _.isNil);
		return this.httpClient.get(this.getFullPath(), { params: filter }).pipe(map((res: ListResponse<any>) => res));
	}

	filterExtended(filter: any): Observable<ListResponse<any>> {
		filter = _.omitBy(filter, [ _.isUndefined, _.isNull, _.isEmpty ]);
		return this.httpClient
			.get(this.getFullPath() + `filter-extended`, { params: filter })
			.pipe(map((res: ListResponse<any>) => res));
	}

	executeGet(path: string): Observable<any> {
		return this.httpClient.get(this.getFullPath() + path).pipe(map((res: any) => res));
	}

	executeGetPDF(path: string): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/pdf');
		return this.httpClient
			.get(this.getFullPath() + path, { headers, responseType: 'blob' })
			.pipe(map((res: any) => res));
	}

	executeDelete(path: string): Observable<any> {
		return this.httpClient.delete(this.getFullPath() + path).pipe(map((res: any) => res));
	}

	getFullPath() {
		return this.apiUrl + this.resource;
	}

	getFilterExtendedBase(filter: any): Observable<any> {
		const path = `filter-extended`;
		filter = _.omitBy(filter, [_.isUndefined, _.isNull, _.isEmpty]);
		return this.httpClient
		  .get(this.getFullPath() + path, { params: filter })
		  .pipe(map((res: any) => res));
	  }
}
