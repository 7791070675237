<div class="popup-card-width">
  <div class="gene-card-title">
    <div class="flex justify-center">
      <div class="flex flex-col">
        <h3 class="placeholder">Por favor ingrese el código de verificación</h3>
      </div>
      <span class="flex"></span>
      <button
        mat-icon-button
        (click)="exit()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-divider></mat-divider>
  </div>

  <mat-dialog-content>
    <div class="flex justify-center pad-all-lg">
      <div class="flex flex-col" *ngIf="type=='EMAIL'">
        <p>Se ha enviado un código de verificación de 6 dígitos a su correo electrónico</p>
      </div>
      <div class="flex flex-col" *ngIf="type=='CELLPHONE'">
        <p>Se ha enviado un código de verificación de 6 dígitos a su telefono celular por medio de SMS</p>
      </div>
    </div>

    <form [formGroup]="formCodigo" autocomplete="off">
      <div class="pad-all-md flex flex-wrap gap-2 justify-center">
        <div class="flex flex-col w-[10%]">
          <mat-form-field
            appearance="outline"
            color="primary">
            <input #inputNumber1 matInput type="text"
                   formControlName="number1"
                   maxlength="1"
                   class="size-field text-center">
          </mat-form-field>
        </div>
        <div class="flex flex-col w-[10%] field">
          <mat-form-field
            appearance="outline">
            <input #inputNumber2 matInput type="text"
                   formControlName="number2"
                   class="size-field text-center"
                   maxlength="1">
          </mat-form-field>
        </div>
        <div class="flex flex-col w-[10%] field">
          <mat-form-field
            appearance="outline">
            <input #inputNumber3 matInput type="text"
                   formControlName="number3"
                   class="size-field text-center"
                   maxlength="1">
          </mat-form-field>
        </div>
        <div class="flex flex-col w-[10%] field">
          <mat-form-field
            appearance="outline">
            <input #inputNumber4 matInput type="text"
                   formControlName="number4"
                   class="size-field text-center"
                   maxlength="1">
          </mat-form-field>
        </div>
        <div class="flex flex-col w-[10%] field">
          <mat-form-field
            appearance="outline">
            <input #inputNumber5 matInput type="text"
                   formControlName="number5"
                   class="size-field text-center"
                   maxlength="1">
          </mat-form-field>
        </div>
        <div class="flex flex-col w-[10%] field">
          <mat-form-field
            appearance="outline">
            <input #inputNumber6 matInput type="text"
                   formControlName="number6"
                   class="size-field text-center"
                   maxlength="1">
          </mat-form-field>
        </div>
      </div>


    </form>


  </mat-dialog-content>

  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-center">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading" class="margin-bottom"></mat-progress-bar>
    <button (click)="clearAll()"
            [disabled]="isLoading"
            mat-raised-button
            matTooltipPosition="above"
            matTooltip="Limpiar Campos"
            color="primary">
      <mat-icon>clear_all
      </mat-icon>
    </button>
    <button (click)="resendCode()"
            [disabled]="isLoading"
            mat-raised-button
            matTooltipPosition="above"
            matTooltip="Reenviar Codigo"
            color="primary">
      <mat-icon>sync</mat-icon>
    </button>
    <button (click)="clickConfirmButton()"
            [disabled] = "!formCodigo.valid"
            mat-raised-button
            color="primary"
            matTooltipPosition="above"
            matTooltip="Confirmar">
      <mat-icon>verified_user</mat-icon>
    </button>
  </mat-dialog-actions>
</div>
