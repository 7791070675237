import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ColorService {
	getColor(estado): string {
		let color = 'inactive-status-bg';
		switch (estado) {
			case 'ACTIVO':
			case 'APROBADO':
			case 'PAGADO':
				color = 'aprobado-status-bg';
				break;
			case 'ANULADO':
			case 'RECHAZADO':
			case 'CANCELADO':
				color = 'anulado-status-bg';
				break;
			case 'INACTIVO':
			case 'VENCIDO':
				color = 'inactive-status-bg';
				break;
			default:
				color = 'pendiente-status-bg';
		}
		return color;
	}
}
