<div>
    <h2 i18n mat-dialog-title>Duplicar Rol</h2>
</div>
<mat-dialog-content>
    <div class="column" class="popup-card-width">
        <div *ngIf="rolExistente" class="flex items-center justify-center">
            <h5>{{'Rol Existente: '+ rolExistente.name}}</h5>
        </div>
        <div>
            <mat-form-field class="margin-selects">
                <mat-label required>Rol Nuevo</mat-label>
                <mat-select [(ngModel)]="rolNuevo">
                    <mat-option *ngFor="let rol of roles" [value]="rol">
                        {{ rol.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button (click)="dialogRef.close(false)" mat-raised-button type="button">Cancelar
    </button>
    <button (click)="onSubmit()" [disabled]="rolNuevo == null" color="primary" mat-raised-button type="submit">
        Crear
    </button>
</mat-dialog-actions>