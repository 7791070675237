import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import CargoAdministrativo from 'src/app/core/models/configuracion-basica/cargo-administrativo-model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CargoAdministrativoServiceImpl } from 'src/app/core/http/configuracion-basica/impl/cargo-administrativo.service.impl';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import { EstadosHelper } from 'src/app/core/helpers/estados.helper';

import { PROCESOS_CARGO } from '../../../../core/constants/proceso-cargo.constants';

@Component({
  selector: 'ef-cargo-administrativo-edit',
  templateUrl: './cargo-administrativo-edit.component.html',
  styleUrls: ['./cargo-administrativo-edit.component.scss']
})
export class CargoAdministrativoEditComponent implements OnInit {
  formEditCargoAdministrativo: FormGroup;
  loading = false;
  mensajeError = false;
  cargoAdministrativo: CargoAdministrativo;
  procesos: Array<string> = Object.values(PROCESOS_CARGO);


  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CargoAdministrativoEditComponent>,
    public toast: ToastrService,

    public cargoAdministrativoService: CargoAdministrativoServiceImpl,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.cargoAdministrativo = data;
    this.procesos.push(this.cargoAdministrativo.proceso);
  }

  ngOnInit() {
    this.loading = true;
    this.formEditCargoAdministrativo = this.formBuilder.group({
      nombre: [
        '',
        [
          Validators.required,
          CustomValidatorsHelper.noWhitespaceValidator
        ]
      ],
      escala: [null, Validators.min(0)],
      proceso: [null, Validators.required],
      estado: [true],
      flagUserSecurity: [{ value: false, disabled: true }]
    });
    this.initForm();
  }

  initForm() {
    this.form.nombre.setValue(this.cargoAdministrativo.nombre);
    this.form.escala.setValue(this.cargoAdministrativo.escala);
    this.form.estado.setValue(
      EstadosHelper.isActivo(this.cargoAdministrativo.estado)
    );
    this.form.proceso.setValue(this.cargoAdministrativo.proceso);
    this.form.flagUserSecurity.setValue(
      this.cargoAdministrativo.flagUserSecurity
    );
    this.loading = false;
  }

  get form() {
    return this.formEditCargoAdministrativo.controls;
  }

  onFormSubmit() {

    this.mensajeError = null;
    const cargoAdminvoEdit = Object.assign(
      {},
      this.formEditCargoAdministrativo.value
    );
    cargoAdminvoEdit.flagUserSecurity = this.formEditCargoAdministrativo.getRawValue().flagUserSecurity;
    cargoAdminvoEdit.estado = EstadosHelper.getEstadoFromBoolean(
      this.formEditCargoAdministrativo.value.estado
    );
    this.cargoAdministrativoService
      .update(String(this.cargoAdministrativo.id), cargoAdminvoEdit)
      .subscribe(
        {
          next: response => {
            ;
            this.dialogRef.close(response)
          },
          error: error => {
            ;
            try {
              for (const field of error.error.errors) {
                this.toast.error(field.message, 'Error');
              }
            } catch (e) {
              this.mensajeError = e.error.message;
            }
          }
        }
      );
  }
}
