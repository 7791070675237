import { Component, OnInit, Inject } from '@angular/core';
import ProcesoModel from '../../../../core/models/configuracion-solicitud-credito/proceso.models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ef-proceso-detail',
  templateUrl: './proceso-detail.component.html',
  styleUrls: ['./proceso-detail.component.scss'],
})

export class ProcesoDetailComponent implements OnInit {
  loading = false;
  procesoDetail: ProcesoModel;

  constructor(
    public dialogRef: MatDialogRef<ProcesoDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.procesoDetail = data;
  }

  ngOnInit(): void { 
  }

}
