import { Component, OnInit, Inject, TemplateRef, ViewChild } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AcuerdoPagoPlanAmortizacionDetalleServiceImpl } from 'src/app/core/http/acuerdo-pago/Impl/acuerdo-pago-plan-amortizacion-detalle.service.impl';
import { AcuerdoPagoPlanAmortizacionServiceImpl } from 'src/app/core/http/acuerdo-pago/Impl/acuerdo-pago-plan-amortizacion.service.impl';
import { ToastrService } from 'ngx-toastr';
import { CurrencyPipe } from '@angular/common';

@Component({
	selector: 'ef-acuerdo-pago-plan-amortizacion-detalle',
	templateUrl: './acuerdo-pago-plan-amortizacion-detalle.component.html',
	styleUrls: [ './acuerdo-pago-plan-amortizacion-detalle.component.scss' ]
})
export class AcuerdoPagoPlanAmortizacionDetalleComponent implements OnInit {
	columnNames: Array<TableColumn>;
	amortizacionDetalle = [];
	messageEmpty = 'No hay registros';
	total = 0;
	isTable = false;
	isMessage = false;
	pageSize = 10;
	filter: any;

	@ViewChild('currencyTemplate', { static: true })
	currencyTemplate: TemplateRef<any>;

	constructor(
		public toast: ToastrService,
		public dialogRef: MatDialogRef<AcuerdoPagoPlanAmortizacionDetalleComponent>,
		public planAmortizacionDetalleService: AcuerdoPagoPlanAmortizacionDetalleServiceImpl,
		public planAmortizacionService: AcuerdoPagoPlanAmortizacionServiceImpl,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.filter = data;
	}

	ngOnInit(): void {
		this.columnNames = [
			{ name: 'Concepto', prop: 'conceptoCuotaPrioridad.concepto', cellClass: 'text-center' },
			{ name: 'Prioridad', prop: 'conceptoCuotaPrioridad.prioridad', cellClass: 'text-center' },
			{ name: 'Valor Pagado', prop: 'valorPagado', cellTemplate: this.currencyTemplate, cellClass: 'text-center' },
			{ name: 'Valor Total', prop: 'valorTotal', cellTemplate: this.currencyTemplate, cellClass: 'text-center' },
			{ name: 'Estado', prop: 'estado', cellClass: 'text-center' }
		];
		
		this.planAmortizacionService.getFilterExtended(this.filter).subscribe(
			(res) => {
				;
				if (res) {
					
					this.planAmortizacionDetalleService
						.getFilterExtended({ acuerdoPagoPlanAmortizacionId: res.data[0].id })
						.subscribe(
							(res) => {
								;
								if (res.data.length > 0) {
									this.amortizacionDetalle = res.data;
									this.isTable = true;
									this.isMessage = false;
									this.total = res.count;
								} else {
									this.isTable = false;
									this.isMessage = true;
								}
							},
							(err) => {
								;
								if (err.error.errors) {
									for (const error of err.error.errors) {
										this.toast.error(error.message, 'Error');
									}
								}
								if (err.error.message) {
									this.toast.error(err.error.message, 'Error');
								}
							}
						);
				}
			},
			(err) => {
				;
				if (err.error.errors) {
					for (const error of err.error.errors) {
						this.toast.error(error.message, 'Error');
					}
				}
				if (err.error.message) {
					this.toast.error(err.error.message, 'Error');
				}
			}
		);
	}

	getPageSize() {
		return this.filter.pageSize;
	}

	getTotalElements(): number {
		return this.total;
	}
}
