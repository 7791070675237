import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PersonaNotificacionServiceImpl } from 'src/app/core/http/configuracion-basica/impl/persona-notificacion-service.impl';
import { RegionEnum } from 'src/app/core/enums/RegionEnum';
import PersonaNotificacionInsertResquest from 'src/app/core/models/configuracion-basica/dto/PersonaNotificacionInsertResquest';
import ClienteValidacionNumeroDocumentoDto from 'src/app/core/models/personas/dtos/cliente-validacion-numero-documento.dto';
import { RegionHelper } from 'src/app/core/helpers/region.helper';

@Component({
  selector: 'ef-persona-notificacion-add',
  templateUrl: './persona-notificacion-add.component.html',
  styleUrls: ['./persona-notificacion-add.component.scss']
})
export class PersonaNotificacionAddComponent implements OnInit {

  formAdd: FormGroup;
  loading = false;
  mensajeError = false;

  procesos: Array<string>;
  regiones: Array<string>;

  formAddPersonas: FormGroup;
  personas: FormArray;

  regionHelper = new RegionHelper();

  constructor(public router: Router,
    public toast: ToastrService,
    
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PersonaNotificacionAddComponent>,
    public personaNotificacion: PersonaNotificacionServiceImpl) {
  }

  ngOnInit(): void {

    this.formAdd = this.formBuilder.group({
      proceso: [null, Validators.required],
      region: [null, [Validators.required]]
    });

    this.getProcesos();
    this.regiones = this.getRegiones();

    this.formAddPersonas = this.formBuilder.group({
      personas: this.formBuilder.array([])
    });

    this.addItem();
  }

  onFormSubmit() {

    if (this.formAddPersonas.valid) {

      let array = new Array();
      this.personas.getRawValue().forEach((item) => {
        array.push(item.persona);
      });

      const formValues = this.formAdd.getRawValue();

      let request = <PersonaNotificacionInsertResquest>{
        proceso: formValues.proceso,
        regionId: this.regionHelper.getFromEnum(formValues.region),
        personas: array
      };

      
      this.personaNotificacion.saveMasivo(request)
        .subscribe(
          {
            next: (res) => {
              ;
              this.toast.success('Registro Exitoso');
              this.dialogRef.close(true);
            },
            error: (err) => {
              ;
              if (err.error.errors) {
                for (const error of err.error.errors) {
                  this.toast.error(error.message, 'Error');
                }
              } else {
                if (err.error.message) {
                  this.toast.error(err.error.message, 'Error');
                }
              }

            }
          }
        );

    } else {
      this.toast.warning('Por favor validar la informacion del personal', 'Importante');

    }

  }

  setPersona(event, item) {
    if (event.nombreCompleto) {
      item.controls.nombreCompleto.setValue(event.nombreCompleto);
      item.controls.numeroDocumento.setValue(event.numeroDocumento);
      item.controls.persona.setValue(event);
    } else {
      item.controls.nombreCompleto.setValue(null);
      item.controls.persona.setValue(null);
      this.toast.warning('No se encontro persona con ese numero de documento', 'Importante');
    }
  }

  addItem() {
    this.personas = this.formAddPersonas.get('personas') as FormArray;
    this.personas.push(this.createItem());
  }

  createItem() {
    return this.formBuilder.group({
      nombreCompleto: [{ value: null, disabled: true }, [Validators.required]],
      numeroDocumento: [null, [Validators.required]],
      persona: [null, [Validators.required]],
    });
  }

  remove(index) {
    this.personas.removeAt(index);
  }

  getRegiones() {
    let documents = new Array<string>();
    for (let type in RegionEnum) {
      documents.push(RegionEnum[type]);
    }
    return documents;
  }

  getProcesos() {
    this.personaNotificacion.getProcesos()
      .subscribe(
        {
          next: (res) => {
            this.procesos = res;
          },
          error: () => {
            this.toast.error('Error al consultar los procesos en la base de datos', 'Error');
          }
        }
      );
  }

}
