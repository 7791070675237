import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import { GestionPasoServiceImpl } from 'src/app/core/http/configuracion-basica/impl/gestion-pasos.service.impl';

@Component({
  selector: 'ef-gestion-pasos-add',
  templateUrl: './gestion-pasos-add.component.html',
  styleUrls: ['./gestion-pasos-add.component.scss']
})
export class GestionPasosAddComponent implements OnInit {

  formAddPaso: FormGroup;
  loading = false;
  mensajeError = false;
  procesos = new Array<string>();
  caracteresEscritos: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private pasosService: GestionPasoServiceImpl,
    private toast: ToastrService,
    public dialogRef: MatDialogRef<GestionPasosAddComponent>,
  ) { }

  ngOnInit() {
    this.formAddPaso = this.formBuilder.group({
      nombre: ['', [Validators.required, CustomValidatorsHelper.noWhitespaceValidator]],
      path: ['', [Validators.maxLength(250)]],
      descripcion: ['', [Validators.required, CustomValidatorsHelper.noWhitespaceValidator, Validators.minLength(10), Validators.maxLength(500)]],
    });

  }

  get form() {
    return this.formAddPaso.controls;
  }

  onFormSubmit() {
    
    this.mensajeError = null;
    const formValues = Object.assign(
      {},
      this.formAddPaso.value
    );
    const newPaso = {
      estado: 'ACTIVO',
      nombre: formValues.nombre,
      path: formValues.path,
      descripcion: formValues.descripcion
    }
    this.pasosService.save(newPaso).subscribe(paso => {
      ;
      this.dialogRef.close(paso)
    },
      error => {
        ;
        try {
          for (const field of error.error.errors) {
            this.toast.error(field.message, 'Error');
          }
        } catch (e) {
        }
      }
    );
  }

  calcularCaracteresEscritos(event: Event): void {
    const texto = (event.target as HTMLTextAreaElement).value;
    this.caracteresEscritos = texto.length;
  }

}
