
<form [formGroup]="formAdd" novalidate autocomplete="off" class="popup-card-width">
    <h2 mat-dialog-title i18n>Nuevo Motor Consulta</h2>
    <mat-dialog-content>
        <div>
            <mat-form-field class="mt-2">
                <mat-label class="placeholder">Nombre</mat-label>
                <input matInput formControlName="nombre" required (keydown)="limitarCaracteresNombre($event)" maxlength="150"/>
                <mat-error *ngIf="formAdd.get('nombre').touched && formAdd.get('nombre').errors">
                    <mat-error *ngIf="formAdd.get('nombre').errors.required" class="required alert-error">
                        <span i18n>Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="form.nombre.touched && form.nombre.errors?.pattern">
                        Este campo no puede estar vacío o contener solo espacios en blanco.
                    </mat-error> 
                </mat-error>
            </mat-form-field>
            
            <mat-form-field class="mt-2">
                <mat-label class="placeholder dialog-label-size">Algoritmo</mat-label>
                <mat-select formControlName="algoritmo" required>
                    <mat-option *ngFor="let option of algoritmo" [value]="option">
                        {{ option }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formAdd.get('algoritmo').touched && formAdd.get('algoritmo').errors?.required"
                    class="required alert-error">
                    <span i18n>Este campo es requerido</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <textarea matInput formControlName="descripcion" (keydown)="limitarCaracteresDescripcion($event)" maxlength="1000" > </textarea>
               
                <mat-label class="placeholder">Descripción</mat-label>
                <mat-error *ngIf="formAdd.get('descripcion').touched && formAdd.get('descripcion').errors">
                
                    <mat-error *ngIf="formAdd.get('descripcion').errors.required" class="required alert-error">
                        <span i18n>Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="form.descripcion.touched && form.descripcion.errors?.pattern">
                        Este campo no puede estar vacío o contener solo espacios en blanco.
                    </mat-error>
                    <mat-error *ngIf="formAdd.get('descripcion').errors && formAdd.get('descripcion').errors.hasOwnProperty('maxlength')">
                        <span i18n>La descripción debe tener máximo 500 caracteres.</span>
                    </mat-error>
                    <mat-error
                        *ngIf="formAdd.get('descripcion').errors && formAdd.get('descripcion').errors.hasOwnProperty('minlength')">
                        <span i18n>La descripción debe tener minimo 10 caracteres.</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none">
        <div class="text-right mt-2">
            <button type="button" mat-raised-button (click)="dialogRef.close()">
                Cancelar
            </button>
            <button (click)="onFormSubmit()" [disabled]="!formAdd.valid" mat-raised-button
                class="accept-button margin-none">
                Crear
            </button>
        </div>
    </mat-dialog-actions>
</form>
 