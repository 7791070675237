import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import BarrioModel from 'src/app/core/models/ubicaciones/barrio.model';
import { Observable } from 'rxjs';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import DepartamentoModel from 'src/app/core/models/ubicaciones/departamento.model';
import MunicipioModel from 'src/app/core/models/ubicaciones/municipio.model';
import PaisModel from 'src/app/core/models/ubicaciones/pais.model';
import { DepartamentoServiceImpl } from 'src/app/core/http/ubicaciones/impl/departamento.service.impl';
import { DialogService } from 'src/app/core/services/dialog.service';
import { ToastrService } from 'ngx-toastr';
import { BarrioServiceImpl } from 'src/app/core/http/ubicaciones/impl/barrio.service.impl';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ef-select-barrio',
  templateUrl: './select-barrio.component.html',
  styleUrls: ['./select-barrio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectBarrioComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class SelectBarrioComponent implements ControlValueAccessor, OnInit, OnChanges {

  public barrio: BarrioModel;

  public barrios: Array<BarrioModel> = [];

  filteredBarrios: Observable<BarrioModel[]>;

  barrioControl = new FormControl(null, CustomValidatorsHelper.validateSelect);

  @Input()
  public departamento: DepartamentoModel;

  @Input()
  public municipio: MunicipioModel;

  @Input()
  public pais: PaisModel | any;

  @Input()
  width: any;

  @Input()
  label: any;

  @Input()
  isRequired = false;

  @Input()
  enableIcon = false;

  isDisabled = false;

  @Output()
  onCopyBarrio = new EventEmitter();

  @Output()
  public onSelectBarrio = new EventEmitter();

  loading = false;

  @Input()
  canAddBarrio = true;

  enableAddBarrio = false;

  onTouched = () => {
  };

  constructor(public departamentoService: DepartamentoServiceImpl,
    public dialogService: DialogService,
    public toast: ToastrService,
    public barrioService: BarrioServiceImpl) {

  }

  ngOnInit(): void {
    this.subscribeBarrios();
  }

  subscribeBarrios() {
    this.filteredBarrios = this.barrioControl.valueChanges
      .pipe(
        map((value: any) => {
          if (value && value.nombre) {
            value = value.nombre;
            this.enableAddBarrio = false;
          } else {
            this.enableAddBarrio = true;
          }
          return this._filter(value);
        }));
  }

  onChangeMunicipio() {
    if (this.municipio && this.departamento) {
      if (this.canAddBarrio) {
        this.enableAddBarrio = true;
      }
      this.departamentoService.getBarrios(this.departamento.id, this.municipio.id).subscribe(
        {
          next: (barrios: any) => {
            this.barrios = barrios.data;
            if (this.barrios.length == 1) {
              this.writeValue(this.barrios[0]);
              this.propagateChange(this.barrio);
              this.onTouched();
            }
            this.subscribeBarrios();
            this.barrioControl.enable();
          }
        }
      );
    }
  }

  onBarrioChange(event) {
    const newBarrio = <BarrioModel>event.option.value;
    this.onChange(event, newBarrio);
  }

  onChange(e: Event, value: any) {
    this.barrio = value;
    this.propagateChange(this.barrio);
    this.onTouched();
  }


  get value(): any {
    return this.barrio;
  };

  set value(v: any) {
    if (v !== this.barrio) {
      this.barrio = v;
    }
  }

  propagateChange = (_: any) => {
  };

  writeValue(barrio: any) {
    this.barrio = barrio;
    this.barrioControl.setValue(barrio);
  }


  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }


  displayFn(barrio?: BarrioModel): string | undefined {
    return barrio ? barrio.nombre : undefined;
  }

  public _filter(value: string): Array<BarrioModel> {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.barrios.filter(barrio => {
        return barrio.nombre.toLowerCase().includes(filterValue);
      });
    }
    return this.barrios;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const municipioChange = changes.municipio;
    if (municipioChange) {
      this.enableAddBarrio = false;
      const anteriorMunicipio = municipioChange.previousValue;
      const nuevoMunicipio = municipioChange.currentValue;

      if (anteriorMunicipio) {
        this.writeValue(null);
        this.barrioControl.setValue(null);
        this.propagateChange(this.barrio);
      }
      if (!nuevoMunicipio && !municipioChange.isFirstChange()) {
        this.barrios = [];
        this.barrioControl.disable();
      } else if (anteriorMunicipio !== nuevoMunicipio) {
        this.barrios = [];
        this.barrioControl.disable();
        this.municipio = nuevoMunicipio;
        this.onChangeMunicipio();
      }
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  copyBarrio() {
    this.onCopyBarrio.emit(true);
  }

  addNewBarrioDialog() {
    this.barrioControl.disable();
    this.dialogService.addNewBarrioDialog(this.pais, this.departamento, this.municipio).subscribe(
      {
        next: (barrio) => {
          this.barrioControl.enable();
          if (barrio) {
            this.getBarrio(barrio);
            this.barrios = [];
            this.onChangeMunicipio();
          }
        }
      });
  }

  getBarrio(idBarrio: any) {
    this.loading = true;
    let barrioAux: any;
    this.barrioService.get(idBarrio)
      .subscribe({
        next: (barrio: BarrioModel) => {
          this.loading = false;
          this.writeValue(barrio);
          this.onSelectBarrio.emit(barrio);
          this.propagateChange(this.barrio);
          this.onTouched();
        },
        error: () => {
          this.toast.error('Error al cargar el barrio, por favor seleccione el barrio creado', 'Error');
          this.barrioControl.setValue(null);
        }
      });
  }
}
