import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ef-cliente-detail',
  templateUrl: './cliente-detail.component.html',
  styleUrls: ['./cliente-detail.component.scss']
})
export class ClienteDetailComponent implements OnInit {

  fotoURL = 'https://image.flaticon.com/icons/svg/3011/3011276.svg';

  cliente;
  ubicacion;
  barrio;
  isDetail: boolean;
  fotoCliente;

  constructor(
    public dialogRef: MatDialogRef<ClienteDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.fotoCliente = data.fotoCliente;
    this.ubicacion = data.ubicacion;
    this.barrio = data.barrio;
    this.isDetail = data.isDetail;
    this.cliente = data.cliente;
  }

  ngOnInit(): void {
  }

  getFotoCliente(): string {
    if (this.fotoCliente != null) {
      this.fotoURL =
        'data:image/' +
        this.fotoCliente.extension +
        ';base64,' +
        this.fotoCliente.base64;
    }
    return this.fotoURL;
  }
}
