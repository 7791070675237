<form [formGroup]="formAdd" novalidate autocomplete="off" class="popup-card-width">
  <div>
    <h2 mat-dialog-title i18n>Nuevo Descuento Detalle</h2>
  </div>
  <mat-dialog-content>
    <div>
      <div>
        <mat-form-field>
          <mat-label class="placeholder">porcentaje</mat-label>
          <input matInput formControlName="porcentaje" type="number"/>
          <span matSuffix>%</span>
          <mat-error *ngIf="form.porcentaje.touched && form.porcentaje.errors">
            <mat-error *ngIf="form.porcentaje.errors.required" class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
            <mat-error *ngIf="!form.porcentaje.errors.required" class="required alert-error">
              <span i18n>{{ form.porcentaje.errors }}</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="margin-selects">
          <mat-label class="placeholder" required>Aplicación</mat-label>
          <mat-select formControlName="aplicacion" required>
            <mat-option *ngFor="let type of aplicaciones" [value]="type">
              {{ type | spaceUnderscore }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.aplicacion.errors">
            <mat-error *ngIf="form.aplicacion.errors.required" class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="margin-selects">
          <mat-label class="placeholder" required>Concepto</mat-label>
          <mat-select formControlName="conceptoCuotaPrioridad" required>
            <mat-option *ngFor="let type of conceptos" [value]="type">
              {{ type.concepto }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.conceptoCuotaPrioridad.errors">
            <mat-error *ngIf="form.conceptoCuotaPrioridad.errors.required" class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
      <button type="button" mat-raised-button (click)="dialogRef.close()">
        Cancelar
      </button>
      <button (click)="onFormSubmit()" [disabled]="!formAdd.valid" mat-raised-button class="accept-button">
        Crear
      </button>
  </mat-dialog-actions>
</form>
