<mat-form-field [style.width.%]="width">
  <input [required]="isRequired" [readonly]="isDisabled" type="text" matInput [matAutocomplete]="autocompleteBarrios"
         [formControl]="barrioControl" (click)="onTouched()"/>
  <mat-placeholder class="placeholder">{{label}}</mat-placeholder>
  <a
    *ngIf="enableIcon"
    (click)="copyBarrio()"
    matSuffix
    mat-icon-button
    matTooltipPosition="above"
    matTooltip="Autocompletar">
    <mat-icon>file_copy</mat-icon>
  </a>
  <a *ngIf="enableAddBarrio" (click)="addNewBarrioDialog()"
     matSuffix
     mat-icon-button
     matTooltipPosition="above"
     matTooltip="Nuevo barrio">
    <mat-icon>add_circle</mat-icon>
  </a>
  <mat-autocomplete #autocompleteBarrios="matAutocomplete" (optionSelected)="onBarrioChange($event)"
                    [displayWith]="displayFn">
    <mat-option *ngFor="let barrioItem of filteredBarrios | async" [value]="barrioItem">
      {{barrioItem.nombre}}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="barrioControl.errors">
    <mat-error *ngIf="barrioControl.errors.required"
               class="required alert-error">
      <span i18n>Este campo es requerido</span>
    </mat-error>
    <mat-error *ngIf="barrioControl.errors.valid"
               class="required alert-error text-erro">
      <span i18n>Seleccione un barrio</span>
    </mat-error>
  </mat-error>
</mat-form-field>
<mat-progress-bar mode="indeterminate" *ngIf="loading" class="margin-bottom"></mat-progress-bar>
<ng-content></ng-content>


