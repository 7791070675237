<div class="popup-card-width">
  <div>
    <h2 mat-dialog-title
        i18n>Observación</h2>
  </div>
  <mat-divider class="primary-border mb-4"></mat-divider>
  <mat-dialog-content *ngIf="observacionDetail">
    <div class="flex flex-wrap justify-between items-center" >
      <div class="flex w-full sm:w-[40%] md:w-[30%]" >
        <strong>Numero Solicitud:</strong>
      </div>
      <div class="flex w-full sm:w-[60%] md:w-[70%]" >
        <span>{{ observacionDetail.solicitudCredito.numeroSolicitud }}</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-between items-center">
      <div class="flex w-full sm:w-[40%] md:w-[30%]">
        <strong>Tipo:</strong>
      </div>
      <div class="flex w-full sm:w-[60%] md:w-[70%]">
        <span>{{ observacionDetail.tipo | spaceUnderscore}}</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-between items-center">
      <div class="flex w-full sm:w-[40%] md:w-[30%]">
        <strong>Fecha:</strong>
      </div>
      <div class="flex w-full sm:w-[60%] md:w-[70%]">
        <span>{{ observacionDetail.fecha }}</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-between items-center">
      <div class="flex w-full sm:w-[40%] md:w-[30%]">
        <strong>Hecho por:</strong>
      </div>
      <div class="flex w-full sm:w-[60%] md:w-[70%]">
				<span>{{observacionDetail.persona.nombreCompleto}}</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-between items-center">
      <div class="flex w-full sm:w-[40%] md:w-[30%]">
        <strong>Estado:</strong>
      </div>
      <div class="flex w-full sm:w-[60%] md:w-[70%]">
        <span>{{ observacionDetail.estado }}</span>
      </div>
    </div>

    <div class="flex flex-wrap justify-between items-center" *ngIf="observacionDetail.descripcion.length < 150">
      <div class="flex w-full sm:w-[40%] md:w-[30%]">
        <strong  class="text-justify">Descripcion:</strong>
      </div>
      <div class="flex w-full sm:w-[60%] md:w-[70%]">
        <span  class="text-justify">{{ observacionDetail.descripcion }}</span>
      </div>
    </div>
    <br>
    <div *ngIf="observacionDetail.descripcion.length >= 150">
      <div class="flex flex-wrap justify-between items-center" >
        <strong>Descripcion:</strong>
      </div>
      <div class="flex flex-wrap justify-between items-center">
        <p class="text-justify">{{ observacionDetail.descripcion }}</p>
      </div>
    </div>


  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button"
            mat-raised-button
            (click)="dialogRef.close()"
            [disabled]="loading">Cerrar
    </button>
  </mat-dialog-actions>
</div>
