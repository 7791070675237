
<mat-dialog-content style="min-width: 80vw;">
      <ef-plan-pago-trebol *ngIf="isComponentAmortizacionAcuerdoPago" [item]="acuerdoPago" [isDetail]="isDetail"
            (cuotasSeleted)="GenerarLinkPago($event, true)"></ef-plan-pago-trebol>
    <mat-dialog-actions class="mt-2 pad-b-none text-right">
        <button type="button" class="btn-color-volver" mat-raised-button (click)="dialogRef.close(false)">
            {{'Cancelar'}}
        </button>
        <button (click)="dialogRef.close(true)" mat-raised-button class="accept-button">
            {{'Aceptar'}}
        </button>
    </mat-dialog-actions>
</mat-dialog-content>