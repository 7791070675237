import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
	constructor(public sanitizer: DomSanitizer) {}

	transform(value) {
		return this.sanitizer.bypassSecurityTrustHtml(value);
	}
}
