<form [formGroup]="formFirmante" novalidate autocomplete="off" class="popup-card-width">
  <div>
    <h2 mat-dialog-title i18n>Editar Firmante</h2>
  </div>
  <div>
    <div>
      <mat-form-field class="mrgn-b-sm">
        <input matInput type="text" formControlName="nombre" placeholder="Nombre">
        <mat-error *ngIf="form.nombre.touched && form.nombre.errors">
          <mat-error *ngIf="form.nombre.errors?.required" class="required alert-error">
            <span i18n>Este campo es requerido</span>
          </mat-error>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close()">Cerrar
    </button>
    <button (click)="onFormSubmit()" [disabled]="!formFirmante.valid" mat-raised-button
      color="primary">Actualizar
    </button>
  </mat-dialog-actions>
</form>
