import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentoFaltanteServiceImpl } from 'src/app/core/http/solicitud-aprobacion-manual/impl/documento-faltante.service.impl';
import { FileHelper } from 'src/app/core/helpers/file.helper';

@Component({
  selector: 'ef-documentos-faltantes-add',
  templateUrl: './documentos-faltantes-add.component.html',
  styleUrls: ['./documentos-faltantes-add.component.scss']
})
export class DocumentosFaltantesAddComponent implements OnInit {

  tipos: Array<string>;

  formAddFiles: FormGroup;
  files: FormArray;

  solicitudId: any;

  constructor(public router: Router,
    public toast: ToastrService,

    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DocumentosFaltantesAddComponent>,
    public documentosFaltantes: DocumentoFaltanteServiceImpl,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.solicitudId = data.solicitudId;
  }

  ngOnInit(): void {
    this.getTipos();
    this.formAddFiles = this.formBuilder.group({
      files: this.formBuilder.array([])
    });
    this.addItem();
  }

  onFormSubmit() {
    let documents = new Array();
    this.files.getRawValue().forEach((item) => {
      let insert = {
        tipoDocumento: item.tipoDocumento,
        descripcion: item.descripcion,
        nombre: item.tipoDocumento,
        extension: 'pdf',
        base64: String(item.base64).split('data:application/pdf;base64,')[1],
        kbytes: this.calculateImageSize(item.base64)
      };
      documents.push(insert);
    });


    let insertRequest = {
      solicitudId: this.solicitudId,
      documents: documents
    };

    this.documentosFaltantes.saveMasivo(insertRequest)
      .subscribe(
        {
          next: (res: any) => {
            let count = 0;
            res.result.forEach((item) => {
              if (!item.status) {
                this.toast.error('No fue posible cargar el documento ' + item.tipoDocumento, 'Error');
                count++;
              }
            });

            if (count == 0) {
              this.toast.success('Todos los archivos fueron cargados exitosamente');
            }
            this.dialogRef.close(true);
          },
          error: (err) => {
            ;
            if (err.error.errors) {
              for (const error of err.error.errors) {
                this.toast.error(error.message, 'Error');
              }
            } else {
              if (err.error.message) {
                this.toast.error(err.error.message, 'Error');
              } else if (err.error.statusCode == 413) {
                this.toast.error('El archivo debe pesar menos de 1MB.', 'Error');
              }
            }
          }
        });

  }


  calculateImageSize(base64): any {
    let padding, inBytes, base64Length;
    if (base64.endsWith('==')) {
      padding = 2;
    } else if (base64.endsWith('=')) {
      padding = 1;
    } else {
      padding = 0;
    }
    base64Length = base64.length;
    inBytes = (base64Length / 4) * 3 - padding;
    let kbytes = inBytes / 1000;
    return kbytes;
  }

  addItem() {
    this.files = this.formAddFiles.get('files') as FormArray;
    this.files.push(this.createItem());
  }

  createItem() {
    return this.formBuilder.group({
      tipoDocumento: [null, [Validators.required]],
      base64: [null, [Validators.required]],
      descripcion: [null, [Validators.required]],
    });
  }

  remove(index) {
    this.files.removeAt(index);
  }

  getTipos() {
    this.documentosFaltantes.getTiposDocumento()
      .subscribe(
        {
          next: (res) => {
            this.tipos = res;
          },
          error: () => {
            this.toast.error('Error al consultar los tipos en la base de datos', 'Error');
          }
        }
      );
  }

  fileChangeEvent(event, item) {
    let filesToUpload = <Array<File>>event.target.files;
    if (filesToUpload[0].type != 'application/pdf') {
      this.toast.warning('Por favor solo seleccionar archivos en formato PDF', 'Importante');
      item.controls.base64.setValue(null);
      return;
    }
    FileHelper.toBase64(filesToUpload[0]).then((data: string) => {
      item.controls.base64.setValue(data);
    });
  }

}
