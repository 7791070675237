export default class BaseFilter {

	pageNumber: number;
	pageSize: number;
	orderField: string;
	orderType: string;

	constructor() {
		this.pageSize = 10;
		this.pageNumber = 0;
	}

}
