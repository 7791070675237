import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { SecurityRolPermisoServiceImpl } from 'src/app/core/http/security/impl/security-rol-permiso.service.impl';
import { SecurityRolServiceImpl } from 'src/app/core/http/security/impl/security-rol.service.impl';

@Component({
  selector: 'jt-rol-duplicar',
  templateUrl: './rol-duplicar.component.html',
  styleUrls: ['./rol-duplicar.component.scss']
})
export class RolDuplicarComponent implements OnInit {

  roles: any[] = [];
  rolNuevo: any = null;
  rolExistente: any;

  constructor(
		public   dialogRef: MatDialogRef<RolDuplicarComponent>,
		
		public  toast: ToastrService,
		@Inject(MAT_DIALOG_DATA) public   data: any,
		public  rolPermisoService: SecurityRolPermisoServiceImpl,
    public  rolService: SecurityRolServiceImpl,
  ) { 
    this.rolExistente = data.rol;
    
  }

  ngOnInit() {
    
    this.rolService.filter({estado: "ACTIVO"}).subscribe({next: (res:any) => {
      
      this.roles = res.data;
    }})
  }

  onSubmit() {
    
    this.rolPermisoService.duplicarRol(this.rolNuevo.id, this.rolExistente.id).subscribe({next: (data:any) => {
      
      this.toast.success(`Los permisos del Rol existente ${this.rolExistente.name} han sido copiados exitosamente al rol nuevo ${this.rolNuevo.name}`);
      this.dialogRef.close();
    }})
  }
}
