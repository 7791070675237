<div class="container">
  <div class="dropzone mrgn-y-sm w-3/4"  (drop)="onFileDropped($event)"
                                        (dragover)="onDragOver($event)"
                                        (dragleave)="onDragLeave($event)"
                                        (dragenter)="onDragEnter($event)">
      <input type="file" class="d-none" fileDropRef id="fileDropRef"
          (change)="onFileChanged($event)" accept=".pdf"/>
      <mat-icon class="icon-aux"> upload_file </mat-icon>
      <h4> Arrastra tu archivo hasta aquí </h4>
      <h5> -- o --</h5>
      <label for="fileDropRef">Selecciona un archivo</label>
  </div>

  <div class="file" *ngIf="selectedFile">
    <div class="d-flex gap-2">
      <mat-icon class="icon-aux"> insert_drive_file </mat-icon>
      <span>{{selectedFile.name}}</span>
    </div>
    <div class="options">
      <button class="mrgn-all-xs cancel-button" mat-raised-button mat-icon-button mat-button-sm
                #tooltip="matTooltip" matTooltip="Previsualizar Instructivo" matTooltipPosition="above" (click)="preview()">
                <mat-icon>search</mat-icon>
      </button>
      <button class="mrgn-all-xs cancel-button" mat-raised-button mat-icon-button mat-button-sm
          #tooltip="matTooltip" matTooltip="Eliminar Instructivo" matTooltipPosition="above"
          (click)="clearFile()">
          <mat-icon>close</mat-icon>
        </button>
    </div>
  </div>
  <div class="buttons">
    <button mat-raised-button *efActionValidate="'linea-credito:crear'" color="primary"
    (click)="closeModal()">
    <span i18n>Cancelar</span>
  </button>
  <button mat-raised-button *efActionValidate="'linea-credito:crear'" color="primary"
      (click)="onUpload()" [disabled]="!selectedFile">
      <span i18n>Guardar instructivo</span>
      <mat-icon class="mrgn-l-md gene-text-xl">save_alt</mat-icon>
  </button>
  </div>

</div>
