import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ef-gestion-descuentos-detail',
  templateUrl: './gestion-descuentos-detail.component.html',
  styleUrls: ['./gestion-descuentos-detail.component.scss']
})
export class GestionDescuentosDetailComponent implements OnInit {

  loading = false;
  descuentoDetail: any;

  constructor(
    public dialogRef: MatDialogRef<GestionDescuentosDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.descuentoDetail = data;
   }

  ngOnInit(): void {
  }

}
