import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import OpcionVistaModel from 'src/app/core/models/configuracion-solicitud-credito/opcion-vista.models';

@Component({
  selector: 'ef-opcion-vista-detail',
  templateUrl: './opcion-vista-detail.component.html',
  styleUrls: ['./opcion-vista-detail.component.scss'],
})
export class OpcionVistaDetailComponent implements OnInit {
  loading = false;
  opcionVistaDetail: OpcionVistaModel;

  constructor(
    public dialogRef: MatDialogRef<OpcionVistaDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.opcionVistaDetail = data;
  }

  ngOnInit(): void { 
  }

  
}
