export default class SessionToken {
  token: string;
  permissions: Array<string>;
  name: string;
  lastLogin: number;
  expiresIn: number;
  username: string;
  email: string;
  key?: string;
}
