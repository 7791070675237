<form [formGroup]="formAdd" novalidate autocomplete="off" class="popup-card-width">
    <h2 mat-dialog-title i18n>Nuevo Historial De Consulta De Sarlaft</h2>
    <mat-dialog-content *ngIf="!loading; else spinner">
        <div>
            <mat-form-field class="mt-2">
                <mat-label class="placeholder">Nombre</mat-label>
                <input matInput formControlName="nombre" required />
                <mat-error *ngIf="formAdd.get('nombre').touched && formAdd.get('nombre').errors">
                    <mat-error *ngIf="formAdd.get('nombre').errors.required" class="required alert-error">
                        <span i18n>Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="formAdd.get('nombre').errors?.pattern">
                        Este campo no puede estar vacío o contener solo espacios en blanco.
                    </mat-error>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="mt-2">
                <mat-label class="placeholder dialog-label-size">Tipo de Documento</mat-label>
                <mat-select formControlName="tipoDocumento" required>
                    <mat-option *ngFor="let option of tipoDocumentos" [value]="option">
                        {{ option }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formAdd.get('tipoDocumento').touched && formAdd.get('tipoDocumento').errors?.required"
                    class="required alert-error">
                    <span i18n>Este campo es requerido</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="mt-2">
                <mat-label class="placeholder">Documento</mat-label>
                <input type="number" matInput formControlName="documento" required />
                <mat-error *ngIf="formAdd.get('documento').touched && formAdd.get('documento').errors">
                    <mat-error *ngIf="formAdd.get('documento').errors.required" class="required alert-error">
                        <span i18n>Este campo es requerido</span>
                    </mat-error>
                    <mat-error *ngIf="formAdd.get('documento').errors?.pattern">
                        Este campo no puede tener letras, puntos o caracteres especiales.
                    </mat-error>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="mt-2">
                <mat-label class="placeholder dialog-label-size">Decisión</mat-label>
                <mat-select formControlName="decision" required>
                    <mat-option *ngFor="let option of decision" [value]="option">
                        {{ option }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formAdd.get('decision').touched && formAdd.get('decision').errors?.required"
                    class="required alert-error">
                    <span i18n>Este campo es requerido</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="mrgn-b-sm w-full">
                <textarea matInput formControlName="observacion" (input)="calcularCaracteresEscritos($event)" > </textarea>
                <p class="mb-0">{{ caracteresEscritos }}/500</p>
                <mat-label class="placeholder">Observación</mat-label>
                <mat-error *ngIf="formAdd.get('observacion').touched && formAdd.get('observacion').errors">
                    <mat-error *ngIf="formAdd.get('observacion').errors.required" class="required alert-error">
                        <span i18n>Este campo es requerido</span>
                    </mat-error>
                    <mat-error
                        *ngIf="formAdd.get('observacion').errors && formAdd.get('observacion').errors.hasOwnProperty('maxlength')">
                        <span i18n>La descripción debe tener como máximo 1000 caracteres.</span>
                    </mat-error>
                    <mat-error
                        *ngIf="formAdd.get('observacion').errors && formAdd.get('observacion').errors.hasOwnProperty('minlength')">
                        <span i18n>La descripción debe tener como minimo 10 caracteres.</span>
                    </mat-error>
                </mat-error>
            </mat-form-field>
            <div class="flex flex-col w-full mt-2">
                <label>Archivo de PDF</label>
                <div
                    class="custom-file-upload block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50">
                    <button type="button" class="upload-button" (click)="triggerFileInput()">Seleccionar
                        archivo</button>
                    <input #fileInput class="hidden-file-input" type="file" accept="application/pdf"
                        (change)="fileChangeEvent($event)" />
                    <p class="!mb-0">{{ fileName }}</p>
                    <button *ngIf="fileName !== 'No ha seleccionado ningún archivo'" class="flex mr-2 button-icon"
                        (click)="clearFile()"><mat-icon>delete</mat-icon></button>
                </div>
                <p class="mt-1 text-sm text-gray-500">.PDF (Máx. 1MB)</p>
            </div>
        </div>
    </mat-dialog-content>
    <ng-template #spinner>
        <mat-spinner class="m-auto"></mat-spinner>
    </ng-template>

    <mat-dialog-actions class="mrgn-b-xs pad-b-none">
        <div class="text-right mt-2">
            <button type="button" mat-raised-button (click)="cerrarModal()">
                Cancelar
            </button>
            <button (click)="onFormSubmit()" [disabled]="!formAdd.valid || !base64File" mat-raised-button
                class="accept-button mat-green-button margin-none">
                Crear
            </button>
        </div>
    </mat-dialog-actions>
</form>