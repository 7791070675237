<div class="popup-card-width" *ngIf="!loading">
	<div class="gene-card-title">
		<div class="flex flex-row items-center">
			<div class="flex flex-col">
				<h3 *ngIf="opcion == 5">{{'Cargar Archivo'}}</h3>
			</div>
		</div>
		<mat-divider></mat-divider>
	</div>

	<mat-dialog-content>

		<div class="row mt-3">
			<div class="col-12 mb-3 sm:mb-4" *ngIf=" opcion == 5">
				<h5>
					Por favor seleccione el archivo para cargar.
				</h5>
			</div>
			<div class="col-12 text-center mb-2">
				<input #file type="file" class="d-none" id="file" (change)='fileChangeEvent($event)'
					accept="application/xls" />
				<label for="file" class="uploadLabel mrgn-b-none" style="cursor:pointer;">Selecciona un archivo</label>
			</div>
			<div class="col-12 text-center mb-4">
				<div *ngFor="let document of documentList; let i = index">
					{{document.name}}
				</div>
			</div>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
		<button type="button" mat-raised-button (click)="dialogRef.close(null)" matTooltipPosition="above"
			matTooltip="Cancelar">Cancelar
		</button>
		<button (click)="aceptar()" matTooltipPosition="above" matTooltip="Aceptar" color="primary" mat-raised-button
			color="primary">
			Cargar documento
		</button>
	</mat-dialog-actions>
</div>

<mat-card class="m-4 spinner-card" *ngIf="loading">
	<div class="spinner-container">
		<mat-spinner diameter="100"></mat-spinner>
		<span class="loading-text">
			Estamos procesando el archivo, esto puede tardar un momento por favor espere...
			<br>
			<div class="text-center">
				<strong>
					{{porcentajeCarga}}
				</strong>
			</div>
		</span>
	</div>
</mat-card>