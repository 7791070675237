import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Toast, ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { PlanCreditoServiceImpl } from 'src/app/core/http/configuracion-basica/impl/plan-credito.service.impl';
import { DialogService } from 'src/app/core/services/dialog.service';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ef-plan-credito-add',
  templateUrl: './plan-credito-add.component.html',
  styleUrls: ['./plan-credito-add.component.scss'],
})
export class PlanCreditoAddComponent implements OnInit {
  formAddPlanCredito: FormGroup;
  loading = false;
  mensajeError = false;
  flagPlanEspecial = false;
  calculosValores: string[] = ['VALOR_FIJO', 'VALOR_DINAMICO'];
  isValorFijo: boolean = false;

  constructor(
    public router: Router,
    public toast: ToastrService,
    
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PlanCreditoAddComponent>,
    public planCreditoService: PlanCreditoServiceImpl
  ) { }

  ngOnInit(): void {
    this.formAddPlanCredito = this.formBuilder.group({
      nombre: ['', Validators.required],
      flagPlanEspecial: [false],
      valorFinanciado: [
        '',
        [Validators.min(0), CustomValidatorsHelper.numeric],
      ],
      valorMensual: ['', [Validators.min(0), CustomValidatorsHelper.numeric]],
      numeroCuota: ['', [Validators.min(0), CustomValidatorsHelper.numeric]],
      calculoValor: [null, Validators.required],
    });
    this.subscribeCalculoValor();
  }

  subscribeCalculoValor() {
    this.formAddPlanCredito
      .get('calculoValor')
      .valueChanges.subscribe({
        next: (calculoValor) => {
          if (calculoValor == 'VALOR_FIJO') {
            this.isValorFijo = true;
            this.formAddPlanCredito
              .get('numeroCuota')
              .setValidators(Validators.required);
            this.formAddPlanCredito
              .get('valorMensual')
              .setValidators(Validators.required);
            this.formAddPlanCredito
              .get('valorFinanciado')
              .setValidators(Validators.required);
          }
          if (calculoValor == 'VALOR_DINAMICO') {
            this.isValorFijo = false;
            this.formAddPlanCredito.get('numeroCuota').setValue(0);
            this.formAddPlanCredito.get('valorMensual').setValue(0);
            this.formAddPlanCredito.get('valorFinanciado').setValue(0);
          }
        }
      });
  }

  eventToggleChange(event) {
    this.flagPlanEspecial = event.checked;
  }

  get form() {
    return this.formAddPlanCredito.controls;
  }

  onFormSubmit() {
    
    const formValues = Object.assign({}, this.formAddPlanCredito.value);
    let valorFinanciado = formValues.valorFinanciado
      .toString()
      .trim()
      .replace(/,/g, '');
    let valorMensual = formValues.valorMensual
      .toString()
      .trim()
      .replace(/,/g, '');

    const planCredito = {
      nombre: formValues.nombre,
      valorFinanaciado: formValues.valorFinanciado,
      numeroCouta: formValues.numeroCuota,
      valorMensaul: formValues.valorMensual,
      valorFinanciadoTexto: this.convertNumeroALetras(valorFinanciado).trim(),
      valorMensualTexto: this.convertNumeroALetras(valorMensual).trim(),
      flagPlanEspecial: this.flagPlanEspecial,
      estado: 'ACTIVO',
      calculoValor: formValues.calculoValor,
    };
    this.planCreditoService.save(planCredito).subscribe(
      {
        next: (plancreditoId: any) => {
          ;
          this.dialogRef.close(plancreditoId);
        },
        error: (err) => {
          ;
          if (err.error.errors) {
            for (const error of err.error.errors) {
              this.toast.error(error.message, 'Error');
            }
          } else {
            if (err.error.message) {
              this.toast.error(err.error.message, 'Error');
            }
          }
        }
      }
    );
  }

  //METODOS CONVERTIR VALOR NUMERICO EN TEXTO

  getUnidades(num) {
    switch (num) {
      case 1:
        return 'UN ';
      case 2:
        return 'DOS ';
      case 3:
        return 'TRES ';
      case 4:
        return 'CUATRO ';
      case 5:
        return 'CINCO ';
      case 6:
        return 'SEIS ';
      case 7:
        return 'SIETE ';
      case 8:
        return 'OCHO ';
      case 9:
        return 'NUEVE ';
    }
    return '';
  }

  getDecenas(num) {
    let decena = Math.floor(num / 10);
    let unidad = num - decena * 10;

    switch (decena) {
      case 1:
        switch (unidad) {
          case 0:
            return 'DIEZ ';
          case 1:
            return 'ONCE ';
          case 2:
            return 'DOCE ';
          case 3:
            return 'TRECE ';
          case 4:
            return 'CATORCE ';
          case 5:
            return 'QUINCE ';
          default:
            return 'DIECI ' + this.getUnidades(unidad);
        }
      case 2:
        switch (unidad) {
          case 0:
            return 'VEINTE';
          default:
            return 'VEINTI' + this.getUnidades(unidad);
        }
      case 3:
        return this.getDecenasY('TREINTA', unidad);
      case 4:
        return this.getDecenasY('CUARENTA', unidad);
      case 5:
        return this.getDecenasY('CINCUENTA', unidad);
      case 6:
        return this.getDecenasY('SESENTA', unidad);
      case 7:
        return this.getDecenasY('SETENTA', unidad);
      case 8:
        return this.getDecenasY('OCHENTA', unidad);
      case 9:
        return this.getDecenasY('NOVENTA', unidad);
      case 0:
        return this.getUnidades(unidad);
    }
  }

  getDecenasY(strSin, numUnidades) {
    if (numUnidades > 0) {
      return strSin + ' Y ' + this.getUnidades(numUnidades);
    }
    return strSin;
  }

  getCentenas(num) {
    let centenas = Math.floor(num / 100);
    let decenas = num - centenas * 100;

    switch (centenas) {
      case 1:
        if (decenas > 0) {
          return 'CIENTO ' + this.getDecenas(decenas);
        }
        return 'CIEN ';
      case 2:
        return 'DOSCIENTOS ' + this.getDecenas(decenas);
      case 3:
        return 'TRESCIENTOS ' + this.getDecenas(decenas);
      case 4:
        return 'CUATROCIENTOS ' + this.getDecenas(decenas);
      case 5:
        return 'QUINIENTOS ' + this.getDecenas(decenas);
      case 6:
        return 'SEISCIENTOS ' + this.getDecenas(decenas);
      case 7:
        return 'SETECIENTOS ' + this.getDecenas(decenas);
      case 8:
        return 'OCHOCIENTOS ' + this.getDecenas(decenas);
      case 9:
        return 'NOVECIENTOS ' + this.getDecenas(decenas);
    }
    return this.getDecenas(decenas);
  }

  getSeccion(num, divisor, strSingular, strPlural) {
    let cientos = Math.floor(num / divisor);
    let resto = num - cientos * divisor;

    let letras = '';

    if (cientos > 0) {
      if (cientos > 1) {
        letras = this.getCentenas(cientos) + '' + strPlural;
      } else {
        letras = strSingular;
      }
    }

    if (resto > 0) {
      letras += '';
    }

    return letras;
  }

  getMiles(num) {
    let divisor = 1000;
    let cientos = Math.floor(num / divisor);
    let resto = num - cientos * divisor;

    let strMiles = this.getSeccion(num, divisor, 'UN MIL', 'MIL');
    let strCentenas = this.getCentenas(resto);

    if (strMiles == '') {
      return strCentenas;
    }

    return strMiles + ' ' + strCentenas;
  }

  getMillones(num) {
    let divisor = 1000000;
    let cientos = Math.floor(num / divisor);
    let resto = num - cientos * divisor;

    let strMillones = this.getSeccion(num, divisor, 'UN MILLON ', 'MILLONES ');
    let strMiles = this.getMiles(resto);

    if (strMillones == '') {
      return strMiles;
    }

    return strMillones + ' ' + strMiles;
  }

  convertNumeroALetras(num) {
    let data = {
      numero: num,
      enteros: Math.floor(num),
      centavos: Math.round(num * 100) - Math.floor(num) * 100,
      letrasCentavos: '',
      letrasMonedaPlural: 'PESOS', //“PESOS”, 'Dólares', 'Bolívares', 'etcs'
      letrasMonedaSingular: 'PESO', //“PESO”, 'Dólar', 'Bolivar', 'etc'
      letrasMonedaCentavoPlural: '',
      letrasMonedaCentavoSingular: '',
    };

    if (data.centavos > 0) {
      data.letrasCentavos =
        'CON ' +
        (function () {
          if (data.centavos == 1) {
            return (
              this.getMillones(data.centavos) +
              ' ' +
              data.letrasMonedaCentavoSingular
            );
          } else {
            return (
              this.getMillones(data.centavos) +
              ' ' +
              data.letrasMonedaCentavoPlural
            );
          }
        })();
    }

    if (data.enteros == 0) {
      return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
    }
    if (data.enteros == 1) {
      return (
        this.getMillones(data.enteros) +
        ' ' +
        data.letrasMonedaSingular +
        ' ' +
        data.letrasCentavos
      );
    } else {
      return (
        this.getMillones(data.enteros) +
        ' ' +
        data.letrasMonedaPlural +
        ' ' +
        data.letrasCentavos
      );
    }
  }
}
