import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ESTADOS_BASE } from 'src/app/core/constants/estados.constants';
import { MotorConsultaServiceImpl } from 'src/app/core/http/configuracion-solicitud-credito/impl/motor-consulta.service.impl';

@Component({
  selector: 'ef-motor-consulta-add',
  templateUrl: './motor-consulta-add.component.html',
  styleUrls: ['./motor-consulta-add.component.scss']
})
export class MotorConsultaAddComponent implements OnInit {


  formAdd: FormGroup;
  algoritmo = ['AQMGO_1', 'AQMGO_2'];
  caracteresEscritos: number = 0;
  motorConsultaNombres: string[];
  loading = false;
  mensajeError = false;
  id: number;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<MotorConsultaAddComponent>,
    private motorConsultaService: MotorConsultaServiceImpl,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) {
    this.id = this.data;
  }

  ngOnInit(): void {
    this.formAdd = this.formBuilder.group({
      nombre: [null, [Validators.required, Validators.pattern(/^\S.*\S$/)]],
      descripcion: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(1000), Validators.pattern(/^\S.*\S$/)]],
      algoritmo: [this.algoritmo[0], [Validators.required]],
    });

    this.motorConsultaService.filter({
      estado: ESTADOS_BASE.ACTIVO,
      id: this.id
    })
      .subscribe({
        next: (rest) => {
          this.motorConsultaNombres = rest.data.map(el => el.nombre.toLowerCase());
        }
      })
  }

  get form() {
    return this.formAdd.controls;
  }

  onFormSubmit() {
    if (navigator.onLine) {
      if (this.formAdd.valid) {
        const motorConsultaNuevo = { ...this.formAdd.value, estado: ESTADOS_BASE.ACTIVO };
        const existNombre = this.motorConsultaNombres.includes(motorConsultaNuevo.nombre.toLowerCase())
        if (existNombre) {
          this.toast.error('Este nombre ya esta en uso');
          return;
        }
        this.motorConsultaService.save(motorConsultaNuevo).subscribe(
          {
            next: (motorConsulta) => {
              this.dialogRef.close(motorConsulta);
            },
            error: (error) => {
              const errorMessage = error.error.message || 'Ha ocurrido un error';
              this.toast.error(errorMessage, 'Error');
            }
          }
        );
      } else {
        this.toast.error('Por favor, completa el formulario correctamente', 'Error');
      }
    } else {
      this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
    }
  }

  limitarCaracteresNombre(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.value.length === 150 && event.key !== 'Backspace') {
      this.toast.warning('Has alcanzado el límite máximo de 150 caracteres en el nombre.');
      event.preventDefault();
    }
  }

  limitarCaracteresDescripcion(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.value.length === 1000 && event.key !== 'Backspace') {
      this.toast.warning('Has alcanzado el límite máximo de 1000 caracteres en la descripción.');
      event.preventDefault();
    }
  }

}
