<div class="popup-card-width">
  <h2 mat-dialog-title i18n>Rechazar Acuerdo de Pago</h2>
  <mat-dialog-content>
    <div class="flex py-4">
      <div class="w-1/2 text-center">
        <strong>Contrato Académico</strong>
        <p>{{contratoAcademico}}</p>
      </div>
      <div class="w-1/2 text-center">
        <strong>Contrato Edufactoring</strong>
        <p>{{contratoEdufactoring}}</p>
      </div>
    </div>
    <div class="flex py-4">
      <div class="w-1/2 text-center">
        <strong>Nombre Titular</strong>
        <p>{{nombreTitular}}</p>
      </div>
      <div class="w-1/2 text-center">
        <strong>Valor Financiado</strong>
        <p>{{valorFinanciado}}</p>
      </div>
    </div>
    <mat-form-field>
      <mat-placeholder>Tipo Rechazo</mat-placeholder>
      <mat-select (selectionChange)="selectTipoRechazo($event)">
        <mat-option *ngFor="let tipoRechazo of getTipoRechazo()" [value]="tipoRechazo">
          {{ tipoRechazo }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="legacy">
      <mat-label>Observación</mat-label>
      <textarea [(ngModel)]="observacion" matInput></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions class="flex justify-end items-end">
    <button mat-raised-button (click)="dialogRef.close(false)"> Cerrar </button>
    <button mat-raised-button color="primary" matTooltip="Rechazar" (click)="rechazarAcuerdoPago()">
      Rechazar Acuerdo de Pago
    </button>
  </mat-dialog-actions>
</div>