import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { ConceptoCuotaPrioridadServiceInterface } from '../concepto-cuota-prioridad';

@Injectable({
	providedIn: 'root'
})
export class ConceptoCuotaPrioridadServiceImpl extends ServiceImplService<any>
	implements ConceptoCuotaPrioridadServiceInterface {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'concepto-cuota-prioridad/';
	}

}