import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Auth0ServiceImpl } from './core/http/security/impl/auth0.service.impl';
import { LocalStorageHelper } from './core/helpers/local-storage.helper';
import { SessionCheckerHelper } from './core/helpers/sessionChecker.helper';
import { LOCAL_STORAGE_CONSTANTS } from './core/constants/local-storage.constants';
import { environment } from 'src/environments/environment';

declare var gtag: (arg0: string, arg1: string, arg2: { page_path: string }) => void;

@Component({
  selector: 'ef-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Edufactoring-cliente';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth0Service: Auth0ServiceImpl,
    private localStorageHelper: LocalStorageHelper,
    private sessionCheckerHelper: SessionCheckerHelper
  ) {}

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      gtag('config', environment.idGA, { page_path: event.urlAfterRedirects });
    });

    this.sessionCheckerHelper.onJwtSessionExpired(() => {
      this.auth0Service.closeSession();
      this.localStorageHelper.addItem(LOCAL_STORAGE_CONSTANTS.SHOW_EXPIRED_TOKEN_MODAL, 'true');
    });

    this.sessionCheckerHelper.onInactivityUser(() => {
      this.auth0Service.logout().subscribe(() => {
        this.auth0Service.closeSession();
        setTimeout(() => { window.location = window.location; }, 0);
      });
    });
  }
}
