import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from '@angular/common/http';
import { OpcionInterface } from '../opcion.service.interface';
import OpcionModel from 'src/app/core/models/configuracion-solicitud-credito/opcion.models';

@Injectable({
  providedIn: 'root',
})
export class OpcionServiceImpl
  extends ServiceImplService<OpcionModel>
  implements OpcionInterface
{
  constructor(private http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'opcion/';
  }
}
