import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceImplService } from '../../service.impl.service';
import { IDebitoAutomaticoDetalle } from '../debito-automatico-detalle.service.interface';

import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import ListResponse from '../../../models/core/list-response.model';

@Injectable({
	providedIn: 'root'
})
export class DebitoAutomaticoDetalleServiceImpl extends ServiceImplService<any> implements IDebitoAutomaticoDetalle {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'debito-automatico-detalle/';
	}

	extended(id: number) {
		return this.http.get(`${this.getFullPath()}${id}/extended`);
	}

	filtroExtended(filter) {
		filter = _.omitBy(filter, [ _.isUndefined, _.isNull, _.isEmpty ]);
		return this.httpClient
			.get(this.getFullPath() + `filtro/extendido`, { params: filter })
			.pipe(map((res: ListResponse<any>) => res));
	}

	paymentezReturnPay(
		debitoAutomaticoDetalleIds: number[],
		motivo: string,
		monto: number,
		transactionId: string,
		codigoRegion: number
	) {
		return this.http.put(`${this.getFullPath()}reembolso/pago`, {
			debitoAutomaticoDetalleIds,
			motivo,
			monto,
			transactionId,
			codigoRegion
		});
	}
}
