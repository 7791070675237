<div class="mb-3 flex flex-col">
  <h2 *ngIf="title" class="placeholder whitespace-nowrap">{{title}}</h2>
  <mat-divider class="mat-divider mb-4 primary-border mat-divider-horizontal" *ngIf="title"></mat-divider>
  <mat-dialog-content>
    <ng-container *ngIf="video">
      <iframe width="100%" height="450" [src]="'https://www.youtube.com/embed/' + deleteMessage | safeVideo"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </ng-container>

    <ng-container *ngIf="!pdf && !video">
      <div class="font-size" [ngClass]="{'pad-t-md': confirmacion}" [innerHTML]="deleteMessage | safeHtml"></div>
    </ng-container>

    <ng-container *ngIf="confirmacion">
      <div class="flex justify-center">
         <div class="w-full" >
          <form [formGroup]="form">
            <mat-form-field [style.width.%]="100">
              <textarea matInput maxlength="1000" placeholder="Digite el Motivo..." formControlName="motivo">
              </textarea>
            </mat-form-field>
          </form>
        </div>
      </div>
    </ng-container>
    <div class="pdf-container-generic" *ngIf="pdf">
      <pdf-viewer *ngIf="pdf" [src]="deleteMessage" [fit-to-page]="false" [original-size]="false" [render-text]='false'  style="height: 100%; width: 100%;"></pdf-viewer>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mt-2 pad-b-none text-right">
    <button *ngIf="!video" type="button" class="btn-color-volver" mat-raised-button (click)="dialogRef.close(false)">
      {{cancel | titlecase }}
    </button>
    <button *ngIf="video" type="button" class="btn-color-volver" mat-raised-button (click)="redirectTo()">
      {{cancel | titlecase}}
    </button>
    <button (click)="dialogRef.close(true)" *ngIf="!info && !confirmacion" mat-raised-button class="btn-color-succes">
      {{accept | titlecase}}
    </button>
    <button (click)="onFormSubmit()" *ngIf="confirmacion" [disabled]="!form.valid" mat-raised-button
      class="btn-color-succes">
      {{accept | titlecase}}
    </button>
  </mat-dialog-actions>
</div>
