export const tipoRechazoConst = {
	RECHAZADO_POR_CLIENTE: 'RECHAZADO_POR_CLIENTE',
	RECHAZADO_POR_PREAPROBACION: 'RECHAZADO_POR_PREAPROBACION',
	VENCIMIENTO_FECHA_PAGO: 'VENCIMIENTO_FECHA_PAGO',
	FALLO_VERIFICACION_IDENTIDAD: 'FALLO_VERIFICACION_IDENTIDAD',
	FALLO_CODIGO_OTP: 'FALLO_CODIGO_OTP',
	PREGUNTAS_RETO_FALLIDA: 'PREGUNTAS_RETO_FALLIDA',
	FALLO_FIRMA_DOCUMENTOS: 'FALLO_FIRMA_DOCUMENTOS',
	INCUMPLIMIENTO_PAGO: 'INCUMPLIMIENTO_PAGO',
	DESCONOCIDO: 'DESCONOCIDO'
};
