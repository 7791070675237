import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ESTADOS_BASE } from 'src/app/core/constants/estados.constants';
import { OpcionVistaServiceImpl } from 'src/app/core/http/configuracion-solicitud-credito/impl/opcion-vista.service.impl';
import OpcionModel from 'src/app/core/models/configuracion-solicitud-credito/opcion.models';

@Component({
  selector: 'ef-opcion-vista-add',
  templateUrl: './opcion-vista-add.component.html',
  styleUrls: ['./opcion-vista-add.component.scss'],
})

export class OpcionVistaAddComponent implements OnInit {
  formAdd: FormGroup;
  opciones: OpcionModel[];
  loading = false;
  mensajeError = false;
  opcionVistaRutas: string[];
  opcionVistaOrden: number[];
  idOpcion: number;
  caracteresEscritos: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<OpcionVistaAddComponent>,
    private opcionVistaService: OpcionVistaServiceImpl,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.idOpcion = this.data;
  }

  ngOnInit(): void {
    this.formAdd = this.formBuilder.group({
      idOpcion: [this.idOpcion, [Validators.required]],
      rutaPath: [null, [Validators.required, Validators.pattern(/^\S.*\S$/)]],
      descripcion: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(500), Validators.pattern(/^\S.*\S$/)]],
      orden: [null, [Validators.required]],
    });
    this.opcionVistaService.filter({
      estado: ESTADOS_BASE.ACTIVO,
      idOpcion: this.idOpcion
    })
      .subscribe({
        next: (rest) => {
          this.opcionVistaRutas = rest.data.map(el => el.rutaPath.toLowerCase());
          this.opcionVistaOrden = rest.data.map(el => el.orden)
        }
      })
  }

  get form() {
    return this.formAdd.controls;
  }

  onFormSubmit() {
    if (navigator.onLine) {
      if (this.formAdd.valid) {
        const opcionVistaNuevo = { ...this.formAdd.value, estado: ESTADOS_BASE.ACTIVO };
        const existPath = this.opcionVistaRutas.includes(opcionVistaNuevo.rutaPath.toLowerCase())
        const existOrden = this.opcionVistaOrden.includes(opcionVistaNuevo.orden)
        if (existPath) {
          this.toast.error('Esta ruta ya esta en uso');
          return;
        }
        if (existOrden) {
          this.toast.error('Este orden ya esta en uso');
          return;
        }
        this.opcionVistaService.save(opcionVistaNuevo).subscribe(
          {
            next: (opcionVista) => {
              this.dialogRef.close(opcionVista);
            },
            error: (error) => {
              const errorMessage = error.error.message || 'Ha ocurrido un error';
              this.toast.error(errorMessage, 'Error');
            }
          }
        );
      } else {
        this.toast.error('Por favor, completa el formulario correctamente', 'Error');
      }
    } else {
      this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
    }
  }

  calcularCaracteresEscritos(event: Event): void {
    const texto = (event.target as HTMLTextAreaElement).value;
    this.caracteresEscritos = texto.length;
  }

}
