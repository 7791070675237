import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from '@angular/common/http';
import { SolicirudCreditoServiceInterface } from '../solicirud-credito.service.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import ListResponse from '../../../models/core/list-response.model';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class SolicitudCreditoServiceImpl extends ServiceImplService<any> implements SolicirudCreditoServiceInterface {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'solicitud-credito/';
	}

	extend(filter?) {
		const path = `${this.getFullPath()}info/extendida`;
		return this.httpClient.get(path, { params: filter }).pipe(map((res: any) => res.data));
	}

	getFechaPrimeraCuota(isCreditoJamestown): Observable<any> {
		const path = `${this.getFullPath()}calcular/fechaPrimeraCuota`;
		return this.httpClient.get(path, { params: isCreditoJamestown }).pipe(map((res: any) => res.data));
	}

	getExtended(solicitudId): Observable<any> {
		const path = `solicitud/${solicitudId}/extendida`;
		return this.executeGet(path).pipe(map((res: any) => res.data));
	}

	saveSolicitud(data: any, path?: string): Observable<any> {
		return this.httpClient.post(`${this.getFullPath()}/solicitud/complete`, data).pipe(map((res: any) => res.data));
	}

	anularSolcitud(id: string, data): Observable<string> {
		return this.httpClient.put(`${this.getFullPath()}${id}/anular`, data).pipe(map((res: any) => res));
	}

	getReportSolicitudCredito(solicitud: number): Observable<any> {
		const path = `${solicitud}/solicitud-reporte/pdf`;
		return this.executeGetPDF(path);
	}

	getSolicitudCreditoReport(solicitud: number): Observable<any> {
		const path = `${solicitud}/solicitud-reporte/report`;
		return this.httpClient.get(`${this.getFullPath() + path}`);
	}

  getReportSolicitudCreditoContrato(solicitud: number): Observable<any> {
    const path = `${solicitud}/solicitud/contrato/completo/pdf`;
    return this.executeGetPDF(path);
  }

  getSolicitudCreditoContratoReport(solicitud: number): Observable<any> {
    const path = `${solicitud}/solicitud/contrato/completo/report`;
    return this.httpClient.get(`${this.getFullPath() + path}`);
  }

  getReportSolicitudCreditoContratoDebito(solicitud: number): Observable<any> {
    const path = `${solicitud}/solicitud/contrato/debito/completo/pdf`;
    return this.executeGetPDF(path);
  }

  getSolicitudCreditoContratoDebitoReport(solicitud: number): Observable<any> {
    const path = `${solicitud}/solicitud/contrato/debito/completo/report`;
    return this.httpClient.get(`${this.getFullPath() + path}`);
  }

	getReportContratoSolicitudCredito(solicitud: any): Observable<any> {
		const path = `${solicitud}/contrato-solicitud/pdf`;
		return this.executeGetPDF(path);
	}

	getContratoSolicitudCreditoReport(solicitud: number): Observable<any> {
		const path = `${solicitud}/contrato-solicitud/report`;
		return this.httpClient.get(`${this.getFullPath() + path}`);
	}

	getReportPagareSolicitudCredito(solicitud: any): Observable<any> {
		const path = `${solicitud}/pagare-solicitud/pdf`;
		return this.executeGetPDF(path);
	}

	generateReporContacts(solicitud: any): Observable<any> {
		const path = `${solicitud}/detalle-generacion-contacto/pdf`;
		return this.executeGetPDF(path);
	}

	generateReportHistorialCredito(solicitud: any): Observable<any> {
		const path = `${solicitud}/report-historial-credito/pdf`;
		return this.executeGetPDF(path);
	}

	getPagareSolicitudCreditoReport(solicitud: number): Observable<any> {
		const path = `${solicitud}/pagare-solicitud/report`;
		return this.httpClient.get(`${this.getFullPath() + path}`);
	}

	getReportCartaPagareSolicitudCredito(solicitud: any): any {
		const path = `${solicitud}/carta-pagare-solicitud/pdf`;
		return this.executeGetPDF(path);
	}

	getCartaPagareSolicitudCreditoReport(solicitud: number): Observable<any> {
		const path = `${solicitud}/carta-pagare-solicitud/report`;
		return this.httpClient.get(`${this.getFullPath() + path}`);
	}

	getReportDebitoAutomaticoSolicitudCredito(solicitud: any): any {
		const path = `${solicitud}/debito-automatico/pdf`;
		return this.executeGetPDF(path);
	}

	getDebitoAutomaticoSolicitudCreditoReport(solicitud: number): Observable<any> {
		const path = `${solicitud}/debito-automatico/report`;
		return this.httpClient.get(`${this.getFullPath() + path}`);
	}

	getContratoAcademico(numeroContrato, estado): Observable<any> {
		let filter = null;
		if (estado) {
			filter = {
				numeroContrato: numeroContrato,
				estado: estado
			};
		} else {
			filter = {
				numeroContrato: numeroContrato
			};
		}
		filter = _.omitBy(filter, [ _.isUndefined, _.isNull, _.isEmpty ]);
		return this.httpClient
			.get(this.getFullPath() + `validate-numero-contrato`, { params: filter })
			.pipe(map((res: ListResponse<any>) => res.data));
	}

	fillForm(body) {
		const url = `${this.getFullPath()}trans-union/idvision/fillform`;
		return this.httpClient.post(url, body);
	}

	sendNumber(body) {
		const url = `${this.getFullPath()}trans-union/idvision/sendnumber`;
		return this.httpClient.post(url, body);
	}

	sendCode(body) {
		const url = `${this.getFullPath()}trans-union/idvision/sendcode`;
		return this.httpClient.post(url, body);
	}

	doByPass(body) {
		const url = `${this.getFullPath()}trans-union/idvision/dobypass`;
		return this.httpClient.post(url, body);
	}

	loadDocuments(body) {
		const url = `${this.getFullPath()}trans-union/load-documents`;
		return this.httpClient.put(url, body);
	}

	editNumeroContrato(body) {
		const url = `${this.getFullPath()}contrato-academico/cambio`;
		return this.httpClient.put(url, body);
	}

	takeExam(body) {
		const url = `${this.getFullPath()}trans-union/idvision/takeexam`;
		return this.httpClient.post(url, body);
	}

	validateCreditoAqMgo(solicitudCreditoId) {
		const url = `${this.getFullPath()}validate-credito/aqmgo`;
		return this.httpClient.post(url, { solicitudCreditoId });
	}

	signDocuments(solicitudId) {
		const url = `${this.getFullPath()}trans-union/idvision/signdocuments?solicitudId=${solicitudId}`;
		return this.httpClient.post(url, {});
	}

  signDocumentsZapsing(solicitudId, plantillaId) {
		const url = `${this.getFullPath()}${solicitudId}/solicitud-credito-firma-electronica/${plantillaId}/plantilla`;
		return this.httpClient.post(url, {});
	}

	getDocuments(solicitudId) {
		const url = `${this.getFullPath()}trans-union/idvision/getdocuments?solicitudId=${solicitudId}`;
		return this.httpClient.post(url, {});
	}

	aprobar(solicitudId, servicioSuite) {
		const url = `${this.getFullPath()}solicitud/${solicitudId}/aprobar?servicioSuite=${servicioSuite}`;
		return this.httpClient.put(url, {});
	}

	rechazar(body) {
		const url = `${this.getFullPath()}solicitud/rechazar`;
		return this.httpClient.put(url, body);
	}

	reintentarFirma(body) {
		const url = `${this.getFullPath()}solicitud/reintentar-firma`;
		return this.httpClient.put(url, body);
	}

	editarFechaPrimeraCuota(body) {
		const url = `${this.getFullPath()}solicitud/editar/fecha-primera-cuota`;
		return this.httpClient.put(url, body);
	}

	getSolicitudCreditoFirmada(solicitud: any): any {
		const path = `${solicitud}/solicitud-credito/firmado`;
		return this.executeGetPDF(path);
	}

	getPagareFirmado(solicitud: any): any {
		const path = `${solicitud}/pagare/firmado`;
		return this.executeGetPDF(path);
	}

  getProcesoPasoOpcion(solicitud: any): any {
		const path = `${solicitud}/proceso-pasos-opciones`;
		return this.httpClient.get(`${this.getFullPath() + path}`);
	}

	getContratoFirmado(solicitud: any): any {
		const path = `${solicitud}/contrato-edufactoring/firmado`;
		return this.executeGetPDF(path);
	}

	getCartaFirmada(solicitud: any): any {
		const path = `${solicitud}/carta-autorizacion-pagare/firmado`;
		return this.executeGetPDF(path);
	}

	getDebitoAutomaticoFirmado(solicitud: any): any {
		const path = `${solicitud}/autorizacion-debito-automatico/firmado`;
		return this.executeGetPDF(path);
	}

	getContratoCartaPagareSolicitudPDF(solicitud: any) {
		const path = `${solicitud}/carta-contrato-pagare/pdf`;
		return this.executeGetPDF(path);
	}

	getContratoCartaPagareSolicitudReport(solicitud: any) {
		const path = `${solicitud}/carta-contrato-pagare/report`;
		return this.httpClient.get(`${this.getFullPath() + path}`)
	}

	getContratoCartaPagareSolicitudFirmado(solicitud: any) {
		const path = `${solicitud}/carta-contrato-pagare/firmado`;
		return this.executeGetPDF(path);
	}

	filterExtended(filter: any): Observable<ListResponse<any>> {
		filter = _.omitBy(filter, [ _.isUndefined, _.isNull, _.isEmpty ]);
		return this.httpClient
			.get(this.getFullPath() + `extended`, { params: filter })
			.pipe(map((res: ListResponse<any>) => res));
	}

	retrySignDocuments(solicitudId) {
		const url = `${this.getFullPath()}trans-union/idvision/signdocuments/reintentar?solicitudId=${solicitudId}`;
		return this.httpClient.post(url, {});
	}

	reiniciarFlujoFirma(solicitudId) {
		const path = `${solicitudId}/reiniciar-flujo-firma`;
		return this.httpClient.put(`${this.getFullPath() + path}`, null);
	}

	postValidarAplicaSeguro(data: any): Observable<any> {
		const path= `${this.getFullPath()}validar-seguro-credito`;
		return this.httpClient.post(path, data);

	}

	desembolsar(solicitudId, body, servicioSuite) {
		const path = `${solicitudId}/desembolsar?servicioSuite=${servicioSuite}`;
		return this.httpClient.put(`${this.getFullPath() + path}`, body);
	}

	firmaManual(body) {
		const url = `${this.getFullPath()}firma-manual-documentos`;
		return this.httpClient.post(url, body);
	}
}
