import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import { ConfiguracionListaSarlafServiceImpl } from 'src/app/core/http/configuracion-solicitud-credito/impl/configuracion-lista-sarlaft.service.impl';

@Component({
  selector: 'ef-configuracion-listas-sarlaft-add',
  templateUrl: './configuracion-listas-sarlaft-add.component.html',
  styleUrls: ['./configuracion-listas-sarlaft-add.component.scss']
})
export class ConfiguracionListasSarlaftAddComponent implements OnInit {

  formAddConfigSarlaft: FormGroup;
  mensajeError = false;
  loading = false;
  caracteresEscritos: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    public configListaSarlaftService: ConfiguracionListaSarlafServiceImpl,
    private toast: ToastrService,
    public dialogRef: MatDialogRef<ConfiguracionListasSarlaftAddComponent>,
  ) { }

  ngOnInit(): void {

    this.formAddConfigSarlaft = this.formBuilder.group({
      nombre: ['', [Validators.required, Validators.maxLength(100)]],
      codigo: ['', [Validators.required, , Validators.maxLength(50)]],
      estado: [null],
      descripcion: ['', [Validators.required, CustomValidatorsHelper.noWhitespaceValidator, Validators.minLength(10), Validators.maxLength(500)]],
      fPendiente: [null],
      fRechazo: [null]
    });
  }

  get form() {
    return this.formAddConfigSarlaft.controls;
  }

  onFormSubmit() {
    
    this.mensajeError = null;
    const formValues = Object.assign(
      {},
      this.formAddConfigSarlaft.value
    );
    const newConfigSarlaft = {
      estado: 'ACTIVO',
      nombre: formValues.nombre,
      codigo: formValues.codigo,
      descripcion: formValues.descripcion,
      fPendiente: formValues.fPendiente,
      fRechazo: formValues.fRechazo
    }
    this.configListaSarlaftService.save(newConfigSarlaft).subscribe(configSarlaft => {
      this.dialogRef.close(configSarlaft)
    },
      error => {
        ;
        try {
          for (const field of error.error.errors) {
            this.toast.error(field.message, 'Error');
          }
        } catch (e) {
        }
      }
    );
  }

  calcularCaracteresEscritos(event: Event): void {
    const texto = (event.target as HTMLTextAreaElement).value;
    this.caracteresEscritos = texto.length;
  }

}
