import {UnitType} from '../enums/unit-type.constants';


export class FileHelper {

  public static toBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  public static convertTo(amount: number, fromUnit: UnitType, toUnit: UnitType) {

    if (fromUnit === toUnit) {
      return amount;
    }

    const fromPow = this.getPow(fromUnit);
    const toPow = this.getPow(toUnit);

    const operator = Math.pow(1024, Math.abs(fromPow - toPow));

    if (fromPow > toPow) {
      return amount * operator;
    }

    return amount / operator;
  }

  public static getPow(unitType: UnitType) {

    switch (unitType) {
      case UnitType.B: return 0;
      case UnitType.KB: return 1;
      case UnitType.MB: return 2;
    }
  }

}
