import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ParametroGeneralService } from 'src/app/core/http/configuracion-basica/impl/parametro-general.service.impl';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'ef-parametros-generales-add',
	templateUrl: './parametros-generales-add.component.html',
	styleUrls: [ './parametros-generales-add.component.scss' ]
})
export class ParametrosGeneralesAddComponent implements OnInit {
	formAdd: FormGroup;
	loading = false;
	mensajeError = false;

	constructor(
		public formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<ParametrosGeneralesAddComponent>,
		public parametrosGeneralesService: ParametroGeneralService,
		public toast: ToastrService
	) {}

	ngOnInit() {
		this.formAdd = this.formBuilder.group({
			nombre: [ null, [ Validators.required, CustomValidatorsHelper.noWhitespaceValidator ] ],
			valor: [ null, [ Validators.required, CustomValidatorsHelper.noWhitespaceValidator ] ]
		});
	}

	get form() {
		return this.formAdd.controls;
	}

	onFormSubmit() {
		if (navigator.onLine) {
			this.mensajeError = null;
			const parametroNuevo = Object.assign({}, this.formAdd.value);
			parametroNuevo.estado = 'ACTIVO';
			this.parametrosGeneralesService.save(parametroNuevo).subscribe(
				(parametroGeneral) => {
					this.dialogRef.close(parametroGeneral);
				},
				(error) => {
					try {
						for (const field of error.error.errors) {
							this.toast.error(field.message, 'Error');
						}
					} catch (e) {
						this.mensajeError = error.error.message;
					}
				}
			);
		} else {
			this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
		}
	}
}
