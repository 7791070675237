import {Injectable} from '@angular/core';
import {ServiceImplService} from '../../service.impl.service';
import {HttpClient} from '@angular/common/http';
import {SolicirudCreditoServiceInterface} from '../solicirud-credito.service.interface';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import ListResponse from '../../../models/core/list-response.model';
import * as _ from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class SolicitudCreditoObservacionServiceImpl extends ServiceImplService<any>
  implements SolicirudCreditoServiceInterface {

  constructor(public http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'solicitud-observaciones/';
  }

  getExtended(observacionId): Observable<any> {
    const path = `${observacionId}/extended`;
    return this.executeGet(path).pipe(map((res: any) => res.data));
  }

  getTiposObservacion(): Observable<any> {
    const path = `tipos-observacion`;
    return this.executeGet(path).pipe(map((res: any) => res.data));
  }

  filterExtended(filter: any): Observable<ListResponse<any>> {
    filter = _.omitBy(filter, [ _.isUndefined, _.isNull, _.isEmpty ]);
    return this.httpClient.get(this.getFullPath() + 'filterExtended', { params: filter }).pipe(map((res: ListResponse<any>) => res));
  }

}
