import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ServiceImplService} from '../../service.impl.service';
import { ISecurityPermissionService } from '../security-permissions.service.interface';

@Injectable()
export class SecurityPermissionServiceImpl extends ServiceImplService<any> implements ISecurityPermissionService {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'security-permissions/';
	}
}
