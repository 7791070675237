import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { EstadosHelper } from 'src/app/core/helpers/estados.helper';
import { OpcionServiceImpl } from 'src/app/core/http/configuracion-solicitud-credito/impl/opcion.service.impl';
import { ProcesoPasoOpcionServiceImpl } from 'src/app/core/http/configuracion-solicitud-credito/impl/proceso-paso-opcion.service.impl';
import OpcionModel from 'src/app/core/models/configuracion-solicitud-credito/opcion.models';

@Component({
	selector: 'ef-opcion-proceso-add',
	templateUrl: './opcion-proceso-add.component.html',
	styleUrls: ['./opcion-proceso-add.component.scss']
})
export class OpcionProcesoAddComponent implements OnInit {
	formConfigOpcionProceso!: FormGroup;
	opciones: Array<OpcionModel> = [];
	isLoading: boolean = false;
	pickedOpcion: FormControl = new FormControl();
	paso: any = 0;
	proceso: any = 0;
	prioridadList: Array<number>;
	nombreOpcionesList: Array<string>;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public formBuilder: FormBuilder,
		public toasterService: ToastrService,
		public opcionServiceImpl: OpcionServiceImpl,
		public dialogRef: MatDialogRef<OpcionProcesoAddComponent>,
		public opcionProcesoServiceImpl: ProcesoPasoOpcionServiceImpl
	) {
		this.paso = data.pasoProceso;
		this.proceso = data.proceso;
		this.prioridadList = data.prioridadList;
		this.nombreOpcionesList = data.nombreOpcionesList;;
	}

	initForm() {
		this.formConfigOpcionProceso = this.formBuilder.group({
			opcion: [null, Validators.required],
			prioridad: [0, [Validators.min(0), Validators.required]],
			estado: [false]
		});
	}

	get form() {
		return this.formConfigOpcionProceso.controls;
	}

	onFormSubmit() {
		const prioridad = this.formConfigOpcionProceso.get('prioridad')?.value;
		if (this.prioridadList.includes(prioridad)) {
			this.toasterService.error("La prioridad ya existe");
			return;
		}


		const opcionP: any = {
			idProcesoPaso: this.paso,
			prioridad: this.formConfigOpcionProceso.get('prioridad')?.value,
			idOpcion: this.formConfigOpcionProceso.get('opcion')?.value.id,

		};
		opcionP['estado'] = 'ACTIVO';
		this.opcionProcesoServiceImpl.post(opcionP)
			.subscribe({
				next: (response: any) => {
					this.toasterService.success('Opcion asignada correctamente');
					this.dialogRef.close();
				}
			}
			);
	}

	ngOnInit() {
		this.isLoading = true;
		this.initForm();
		this.opcionServiceImpl.getAll()
			.pipe(
				finalize(() => {
					this.isLoading = false
				})
			)
			.subscribe({
				next: (opciones: any) => {
					this.opciones = opciones.filter((opcion: any) => !!EstadosHelper.isActivo(opcion.estado) &&
						!(this.nombreOpcionesList.includes(opcion.nombre)));
				}
			});
	}

}
