import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TipoDocumentoServiceImpl } from '../../../../core/http/configuracion-solicitud-credito/impl/tipo-documento.service.impl';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';

@Component({
	selector: 'ef-gestion-tipo-documentos-add',
	templateUrl: './gestion-tipo-documentos-add.component.html',
	styleUrls: ['./gestion-tipo-documentos-add.component.scss']
})
export class GestionTipoDocumentosAddComponent implements OnInit {

	formAdd: FormGroup;
	loading = false;
	mensajeError = false;
	caracteresEscritos: number = 0;

	constructor(
		public formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<GestionTipoDocumentosAddComponent>,
		public tipoDocumentoService: TipoDocumentoServiceImpl,
		public toast: ToastrService
	) { }

	ngOnInit(): void {
		this.formAdd = this.formBuilder.group({
			nombre: [null, [Validators.required, CustomValidatorsHelper.noWhitespaceValidator]],
			descripcion: [null, [Validators.required, CustomValidatorsHelper.noWhitespaceValidator, Validators.minLength(10), Validators.maxLength(500)]]
		});
	}


	get form() {
		return this.formAdd.controls;
	}

	onFormSubmit() {
		if (navigator.onLine) {
			this.mensajeError = null;
			const tipoDocumento = Object.assign({}, this.formAdd.value);
			tipoDocumento.estado = 'ACTIVO';
			this.tipoDocumentoService.save(tipoDocumento).subscribe(
				(res) => {
					this.dialogRef.close(res);
				},
				(error) => {
					try {
						for (const field of error.error.errors) {
							this.toast.error(field.message, 'Error');
						}
					} catch (e) {
						this.mensajeError = error.error.message;
					}
				}
			);
		} else {
			this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
		}
	}

	calcularCaracteresEscritos(event: Event): void {
		const texto = (event.target as HTMLTextAreaElement).value;
		this.caracteresEscritos = texto.length;
	}
}
