import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from '@angular/common/http';
import {PlanCreditoInterface} from '../plan-credito.interface';

@Injectable({
	providedIn: 'root'
})
export class PlanCreditoServiceImpl extends ServiceImplService<any> implements PlanCreditoInterface {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'plan-credito/';
	}
}
