<div>
	<div class="gene-card-title">
		<div class="d-flex flex-column justify-center items-center">
      <button mat-icon-button (click)="exit()" class="align-self-end">
				<mat-icon>close</mat-icon>
			</button>
			<div class="flex flex-col items-center">
				<h3>Confirmación de Código OTP - Consulta SARLAFT</h3>
			</div>
			<span class="d-flex"></span>
		</div>
		<mat-divider class="primary-border mb-2"></mat-divider>
	</div>

	<mat-dialog-content>
		<div class="p-3 flex justify-center">
			<div class="flex flex-col" *ngIf="type === 'EMAIL'">
				<p>Se ha enviado un código de verificación de 6 dígitos al correo electrónico del cliente junto a las condiciones e información de su consulta en SARLAFT.</p>
			</div>
			<div class="flex flex-col" *ngIf="type === 'SMS'">
				<p>Se ha enviado un código de verificación de 6 dígitos al telefono celular del cliente por medio de SMS junto a las condiciones e información de su consulta en SARLAFT.</p>
			</div>
		</div>

		<form [formGroup]="formCodigo" autocomplete="off">
			<div class="flex justify-center gap-3 flex-wrap px-3 code-form">
				<div class="flex flex-col w-[10%]">
					<mat-form-field appearance="outline" color="primary">
						<input #inputNumber1 matInput type="text" formControlName="number1" maxlength="1"
							class="size-field text-center">
					</mat-form-field>
				</div>
				<div class="flex flex-col w-[10%] field">
					<mat-form-field appearance="outline">
						<input #inputNumber2 matInput type="text" formControlName="number2" class="size-field text-center"
							maxlength="1">
					</mat-form-field>
				</div>
				<div class="flex flex-col w-[10%] field">
					<mat-form-field appearance="outline">
						<input #inputNumber3 matInput type="text" formControlName="number3" class="size-field text-center"
							maxlength="1">
					</mat-form-field>
				</div>
				<div class="flex flex-col w-[10%] field">
					<mat-form-field appearance="outline">
						<input #inputNumber4 matInput type="text" formControlName="number4" class="size-field text-center"
							maxlength="1">
					</mat-form-field>
				</div>
				<div class="flex flex-col w-[10%] field">
					<mat-form-field appearance="outline">
						<input #inputNumber5 matInput type="text" formControlName="number5" class="size-field text-center"
							maxlength="1">
					</mat-form-field>
				</div>
				<div class="flex flex-col w-[10%] field">
					<mat-form-field appearance="outline">
						<input #inputNumber6 matInput type="text" formControlName="number6" class="size-field text-center"
							maxlength="1">
					</mat-form-field>
				</div>
			</div>
		</form>
	</mat-dialog-content>

	<mat-dialog-actions class="mrgn-b-xs pad-b-none text-center d-flex flex-column justify-content-center gap-2">
		<mat-progress-bar mode="indeterminate" *ngIf="isLoading" class="mb-4"></mat-progress-bar>
		<button (click)="clearAll()" [disabled]="isLoading" mat-raised-button matTooltipPosition="above" class="button-action"
			matTooltip="Limpiar Campos" color="primary">
			<mat-icon>clear_all
			</mat-icon>
		</button>
		<button (click)="resendCode()" [disabled]="isLoading" mat-raised-button matTooltipPosition="above" class="button-action"
			matTooltip="Reenviar Código" color="primary">
			<mat-icon>sync</mat-icon>
		</button>
		<button (click)="resendCodeToSMS()" mat-raised-button matTooltipPosition="above" class="button-action"
			matTooltip="Reenviar Código por SMS" color="primary">
			<mat-icon>forward_to_inbox</mat-icon>
		</button>
		<button (click)="clickConfirmButton()" [disabled]="!formCodigo.valid" mat-raised-button color="primary" class="button-action"
			matTooltipPosition="above" matTooltip="Confirmar Correo">
			<mat-icon>verified_user</mat-icon>
		</button>
	</mat-dialog-actions>
</div>
