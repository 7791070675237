import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidatorsHelper } from 'src/app/core/helpers/customValidators.helper';
import { EstadosHelper } from 'src/app/core/helpers/estados.helper';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PasoProcesoServiceImpl } from 'src/app/core/http/configuracion-solicitud-credito/impl/proceso-paso.service.impl';
import { ProcesoPasoOpcionServiceImpl } from 'src/app/core/http/configuracion-solicitud-credito/impl/proceso-paso-opcion.service.impl';

@Component({
	selector: 'ef-details-paso-opcion',
	templateUrl: './details-paso-opcion.component.html',
	styleUrls: ['./details-paso-opcion.component.scss']
})
export class DetailsPasoOpcionComponent implements OnInit {
	objeto;
	tipo;
	ordenList: Array<number>;
	prioridadList: Array<number>;
	formDetail!: FormGroup;

	constructor(
		public toast: ToastrService,
		public formBuilder: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<DetailsPasoOpcionComponent>,
		public pasoProcesoServiceImpl: PasoProcesoServiceImpl,
		public opcionProcesoServiceImpl: ProcesoPasoOpcionServiceImpl,

	) {
		this.ordenList = data.ordenList;
		this.prioridadList = data.prioridadList;
		this.objeto = data.pasoProceso;
		this.tipo = data.type;
	}

	ngOnInit() {
		this.initForm();
		this.formDetail.get('nombre')?.setValue(this.objeto.nombre);
		this.formDetail.get('descripcion')?.setValue(this.objeto.descripcion);
		this.formDetail.get('orden')?.setValue(this.objeto.orden);
		this.formDetail.get('prioridad')?.setValue(this.objeto.prioridad);
		this.formDetail.get('estado')?.setValue(EstadosHelper.isActivo(this.objeto.estado));
	}

	initForm() {
		this.formDetail = this.formBuilder.group({
			orden: [0, [Validators.required, Validators.min(0), CustomValidatorsHelper.numeric]],
			prioridad: [0, [Validators.required, Validators.min(0), CustomValidatorsHelper.numeric]],
			estado: [null],
			flagClasesDesorden: [null]
		});
	}

	get form() {
		return this.formDetail.controls;
	}

	onFormSubmit() {
		if (this.tipo === 'paso') {
			const orden = this.formDetail.get('orden')?.value;
			const nombre = this.formDetail.get('nombre')?.value;
			if (this.ordenList.includes(orden)) {
				this.toast.error("El orden ya existe");
				return;
			}

			const data = {
				estado: EstadosHelper.getEstadoFromBoolean(this.formDetail.get('estado')?.value),
				idPaso: this.objeto.paso.id,
				orden: this.formDetail.get('orden')?.value,
				idProceso: this.objeto.proceso.id
			};
			this.pasoProcesoServiceImpl.update(this.objeto.id, data).subscribe(
				(resp: any) => {
					this.toast.success(resp['message'], 'proceso-paso');
					this.objeto.estado = data.estado;
					this.objeto.orden = data.orden;
					this.dialogRef.close(this.objeto);
				},
				(err: any) => {
					this.toast.error('Ocurrió un error al actualizar.', 'proceso-paso');
				}
			);
		} else {
			const prioridad = this.formDetail.get('prioridad')?.value;
			if (this.prioridadList.includes(prioridad)) {
				this.toast.error("La prioridad ya existe");
				return;
			}


			const data = {
				estado: EstadosHelper.getEstadoFromBoolean(this.formDetail.get('estado')?.value),
				idProcesoPaso: this.objeto.procesoPaso.id,
				prioridad: this.formDetail.get('prioridad')?.value,
				idOpcion: this.objeto.opcion.id,
			};
			this.opcionProcesoServiceImpl.update(this.objeto.id, data).subscribe(
				(resp: any) => {
					this.toast.success(resp['message'], 'opcion-proceso');
					this.objeto.prioridad = data.prioridad;
					this.objeto.estado = data.estado;
					this.dialogRef.close(this.objeto);
				},
				(err: any) => {
					this.toast.error('Ocurrió un error al actualizar', 'opcion-proceso');
				}
			);
		}
	}
}
