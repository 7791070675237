import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
// import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { LOCAL_STORAGE_CONSTANTS } from '../constants/local-storage.constants';
import { DialogService } from '../services/dialog.service';
import { LocalStorageHelper } from '../helpers/local-storage.helper';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private _unsubscribe = new Subject<void>();

  constructor(
    private _router: Router,
    private localStorageHelper: LocalStorageHelper,
    private toast: ToastrService,
    // public AuthenticationServiceImpl: AuthenticationServiceImpl,
    public dialogService: DialogService,
  ) {


  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (!window.navigator.onLine) {
      // if there is no internet, throw a HttpErrorResponse error
      // since an error is thrown, the function will terminate here
      this.toast.error('Por favor verifique su conexión a internet', 'Sin conexión')
      return throwError('Sin conexión a Internet');
    }

    const token: string = this.localStorageHelper.getItem(
      LOCAL_STORAGE_CONSTANTS.TOKEN
    );

    // Si hay token lo seteamos
    if (!request.headers.has('noAuth') && token && !request.url.includes('apiip.net')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        },

      });
    }

    if (request.url.includes('/v1/estudiantes/estudiante/info')) {
      return next.handle(request);
    }
    // Se omite el modal de error para esta solicitud debido que se maneja en el componente para redirección al flujo alternativo
    if (request.url.includes('/v1/solicitud-credito/trans-union/idvision/signdocuments')) {
      return next.handle(request);
    }
    // Peticiones
    return next.handle(request).pipe(
      map((event: any) => {
        try {
          const myBody = event.body;
          let token: string = myBody.data.accessToken || '';
          // Si tienes Token refrescalo
          if (token) {
            localStorage.setItem('accessToken', myBody.data.accessToken);
            // this.AuthenticationServiceImpl.saveToken(myBody.data);
          }
          let expiresIn: string = myBody.data.expiresIn || '';
          // Si tienes Token refrescalo
          if (expiresIn) {
            localStorage.setItem('expiresIn', myBody.data.expiresIn);
          }
        } catch (error) { }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        var errorCode = error.status;
        // Error en el Server o No autorizado o Session expirada
        /* Swal.fire(error.error.error.message + ' (' + error.error.error.code + ')', '', 'error')
            this.userService.logout()
            this._router.navigate(['/login']) */
        if (
          error instanceof HttpErrorResponse &&
          ((error.status >= 500 || error.status === 403) && error.status != 504)
        ) {
            this.dialogService.showServerErrorDialog(error);
        } else {
          if (errorCode === 0 || errorCode === 401) {
            // Reiniciamos Todo
            // Swal.fire({
            //   position: 'center',
            //   icon: 'error',
            //   title: 'Ha ocurrido un error.',
            //   text: error.error.message,
            //   showConfirmButton: true,
            // });
          }
          // Otros errores
          else {
            if (errorCode != 504) {
              if (error.error) {
                console.log(error)
                if (error.error.errors) {
                  for (const errors of error.error.errors) {
                    this.toast.error(errors.message, errors.field);
                  }
                }
                else if (error.error.error_description) {
                  this.toast.error(error.error.error_description);
                }
                else {
                  if (request.url.includes('/v1/personas/documento/') && request.url.includes('/fotografia') && !environment.production) {
                    if (error.error.message == "No se pudo encontrar el file.") {
                      this.toast.warning("No se ha encontrado una foto, por favor cargue una.");
                    } else {
                      this.toast.warning(error.error.message);
                    }
                  }
                  else {
                    // Swal.fire({
                    //   position: 'center',
                    //   icon: 'error',
                    //   title: 'Ha ocurrido un error.',
                    //   text: error.error ? error.error.message || error.error.error_description : error,
                    //   showConfirmButton: true,
                    // });
                  }
                }
              }
            }
          }
        }

        // Retorna el error
        return throwError(() => error);
      }),
      finalize(() => {
        this._unsubscribe.next();
        this._unsubscribe.complete();
      })
    );
  }
}
