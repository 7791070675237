<form [formGroup]="formAddObservacion" novalidate class="popup-card-width">
  <div>
    <h2 mat-dialog-title i18n>Registrar Observación</h2>
  </div>
  <mat-dialog-content>
    <div>
      <div class="flex">
        <div class="flex flex-col w-full">
          <mat-form-field class="pad-t-aux">
            <mat-label class="placeholder">Tipo Observacion</mat-label>
            <mat-select formControlName="tipoObservacion" required>
              <mat-option *ngFor="let item of listTipoObservacions" [value]="item">
                {{item | spaceUnderscore}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.tipoObservacion.touched && form.tipoObservacion.errors">
              <mat-error *ngIf="form.tipoObservacion.errors.required" class="required alert-error">
                <span i18n>Este campo es requerido</span>
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="flex">
        <mat-form-field>
          <textarea matInput autocomplete="off" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="6"
            maxlength="1000" formControlName="descripcion"></textarea>
          <mat-label class="placeholder">Descripción</mat-label>
          <mat-error *ngIf="form.descripcion.touched && form.descripcion.errors">
            <mat-error *ngIf="form.descripcion.errors.required" class="required alert-error">
              <span i18n>Este campo es requerido</span>
            </mat-error>
          </mat-error>
        </mat-form-field>

      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close()" [disabled]="loading">Cerrar
    </button>
    <button (click)="onFormSubmit()" type="submit" [disabled]="!formAddObservacion.valid || loading" mat-raised-button
      color="primary">Crear
    </button>
  </mat-dialog-actions>
</form>
