import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ef-acuerdo-pago-observaciones-detail',
  templateUrl: './acuerdo-pago-observaciones-detail.component.html',
  styleUrls: ['./acuerdo-pago-observaciones-detail.component.scss']
})
export class AcuerdoPagoObservacionesDetailComponent implements OnInit {

  loading = false;
  observacionDetail: any;

  constructor(public dialogRef: MatDialogRef<AcuerdoPagoObservacionesDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
   this.observacionDetail = data.observacionDetail;
   
   }

  ngOnInit(): void {
  }

}
