<mat-form-field [style.width.%]="width">
  <input [required]="isRequired" [readonly]="isDisabled" type="text" matInput
    [matAutocomplete]="autocompleteDepartamentos" [formControl]="departamentoControl" (click)="onTouched()" />
  <mat-placeholder class="placeholder">{{label}}</mat-placeholder>
  <a *ngIf="enableIcon" (click)="copyDepartamento()" matSuffix mat-icon-button matTooltipPosition="above"
    matTooltip="Autocompletar">
    <mat-icon>file_copy</mat-icon>
  </a>
  <a *ngIf="enableAdd && enableAddForm" (click)="addNewUbicacionDialog()" matSuffix mat-icon-button
    matTooltipPosition="above" matTooltip="Nueva Ubicacion">
    <mat-icon>add_circle</mat-icon>
  </a>
  <mat-autocomplete #autocompleteDepartamentos="matAutocomplete" (optionSelected)="onDepartamentoChange($event)"
    [displayWith]="displayFn">
    <mat-option *ngFor="let item of filteredDepartamentos | async" [value]="item">
      {{item.departamento}}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="departamentoControl.errors">
    <mat-error *ngIf="departamentoControl.errors.required" class="required alert-error">
      <span i18n>Este campo es requerido</span>
    </mat-error>
    <mat-error *ngIf="departamentoControl.errors.valid" class="required alert-error text-erro">
      <span i18n>Seleccione un departamento</span>
    </mat-error>
  </mat-error>
</mat-form-field>
<ng-content></ng-content>
