<form [formGroup]="formAdd" novalidate autocomplete="off" class="popup-card-width">
    <div>
        <h2 mat-dialog-title i18n>Nuevo Firmante</h2>
    </div>
    <mat-dialog-content>
        <div>
            <div class="flex flex-col">
                <mat-form-field class="mrgn-b-sm">
                    <mat-label>Firmante</mat-label>
                    <mat-select formControlName="firmante">
                        <mat-option *ngFor="let firmante of firmantes" [value]="firmante">
                            {{firmante.nombre | spaceUnderscore}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.firmante.touched && form.firmante.errors">
                        <mat-error *ngIf="form.firmante.errors?.required" class="required alert-error">
                            <span i18n>Este campo es requerido</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="mrgn-b-xs pad-b-none">
        <div>
            <button type="button" mat-raised-button (click)="dialogRef.close()">Cancelar
            </button>
            <button (click)="onFormSubmit()" [disabled]="!formAdd.valid" mat-raised-button
                color="primary">Crear
            </button>
        </div>
    </mat-dialog-actions>
</form>