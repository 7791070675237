<form [formGroup]="formEdit" novalidate autocomplete="off" class="popup-card-width">
    <h2 mat-dialog-title i18n>Editar Opción</h2>
    <mat-dialog-content>
        <div class="full-wid mrgn-b-xs mt-2">
            <div class="full-wid flex gap-3">
                <div class="md:w-[100%] xs:w-full">
                    <mat-form-field>
                        <mat-label class="placeholder">Nombre</mat-label>
                        <input matInput formControlName="nombre" required />
                        <mat-error *ngIf="form.nombre.touched && form.nombre.errors">
                            <mat-error *ngIf="form.nombre.errors.required" class="required alert-error">
                                <span i18n>Este campo es requerido</span>
                            </mat-error>
                            <mat-error *ngIf="form.nombre.touched && form.nombre.errors?.pattern">
                                Este campo no puede estar vacío o contener solo espacios en blanco.
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="full-wid flex gap-3 mt-2">
                <div class="md:w-[100%] xs:w-full">
                    <mat-form-field>
                        <mat-label class="placeholder dialog-label-size">Tipo de Persona</mat-label>
                        <mat-select formControlName="tipoPersona" required>
                            <mat-option *ngFor="let type of tipoPersona" [value]="type">
                                {{ type }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.tipoPersona.touched && form.tipoPersona.errors?.required"
                            class="required alert-error">
                            <span i18n>Este campo es requerido</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="full-wid flex gap-3 mt-2">
                <div class="md:w-[100%] xs:w-full">
                    <mat-form-field>
                        <mat-label class="placeholder">Descripción</mat-label>
                        <textarea matInput formControlName="descripcion"  (input)="calcularCaracteresEscritosInput($event)" > </textarea>
                        <p class="mb-0">{{ caracteresEscritos }}/500</p>
                        <mat-error *ngIf="form.descripcion.touched && form.descripcion.errors">
                            <mat-error *ngIf="form.descripcion.errors.required" class="required alert-error">
                                <span i18n>Este campo es requerido</span>
                            </mat-error>
                            <mat-error *ngIf="form.descripcion.touched && form.descripcion.errors?.pattern">
                                Este campo no puede estar vacío o contener solo espacios en blanco.
                            </mat-error>
                            <mat-error
                                *ngIf="formEdit.get('descripcion').errors && formEdit.get('descripcion').errors.hasOwnProperty('maxlength')">
                                <span i18n>La descripción debe tener máximo 500 caracteres.</span>
                            </mat-error>
                            <mat-error
                                *ngIf="formEdit.get('descripcion').errors && formEdit.get('descripcion').errors.hasOwnProperty('minlength')">
                                <span i18n>La descripción debe tener minimo 10 caracteres.</span>
                            </mat-error>
                        </mat-error>      
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mrgn-b-xs pad-b-none">
        <div class="text-right mt-2">
            <button type="button" mat-raised-button (click)="dialogRef.close()">
                Cancelar
            </button>
            <button (click)="onFormSubmit()" [disabled]="!formEdit.valid" mat-raised-button class="accept-button">
                Actualizar
            </button>
        </div>
    </mat-dialog-actions>
</form>
