import { ServiceImplService } from '../../service.impl.service';
import {IFirmanteServiceInterface} from '../firmante.service.interface';
import FirmanteModel from '../../../models/configuracion-firma-electronica/firmante.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


@Injectable({ providedIn: "root" })
export class FirmanteServiceImpl extends ServiceImplService<FirmanteModel> implements IFirmanteServiceInterface {
	constructor(public  http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = "firmante/";
	}
}

