import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ParametrosGeneralesAddComponent } from '../../../configuracion-basica/parametros-generales/parametros-generales-add/parametros-generales-add.component';
import { AcuerdoPagoDescuentoServiceImpl } from '../../../../core/http/configuracion-basica/impl/acuerdo-pago-descuento.service.impl';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomValidatorsHelper } from '../../../../core/helpers/customValidators.helper';

@Component({
  selector: 'ef-gestion-descuentos-add',
  templateUrl: './gestion-descuentos-add.component.html',
  styleUrls: ['./gestion-descuentos-add.component.scss'],
})
export class GestionDescuentosAddComponent implements OnInit {
  formAdd: FormGroup;
  loading = false;
  mensajeError = false;
  tipos = [
    'PROSPECTO_POSITIVO',
    'PROSPECTO_NEGATIVO',
    'REACTIVACION_ACADEMICA',
  ];
  caracteresEscritos: number = 0;

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ParametrosGeneralesAddComponent>,
    public acuerdoPagoDescuentoService: AcuerdoPagoDescuentoServiceImpl,
    public toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.formAdd = this.formBuilder.group({
      nombre: [null, [Validators.required]],
      tipo: [null, [Validators.required]],
      descripcion: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(500)]],
    });
  }

  get form() {
    return this.formAdd.controls;
  }

  onFormSubmit() {
    if (navigator.onLine) {
      this.mensajeError = null;
      const descuentoNuevo = Object.assign({}, this.formAdd.value);
      descuentoNuevo.estado = 'ACTIVO';
      this.acuerdoPagoDescuentoService.save(descuentoNuevo).subscribe(
        {
          next: (descuento) => {
            this.dialogRef.close(descuento);
          },
          error: (error) => {
            try {
              for (const field of error.error.errors) {
                this.toast.error(field.message, 'Error');
              }
            } catch (e) {
              this.mensajeError = error.error.message;
            }
          }
        }
      );
    } else {
      this.toast.error('Por favor, revisa tu conexión a internet', 'Error');
    }
  }

  calcularCaracteresEscritos(event: Event): void {
    const texto = (event.target as HTMLTextAreaElement).value;
    this.caracteresEscritos = texto.length;
  }
}
