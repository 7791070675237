import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Enfermedad from 'src/app/core/models/configuracion-solicitud-credito/enfermedad-model';

@Component({
  selector: 'ef-enfermedades-detail',
  templateUrl: './enfermedades-detail.component.html',
  styleUrls: ['./enfermedades-detail.component.scss']
})
export class EnfermedadesDetailComponent implements OnInit {

  loading = false;
  enfermedadDetail: Enfermedad;

  constructor(
    public dialogRef: MatDialogRef<EnfermedadesDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.enfermedadDetail = data;
  }

  ngOnInit(): void {
  }

}
