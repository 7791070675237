import { Component, OnInit, Inject } from '@angular/core';
import { environment } from '../../../../environments/environment.prod';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DepartamentoServiceImpl } from 'src/app/core/http/ubicaciones/impl/departamento.service.impl';
import DepartamentoModel from 'src/app/core/models/ubicaciones/departamento.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { UbicacionGeograficaServiceImpl } from 'src/app/core/http/ubicaciones/impl/ubicacion-geografica.service.impl';
import MunicipioModel from 'src/app/core/models/ubicaciones/municipio.model';
import UbicacionGeograficaModel from 'src/app/core/models/ubicaciones/ubicacion-geografica.model';
import { lastValueFrom } from 'rxjs';

declare var $;
declare var Paymentez;

@Component({
	selector: 'ef-debito-automatico',
	templateUrl: './debito-automatico.component.html',
	styleUrls: ['./debito-automatico.component.scss']
})
export class DebitoAutomaticoComponent implements OnInit {
	usuario;
	solicitud;
	municipios = [];
	needRegion = false;
	departamentos = [];
	regionForm: FormGroup;
	region: UbicacionGeograficaModel = null;

	constructor(
		public dialogRef: MatDialogRef<DebitoAutomaticoComponent>,
		public ubicacionService: UbicacionGeograficaServiceImpl,
		public departamentoService: DepartamentoServiceImpl,
		@Inject(MAT_DIALOG_DATA) public data: any,

		public fBuilder: FormBuilder,
		public toast: ToastrService
	) {
		this.needRegion = data.needRegion;
	}

	/**
	 * Método para la configuración del formulario reactivo y tratamiento de cada uno de los combos de los datos
	 * a elegir para nuestro proceso.
	 */
	public loadForm() {
		this.regionForm = this.fBuilder.group({
			departamento: [null, Validators.required],
			municipio: [null, Validators.required],
			region: [null, Validators.required]
		});
	}

	/**
	 * Método para la carga de la información de la región basada en el id de la ubicación.
	 * @param idUbicacion id de una ubicación de un municipio.
	 */
	public async loadRegion(idUbicacion: number, submitButton) {

		try {
			return await lastValueFrom(this.ubicacionService.get(String(idUbicacion)));
		} catch (err) {
			if (err.error.errors) {
				for (const error of err.error.errors) {
					this.toast.error(error.message, error.field);
				}
			} else {
				if (err.error.message) {
					this.toast.error(err.error.message, 'Error');
				}
			}
		}
	}

	/**
	 * Método para la carga de todos los municipios, basados en el id de un departamento.
	 * @param idDepartamento id del departamento elegido.
	 */
	public loadMunicipios(idDepartamento: number) {

		this.departamentoService.getMunicipios(idDepartamento)
			.subscribe({
				next: (municipios: any) => {
					this.municipios = municipios.data;
					this.municipios = this.municipios.map((el: MunicipioModel) => {
						const item = {
							...el,
							nombre: el.municipio
						};
						return item;
					});
				},
				error: (err) => {
					if (err.error.errors) {
						for (const error of err.error.errors) {
							this.toast.error(error.message, error.field);
						}
					} else {
						if (err.error.message) {
							this.toast.error(err.error.message, 'Error');
						}
					}
				}
		});
	}

	/**
	 * Método para la carga de todos los departamentos.
	 */
	public loadDepartamentos() {

		this.departamentoService.getAll().subscribe({
			next: (departamentos: any) => {
				this.departamentos = departamentos.data;
				this.departamentos = this.departamentos.map((el: DepartamentoModel) => {
					const item = {
						...el,
						nombre: el.departamento
					};
					return item;
				});
			},
			error: (err) => {
				if (err.error.errors) {
					for (const error of err.error.errors) {
						this.toast.error(error.message, error.field);
					}
				} else {
					if (err.error.message) {
						this.toast.error(err.error.message, 'Error');
					}
				}
			}
		});
	}

	ngOnInit(): void {
		const submitButton = $('.btn');
		let region = null;
		const toast = this.toast;
		const refModal = this.dialogRef;
		const submitInitialText = $('.btn').text();
		if (this.needRegion) {
			if (!this.region) {
				submitButton.attr('disabled', 'disabled');
			}
			this.loadForm();
			this.loadDepartamentos();
			this.regionForm.get('departamento').valueChanges.subscribe({
				next: (departamento: DepartamentoModel) => {
					this.region = null;
					submitButton.attr('disabled', 'disabled');
					this.loadMunicipios(departamento.id);
				}
			});
			this.regionForm.get('municipio').valueChanges.subscribe({
				next: async (municipio: MunicipioModel) => {
					this.region = await this.loadRegion(municipio.idUbicacion, submitButton);
					if (this.region) {
						region = this.region;
						submitButton.removeAttr('disabled');
					}
				}
			});
		}
		this.usuario = JSON.parse(localStorage.getItem('cliente'));
		/**
		 * Enviamos los parametros de configuración al init de Paymentez.
		 * @param ENV_MODE Modo: pruebas 'stg', produccion 'prod', local 'local'.
		 * @param APP_CODE Código de la aplicación (Entregada por Paymentez).
		 * @param APP_KEY Llave de la aplicación (Entregada por Paymentez).
		 */
		Paymentez.init(environment.paymentezMode, environment.paymentezAppCode, environment.paymentezAppKey);
		/**
		 * Modificamos el DOM para incluir los inputs del formulario para
		 * realizar todo el proceso de agregar tarjeta en Paymentez.
		 */
		$('.paymentez-form').not('.checkout').each(function (i, obj) {
			$(obj).PaymentezForm();
		});
		/**
		 * Colocamos el nombre completo del cliente en el campo nombre
		 * al agregar la tarjeta. Además podemos bloquear el campo, para
		 * evitar que se modifique.
		 */
		$('.name').val(this.usuario.nombreCompleto);
		// $('.name').attr('disabled', 'disabled');
		/** Metodo para capturar el evento del submit del formulario,
		 * aquí realizamos el llamado a los métodos de Paymentez para
		 * agregar una tarjeta, dependiendo de la respuesta las enviamos
		 * si es exitosa al successHandler y si falla al errorHandler.
		 */
		$('#add-card-form').submit(function (e) {
			let cardNumber = $('.card-number').val();
			const regexMap = [
				{
					logo: 'https://s3.amazonaws.com/cdn.images/cc/image/ic_visa.svg',
					regEx: /^4[0-9]{5}/gi,
					cardType: 'VISA'
				},
				{
					logo: 'https://s3.amazonaws.com/cdn.images/cc/image/ic_mastercard.svg',
					regEx: /^5[1-5][0-9]{4}/gi,
					cardType: 'MASTERCARD'
				},
				{
					logo: 'https://s3.amazonaws.com/cdn.images/cc/image/ic_amex.sv',
					regEx: /^3[47][0-9]{3}/gi,
					cardType: 'AMEX'
				},
				{
					logo: 'https://s3.amazonaws.com/cdn.images/cc/image/ic_maestro.svg',
					regEx: /^(5[06-8]\d{4}|6\d{5})/gi,
					cardType: 'MAESTRO'
				},
				{
					logo: 'https://s3.amazonaws.com/cdn.images/cc/image/ic_diners.svg',
					regEx: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/gi,
					cardType: 'DINERS CLUB'
				},
				{
					logo: 'https://s3.amazonaws.com/cdn.images/cc/image/ic_jcb.svg',
					regEx: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/gi,
					cardType: 'JCB'
				},
				{
					logo: 'https://s3.amazonaws.com/cdn.images/cc/image/ic_discover.svg',
					regEx: /^6(?:011|5[0-9]{2})[0-9]{3,}$/gi,
					cardType: 'DISCOVER'
				}
			];
			let payCardType = '';
			for (var j = 0; j < regexMap.length; j++) {
				if (String(cardNumber.replace(' ', '')).match(regexMap[j].regEx)) {
					payCardType = regexMap[j].cardType;
					break;
				}
			}
			const icon = $('.card-type-icon').get(0);
			const style = icon.attributes.getNamedItem('style');
			let iconCardType = null;
			if (style != null) {
				const url = style.nodeValue.split(':');
				const protocolo = url[1].replace('url', '').replace('("', '');
				const pathUrl = url[2].replace('");', '');
				iconCardType = String(protocolo + pathUrl).replace('https//', '').trim();
			}
			const myCard = $('#my-card');
			$('#messages').text('');
			const cardToSave = myCard.PaymentezForm('card');
			if (cardToSave == null) {
				$('#messages').text('Datos Inválidos');
			} else {
				submitButton.attr('disabled', 'disabled').text('Procesando Transacción...');
				this.usuario = JSON.parse(localStorage.getItem('cliente'));
				if (!region) {
					this.solicitud = JSON.parse(localStorage.getItem('solicitud'));
				}
				const uid = String(this.usuario.id);
				const email = this.usuario.email;
				Paymentez.addCard(
					uid,
					email,
					cardToSave,
					/**
					 * Método para capturar si fue exitoso, agregar una tarjeta
					 * en Paymentez. Se realiza todo el tratamiento para guardar
					 * la información del response de Paymentez en nuestras
					 * bases de datos.
					 */
					(cardResponse) => {
						if (cardResponse.card.status === 'valid') {
							$('#messages').html('Card Successfully Added');
						} else if (cardResponse.card.status === 'review') {
							$('#messages').html('Card Under Review');
						} else {
							$('#messages').html('Error<br>' + cardResponse.card.message);
						}
						if (cardResponse.card.status === 'rejected' || cardResponse.card.token === '') {
							toast.error(
								`La tarjeta fue rechazada, por favor intentelo con una tarjeta diferente.
								 Referencia: ${cardResponse.card.transaction_reference}`
							);
						} else {
							const bean = {
								cardBin: cardResponse.card.bin,
								cardBrandName: payCardType,
								cardExpiryMonth: cardResponse.card.expiry_month,
								cardExpiryYear: cardResponse.card.expiry_year,
								cardHolderName: $('.name').val(),
								cardNumber: cardResponse.card.number,
								cardOrigin: cardResponse.card.origin,
								cardStatus: cardResponse.card.status,
								cardToken: cardResponse.card.token,
								cardTransactionReference: cardResponse.card.transaction_reference,
								cardType: cardResponse.card.type,
								cardUrlLogoPing: iconCardType,
								clienteId: this.usuario.id,
								estado: 'ACTIVO',
								flagDebitoAutomatico: true,
								flagFileUpload: false
							};
							if (region) {
								bean['codigoRegion'] = region.codigoRegion;
							} else {
								bean['codigoRegion'] = this.solicitud.ubicacionGeografica.codigoRegion;
							}
							// 	"cardMask": "string",
							// 	"motivoRetiro": "string"
							submitButton.removeAttr('disabled');
							submitButton.text(submitInitialText);
							toast.success('Se ha registrado la tarjeta con éxito.');
							refModal.close(bean);
						}
					},
					/**
					 * Método para capturar si falla al agregar una tarjeta en
					 * Paymentez.
					 * @param err JSON con la información del error.
					 */
					(err) => {
						if (err.error.type != 'Server Error') {
							toast.warning(err.error.type);
							refModal.close(true);
						}
						$('#messages').html(err.error.type);
						submitButton.removeAttr('disabled');
						submitButton.text(submitInitialText);
					}
				);
			}
			e.preventDefault();
		});
	}
}
