import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'underscoreToSpace'
})
export class UnderscoreToSpacePipe implements PipeTransform {
  transform(value: string): string {
    const replacedString = value.replace(/_{2,}/g, ' - ');
    const finalString = replacedString.replace(/_/g, ' ');
    return finalString;
  }
}
