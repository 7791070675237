<div novalidate autocomplete="off" class="popup-card-width">

  <div>
    <h2 mat-dialog-title i18n>Actualización de Datos</h2>
  </div>

  <mat-dialog-content>

    <div class="flex justify-center items-center">
      <div class="w-[40%] text-center pad-y-sm">
        <strong>Numero Documento Actual:</strong>
        <p>{{numeroDocumentoActual}}</p>
      </div>
      <div class="w-[40%] text-center pad-y-sm">
        <strong>Email Actual:</strong>
        <p class="text-break">{{emailActual == '' || emailActual == null ? 'NO REGISTRA' : emailActual}}</p>
      </div>
    </div>

    <div>
      <div>
        <ef-validate-cliente-numero-documento [width]="100" (personaEmitter)="setNumeroDocumento($event)"
          [isPerson]="true" class="pad-t-aux">
        </ef-validate-cliente-numero-documento>

        <ef-validate-email [width]="100" (emailValueEmitter)="setEmail($event)" class="pad-t-aux">
        </ef-validate-email>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close()">Cancelar
    </button>

    <button (click)="onFormSubmit()" mat-raised-button color="primary">Crear
    </button>
  </mat-dialog-actions>
</div>
