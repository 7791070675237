<mat-form-field [style.width.%]="width">
	<mat-placeholder >
		<span [ngStyle]="{'color':errors && touched?'red':'black'}">{{title}}</span>
	</mat-placeholder>
	<mat-select [required]="isRequired" type="text" matInput [disabled]="isDisabled" [(ngModel)]="value"
		[multiple]="multi" (selectionChange)="change($event)">
		<mat-option *ngFor="let item of items" [value]="item">
			<span *ngIf="!item.hasOwnProperty('nombre')">{{item | spaceUnderscore}}</span>
			<span *ngIf="item.hasOwnProperty('nombre')">{{item.nombre | spaceUnderscore}}</span>
		</mat-option>
	</mat-select>
	<mat-error *ngIf="errors">
		{{errors | json}}
	</mat-error>
</mat-form-field>
