import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from '@angular/common/http';
import {CargoAdministrativoServiceInterface} from '../cargo-administrativo.service.interface';

@Injectable({
  providedIn: 'root'
})
export class CargoAdministrativoServiceImpl extends ServiceImplService<any> implements CargoAdministrativoServiceInterface {
  constructor(public http: HttpClient) {
    super();
    this.httpClient = http;
    this.resource = 'cargo-administrativo/';
  }
}
