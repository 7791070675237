export const LOCAL_STORAGE_CONSTANTS = {
  TOKEN: 'token',
  SEDES: 'sedes',
  PERMISOS: 'permissions',
  NAME: 'name',
  LAST_LOGIN: 'lastLogin',
  SEDE_ACTUAL: 'selectedSede',
  USER: 'user',
  REDIRECT_URL: 'redirectUrl',
  SHOW_EXPIRED_TOKEN_MODAL: 'showExpiredTokenModal',
  USERNAME: 'username',
  EXPIRE_IN: 'expiresIn',
  SESSION_TEMP: 'sessionTemp'
};
