import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'ef-modal-pdfs',
  templateUrl: './modal-pdfs.component.html',
  styleUrls: ['./modal-pdfs.component.scss']
})
export class ModalPdfsComponent {

  @Input() title: string;
  @Input() deleteMessage: string;
  @Input() accept;
  @Input() cancel;
  @Input() hideAccept = true;

  constructor(
    public dialogRef: MatDialogRef<ModalPdfsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.deleteMessage = data.message;
    this.accept = data.accept;
    this.cancel = data.cancel;
    this.hideAccept = data.hideAccept;
  }
}
