import {Component, OnInit, Input, forwardRef, ViewEncapsulation, Output, EventEmitter} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
	selector: 'ef-select-generic',
	templateUrl: './select-generic.component.html',
	styleUrls: [ './select-generic.component.scss' ],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectGenericComponent),
			multi: true
		}
	],
	encapsulation: ViewEncapsulation.None
})
export class SelectGenericComponent implements ControlValueAccessor, OnInit {
	public _errors;

	item: any;
	@Input() isDisabled = false;
  @Output() concepto = new EventEmitter<any>();

	@Input() title = 'Titulo';
	@Input() width = 100;
	@Input() items: any[];
	@Input() isRequired = false;
	@Input() multi = false;
	@Input() touched = false;
	@Input()
	set errors(errors) {
		this._errors = errors;
	};


	onChange = (_: any) => {};
	onTouched = () => {};

	constructor() {}

	get value(): any {
		return this.item;
	}

	set value(v: any) {
		this.item = v;
		this.onTouched();
		this.onChange(v);
	}

	writeValue(obj: any): void {
		if (obj) {
			this.item = obj || '';
		} else {
			this.item = '';
		}
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	ngOnInit(): void {}

	get errors() {
		return this._errors;
	}

  change(event) {
    this.concepto.emit(event.value)
  }
}
