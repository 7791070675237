<div class="popup-card-width">
  <div>
    <h2 mat-dialog-title i18n>{{title}}</h2>
  </div>
  <mat-divider class="primary-border mrgn-b-xs"></mat-divider>
  <mat-dialog-content>
    <div class="flex flex-wrap">
      <div class="w-full text-center"
        *ngIf="elements.length == 0">
        <mat-icon class="jt-large-icon">mood_bad</mat-icon>
        <p>
          {{ mensajeEmpty }}
        </p>
      </div>
       <div class="w-full" >
        <ef-data-table [columnNames]="columnNames" [data]="elements" [showFilter]="false" [rowHeight]="50"
          [isFilter]="elements.length > 10 ? true:false" *ngIf="elements.length != 0 && !isLoading">
        </ef-data-table>

        <ng-template #valor let-row="row" let-value="value" class="text-center">
          <p class="mrgn-all-md">
            {{row.valor | currency:'COP':'symbol':'1.0-0'}}
          </p>
        </ng-template>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close()">
      Cerrar
    </button>
  </mat-dialog-actions>
</div>
