import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'ef-session-expired',
	templateUrl: './session-expired.component.html',
	styleUrls: [ './session-expired.component.scss' ]
})
export class SessionExpiredComponent implements OnInit {
	constructor(public dialogRef: MatDialogRef<SessionExpiredComponent>) {}

	ngOnInit() {}

	close() {
		this.dialogRef.close();
	}
}
