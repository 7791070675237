import { ServiceImplService } from '../../service.impl.service';
import { IPlantillaFirmanteServiceInterface } from "../plantilla-firmante.service.interface";
import { map } from "rxjs/operators";
import {Injectable} from '@angular/core';
import PlantillaFirmanteModel from '../../../models/configuracion-firma-electronica/plantilla-firmante.models';
import {HttpClient} from '@angular/common/http';

@Injectable({ providedIn: "root" })
export class PlantillaFirmanteServiceImpl extends ServiceImplService<PlantillaFirmanteModel> implements IPlantillaFirmanteServiceInterface {
  constructor(public  http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = "plantilla-firmante/";
	}

	getExtended(id: number): any {
		const path = `${id}/extended`;
		return this.executeGet(path).pipe(map((res: any) => res.data));
	}
}
