<form [formGroup]="formAddUbicacion" novalidate autocomplete="off" class="popup-card-width">
  <div>
    <h2 mat-dialog-title i18n>Nueva Ubicacion Geografica</h2>
  </div>
  <mat-dialog-content>

    <div>
      <div class="flex gap-[15px]">
        <div class="flex flex-col">
          <ef-select-pais [label]="'Pais'" [width]="100" [isRequired]="true" formControlName="pais">
          </ef-select-pais>
        </div>
      </div>
      <div class="flex gap-[15px]">
        <div class="flex flex-col">
          <ef-select-region [isRequired]="true" [width]="100" [label]="'Region'" formControlName="region">
          </ef-select-region>
        </div>
      </div>


      <div *ngIf="!fDepartamento">
        <div class="flex gap-[15px]">
          <div class="flex flex-col">
            <mat-form-field class="pad-t-aux">
              <input matInput type="number" formControlName="codDepartamento" maxlength="10" required>
              <mat-placeholder class="placeholder">Codigo Departamento</mat-placeholder>
              <mat-error *ngIf="form.codDepartamento.errors">
                <mat-error *ngIf="form.codDepartamento.errors.required" class="required alert-error">
                  <span i18n>Este campo es requerido</span>
                </mat-error>
                <mat-error *ngIf="!form.codDepartamento.errors.required" class="required alert-error">
                  <span i18n>{{form.codDepartamento.errors}}</span>
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="flex gap-[15px]">
        <div class="flex flex-col">
          <mat-form-field class="pad-t-aux">
            <input matInput type="text" formControlName="departamento" maxlength="100" required>
            <mat-placeholder class="placeholder">Departamento</mat-placeholder>
            <mat-error *ngIf="form.departamento.errors">
              <mat-error *ngIf="form.departamento.errors.required" class="required alert-error">
                <span i18n>Este campo es requerido</span>
              </mat-error>
              <mat-error *ngIf="!form.departamento.errors.required" class="required alert-error">
                <span i18n>{{form.departamento.errors}}</span>
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="flex gap-[15px]">
        <div class="flex flex-col">
          <mat-form-field class="pad-t-aux">
            <input matInput type="number" formControlName="codMunicipio" maxlength="10" required>
            <mat-placeholder class="placeholder">Codigo Municipio</mat-placeholder>
            <mat-error *ngIf="form.codMunicipio.errors">
              <mat-error *ngIf="form.codMunicipio.errors.required" class="required alert-error">
                <span i18n>Este campo es requerido</span>
              </mat-error>
              <mat-error *ngIf="!form.codMunicipio.errors.required" class="required alert-error">
                <span i18n>{{form.codMunicipio.errors}}</span>
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="flex gap-[15px]">
        <div class="flex flex-col">
          <mat-form-field class="pad-t-aux">
            <input matInput type="text" formControlName="municipio" maxlength="100" required>
            <mat-placeholder class="placeholder">Municipio</mat-placeholder>
            <mat-error *ngIf="form.municipio.errors">
              <mat-error *ngIf="form.municipio.errors.required" class="required alert-error">
                <span i18n>Este campo es requerido</span>
              </mat-error>
              <mat-error *ngIf="!form.municipio.errors.required" class="required alert-error">
                <span i18n>{{form.municipio.errors}}</span>
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close(false)">Cancelar
    </button>
    <button type="submit" (click)="onFormSubmit()" [disabled]="!formAddUbicacion.valid" mat-raised-button
      color="primary">Crear
    </button>
  </mat-dialog-actions>
</form>
