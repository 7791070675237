<form [formGroup]="formEdit" novalidate autocomplete="off" class="popup-card-width">
    <div>
        <h2 mat-dialog-title i18n>Editar Parámetro General</h2>
    </div>
    <mat-dialog-content>
        <div>
            <div class="full-wid">
                <mat-form-field>
                    <mat-label class="placeholder">Prioridad</mat-label>
                    <input matInput formControlName="prioridad">
                    <mat-error *ngIf="form.prioridad.touched && form.prioridad.errors">
                        <mat-error *ngIf="form.prioridad.errors.required" class="required alert-error">
                            <span i18n>Este campo es requerido</span>
                        </mat-error>
                        <mat-error *ngIf="form.prioridad.errors.numeric" class="required alert-error">
                            <span i18n>Este campo debe ser numérico</span>
                        </mat-error>
                        <mat-error *ngIf="!form.prioridad.errors.required" class="required alert-error">
                            <span i18n>{{form.prioridad.errors}}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label class="placeholder">Concepto</mat-label>
                    <input matInput formControlName="concepto">
                    <mat-error *ngIf="form.concepto.touched && form.concepto.errors">
                        <mat-error *ngIf="form.concepto.errors.required" class="required alert-error">
                            <span i18n>Este campo es requerido</span>
                        </mat-error>
                        <mat-error *ngIf="!form.concepto.errors.required" class="required alert-error">
                            <span i18n>{{form.concepto.errors}}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <ef-select-generic class="margin-selects" formControlName="formControl" [items]="formControls" [title]="'Form_Control'">
                </ef-select-generic>
                <mat-error *ngIf="form.formControl.touched && form.formControl.errors">
                  <mat-error *ngIf="form.formControl.errors.required" class="required alert-error">
                    <span i18n>Este campo es requerido</span>
                  </mat-error>
                  <mat-error *ngIf="!form.formControl.errors.required" class="required alert-error">
                    <span i18n>{{form.formControl.errors}}</span>
                  </mat-error>
                </mat-error>
                <div class="flex gap-3">
                    <mat-slide-toggle formControlName="vigencia" class="mrgn-b-sm">
                        {{form.vigencia.value ? 'VIGENTE' : 'NO VIGENTE'}}
                    </mat-slide-toggle>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
            <button type="button" mat-raised-button (click)="dialogRef.close()">Cancelar
            </button>
            <button (click)="onFormSubmit()" [disabled]="!formEdit.valid" mat-raised-button class="accept-button">Actualizar
            </button>
    </mat-dialog-actions>
</form>
