import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceImplService } from '../../service.impl.service';
import { IAcuerdoPagoDescuentoDetalleService } from '../acuerdo-pago-descuento-detalle.service.impl';
import { Observable } from 'rxjs';
import listResponseModel from 'src/app/core/models/core/list-response.model';
import ListResponse from 'src/app/core/models/core/list-response.model';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';


@Injectable({
	providedIn: 'root'
})
export class AcuerdoPagoDescuentoDetalleServiceImpl extends ServiceImplService<any> implements IAcuerdoPagoDescuentoDetalleService {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'acuerdo-pago-descuento-detalle/';
	}

    getFilterExtended(filter: any): Observable<listResponseModel<any>> {
        filter = _.omitBy(filter, _.isNil);
		return this.httpClient
			.get(this.getFullPath() + `filter-extended`, { params: filter })
			.pipe(map((res: ListResponse<any>) => res));
    }
}