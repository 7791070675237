import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import ListResponse from '../../../models/core/list-response.model';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { DocumentoFaltanteServiceInterface } from '../documento-faltante.service.interface';

@Injectable({
	providedIn: 'root'
})
export class DocumentoFaltanteServiceImpl extends ServiceImplService<any> implements DocumentoFaltanteServiceInterface {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'solicitud-aprobacion-manual-adjunto/';
	}

	getTiposDocumento(): Observable<Array<any>> {
		return this.httpClient
			.get(this.getFullPath() + `tipos-documento`)
			.pipe(map((res: ListResponse<any>) => res.data));
	}

	getFilterExtended(filter: any): Observable<ListResponse<any>> {
		filter = _.omitBy(filter, [ _.isUndefined, _.isNull, _.isEmpty ]);
		return this.httpClient
			.get(this.getFullPath() + `filterExtended`, { params: filter })
			.pipe(map((res: ListResponse<any>) => res));
	}

	getExtended(id: string): Observable<any> {
		const path = `extended/${id}`;
		return this.httpClient.get(this.getFullPath() + path).pipe(map((res: any) => res.data));
	}

	saveMasivo(data: any): Observable<any> {
		return this.httpClient.post(this.getFullPath() + `registro-masivo`, data).pipe(map((res: any) => res));
	}

	getDocumentoAdjuntoPDF(id: string): Observable<any> {
		const path = `${id}/documento-adjunto/pdf`;
    return this.executeGetPDF(path);
	}
}
