import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import GestionPasos from 'src/app/core/models/configuracion-solicitud-credito/gestion-pasos.model';

@Component({
  selector: 'ef-gestion-pasos-detail',
  templateUrl: './gestion-pasos-detail.component.html',
  styleUrls: ['./gestion-pasos-detail.component.scss']
})
export class GestionPasosDetailComponent {

  pasoDetail: GestionPasos;

  constructor(
    public dialogRef: MatDialogRef<GestionPasosDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.pasoDetail = data;
  }

}
