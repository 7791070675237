import { Injectable } from '@angular/core';
import { ServiceImplService } from '../../service.impl.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import ListResponse from '../../../models/core/list-response.model';
import * as _ from 'lodash';
import { AcuerdoPagoPlanAmortizacionServiceInterface } from '../acuerdo-pago-plan-amortizacion.service.interface';

@Injectable({
	providedIn: 'root'
})
export class AcuerdoPagoPlanAmortizacionServiceImpl extends ServiceImplService<any>
	implements AcuerdoPagoPlanAmortizacionServiceInterface {
	constructor(public http: HttpClient) {
		super();
		this.httpClient = http;
		this.resource = 'acuerdo-pago-plan-amortizacion/';
	}

	getFilterExtended(filter: any): Observable<ListResponse<any>> {
		filter = _.omitBy(filter, _.isNil);
		return this.httpClient
			.get(this.getFullPath() + `filter-extended`, { params: filter })
			.pipe(map((res: ListResponse<any>) => res));
	}
}
