<form [formGroup]="formAdd" novalidate class="!overflow-hidden">
  <div class="flex justify-center mb-2">
    <div class="flex flex-col">
      <h2 class="placeholder">{{isAprobar ? 'Aprobar Solicitud' : 'Rechazar Solicitud'}}</h2>
    </div>
  </div>
  <mat-divider class="primary-border mb-3"></mat-divider>
  <mat-dialog-content>
    <div class="flex flex-col">
      <div class="flex flex-wrap justify-between">
        <div class="w-1/2 p-2" *ngFor="let razon of razones">
          <div class="flex pb-2" *ngIf="razon != 'OTRO'">
            <mat-checkbox (change)="setData($event, razon)"> {{razon | spaceUnderscore}}</mat-checkbox>
          </div>
          <div class="flex justify-between items-center pb-2 space-x-5" *ngIf="razon == 'OTRO'">
            <div class="flex flex-col w-1/6">
              <mat-checkbox (change)="setDataOtro($event)"> {{razon | spaceUnderscore}}</mat-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-start items-start" *ngIf="isOtroSelected">
        <mat-form-field class="w-full mx-">
          <input matInput type="text" [formControl]="otroControl" class="form-control">
          <mat-placeholder class="placeholder">Otro...</mat-placeholder>
        </mat-form-field>
      </div>
      <mat-form-field class="mt-3" *ngIf="!isAprobar">
        <mat-label class="placeholder dialog-label-size">Motivo de rechazo</mat-label>
        <mat-select formControlName="motivoRechazo" class="form-control" required>
          <mat-option *ngFor="let item of motivoRechazo" [value]="item">{{ item }}</mat-option>
        </mat-select>
        <mat-error *ngIf="formAdd.get('motivoRechazo').touched && formAdd.get('motivoRechazo').errors">
          <mat-error *ngIf="formAdd.get('motivoRechazo').errors.required" class="required alert-error">
            <span i18n>Este campo es requerido</span>
          </mat-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="mrgn-b-sm mt-3">
        <textarea matInput type="text" required="true" maxlength="500" minlength="12"
          placeholder="Digite el motivo u observación..." (input)="calcularCaracteresEscritos($event)"
          formControlName="observacion" class="form-control"></textarea>
        <mat-label class="placeholder">Observación</mat-label>
        <mat-error *ngIf="formAdd.get('observacion').touched && formAdd.get('observacion').errors">
          <mat-error *ngIf="formAdd.get('observacion').errors.required" class="required alert-error">
            <span i18n>Este campo es requerido</span>
          </mat-error>
          <mat-error *ngIf="formAdd.get('observacion').errors.maxlength" class="required alert-error">
            <span i18n>La descripción debe tener como máximo 500 caracteres.</span>
          </mat-error>
          <mat-error *ngIf="formAdd.get('observacion').errors.minlength" class="required alert-error">
            <span i18n>La descripción debe tener como minimo 12 caracteres.</span>
          </mat-error>
        </mat-error>
      </mat-form-field>
      <p class="mb-2 flex justify-end text-sm">{{ caracteresEscritos }}/500</p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mrgn-b-xs pad-b-none text-right">
    <button type="button" mat-raised-button (click)="dialogRef.close()">Cancelar
    </button>
    <button #submitButton [disabled]="formAdd.invalid" (click)="onFormSubmit(); submitButton.disabled=true" type="submit" mat-raised-button color="primary">
      Guardar
  </button>
  </mat-dialog-actions>
</form>