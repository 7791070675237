import { Injectable } from '@angular/core';
import { IAuth0Service } from '../auth0.service.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { LocalStorageHelper } from 'src/app/core/helpers/local-storage.helper';
import { LOCAL_STORAGE_CONSTANTS } from 'src/app/core/constants/local-storage.constants';
import { ROUTES_CONST } from 'src/app/core/constants/routes.constants';
import SessionToken from 'src/app/core/models/security/session-token.model';
import { Login } from 'src/app/core/models/security/login.model';
import { ChangePassword } from 'src/app/core/models/security/change-password.model';
import { ChangePasswordResponse } from 'src/app/core/models/security/change-password-response.model';
import { Observable } from 'rxjs';
import { ServiceImplService } from '../../service.impl.service';
import LoginHistory from '../../../models/security/login-history.model';
import { map } from 'rxjs/operators';
import addSeconds from 'date-fns/addSeconds';
import User from '../../../models/security/user.model';
import { format, isBefore } from 'date-fns';
import { environment } from '../../../../../environments/environment';

const AUTH_URL = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  USER_INFO: 'user',
  LOGIN_HISTORY: 'loginHistory',
  CHANGE_PASS: 'changePassword',
};

@Injectable()
export class Auth0ServiceImpl
  extends ServiceImplService<Login>
  implements IAuth0Service
{
  constructor(
    public http: HttpClient,
    public localStorageHelper: LocalStorageHelper,
    public router: Router,
    public jwtHelper: JwtHelperService
  ) {
    super();
    this.httpClient = http;
    this.resource = 'security-user/';
    this.apiUrl = environment.apiUrl;
  }

  getIPAddress() {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', [
      'text/html',
      'application/xhtml+xml',
      'application/xml',
      'application/json',
    ]);
    return this.httpClient.get('https://api.ipify.org?format=json', {
      headers,
    });
  }

  login(login: Login): Observable<any> {
    // return this.httpClient.post(`${this.getFullPath()}usuario/login`, login).pipe(
    // 	map((info: any) => {
    // 		const infoUser = this.jwtHelper.decodeToken(info.access_token);
    // 		const permisions: Array<string> = String(infoUser.scope).split(' ');
    // 		let session: SessionToken = {
    // 			token: info.access_token,
    // 			permissions: permisions,
    // 			name: infoUser.name,
    // 			expiresIn: info.expires_in,
    // 			lastLogin: infoUser.previous_login,
    // 			username: infoUser.username,
    // 			email: infoUser.email
    // 		};
    // 		if (info.hasOwnProperty('key')) {
    // 			session.key = info.key;
    // 		}
    // 		return session;
    // 	})
    // );
    return this.httpClient.post(`${this.getFullPath()}usuario/login`, login);
  }

  confirmarClave(email, password, passwordKey, securityId) {
    return this.httpClient
      .put(`${this.getFullPath()}usuario/confirmar-clave`, {
        email,
        password,
        passwordKey,
        securityId,
      })
      .pipe(map((res: any) => res.data));
  }

  logout(): Observable<SessionToken> {
    return this.post(this.getSessionToken(), AUTH_URL.LOGOUT).pipe(
      map((res) => <SessionToken>res)
    );
  }

  getUserInfo(): Observable<User> {
    return this.post(this.getSessionToken(), AUTH_URL.USER_INFO).pipe(
      map((res) => <User>res)
    );
  }

  getLoginHistory(): Observable<Array<LoginHistory>> {
    return this.post(this.getSessionToken(), AUTH_URL.LOGIN_HISTORY).pipe(
      map((res) => <Array<LoginHistory>>res)
    );
  }

  closeSession(): void {
    this.localStorageHelper.removeItem(LOCAL_STORAGE_CONSTANTS.TOKEN);
    this.localStorageHelper.removeItem(LOCAL_STORAGE_CONSTANTS.PERMISOS);
    this.localStorageHelper.removeItem(LOCAL_STORAGE_CONSTANTS.LAST_LOGIN);
    this.localStorageHelper.removeItem(LOCAL_STORAGE_CONSTANTS.NAME);
    this.localStorageHelper.removeItem(LOCAL_STORAGE_CONSTANTS.EXPIRE_IN);
    this.localStorageHelper.removeItem(LOCAL_STORAGE_CONSTANTS.USERNAME);
    this.router.navigate([ROUTES_CONST.LOGIN.routerLink]);
  }

  saveToken(token) {
	const infoUser = this.jwtHelper.decodeToken(token.access_token);
	this.localStorageHelper.addItem(LOCAL_STORAGE_CONSTANTS.TOKEN, token.access_token);
	this.loadSessionInfo(token, infoUser);
}

async loadSessionInfo(token, infoUser) {
	try {
		const permisos: any = await this.httpClient.get(`${this.getFullPath()}usuario/permisos/${infoUser.username}`).toPromise()
		const permissions: Array<string> = String(permisos.data).split(' ');
		let session = new SessionToken();
		session = {
			token:token.access_token,
			permissions,
			name: infoUser.name,
			expiresIn: token.expires_in,
			lastLogin: infoUser.previous_login,
			username: infoUser.username,
			email: infoUser.email
		};
		if (token.hasOwnProperty('key')) {
			session.key = token.key;
		}
		this.initSession(session);
	} catch(e) {
		console.log(e);
	}
}

  initSession(sessionToken: SessionToken): void {
    let redirectUrl;
    if (sessionToken.key) {
      this.localStorageHelper.addItem(
        LOCAL_STORAGE_CONSTANTS.SESSION_TEMP,
        JSON.stringify(sessionToken)
      );
      redirectUrl = ROUTES_CONST.CONFIRM_PSWD.routerLink;
    } else {
      if (!redirectUrl) {
        this.localStorageHelper.addItem(
          LOCAL_STORAGE_CONSTANTS.TOKEN,
          sessionToken.token
        );
        this.localStorageHelper.addItem(
          LOCAL_STORAGE_CONSTANTS.PERMISOS,
          JSON.stringify(sessionToken.permissions)
        );
        this.localStorageHelper.addItem(
          LOCAL_STORAGE_CONSTANTS.LAST_LOGIN,
          String(sessionToken.lastLogin)
        );
        this.localStorageHelper.addItem(
          LOCAL_STORAGE_CONSTANTS.NAME,
          sessionToken.name
        );
        const expiresAt = addSeconds(new Date(), sessionToken.expiresIn);
        this.localStorageHelper.addItem(
          LOCAL_STORAGE_CONSTANTS.EXPIRE_IN,
          JSON.stringify(expiresAt)
        );
        this.localStorageHelper.addItem(
          LOCAL_STORAGE_CONSTANTS.USERNAME,
          sessionToken.username
        );
        this.router.navigate([ROUTES_CONST.HOME.routerLink]);
        redirectUrl = ROUTES_CONST.HOME.routerLink;
      } else {
        this.localStorageHelper.removeItem(
          LOCAL_STORAGE_CONSTANTS.REDIRECT_URL
        );
      }
    }
    this.router.navigate([redirectUrl]);
  }

  addUserToSession(user: User): void {
    this.localStorageHelper.addItem(
      LOCAL_STORAGE_CONSTANTS.USER,
      JSON.stringify(user)
    );
  }

  getUserFromSession(): User {
    const user = new User();
    const lastLogin = this.localStorageHelper.getItem(
      LOCAL_STORAGE_CONSTANTS.LAST_LOGIN
    );
    user.lastLogin = lastLogin !== "null"
      ? format(new Date(lastLogin), 'dd-MMM-yyyy HH:mm:ss')
      : format(new Date(), 'dd-MMM-yyyy HH:mm:ss');
    user.name = this.localStorageHelper.getItem(LOCAL_STORAGE_CONSTANTS.NAME);
    return user;
  }

  getAvailableSedes(): Array<string> {
    return JSON.parse(
      this.localStorageHelper.getItem(LOCAL_STORAGE_CONSTANTS.SEDES)
    );
  }

  getSelectedSedes(): Array<string> {
    return JSON.parse(
      this.localStorageHelper.getItem(LOCAL_STORAGE_CONSTANTS.SEDE_ACTUAL)
    );
  }

  setSelectedSedes(sedes: Array<string>): void {
    return this.localStorageHelper.addItem(
      LOCAL_STORAGE_CONSTANTS.SEDE_ACTUAL,
      JSON.stringify(sedes)
    );
  }

  hasAccess(action: string): boolean {
    if (!action) {
      return false;
    }
    const permissions = this.getPermissions();
    const wildcardAction = action.split(':')[0] + ':*';
    return permissions.includes(action) || permissions.includes(wildcardAction);
  }

  hasModule(module: string): boolean {
    if (!module) {
      return true;
    }
    const modules = this.getPermissions().map(
      (permission: String) => permission.split(':')[0]
    );
    return modules.indexOf(module) > -1;
  }

  isValidSession(): boolean {
    try {
      const token = this.getSessionToken();
      const expiresIn = this.getExpiration();
      return token && isBefore(new Date(), expiresIn);
    } catch (e) {
      return false;
    }
  }

  changePass(
    changePassword: ChangePassword
  ): Observable<ChangePasswordResponse> {
    return this.httpClient
      .put(`${this.getFullPath()}usuario/cambio-clave`, changePassword)
      .pipe(map((res) => <ChangePasswordResponse>res));
  }

  resetPass(body) {
    return this.httpClient.put(
      `${this.getFullPath()}usuario/reset-clave`,
      body
    );
  }

  getUserDashboard(): Array<string> {
    const dashboardPermisos = this.getPermissions().filter(
      (permission: string) => permission.includes(':dashboard')
    );
    return dashboardPermisos;
  }

  findPermissionListado() {
    const dashboardPermisos = this.getPermissions().filter(
      (permission: string) => permission.includes('solicitud-credito:')
    );
    return dashboardPermisos.length ? dashboardPermisos : null;
  }

  findPermissionEstudiante() {
    const dashboardPermisos = this.getPermissions().filter(
      (permission: string) => permission.includes('estudiante:dashboard')
    );
    return dashboardPermisos.length > 0 ? true : false;
  }

  public getSessionToken(): SessionToken {
    const sessionToken = new SessionToken();
    sessionToken.token = this.localStorageHelper.getItem(
      LOCAL_STORAGE_CONSTANTS.TOKEN
    );
    return sessionToken;
  }

  public getPermissions(): Array<string> {
    return JSON.parse(
      this.localStorageHelper.getItem(LOCAL_STORAGE_CONSTANTS.PERMISOS)
    );
  }

  public getExpiration() {
    const expiration = JSON.parse(
      this.localStorageHelper.getItem(LOCAL_STORAGE_CONSTANTS.EXPIRE_IN)
    );
    return new Date(expiration);
  }
}
